import {
    PurchaseOrder,
    SupplyChainMap,
} from './../../domain/models/purchase.model';
import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { ApiServer } from './api.server';
import { PurchaseOrderUpdateDataObject } from '../hooks/api/purchases/use-update-purchase';

/**
 * @class
 * @name PurchaseApiClient
 * @description The Purchase API client for LFC
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */
export class PurchaseApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'purchases', 'purchases');
    }

    async list(workspace: string, diversion: string = '0') {
        console.log(`client<purchase>| list(): Enter`);
        console.log(`client<purchase>| list(): $workspace = ${workspace}`);

        return super.get('list', workspace, diversion);
    }

    async listItems(workspace: string, diversion: string = '0') {
        console.log(`client<purchase>| list(): Enter`);
        console.log(`client<purchase>| list(): $workspace = ${workspace}`);
        return super.get('listItems', workspace, diversion);
    }

    async get(workspace: string, item: string, diversion: string = '0') {
        console.log(`client<purchase>| get(): Enter`);
        console.log(`client<purchase>| get(): $workspace = ${workspace}`);
        console.log(`client<purchase>| get(): $item = ${item}`);

        return super.get('get', workspace, item, diversion);
    }

    async create(
        workspace: string,
        purchase: PurchaseOrder,
        diversion: string = '0',
    ) {
        console.log(`client<purchase>| create(): Enter`);
        console.log(`client<purchase>| create(): $workspace = ${workspace}`);
        console.log(
            `client<purchase>| create(): $data = ${JSON.stringify(purchase)}`,
        );

        return super.post('create', workspace, diversion, purchase);
    }

    async update(
        workspace: string,
        item: string,
        purchase: PurchaseOrderUpdateDataObject,
        diversion: string = '0',
    ) {
        console.log(`client<purchase>| create(): Enter`);
        console.log(`client<purchase>| create(): $workspace = ${workspace}`);
        console.log(`client<purchase>| create(): $item = ${item}`);
        console.log(
            `client<purchase>| create(): $data = ${JSON.stringify(purchase)}`,
        );

        return super.patch('update', workspace, item, diversion, purchase);
    }

    async delete(workspace: string, item: string, diversion: string = '0') {
        console.log(`client<purchase>| delete(): Enter`);
        console.log(`client<purchase>| delete(): $workspace = ${workspace}`);
        console.log(`client<purchase>| delete(): $item = ${item}`);
        console.log(
            `client<purchase>| delete(): $diversion = ${JSON.stringify(
                diversion,
            )}`,
        );

        return super.delete('delete', workspace, item, diversion);
    }

    async trace(workspace: string, item: string, diversion: string = '0') {
        console.log(`client<purchase>| trace(): Enter`);
        console.log(`client<purchase>| trace(): $workspace = ${workspace}`);
        console.log(`client<purchase>| trace(): $item = ${item}`);

        return super.get('trace', workspace, item, diversion);
    }

    async download(workspace: string, purchaseId: string) {
        console.log(`client<purchase>| trace(): Enter`);
        console.log(`client<purchase>| trace(): $workspace = ${workspace}`);
        console.log(`client<purchase>| trace(): $purchaseId = ${purchaseId}`);

        return super.post('download', workspace, purchaseId, {});
    }

    async reverse(
        workspace: string,
        purchaseId: string,
        diversion: string = '0',
    ) {
        console.log(`client<purchase>| trace(): Enter`);
        console.log(`client<purchase>| trace(): $workspace = ${workspace}`);
        console.log(`client<purchase>| trace(): $purchaseId = ${purchaseId}`);

        return super.get('reverse', workspace, purchaseId, diversion);
    }

    async dispatch(workspace: string, payload: SupplyChainMap) {
        console.log(`client<purchase>| trace(): Enter`);
        console.log(`client<purchase>| trace(): $workspace = ${workspace}`);
        console.log(`client<purchase>| trace(): $payload = ${payload}`);

        return super.post('dispatch', workspace, payload);
    }

    async listByBatch(ids: string[]) {
        console.log(`client<purchase>| listByBatch(): Enter`);
        console.log(`client<purchase>| listByBatch(): $ids = ${ids}`);

        return super.post('listByBatch', {
            orderIds: ids,
        });
    }
}
