import { AccountApiClient } from '../../infrastructure/clients/account.server';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';

export interface AddWorkspaceRequest {}

export class AccountService {
    private server: AccountApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new AccountApiClient(config, token);
    }

    async addWorkspace(account: string, params: AddWorkspaceRequest) {
        console.log(`service<account>| addWorkspace(): Enter`);
        console.log(`service<account>| addWorkspace(): $account = ${account}`);

        return await this.server.addWorkspace(account, params);
    }
}
