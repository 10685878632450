import {
    IAddTableColumns,
    ITableProps,
    Input,
    Link,
    Modal,
    RadioChangeEvent,
    RadioGroup,
    Select,
    Subtitle,
    Table,
    Text,
    Upload,
    UploadFile,
    UploadTable,
    useForm,
} from '@ianneo/ui-library';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import certfications from '../../../app/certificates.json';
import { SupportDocType } from '../../../domain/enums/support-doctype.enum';
import { SupportDocument } from '../../../domain/models/ruleset.model';
import { SalesOrder } from '../../../domain/models/sales-order.model';
import { useUpdateOrder } from '../../../infrastructure/hooks/api/order/use-update-order';
import { useUploadOrderDocuments } from '../../../infrastructure/hooks/api/order/use-upload-order-doc';
import { usePurchase } from '../../../infrastructure/hooks/api/purchases/use-purchase';
import { getDocumentValue } from '../../../infrastructure/utils/document-type-labeler';

export interface DocumentUpload {
    id?: number;
    docId?: number;
    file: UploadFile[];
    edited: boolean;
    document?: SupportDocType;
    certificateNumber?: string;
}
export interface DocumentProps {
    selected?: string;
    open: boolean;
    order: SalesOrder;
    delegateId?: string;
    toggle: () => void;
}

interface OrderDocumentItem {
    id: string;
    document: SupportDocType;
    files: UploadFile[];
}

interface MaterialCertificateItem {
    certificate: string;
    files: UploadFile[];
}

interface OptionalDocumentItem {
    id: string;
    type: SupportDocType;
    file: UploadFile;
}

export const Documents: React.FC<DocumentProps> = ({
    selected,
    open,
    toggle,
    order,
    delegateId,
}) => {
    const { t } = useTranslation();

    const [form] = useForm<OptionalDocumentItem>();

    const [rules, setRules] = useState<SupportDocument[]>([]);
    const [files, setFiles] = useState<OrderDocumentItem[]>([]);
    const [optionalFiles, setOptionalFiles] = useState<UploadFile[]>([]);

    const [certificates, setCertificates] = useState<MaterialCertificateItem[]>(
        [],
    );
    const [removedFiles, setRemovedFiles] = useState<string[]>([]);

    const [menu, setMenu] = useState('required');
    const menuOptions = useMemo(
        () => [
            { label: 'Required Documents', value: 'required' },
            { label: 'Optional Documents', value: 'optional' },
            { label: 'Material Certification', value: 'certificates' },
        ],
        [],
    );

    const { data: purchase } = usePurchase({
        id: order.purchaseOrderId || '',
        customWorkspaceId: order.customer?.buyer?.id,
    });

    const certificatesRequired = useMemo(() => {
        const uniqueCertificates = new Set<string>();

        purchase?.manifest?.forEach((x) => {
            x.purchaseables.compositions?.forEach((y) => {
                y.certificatesRequired?.forEach((z) => {
                    uniqueCertificates.add(z);
                });
            });
        });

        return Array.from(uniqueCertificates);
    }, [purchase]);

    const { mutateAsync: upload } = useUploadOrderDocuments();
    const { mutateAsync: update } = useUpdateOrder();

    const handleMenuChange = (e: RadioChangeEvent) => {
        setMenu(e.target.value);
    };

    const onUpload = useCallback((f: UploadFile[], type: SupportDocType) => {
        setFiles((x) => {
            return x.map((y) => {
                if (y.document === type) {
                    return {
                        ...y,
                        document: y.document,
                        files: f,
                    };
                }

                return y;
            });
        });
    }, []);

    const onCertificateUpload = useCallback((f: UploadFile[], type: string) => {
        setCertificates((x) => {
            return x.map((y) => {
                if (y.certificate === type) {
                    return {
                        certificate: y.certificate,
                        files: f,
                    };
                }

                return y;
            });
        });
    }, []);

    const onOptionalUpload = useCallback(
        (f: UploadFile[]) => {
            setOptionalFiles((prev) => {
                prev.forEach((x) => {
                    if (f.find((y) => x.uid === y.uid)) return;

                    setRemovedFiles((z) => [...z, x.uid]);
                });

                return f;
            });

            const values = form.getFieldsValue(true);
            const items: OptionalDocumentItem[] = values.items
                ? values.items
                : [];

            const newItems: OptionalDocumentItem[] = [];
            f.forEach((x) => {
                const item = items.find((y) => y.id === x.uid);
                if (item) return;

                const record: OptionalDocumentItem = {
                    id: x.uid,
                    file: x,
                    type: SupportDocType.AUDIT_REPORT,
                };

                newItems.push(record);
            });

            values.items = [...items, ...newItems];
        },
        [form],
    );

    const reset = () => {
        setFiles([]);
        setRules([]);
        setCertificates([]);
        setOptionalFiles([]);

        form.resetFields();

        toggle();
    };

    const submit = async () => {
        const clonedDocs = _.cloneDeep(order.documents || []);

        // Handle order documents
        const uploads = await Promise.all(
            files.map(async (x) => {
                return await upload({
                    documents: x.files
                        .filter((y) => y.originFileObj)
                        .map((y) => ({
                            // id: Number(y.uid),
                            document: x.document,
                            file: [y],
                            edited: true,
                        })),
                    delegateId,
                });
            }),
        );

        uploads.forEach((x) => {
            clonedDocs.push(...x);
        });

        // Handle optional documents
        const { items } = form.getFieldsValue(true);

        const optionalUploads = await Promise.all(
            items
                .filter((x: OptionalDocumentItem) => {
                    return x.file.originFileObj ? true : false;
                })
                .map(async (x: OptionalDocumentItem) => {
                    const response = await upload({
                        documents: [
                            {
                                document: x.type,
                                file: [x.file],
                                edited: true,
                            },
                        ],
                        delegateId,
                    });

                    return response;
                }),
        );

        optionalUploads.forEach((x) => {
            clonedDocs.push(...x);
        });

        // Handle material certificates
        const certificateUploads = await Promise.all(
            certificates.map(async (x) => {
                const response = await upload({
                    documents: x.files
                        .filter((y) => y.originFileObj)
                        .map(
                            (y) =>
                                ({
                                    certificateNumber:
                                        certfications.find(
                                            (z) => z.name === x.certificate,
                                        )?.code || '',
                                    document:
                                        SupportDocType.PRODUCT_CERTIFICATE,
                                    file: [y],
                                    edited: true,
                                } satisfies DocumentUpload),
                        ),
                    delegateId,
                });

                return response;
            }),
        );

        certificateUploads.forEach((x) => {
            clonedDocs.push(...x);
        });

        await update({
            id: order.id || '',
            delegateId,
            args: {
                documents: clonedDocs
                    .filter(
                        (x) => !removedFiles.includes(x.id?.toString() || ''),
                    )
                    .map((x) => {
                        const found = items.find(
                            (y: OptionalDocumentItem) =>
                                x.id?.toString() === y.id?.toString(),
                        );

                        return {
                            ...x,
                            type: found ? found.type : x.type,
                        };
                    }),
            },
        });

        toggle();
    };

    const orderColumns: ITableProps<OrderDocumentItem>['columns'] = useMemo(
        () => [
            {
                title: t('order:modal.document.name'),
                width: '50%',
                render: (_: any, item: OrderDocumentItem) => (
                    <Text>
                        {item.document ? getDocumentValue(item.document) : ''}
                    </Text>
                ),
            },
            {
                title: t('common:upload'),
                width: '50%',
                render: (_: any, item: OrderDocumentItem) => {
                    return (
                        <Upload
                            files={item.files}
                            showUploadList
                            onChange={({ file, fileList }) => {
                                if (file.status === 'removed') {
                                    setRemovedFiles((x) => [...x, file.uid]);
                                }

                                onUpload(fileList, item.document);
                            }}
                        >
                            <Link>{t('order:modal.document.upload')}</Link>
                        </Upload>
                    );
                },
            },
        ],
        [t, onUpload],
    );

    const certificateColumns: ITableProps<MaterialCertificateItem>['columns'] =
        useMemo(
            () => [
                {
                    title: t('order:modal.document.name'),
                    width: '50%',
                    render: (_: any, item: MaterialCertificateItem) => (
                        <Text>{item.certificate}</Text>
                    ),
                },
                {
                    title: t('common:upload'),
                    width: '50%',
                    render: (_: any, item: MaterialCertificateItem) => {
                        return (
                            <Upload
                                files={item.files}
                                showUploadList
                                onChange={({ file, fileList }) => {
                                    if (file.status === 'removed') {
                                        setRemovedFiles((x) => [
                                            ...x,
                                            file.uid,
                                        ]);
                                    }

                                    onCertificateUpload(
                                        fileList,
                                        item.certificate,
                                    );
                                }}
                            >
                                <Link>{t('order:modal.document.upload')}</Link>
                            </Upload>
                        );
                    },
                },
            ],
            [t, onCertificateUpload],
        );

    const optionalColumns: IAddTableColumns<any>[] = useMemo(
        () => [
            {
                title: 'Name',
                dataIndex: ['file', 'name'],
                component: <Input readOnly />,
            },
            {
                title: 'Document Type',
                dataIndex: 'type',
                width: '50%',
                component: (
                    <Select
                        options={Object.values(SupportDocType)
                            .filter(
                                (x) =>
                                    x !== SupportDocType.PRODUCT_CERTIFICATE &&
                                    !rules.find((y) => y.document === x),
                            )
                            .map((x) => ({
                                label: getDocumentValue(x),
                                value: x,
                            }))}
                        getPopupContainer={undefined}
                    />
                ),
            },
        ],
        [rules],
    );

    useEffect(() => {
        const newRules = new Set<SupportDocument>();
        //const supplyChainNodeType = purchase?.owner?.purchaseProcesses || purchase?.owner?.supplier?.seller?.processes || [];
        // corrected this line since purchaseProcesses maybe null if imported from XTS
        const supplyChainNodeType = purchase?.owner?.purchaseProcesses && purchase?.owner?.purchaseProcesses.length > 0 ?
            purchase?.owner?.purchaseProcesses : (
                purchase?.owner?.supplier?.seller?.processes ?? []
            );
        console.log(`***test***: supplyChainNodeType: ${JSON.stringify(supplyChainNodeType)}`);

        if (!purchase) return;

        purchase?.owner?.rules?.forEach((x) => {
            x.documentation?.forEach((y) => {
                if (!y.appliesTo) return;

                // This does a check to see if the rules apply to the company supply chain type
                const appliesTo = y.appliesTo.map((x) => x.toString());
                const intersection = supplyChainNodeType.filter((x) =>
                    appliesTo.includes(x),
                );

                if (intersection.length === 0) return;

                newRules.add(y);
            });
        });

        setRules(Array.from(newRules));
    }, [open, purchase]);

    useEffect(() => {
        if (rules.length === 0) return;

        setFiles(
            rules
                .filter((x) => x.document)
                .map((x) => {
                    return {
                        id: x.id?.toString() || '',
                        document: x.document,
                        files: (order.documents || [])
                            ?.filter((y) => y.type === x.document)
                            .map(
                                (y) =>
                                    ({
                                        uid: y.id?.toString() || '',
                                        name: y.file.originalName,
                                    } satisfies UploadFile),
                            ),
                    };
                }),
        );
    }, [rules, order, open]);

    useEffect(() => {
        setCertificates(
            certificatesRequired.map((x) => {
                const existingFiles = (order.documents || [])
                    .filter(
                        (y) =>
                            y.type === SupportDocType.PRODUCT_CERTIFICATE &&
                            y.certificateNumber ===
                                certfications.find((z) => z.name === x)?.code,
                    )
                    .map(
                        (y) =>
                            ({
                                uid: y.id?.toString() || '',
                                name: y.file.originalName,
                            } satisfies UploadFile),
                    );

                return { certificate: x, files: [...existingFiles] };
            }),
        );
    }, [certificatesRequired, open, order.documents]);

    useEffect(() => {
        if (!form) return;

        const items: OptionalDocumentItem[] = [];

        order.documents
            ?.filter((x) => {
                const found = rules.find((y) => y.document === x.type);

                return found ? false : true;
            })
            .forEach((x) => {
                if (x.type === SupportDocType.PRODUCT_CERTIFICATE) return;

                items.push({
                    id: x.id?.toString() || '',
                    type: x.type,
                    file: {
                        uid: x.id?.toString() || '',
                        name: x.file.originalName,
                    },
                });
            });

        form.setFieldValue('items', items);
        setOptionalFiles(items.map((x) => x.file));
    }, [form, open, order.documents, rules]);

    useEffect(() => {}, [form, order]);

    return (
        <>
            <Modal
                open={open}
                title={t('order:modal.document.title')}
                okFn={submit}
                // closable={false}
                cancelFn={reset}
                width={1000}
            >
                <RadioGroup
                    optionType="button"
                    options={menuOptions}
                    value={menu}
                    onChange={handleMenuChange}
                ></RadioGroup>

                {menu === 'required' && (
                    <Table
                        showColumns={false}
                        showFilter={false}
                        showSearch={false}
                        columns={orderColumns}
                        dataSource={files}
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                    />
                )}

                {menu === 'optional' && (
                    <>
                        <div style={{ marginTop: '15px' }}></div>
                        <UploadTable
                            form={form}
                            files={optionalFiles}
                            setFiles={onOptionalUpload}
                            columns={optionalColumns}
                            scroll={{ x: 'max-content' }}
                            style={{ marginTop: '15px' }}
                            footer={() => (
                                <Subtitle>
                                    * Do note that the file name is read only
                                </Subtitle>
                            )}
                        />
                    </>
                )}

                {menu === 'certificates' && (
                    <Table
                        showColumns={false}
                        showFilter={false}
                        showSearch={false}
                        columns={certificateColumns}
                        dataSource={certificates}
                        pagination={false}
                        scroll={{ x: 'max-content' }}
                    />
                )}
            </Modal>
        </>
    );
};
