import { useQuery } from '@tanstack/react-query';
import { Supplier } from '../../../../domain/models/supplier.model';
import { PartnerService } from '../../../../domain/services/partner.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePartnerService } from './use-partner-service';

const getPartners = async (
    service: PartnerService,
    id: string,
): Promise<Supplier[]> => {
    const response = await service.list(id);

    return response;
};

export function usePartners(workspaceId?: string) {
    const context = useAppContext();
    const { service } = usePartnerService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'partners',
            { workspace: workspaceId ? workspaceId : context.workspace?.id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id) return;
            return await getPartners(
                service,
                workspaceId ? workspaceId : context.workspace.id,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch partners information: ${error.message}`,
            });
        },
    });
}
