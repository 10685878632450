import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CompanyDocumentType } from '../../../../../domain/enums/company-doctype.enum';
import { useAlertContext } from '../../../use-alert.hook';
import useAppContext from '../../../use-context.hook';
import { useCompanyDocumentService } from './use-document-service';

export interface UploadDocumentsInterface {
    id: string;
    params: UpdateCompanyDocumentParams;
}

export interface UpdateCompanyDocumentParams {
    comment?: string;
    isPrivate?: boolean;
    nature: CompanyDocumentType;
}

/**
 * Upload certification API which will be updated when any of the parameters in the [] array changes
 * Also will set the refresh state to true which will trigger the useEffect hook to fetch the documents
 */
export function useUpdateDocument() {
    const context = useAppContext();
    const { service: documentService } = useCompanyDocumentService();
    const { setAlert } = useAlertContext();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, params }: UploadDocumentsInterface) => {
            if (!context.workspace?.id) return;

            await documentService.update(context.workspace.id, id, params);
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: ['companyDocuments', { type: context.workspace?.id }],
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to upload documents: ${error.message}`,
                title: 'Upload Company Documents',
            });
        },
    });
}
