import { useQuery } from '@tanstack/react-query';
import { Workspace } from '../../../../domain/models/workspace.model';
import { WorkspaceService } from '../../../../domain/services/workspace.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useWorkspaceService } from './use-workspace-service';

const getWorkspaceByCompanyName = async (
    service: WorkspaceService,
    name: string,
): Promise<Workspace[]> => {
    const response = await service.getByCompanyName(name);

    return response;
};

export function useWorkspaceByCompanyName(workspaceId: string, name: string) {
    const context = useAppContext();
    const { service } = useWorkspaceService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'workspaceByCompanyName',
            { type: workspaceId, input: name },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !name) return [];
            return await getWorkspaceByCompanyName(service, name);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch workspace by company name: ${error.message}`,
            });
        },
    });
}
