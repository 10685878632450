import { BellOutlined } from '@ant-design/icons';
import {
    Badge,
    Empty,
    EmptySimple,
    List,
    ListItem,
    ListItemMeta,
    Popover,
} from '@ianneo/ui-library';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useEffect } from 'react';
import AppContext from '../../../domain/models/app.context.model';
import { NotificationMessage } from '../../../domain/models/notification-message.model';
import { socket } from '../../../infrastructure/clients/socket';
import { useNotifications } from '../../../infrastructure/hooks/api/notifications/use-notifications';

interface NotificationsComponentProps {
    styles?: React.CSSProperties;
}

export const Notifications: React.FC<NotificationsComponentProps> = ({
    styles,
}) => {
    const { data } = useNotifications();
    const context = useContext(AppContext);
    const client = useQueryClient();

    const renderNotificationContent = () => {
        return (
            <>
                <div
                    style={{
                        maxHeight: '150px',
                        overflowY: 'auto',
                        width: '250px',
                        height: '150px',
                        ...styles,
                    }}
                >
                    {(data || [])?.filter((x) => !x.readOn).length > 0 ? (
                        <List
                            dataSource={data?.filter((x) => !x.readOn)}
                            renderItem={(item: NotificationMessage, index) => {
                                return (
                                    <ListItem key={index}>
                                        <ListItemMeta
                                            style={{ padding: '8px' }}
                                            title={
                                                item.title?.locales?.find(
                                                    (x) =>
                                                        x.localeName === 'en',
                                                )?.text
                                            }
                                            description={
                                                item.contents?.locales?.find(
                                                    (x) =>
                                                        x.localeName === 'en',
                                                )?.text
                                            }
                                        ></ListItemMeta>
                                    </ListItem>
                                );
                            }}
                        ></List>
                    ) : (
                        <Empty image={EmptySimple}></Empty>
                    )}
                </div>
            </>
        );
    };

    const checkWorkspace = useCallback(
        (id: string) => context.workspace?.id === id,
        [context.workspace?.id],
    );

    useEffect(() => {
        function onConnect() {
            console.log(`socket: connecting`);
            socket.emit('get_notifications', {
                workspace: context.workspace?.id,
                user: context.user?.id,
            });
        }

        function onDisconnect() {
            console.log(`socket: disconnecting`);
        }

        function onNewNotification(value: NotificationMessage) {
            console.log(`socket: received new value ${JSON.stringify(value)}`);
            if (!value.owner?.workspace?.id) return;

            if (checkWorkspace(value.owner.workspace.id)) {
                client.setQueriesData(
                    ['notifications', { workspace: context.workspace?.id }],
                    (data: any) => {
                        return [...data, value];
                    },
                );
            }
        }

        function onReadNotification(value: any) {
            console.log(`socket: received read value ${JSON.stringify(value)}`);
        }

        function onGetNotifications(value: any) {
            console.log(`socket: received value ${JSON.stringify(value)}`);
        }

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        socket.on('return_notifications', onGetNotifications);
        socket.on('read_notification', onReadNotification);
        socket.on('new_notification', onNewNotification);

        socket.connect();

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            socket.off('new_notification', onNewNotification);
            socket.off('read_notification', onReadNotification);
            socket.off('return_notifications', onGetNotifications);
        };
    }, [context.user?.id, context.workspace?.id, checkWorkspace, client]);

    return (
        <Popover
            autoAdjustOverflow
            trigger={['hover']}
            content={renderNotificationContent()}
        >
            <Badge count={data?.filter((x) => !x.readOn).length}>
                <BellOutlined />
            </Badge>
        </Popover>
    );
};
