import {
    Form,
    FormInstance,
    FormItem,
    Input,
    Modal,
    useForm,
} from '@ianneo/ui-library';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface OnboardingContactProps {
    form: FormInstance;
    open: boolean;
    name: string;
    setOpen: (open: boolean) => void;
}

export default function OnboardingContact(props: OnboardingContactProps) {
    const { form, open, name, setOpen } = props;
    const { t } = useTranslation();
    const [contactForm] = useForm();

    const submit = () => {
        form.setFieldValue(name, contactForm.getFieldsValue(true));

        reset();
    };

    const reset = () => {
        contactForm.resetFields();
        setOpen(false);
    };

    useEffect(() => {
        contactForm.resetFields();
        const values = form.getFieldValue(name);
        contactForm.setFieldsValue(values);
    }, [open, form, contactForm, name]);

    return (
        <>
            <Modal
                title={t('onboarding:title')}
                open={open}
                okFn={submit}
                cancelFn={reset}
            >
                <Form form={contactForm}>
                    <FormItem label={t('onboarding:email')} name="email">
                        <Input />
                    </FormItem>

                    <FormItem
                        label={t('onboarding:firstName')}
                        name="firstName"
                    >
                        <Input />
                    </FormItem>

                    <FormItem label={t('onboarding:lastName')} name="lastName">
                        <Input />
                    </FormItem>

                    <FormItem
                        label={t('onboarding:phone')}
                        name="phone"
                        rules={[
                            {
                                pattern: /^\+?[ 1-9][0-9]{7,14}$/,
                                message:
                                    'Please enter a valid phone number, include country code!',
                            },
                        ]}
                    >
                        <Input />
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
}
