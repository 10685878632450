import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { InfoCard } from '@ianneo/component-library';
import {
    Button,
    Card,
    DescriptionItems,
    Descriptions,
    Popover,
    RadioGroup,
    Subtitle,
    Text,
    Title,
} from '@ianneo/ui-library';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from '../../../domain/models/company.model';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';
import useVerifyAdmin from '../../../infrastructure/hooks/use-verify-admin';
import { DescripionItemType } from '../Partners/partner-detail/partner-detail.layout';
import Certifications from './certifications';
import Documents from './documents';
import { Template } from './templates/templates.component';
import { getProcessValue } from '../../../infrastructure/utils/process-labeler';

interface CompanyLayoutProps {
    company: Company;
    menu: 'details' | 'documents' | 'certifications' | 'templates';
    menuOptions: any[];
    setMenu: (value: 'details' | 'documents' | 'certifications') => void;
    setEditMode: (value: boolean) => void;
}

const CompanyLayout: React.FC<CompanyLayoutProps> = ({
    company,
    menu,
    setMenu,
    setEditMode,
    menuOptions,
}) => {
    const { t } = useTranslation();
    const { isAdmin } = useVerifyAdmin();
    const context = useAppContext();

    const descriptions: DescripionItemType[] = useMemo(
        () => [
            { label: t('company:details.entityName'), value: company?.name },
            { label: t('company:details.higgId'), value: company?.higg },
            { label: t('company:details.companyUrl'), value: company?.website },
            {
                label: t('company:details.lfSupplierCode'),
                value: company?.lfSupplierCode,
            },
            { label: t('company:details.capacity'), value: company?.capacity },
            { label: t('company:details.oarId'), value: company?.oar },
            {
                label: t('company:details.contactBusiness'),
                value: company?.contactBusiness?.phone,
            },
            {
                label: t('company:details.contactCompliance'),
                value: company?.contactCompliance?.phone,
            },
            {
                label: t('company:details.complianceLevel'),
                value: company?.complianceLevel,
            },
            {
                label: t('company:details.contactEmail'),
                value: context?.workspace?.account?.admin,
            },
            {
                label: t('company:details.complianceRating'),
                value: company?.complianceRating,
            },
            {
                label: t('company:details.complianceExpiresOn'),
                value: company?.complianceExpiresOn
                    ? moment(company?.complianceExpiresOn).format('DD/MM/YYYY')
                    : '',
            },
            { label: t('company:details.country'), value: company?.country },
            { label: t('company:details.dbaName'), value: company?.dbaName },
            {
                label: t('company:details.dbaRegistration'),
                value: company?.dbaRegistration,
            },
            {
                label: t('company:details.dbaReference'),
                value: company?.dbaReference,
            },
            {
                label: t('company:details.employees'),
                value: company?.employees,
            },
            { label: t('company:details.gln'), value: company?.gln },
            { label: t('company:details.phoneNumber'), value: company?.phone },
            { label: t('company:details.city'), value: company?.city },
            { label: t('company:details.duns'), value: company?.duns },
            {
                label: t('company:details.address'),
                value: company?.addressLine1,
            },
            {
                label: t('company:details.brNo'),
                value: company?.registrationNumber,
            },
            { label: t('company:details.vatId'), value: company?.vat },
            { label: t('company:details.zhdcId'), value: company?.zdhcId },
            {
                label: t('company:details.memberSince'),
                value: company?.createdOn
                    ? new Date(company?.createdOn).toLocaleDateString()
                    : undefined,
            },
        ],
        [company, t, context.workspace?.account],
    );

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '8px',
                            backgroundColor: '#972d47',
                            minHeight: '40px',
                            minWidth: '8px',
                        }}
                    ></div>
                    <Title>{t('company:name')}</Title>
                    <Subtitle>{t('company:description')}</Subtitle>
                </div>

                <InfoCard
                    showIcon
                    tags={
                        company?.supplyChainNodeType?.map((x) =>
                            getProcessValue(x),
                        ) || []
                    }
                    infoTitle={
                        <div
                            style={{
                                display: 'flex',
                                gap: '12px',
                                alignItems: 'center',
                            }}
                        >
                            <Text style={{ alignSelf: 'unset' }}>
                                {company.name}
                            </Text>
                            {company?.isProfileCompleted === true ? (
                                <Popover
                                    style={{ color: 'green' }}
                                    placement="right"
                                    content={
                                        <Text
                                            style={{
                                                color: 'green',
                                                alignSelf: 'unset',
                                            }}
                                        >
                                            {t('company:activated')}
                                        </Text>
                                    }
                                >
                                    <CheckCircleOutlined
                                        style={{ color: 'green' }}
                                    />
                                </Popover>
                            ) : (
                                <Popover
                                    placement="right"
                                    trigger={'click'}
                                    content={
                                        <Text
                                            style={{
                                                color: 'grey',
                                                alignSelf: 'unset',
                                            }}
                                        >
                                            {t('company:notActivated')}
                                        </Text>
                                    }
                                >
                                    <CloseCircleOutlined
                                        style={{ color: 'grey' }}
                                    />
                                </Popover>
                            )}
                        </div>
                    }
                    information={
                        <>
                            <Text
                                style={{
                                    alignSelf: 'unset',
                                }}
                            >
                                {t('company:header.supplierCode')}:{' '}
                                {company?.lfSupplierCode
                                    ? company.lfSupplierCode
                                    : 'N/A'}
                            </Text>

                            <Text
                                style={{ fontSize: '12px', alignSelf: 'unset' }}
                            >
                                {t('company:header.introduction')}:{' '}
                                {company?.introduction
                                    ? company.introduction
                                    : 'N/A'}
                            </Text>
                        </>
                    }
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <RadioGroup
                            options={menuOptions}
                            defaultValue={menu}
                            onChange={(e) => setMenu(e.target.value)}
                            optionType="button"
                        />
                        <Button
                            hidden={isAdmin}
                            // mode="create"
                            onClick={() => setEditMode(true)}
                        >
                            {t('common:edit')}
                        </Button>
                    </div>

                    {menu === 'details' ? (
                        <>
                            <Card>
                                <Descriptions
                                // items={descriptions}
                                >
                                    {descriptions.map((x, i) => (
                                        <DescriptionItems
                                            key={i}
                                            label={x.label}
                                            span={x.span}
                                        >
                                            {x.value}
                                        </DescriptionItems>
                                    ))}
                                </Descriptions>
                            </Card>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    gap: '32px',
                                }}
                            >
                                <Card
                                    style={{ flex: 1, minHeight: '200px' }}
                                    title={t(
                                        'company:details.overallPerformance',
                                    )}
                                ></Card>
                                <Card
                                    style={{ flex: 1 }}
                                    title={t(
                                        'company:details.networkRiskDetection',
                                    )}
                                ></Card>
                            </div>
                        </>
                    ) : null}
                </div>

                {menu === 'documents' ? <Documents /> : null}

                {menu === 'certifications' ? <Certifications /> : null}

                {menu === 'templates' ? <Template /> : null}
            </div>
        </>
    );
};

export default CompanyLayout;
