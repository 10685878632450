import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImportBatch } from '../../../infrastructure/hooks/api/file/use-import-batch';
import ImportDownloadTemplate from './import-download-template';
import ImportUploadTemplate from './import-upload-template';
import { StepModal, UploadFile } from '@ianneo/ui-library';

interface ImportProps {
    extension?: 'csv' | 'xlsx';
    type: string;
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function Import({
    extension = 'xlsx',
    type,
    open,
    setOpen,
}: ImportProps) {
    const { t } = useTranslation();
    const Document = require(`/public/templates/${type}_template.${extension}`);

    const [files, setFiles] = useState<UploadFile[]>([]);

    const { mutateAsync: importBatch } = useImportBatch();

    const reset = () => {
        setOpen(false);

        setFiles([]);
    };

    const submit = async () => {
        if (!files.length || files.length < 1) return;

        const file = files[0];

        await importBatch({
            file,
            type,
            params: { contentType: file?.type || '', filename: file.name },
        });

        reset();
    };

    return (
        <>
            <StepModal
                title={t('user:listing.user.importTitle')}
                open={open}
                width={'60vw'}
                bodyStyle={{
                    height: '30vh',
                }}
                cancelFn={reset}
                okFn={submit}
                stepContent={[
                    {
                        title: t('user:listing.user.downloadTemplate'),
                        content: (
                            <ImportDownloadTemplate
                                type={type}
                                extension={extension}
                                document={Document}
                            />
                        ),
                    },
                    {
                        title: t('user:listing.user.uploadFile'),
                        content: (
                            <ImportUploadTemplate
                                files={files}
                                setFiles={setFiles}
                            />
                        ),
                    },
                ]}
            ></StepModal>
        </>
    );
}
