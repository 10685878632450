import { useQuery } from '@tanstack/react-query';
import { PurchaseOrderVersion } from '../../../../domain/models/purchase.model';
import { SalesOrder } from '../../../../domain/models/sales-order.model';
import { OrderService } from '../../../../domain/services/order.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseService } from '../purchases/use-purchase-service';
import { useOrderService } from './use-order-service';

interface UseOrderInterface {
    id?: string;
    enabled?: boolean;
    delegateId?: string;
}

const getOrder = async (
    service: OrderService,
    workspace: string,
    id: string,
): Promise<SalesOrder> => {
    const response = await service.getPo(workspace, id);

    return response;
};

export function useOrder({
    id,
    enabled = true,
    delegateId,
}: UseOrderInterface) {
    const context = useAppContext();
    const { service } = useOrderService();
    const { service: purchaseService } = usePurchaseService();
    const { setAlert } = useAlertContext();

    return useQuery<SalesOrder>({
        queryKey: [
            'order',
            { workspace: delegateId || context.workspace?.id, id: id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !id) return {};

            const order = await getOrder(
                service,
                delegateId || context.workspace.id,
                id,
            );

            const purchase = (await purchaseService.get(
                order.customer?.buyer?.id || '',
                order.purchaseOrderId || '',
                '1',
            )) as PurchaseOrderVersion;

            return {
                ...order,
                purchaseProcesses: purchase.owner?.purchaseProcesses,
            };
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch order information: ${error.message}`,
            });
        },
        enabled: enabled,
    });
}
