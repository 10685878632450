import { WorkspaceApiClient } from '../../infrastructure/clients/workspace.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { Feature } from '../models/feature.model';
import { Workspace } from '../models/workspace.model';

export class WorkspaceService {
    private server: WorkspaceApiClient;

    constructor(
        config: ServiceConfigInterface,
        private readonly token: string,
    ) {
        this.server = new WorkspaceApiClient(config, token);
    }

    static convert(source: any): Workspace {
        console.log(`service<workspace>| convert(): Enter`);
        console.log(
            `service<workspace>| convert(): $source = ${JSON.stringify(
                source,
            )}`,
        );

        return source;
    }

    async get(workspace: string, diversion: string = '0') {
        console.log(`service<workspace>| get(): Enter`);
        console.log(`service<workspace>| get(): $workspace = ${workspace}`);
        return await this.server.get(workspace);
    }

    async getByCompanyName(name: string, diversion: string = '0') {
        console.log(`service<workspace>| getByCompanyName(): Enter`);
        console.log(`service<workspace>| getByCompanyName(): $name = ${name}`);
        console.log(
            `service<workspace>| getByCompanyName(): $diversion = ${diversion}`,
        );
        const response: any[] = await this.server.getByCompanyName(
            name,
            diversion,
        );
        return response.map((workspace) => WorkspaceService.convert(workspace));
    }

    async getMembers(workspace: string) {
        console.log(`service<workspace>| getMembers(): Enter`);
        console.log(
            `service<workspace>| getMembers(): $workspace = ${workspace}`,
        );

        return await this.server.getMembers(workspace);
    }

    async getFeatures(): Promise<Feature[]> {
        console.log(`service<workspace>| getFeatures(): Enter`);
        return await this.server.getFeatures();
    }

    async getRoleInfo(workspace: string, id: string) {
        console.log(`service<workspace>| getRoleInfo(): Enter`);
        console.log(
            `service<workspace>| getRoleInfo(): $workspace = ${workspace}`,
        );
        console.log(`service<workspace>| getRoleInfo(): $id = ${id}`);

        return await this.server.getRoleInfo(workspace, id);
    }

    async verifyRegistrationCode(code: string, country: string) {
        console.log(`service<workspace>| verifyRegistrationCode(): Enter`);
        console.log(
            `service<workspace>| verifyRegistrationCode(): $code = ${code}`,
        );
        console.log(
            `service<workspace>| verifyRegistrationCode(): $country = ${country}`,
        );

        return await this.server.verifyRegistration(code, country);
    }
}
