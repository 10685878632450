import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useAwardService } from './use-award-service';

export function useDeleteAward() {
    const context = useAppContext();
    const { service } = useAwardService();
    const { setAlert } = useAlertContext();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (id: string) => {
            if (!id || !context.workspace?.id) return;

            await service.delete(context.workspace.id, id);
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: ['companyAwards'],
            });

            setAlert({
                type: 'success',
                message: 'Award deleted successfully',
                title: 'Delete Award',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to delete award: ${error.message}`,
                title: 'Delete Award',
            });
        },
    });
}
