import { ArrowRightOutlined } from '@ant-design/icons';
import {
    Card,
    Empty,
    EmptySimple,
    Link,
    List,
    ListItem,
    ListItemMeta,
    RadioGroup,
    Space,
} from '@ianneo/ui-library';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Task } from '../../../../domain/models/task.model';
import { useTasks } from '../../../../infrastructure/hooks/api/task/use-tasks';

interface ActivitiesComponentProps {}

const ActivitiesComponent: React.FC<ActivitiesComponentProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [menu, setMenu] = useState<'updates' | 'alerts' | 'news'>('alerts');
    const menuOptions = useMemo(
        () => [
            // { label: t('dashboard:activities.updates'), value: 'updates' },
            { label: t('dashboard:activities.alerts'), value: 'alerts' },
            // { label: t('dashboard:activities.news'), value: 'news' },
        ],
        [t],
    );

    const { data } = useTasks();

    const onChange = (value: any) => {
        setMenu(value.target.value);
    };

    const handleRedirect = (item: Task) => {
        if (item.sourceType?.toLocaleLowerCase().includes('order')) {
            navigate(`/orders/${item.sourceIdentity}`);
        }

        if (item.sourceType?.toLocaleLowerCase().includes('purchase')) {
            navigate(`/purchases/${item.sourceIdentity}`);
        }
    };

    const renderListItem = (item: Task, index: number) => {
        return (
            <ListItem
                key={index}
                style={{
                    backgroundColor: '#f2f2f2',
                }}
            >
                <ListItemMeta
                    style={{
                        padding: '8px',
                    }}
                    title={
                        <Link>
                            <Space
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                                onClick={() => handleRedirect(item)}
                            >
                                {
                                    item.title?.locales?.find(
                                        (x) => x.localeName === 'en',
                                    )?.text
                                }

                                <ArrowRightOutlined
                                    style={{
                                        flex: 1,
                                        color: '#972D47',
                                    }}
                                />
                            </Space>
                        </Link>
                    }
                    description={
                        item.content?.locales?.find(
                            (x) => x.localeName === 'en',
                        )?.text
                    }
                ></ListItemMeta>
            </ListItem>
        );
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                    flex: 1,
                    height: '100%',
                }}
            >
                <RadioGroup
                    options={menuOptions}
                    onChange={onChange}
                    optionType="button"
                    defaultValue={menu}
                />

                <Card
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                    bodyStyle={{
                        display: 'flex',
                        flex: 1,
                        padding: '8px',
                    }}
                >
                    {menu === 'updates' ? (
                        <>
                            <Empty image={EmptySimple} />
                        </>
                    ) : null}

                    {menu === 'alerts' ? (
                        <>
                            <List
                                style={{ width: '100%' }}
                                dataSource={data?.filter(
                                    (x) =>
                                        x.sourceType !== 'VerificationRequsest',
                                )}
                                renderItem={renderListItem}
                            ></List>
                        </>
                    ) : null}

                    {menu === 'news' ? (
                        <>
                            <Empty image={EmptySimple} />
                        </>
                    ) : null}
                </Card>
            </div>
        </>
    );
};

export default ActivitiesComponent;
