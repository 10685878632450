import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    LinkOutlined,
} from '@ant-design/icons';
import {
    AddTable,
    CollapsibleDropdown,
    Form,
    FormItem,
    InputNumber,
    Link,
    Modal,
    Popconfirm,
    Select,
    Space,
    Table,
    Text,
    useForm,
} from '@ianneo/ui-library';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { utils, writeFileXLSX } from 'xlsx';
import { getMaterialValue } from '../../../../../domain/enums/material-type.enum';
import { UnitType } from '../../../../../domain/enums/unit-type.enum';
import { ProductMaterial } from '../../../../../domain/models/material.model';
import {
    Composition,
    ProductVersion,
} from '../../../../../domain/models/product.model';
import { useMaterials } from '../../../../../infrastructure/hooks/api/materials/use-materials';
import { useLinkProductMaterial } from '../../../../../infrastructure/hooks/api/products/use-link-product-material';
import { useUpdateProduct } from '../../../../../infrastructure/hooks/api/products/use-update-product';
import useVerifyAdmin from '../../../../../infrastructure/hooks/use-verify-admin';
import { getUnitValue } from '../../../../../infrastructure/utils/unit-type-labeler';

interface ProductMaterialsComponentProps {
    data: ProductVersion;
}

const ProductMaterialsComponent: React.FC<ProductMaterialsComponentProps> = (
    args,
) => {
    const { t } = useTranslation();
    const { delegateId } = useParams();
    const { isAdmin } = useVerifyAdmin();
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [addForm] = useForm();
    const [editForm] = useForm();
    const [editData, setEditData] = useState<Composition>();
    const [newMaterials, setNewMaterials] = useState<ProductMaterial[]>([]);
    console.log(args);

    const { data: materials } = useMaterials(delegateId);
    const { mutateAsync: link } = useLinkProductMaterial();
    const { mutateAsync: update } = useUpdateProduct();

    const exportMaterials = useCallback(() => {
        if (!args.data.compositions) return;
        const { compositions } = args.data;

        const flattendedData = compositions.map((x) => {
            const { material, certificatesRequired, ...rest } = x;

            return {
                ...rest,
                name: material?.name,
                description: material?.description,
                certificatesRequired: certificatesRequired?.join(','),
            };
        });

        const ws = utils.json_to_sheet(flattendedData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Data');
        writeFileXLSX(wb, `Materials-${new Date().getTime()}.xlsx`);
    }, [args.data]);

    const handleRemoveMaterial = useCallback(
        async (item: ProductVersion) => {
            const composition = args.data.compositions?.filter(
                (x) => x.id !== item.id,
            );

            await update({
                id: args.data.product?.id || '',
                args: {
                    components: composition,
                    name: args.data.product?.name,
                    cost: args.data.cost,
                    weight: args.data.weight,
                },
                delegateId,
            });
        },
        [args.data, update, delegateId],
    );

    const filteredMaterials = useMemo(
        () =>
            materials?.filter(
                (x) =>
                    !args.data.compositions?.some(
                        (y) => y.material?.id === x.id,
                    ),
            ),
        [materials, args.data.compositions],
    );

    const addColumns = [
        {
            title: t('product:materials.modal.link.materialName'),
            dataIndex: 'material',
            key: 'material',
            component: (
                <Select
                    options={filteredMaterials?.map((x) => ({
                        label: x.name,
                        value: x.id,
                        key: x.id,
                    }))}
                />
            ),
            required: true,
            rules: [],
            width: '20%',
        },
        {
            title: t('product:materials.modal.link.consumption'),
            dataIndex: 'consumption',
            key: 'consumption',
            component: <InputNumber />,
            required: true,
            rules: [
                {
                    pattern: /^\d*\.?\d*$/,
                    message: 'Please input a positive number only.',
                },
            ],
        },
        {
            title: t('product:materials.modal.link.unit'),
            dataIndex: 'unit',
            key: 'unit',
            component: (
                <Select
                    options={Object.values(UnitType).map((x) => ({
                        key: x,
                        value: x,
                        label: getUnitValue(x),
                    }))}
                />
            ),
            skipFilter: true,
            required: true,
            rules: [],
            width: '20%',
        },
        {
            title: t('product:materials.modal.link.weight'),
            dataIndex: 'weight',
            key: 'weight',
            component: <InputNumber />,
            rules: [
                {
                    pattern: /^\d*\.?\d*$/,
                    message: 'Please input a positive number only.',
                },
            ],
            required: true,
        },
    ];

    const columns = useMemo(() => {
        const col = [
            {
                title: t('product:materials.table.name'),
                dataIndex: ['material', 'name'],
            },
            {
                title: 'Material Category',
                render: (_: any, item: Composition) => {
                    console.log(item);
                    return (
                        <Text>
                            {getMaterialValue(
                                item.material?.material?.category,
                            )}
                        </Text>
                    );
                },
            },
            {
                title: t('product:materials.table.description'),
                dataIndex: ['material', 'description'],
            },
            {
                title: t('product:materials.table.consumption'),
                dataIndex: 'consumption',
            },
            {
                title: t('product:materials.table.percentage'),
                render: (_: any, item: Composition) => {
                    return (
                        <Text>
                            {(
                                ((item.consumption || 0) /
                                    1000 /
                                    (item.weight || 0)) *
                                100
                            ).toFixed(2)}
                            %
                        </Text>
                    );
                },
            },
            {
                title: t('product:materials.table.unit'),
                render: (_: any, item: Composition) => (
                    <Text>{getUnitValue(item.material?.unit)}</Text>
                ),
            },
            {
                title: t('product:detail.weight'),
                dataIndex: 'weight',
            },
        ];

        if (!isAdmin) {
            col.push({
                title: t('common:actions'),
                render: (_: any, item: Composition) => (
                    <>
                        <Space>
                            <Link
                                onClick={() => {
                                    setEditOpen(true);
                                    setEditData(item);
                                }}
                                disabled={isAdmin}
                            >
                                <EditOutlined />
                            </Link>

                            <Popconfirm
                                title="Confirm to delete?"
                                submit={async () => handleRemoveMaterial(item)}
                                disabled={isAdmin}
                            >
                                <DeleteOutlined style={{ color: 'red' }} />
                            </Popconfirm>
                        </Space>
                    </>
                ),
            });
        }

        return col;
    }, [t, handleRemoveMaterial, isAdmin]);

    const handleLinkMaterial = async () => {
        const { items } = addForm.getFieldsValue(true);

        const newComponents = items.map((x: any) => {
            return {
                consumption: Number(x.consumption),
                material: {
                    id: x.material,
                    unit: x.unit,
                },
                weight: x.weight,
            };
        });

        const components = [
            ...(args.data.compositions || []),
            ...newComponents,
        ];

        await link({
            id: args.data.product?.id || '',
            linkMaterial: {
                components: components,
                name: args.data.product?.name || '',
            },
            delegateId,
        });

        setOpen(false);
        addForm.resetFields();
        setNewMaterials([]);
    };

    const handleEditMaterial = async () => {
        try {
            await editForm.validateFields();
            const values = editForm.getFieldsValue(true);
            const composition = args.data.compositions?.find(
                (x) => x.id === editData?.id,
            );
            if (!composition) return;

            composition.consumption = values.consumption;
            composition.material!.unit = values.unit;
            composition.weight = values.weight;

            await update({
                id: args.data.product?.id || '',
                args: {
                    components: args.data.compositions,
                    cost: args.data.cost,
                    weight: args.data.weight,
                    name: args.data.product?.name,
                },
                delegateId,
            });

            setEditOpen(false);
            editForm.resetFields();
        } catch (err) {
            return;
        }
    };

    useEffect(() => {
        if (editData && editOpen) {
            editForm.setFieldsValue({
                description: editData.material?.description,
                consumption: editData.consumption,
                unit: editData.material?.unit,
                weight: editData.weight,
            });
        }
    }, [editData, editForm, editOpen]);

    return (
        <>
            <Table
                dataSource={args.data.compositions}
                columns={columns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                actions={
                    <CollapsibleDropdown
                        menu={{
                            items: [
                                {
                                    label: t('product:materials.table.export'),
                                    key: 'export',
                                    icon: <DownloadOutlined />,
                                    onClick: async () =>
                                        await exportMaterials(),
                                },
                                {
                                    label: t('product:materials.table.link'),
                                    key: 'add',
                                    icon: <LinkOutlined />,
                                    onClick: () => setOpen(true),
                                },
                            ],
                        }}
                    ></CollapsibleDropdown>
                }
            />

            {open ? (
                <Modal
                    open={open}
                    title={t('product:materials.modal.link.title')}
                    width={800}
                    okFn={handleLinkMaterial}
                    cancelFn={() => setOpen(false)}
                >
                    <AddTable
                        columns={addColumns}
                        dataSource={newMaterials}
                        scroll={{ x: 'max-content' }}
                        form={addForm}
                    />
                </Modal>
            ) : null}

            {editOpen ? (
                <>
                    <Modal
                        title={t('product:materials.modal.edit.title')}
                        open={editOpen}
                        okFn={handleEditMaterial}
                        cancelFn={() => setEditOpen(false)}
                    >
                        <Form form={editForm}>
                            <FormItem
                                name="consumption"
                                rules={[
                                    {
                                        pattern: /^\d*\.?\d*$/,
                                        message:
                                            'Please input a positive number only.',
                                    },
                                ]}
                                label={t(
                                    'product:materials.modal.edit.consumption',
                                )}
                            >
                                <InputNumber />
                            </FormItem>

                            <FormItem
                                name="unit"
                                label={t('product:materials.modal.edit.unit')}
                            >
                                <Select
                                    options={Object.values(UnitType).map(
                                        (x) => ({
                                            key: x,
                                            value: x,
                                            label: getUnitValue(x),
                                        }),
                                    )}
                                />
                            </FormItem>

                            <FormItem
                                name="weight"
                                label={t('product:materials.modal.edit.weight')}
                                rules={[
                                    {
                                        pattern: /^\d*\.?\d*$/,
                                        message:
                                            'Please input a positive number only.',
                                    },
                                ]}
                            >
                                <InputNumber />
                            </FormItem>
                        </Form>
                    </Modal>
                </>
            ) : null}
        </>
    );
};

export default ProductMaterialsComponent;
