import {
    Cascader,
    Dragger,
    Form,
    FormItem,
    Input,
    Select,
    StepModal,
    UploadFile,
    useForm,
} from '@ianneo/ui-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitType } from '../../../../domain/enums/unit-type.enum';
import { i18n } from '../../../../domain/models/i18n.model';
import { ProductMaterial } from '../../../../domain/models/material.model';
import { Rule } from '../../../../domain/models/purchase.model';
import { useUploadImage } from '../../../../infrastructure/hooks/api/file/use-upload-image';
import { useCreateMaterial } from '../../../../infrastructure/hooks/api/materials/use-create-material';
import { useMaterialCategories } from '../../../../infrastructure/hooks/api/materials/use-material-categories';
import { useMaterialService } from '../../../../infrastructure/hooks/api/materials/use-material-service';
import { getUnitValue } from '../../../../infrastructure/utils/unit-type-labeler';
import { useGetDelegations } from '../../../_api/delegations/hooks/useGetDelegations';
import { DelegationComponent } from '../../Partners/add-partner/delegations.component';

interface AddMaterialComponentProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

interface Option {
    value: string | number;
    label: string;
    children?: Option[];
    isLeaf?: boolean;
    loading?: boolean;
}

export interface MaterialCategoryChildren {
    category: string;
    createdOn: Date;
    description: i18n;
    id: string;
    lastUpdatedOn: Date;
    name: i18n;
    rules: Rule[];
    unit: string;
}

const AddMaterialComponent: React.FC<AddMaterialComponentProps> = (args) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [options, setOptions] = useState<Option[]>([]);

    const { delegations } = useGetDelegations();
    const [onBehalfOf, setOnBehalfOf] = useState(0);

    const measureUnits = Object.values(UnitType).map((x) => ({
        key: x,
        value: x,
        label: getUnitValue(x),
    }));

    const { mutateAsync: createMaterial } = useCreateMaterial();
    const { mutateAsync: uploadImage } = useUploadImage();

    const defaultRules = [
        { required: true, message: t('material:modal.create.errorMsg') },
    ];

    const close = () => {
        args.setOpen(false);

        form.resetFields();
        setFiles([]);
    };

    const submit = async () => {
        await form.validateFields();

        const workspaceId = delegations.find((x) => x.id === onBehalfOf)
            ?.workspace?.id;
        const values = form.getFieldsValue(true);

        const images = await uploadImage({
            files,
            type: 'material',
            workspaceId,
        });

        const request: ProductMaterial = Object.assign({}, values);
        request.pictures = images;
        request.material = values.material[values.material.length - 1];

        await createMaterial({ material: request, workspaceId });

        close();
    };

    const { service } = useMaterialService();

    const { data: materials } = useMaterialCategories();

    const loadData = async (selectedOptions: any) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];

        const response: MaterialCategoryChildren[] = await service.getCategory(
            targetOption.value,
        );

        targetOption.loading = true;

        // load options lazily
        setTimeout(() => {
            targetOption.loading = false;
            targetOption.children = response.map((x) => ({
                label:
                    x.name?.locales?.find((x) => x.localeName === 'en')?.text ||
                    '',
                value: x.id,
            }));

            setOptions([...options]);
        }, 500);
    };

    useEffect(() => {
        if (materials) {
            const response = Object.entries(materials).map(([key, value]) => {
                return {
                    value: key,
                    label: value.name?.find((x: any) => x.locale === 'en')
                        ?.text,
                    isLeaf: false,
                };
            });

            setOptions(response);
        }
    }, [materials]);

    return (
        <>
            <StepModal
                open={args.open}
                title={t('material:modal.create.title')}
                width={'60vw'}
                okFn={submit}
                closable={false}
                cancelFn={close}
                subtitle={t('material:modal.create.subtitle') || ''}
                stepContent={[
                    {
                        title: 'On Behalf Of.',
                        content: (
                            <DelegationComponent
                                form={form}
                                onBehalfOf={onBehalfOf}
                                setOnBehalfOf={setOnBehalfOf}
                            />
                        ),
                    },
                    {
                        title: t('common:steps.required'),
                        content: (
                            <>
                                <Form form={form}>
                                    <FormItem
                                        name="name"
                                        label={t('material:modal.create.name')}
                                        rules={defaultRules}
                                        required
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        name="description"
                                        label={t(
                                            'material:modal.create.description',
                                        )}
                                        rules={defaultRules}
                                        required
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        name="unit"
                                        label={t('material:modal.create.unit')}
                                        rules={defaultRules}
                                        required
                                    >
                                        <Select options={measureUnits} />
                                    </FormItem>

                                    <FormItem
                                        name="material"
                                        label={t(
                                            'material:modal.create.material',
                                        )}
                                        rules={defaultRules}
                                        required
                                    >
                                        <Cascader
                                            options={options}
                                            changeOnSelect
                                            loadData={loadData}
                                            style={{ width: '100%' }}
                                        />
                                    </FormItem>

                                    <FormItem label={t('common:upload')}>
                                        <Dragger
                                            fileList={files}
                                            setFiles={setFiles}
                                            accept="image/png,image/jpeg,image/jpg"
                                            className="custom-upload-css"
                                            defaultDescription="Supported file types are JPG, PNG"
                                        />
                                    </FormItem>
                                </Form>
                            </>
                        ),
                    },
                ]}
            ></StepModal>
        </>
    );
};

export default AddMaterialComponent;
