import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useOrderService } from './use-order-service';

export interface UpdateOrderParams {
    id: string;
    delegateId?: string;
    args: any;
}

export function useUpdateOrder() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useOrderService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ id, delegateId, args }: UpdateOrderParams) => {
            if (!context.workspace?.id) return;
            await service.update(delegateId || context.workspace.id, id, args);
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries({
                queryKey: [
                    'orders',
                    { workspace: delegateId || context.workspace?.id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Order updated successfully',
                title: 'Update Order',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to update order: ${error.message}`,
                title: 'Update Order',
            });
        },
    });
}
