import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    AccountService,
    AddWorkspaceRequest,
} from '../../../../domain/services/account.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useAccountService } from './use-account-service';

export interface AddWorkspaceParams {
    account: string;
    params: AddWorkspaceRequest;
}

const addWorkspace = async (
    service: AccountService,
    account: string,
    params: AddWorkspaceRequest,
) => {
    return await service.addWorkspace(account, params);
};

export function useAddWorkspace() {
    const context = useAppContext();
    const client = useQueryClient();

    const { service } = useAccountService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async (args: AddWorkspaceParams) => {
            if (!context.workspace?.id || !args) return;

            await addWorkspace(service, args.account, args.params);
        },
        onSuccess: () => {
            client.invalidateQueries({
                queryKey: ['workspaces', { workspace: context.workspace?.id }],
            });

            setAlert({
                type: 'success',
                message: 'Workspace added successfully',
                title: 'Add New Workspace',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to add workspace: ${error.message}`,
                title: 'Add New Workspace',
            });
        },
    });
}
