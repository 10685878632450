import { useQuery } from '@tanstack/react-query';
import { ProductCategory } from '../../../../domain/models/category.model';
import { CategoryService } from '../../../../domain/services/category.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { CATEGORIES_QUERY_KEY } from './categories-query-key';
import { useCategoryService } from './use-category-service';

const getCategories = async (
    service: CategoryService,
    id: string,
): Promise<ProductCategory[]> => {
    const response = await service.list(id);

    return response;
};

export function useCategories(workspaceId?: string) {
    const context = useAppContext();
    const { service } = useCategoryService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: CATEGORIES_QUERY_KEY(
            workspaceId || context.workspace?.id || '',
        ),
        queryFn: async () => {
            if (!context.workspace?.id && !workspaceId) return;
            return await getCategories(
                service,
                workspaceId || context.workspace?.id || '',
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch categories: ${error.message}`,
            });
        },
    });
}
