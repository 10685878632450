import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { CollapsibleDropdown, Table, Tag, Text } from '@ianneo/ui-library';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Supplier } from '../../../../domain/models/supplier.model';
import {
    NominatedList,
    NominatedListRequest,
} from '../../../../domain/zod/nominated-list.model';
import { useUpdateNominatedList } from '../../../../infrastructure/hooks/api/nominated-list/use-update-nominated-list';
import useVerifyAdmin from '../../../../infrastructure/hooks/use-verify-admin';
import { NominatedListType } from '../add-nominated-list';
import { useParams } from 'react-router-dom';

interface NominatedListTableProps {
    data: NominatedList;
    type: NominatedListType;
    toggle: () => void;
}

export const NominatedListTable = ({
    data,
    type,
    toggle,
}: NominatedListTableProps) => {
    const { t } = useTranslation();
    const { workspace } = useParams();
    const { mutateAsync: update } = useUpdateNominatedList();
    const { isAdmin } = useVerifyAdmin();

    const handleDelete = useCallback(
        async (type: NominatedListType, id: string) => {
            if (!data.id) return;

            const existingSuppliers = (data.suppliers || []).map(
                (x) => x.id || '',
            );
            const existingAssigned = (data.assigned || []).map(
                (x) => x.id || '',
            );

            const args: NominatedListRequest = {
                name: data.name || '',
                suppliers:
                    type === NominatedListType.SUPPLIER
                        ? existingSuppliers.filter((x) => x !== id)
                        : existingSuppliers,
                assigned:
                    type === NominatedListType.ASSIGNED
                        ? existingAssigned.filter((x) => x !== id)
                        : existingAssigned,
            };

            await update({ id: data.id, args, delegateId: workspace });
        },
        [data, update, workspace],
    );

    const columns = useMemo(() => {
        const columns = [
            {
                title: t('partner:listing.table.company'),
                dataIndex: ['seller', 'name'],
                render: (_: any, item: Supplier) => (
                    <Text>
                        {item.seller?.name}{' '}
                        {item.isNominated ? <CheckOutlined /> : null}
                    </Text>
                ),
            },
            {
                title: t('partner:listing.table.process'),
                dataIndex: ['seller', 'processes'],
                render: (_: any, item: Supplier) => (
                    <>
                        {item.seller?.processes?.map((process) => (
                            <Tag>{process}</Tag>
                        ))}
                    </>
                ),
            },
            {
                title: t('partner:listing.table.facilities'),
                dataIndex: ['seller', 'facilities'],
                render: () => <>-</>,
            },
            {
                title: t('partner:listing.table.country'),
                dataIndex: ['company', 'company', 'country'],
            },
            {
                title: 'Engaged Date',
                dataIndex: ['createdOn'],
                render: (_: any, item: Supplier) => (
                    <>
                        {item.createdOn
                            ? new Date(item.createdOn).toLocaleDateString()
                            : '-'}
                    </>
                ),
            },
            {
                title: t('partner:listing.table.activatedOn'),
                dataIndex: ['activatedOn'],
                render: (_: any, item: Supplier) => (
                    <>
                        {item.activatedOn
                            ? new Date(item.activatedOn).toLocaleDateString()
                            : '-'}
                    </>
                ),
            },
            {
                title: t('common:actions'),
                dataIndex: ['actions'],
                render: (_: any, item: Supplier) => (
                    <DeleteOutlined
                        style={{ color: '#972d47' }}
                        onClick={() => handleDelete(type, item.id || '')}
                    />
                ),
            },
        ];

        if (isAdmin) {
            columns.pop();
        }

        return columns;
    }, [t, handleDelete, type, isAdmin]);

    return (
        <Table
            key={type}
            dataSource={
                type === NominatedListType.SUPPLIER
                    ? data.suppliers
                    : data.assigned
            }
            actions={
                <CollapsibleDropdown
                    menu={{
                        items:
                            type === NominatedListType.ASSIGNED
                                ? [
                                      {
                                          label: t('common:edit'),
                                          key: 'edit',
                                          onClick: () => toggle(),
                                          icon: <EditOutlined />,
                                      },
                                  ]
                                : [],
                    }}
                ></CollapsibleDropdown>
            }
            rowKey="id"
            columns={columns}
            scroll={{ x: 'max-content' }}
        ></Table>
    );
};
