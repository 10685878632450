import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { ApiServer } from './api.server';

export class AdminApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'admin');
    }

    async consumeSession(session: string, code: string) {
        console.log(`client<admin>| consumeSession(): Enter`);
        console.log(`client<admin>| consumeSession(): $session = ${session}`);

        return await super.put('consume', session, { code: code });
    }
}
