import { Modal, Select, Text } from '@ianneo/ui-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface SwitchLanguageProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function SwitchLanguage(props: SwitchLanguageProps) {
    const { open, setOpen } = props;

    const { t, i18n } = useTranslation();
    const [selected, setSelected] = useState<React.Key>('');

    const lngOptions = [
        { label: 'English', value: 'en' },
        { label: 'Chinese (Traditional)', value: 'ch' },
    ];

    const switchLanguage = async () => {
        i18n.changeLanguage(selected.toString());
        localStorage.setItem('sessionLng', selected.toString());

        setOpen(false);
    };

    useEffect(() => {
        setSelected(i18n.language);
    }, [i18n.language]);

    return (
        <Modal
            title={t('common:language.header')}
            open={open}
            okFn={switchLanguage}
            cancelFn={() => setOpen(false)}
        >
            <div
                style={{
                    display: 'flex',
                    gap: '24px',
                    justifyContent: 'center',
                }}
            >
                <Text style={{ alignSelf: 'center', width: '40%' }}>
                    {t('common:language.selectLanguage')}:
                </Text>

                <Select
                    style={{ width: '60%' }}
                    onChange={(item) => setSelected(item)}
                    value={selected}
                    options={lngOptions.map((x) => ({
                        label: x.label,
                        value: x.value,
                    }))}
                />
            </div>
        </Modal>
    );
}
