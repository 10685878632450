import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { FileService } from '../../../../domain/services/file.service';
import { Attachment } from '../../../../domain/types/attachment.type';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useFileService } from '../file/use-files-service';

interface DownloadDocumentInterface {
    files: Attachment[];
}

const downloadFiles = async (
    service: FileService,
    id: string,
    files: Attachment[],
) => {
    const links: any[] = [];
    await Promise.all(
        files.map(async (file: Attachment) => {
            const token = await service.download(id, file);

            const response = await axios.get(token.token, {
                responseType: 'blob',
            });

            links.push({
                url: response.data,
                name: token.attachment.originalName,
            });
        }),
    );

    const zip = new JSZip();
    links.forEach((link) => {
        zip.file(link.name, link.url);
    });

    zip.generateAsync({ type: 'blob' }).then((zipFile) => {
        const currentDate = new Date().getTime();
        const fileName = `documents-${currentDate}.zip`;
        return saveAs(zipFile, fileName);
    });
};

export function useDownloadPurchaseDocuments() {
    const context = useAppContext();
    const { service } = useFileService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async (props: DownloadDocumentInterface) => {
            return await downloadFiles(
                service,
                context.workspace?.id || '',
                props.files,
            );
        },
        onSuccess: () => {},
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to download file: ${error.message}`,
                title: 'Download Files',
            });
        },
    });
}
