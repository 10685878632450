import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAppContext from '../../../../infrastructure/hooks/use-context.hook';
import { useDelegationService } from './useDelegationService';
import { CreateDelegationParams } from '../delegation.model';
import { toast } from 'react-toastify';

export function useCreateDelegation() {
    const context = useAppContext();
    const service = useDelegationService();
    const client = useQueryClient();

    return useMutation({
        mutationKey: ['delegations', 'create'],
        mutationFn: async (data: CreateDelegationParams) => {
            return await service.create(context.workspace?.id || '', data);
        },
        onSuccess: () => {
            client.invalidateQueries(['delegations']);
            toast.success('Delegation created');
        },
        onError: () => {
            toast.error('Failed to create delegation');
        },
    });
}
