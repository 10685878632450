import { InfoCard } from '@ianneo/component-library';
import { Card, RadioGroup, Subtitle, Text, Title } from '@ianneo/ui-library';
import { useTranslation } from 'react-i18next';
import { ProductVersion } from '../../../../domain/models/product.model';
import ProductMaterials from './product-materials';
import ProductOverview from './product-overview';

interface ProductDetailLayoutProps {
    data: ProductVersion;
    images?: string[];
    menu: 'overview' | 'materials';
    setMenu: (args: 'overview' | 'materials') => void;
    menuOptions: { label: string; value: string }[];
}

const ProductDetailLayout: React.FC<ProductDetailLayoutProps> = (args) => {
    const { t } = useTranslation();

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '8px',
                            backgroundColor: '#972d47',
                            minHeight: '40px',
                            minWidth: '8px',
                        }}
                    ></div>
                    <Title>{t('product:name')}</Title>
                    <Subtitle>{t('product:description')}</Subtitle>
                </div>

                <InfoCard
                    infoTitle={args.data.product?.name}
                    information={
                        <>
                            <Text>
                                {t('product:detail.productCode')}:{' '}
                                {args.data.product?.externalDataId}
                            </Text>
                        </>
                    }
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                >
                    <RadioGroup
                        options={args.menuOptions}
                        defaultValue={args.menu}
                        onChange={(e) => args.setMenu(e.target.value)}
                        optionType="button"
                    />

                    <Card>
                        {args.menu === 'overview' ? (
                            <ProductOverview
                                data={args.data}
                                images={args.images}
                            />
                        ) : (
                            <ProductMaterials data={args.data} />
                        )}
                    </Card>
                </div>
            </div>
        </>
    );
};

export default ProductDetailLayout;
