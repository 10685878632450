import { useMemo } from 'react';
import useAppConfig from '../../../../infrastructure/hooks/use-config.hook';
import useAppContext from '../../../../infrastructure/hooks/use-context.hook';
import { DelegationService } from '../delegation.service';

export function useDelegationService() {
    const config = useAppConfig();
    const context = useAppContext();

    const service = useMemo(() => {
        if (context.token) {
            return new DelegationService(config, context.token);
        } else {
            throw new Error('Token is not defined');
        }
    }, [config, context.token]);

    return service;
}
