import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAppContext from '../../use-context.hook';
import { useProductService } from './use-product-service';
import { useAlertContext } from '../../use-alert.hook';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';

export interface DeleteProductParams {
    id?: string;
    workspaceId?: string;
}

export function useDeleteProduct() {
    const context = useAppContext();
    const client = useQueryClient();
    const { t } = useTranslation();
    const { service } = useProductService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ id, workspaceId }: DeleteProductParams) => {
            if (!context.workspace?.id || !id) return {};

            return await service.delete(
                workspaceId || context.workspace.id,
                id,
            );
        },
        onSuccess: (_, { id, workspaceId }) => {
            client.invalidateQueries({
                queryKey: [
                    'products',
                    { workspace: workspaceId || context.workspace?.id },
                ],
            });

            client.invalidateQueries({
                queryKey: [
                    'product',
                    { workspace: workspaceId || context.workspace?.id, id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Product deleted successfully',
                title: 'Delete Product',
            });
        },
        onError: (err) => {
            const error = err as Error;

            if (err instanceof AxiosError) {
                if (err.response?.status === 403) {
                    return setAlert({
                        type: 'error',
                        message: t('common:error.403.message') || '',
                        title: t('common:error.403.title') || '',
                    });
                }
            } else {
                setAlert({
                    type: 'error',
                    message: `Internal server error: ${error.message}`,
                    title: 'Delete Product',
                });
            }
        },
    });
}
