import { useEffect, useMemo, useState } from 'react';
import { Company } from '../../../domain/models/company.model';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';
import CompanyLayout from './company.layout';
import { useTranslation } from 'react-i18next';
import EditCompany from './edit-company/edit-company';

interface CompanyComponentProps {}

const CompanyComponent: React.FC<CompanyComponentProps> = (props) => {
    const { t } = useTranslation();
    const context = useAppContext();
    const [company, setCompany] = useState<Company>({});
    const [menu, setMenu] = useState<
        'details' | 'documents' | 'certifications' | 'templates'
    >('details');
    const [editMode, setEditMode] = useState(false);

    const menuOptions = useMemo(
        () => [
            { label: t('company:menu.details'), value: 'details' },
            { label: t('company:menu.documents'), value: 'documents' },
            {
                label: t('company:menu.certifications'),
                value: 'certifications',
            },
            {
                label: t('company:menu.templates'),
                value: 'templates',
            },
        ],
        [t],
    );

    useEffect(() => {
        setCompany(context.workspace?.company || {});
    }, [context.workspace]);

    return (
        <>
            <CompanyLayout
                company={company}
                menu={menu}
                menuOptions={menuOptions}
                setMenu={setMenu}
                setEditMode={setEditMode}
            />

            <EditCompany
                open={editMode}
                setOpen={setEditMode}
                company={company}
                setCompany={setCompany}
            />
        </>
    );
};

export default CompanyComponent;
