import { useMemo } from 'react';
import { CategoryService } from '../../../../domain/services/category.service';
import useAppConfig from '../../use-config.hook';
import useAppContext from '../../use-context.hook';

export function useCategoryService() {
    const config = useAppConfig();
    const context = useAppContext();

    const service = useMemo(() => {
        if (context.token && context.workspace?.id) {
            return new CategoryService(config, context.token);
        } else {
            throw new Error('Session Expired');
        }
    }, [config, context.token, context.workspace?.id]);

    return { service };
}
