import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NominatedList } from '../../../../domain/zod/nominated-list.model';
import { useUpdateNominatedList } from '../../../../infrastructure/hooks/api/nominated-list/use-update-nominated-list';
import { usePartners } from '../../../../infrastructure/hooks/api/partner/use-partners';
import { Form, FormItem, Modal, Select, useForm } from '@ianneo/ui-library';
import { useParams } from 'react-router-dom';

interface EditNominatedListProps {
    data: NominatedList;
    open: boolean;
    toggle: () => void;
}

export const EditNominatedList = ({
    data,
    open,
    toggle,
}: EditNominatedListProps) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const { workspace } = useParams();
    const jointArray = useMemo(
        () => [...(data.assigned || []), ...(data.suppliers || [])],
        [data.assigned, data.suppliers],
    );

    const { data: partners } = usePartners();
    const { mutateAsync: update } = useUpdateNominatedList();

    const reset = () => {
        form.resetFields();
        toggle();
    };

    const submit = async () => {
        try {
            if (!data?.id) return;

            await form.validateFields();
            const request = form.getFieldsValue(true);

            const args = {
                suppliers: data.suppliers?.map((x) => x.id || '') || [],
                name: data.name || '',
                assigned: [
                    ...(request.assigned || []),
                    ...(data.assigned || []).map((x) => x.id),
                ],
            };

            await update({ id: data.id, args, delegateId: workspace });

            reset();
        } catch (err) {
            return;
        }
    };

    return (
        <>
            <Modal
                open={open}
                title={t('nominatedList:modal.edit.title')}
                okFn={submit}
                cancelFn={reset}
            >
                <Form form={form}>
                    <FormItem
                        label={t('nominatedList:modal.edit.name')}
                        name="assigned"
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            showArrow
                            options={partners
                                ?.map((x) => ({
                                    label: x.seller?.name,
                                    value: x.id,
                                    key: x,
                                }))
                                .filter(
                                    (x) =>
                                        !jointArray.some(
                                            (y) => x.value === y.id,
                                        ),
                                )}
                        />
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
};
