import { useMemo } from 'react';
import { MeService } from '../../../../domain/services/me.service';
import useAppConfig from '../../use-config.hook';
import useAppContext from '../../use-context.hook';

interface MeServiceProps {
    token?: string;
}

export function useMeService(args: MeServiceProps) {
    const config = useAppConfig();
    const context = useAppContext();

    const service = useMemo(() => {
        if (context.token || args.token) {
            return new MeService(
                config,
                (args.token ? args.token : context.token) || '',
            );
        }
    }, [config, context.token, args]);

    return { service };
}
