import { useQuery } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useNominatedListService } from './use-nominated-list.service';

export function useNominatedList({
    workspaceId,
    enabled = true,
}: {
    workspaceId?: string;
    enabled?: boolean;
}) {
    const context = useAppContext();

    const { service } = useNominatedListService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'nominated-list',
            { workspace: workspaceId || context.workspace?.id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id) return [];
            return await service.list(workspaceId || context.workspace.id);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                title: 'Nominate List',
                message: `Failed to fetch nominated list information: ${error.message}`,
            });
        },
        enabled,
    });
}
