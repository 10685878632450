import { MeClient } from '../../infrastructure/clients/me.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { UserProfileInterface } from '../interfaces/profile.interface';
import { Permission } from '../models/permission.model';
import { User } from '../models/user.model';
import { Workspace } from '../models/workspace.model';
import { WorkspaceService } from './workspace.service';

export class MeService {
    constructor(
        private readonly config: ServiceConfigInterface,
        private readonly token: string,
    ) {}

    private async getPermissions(workspace: string) {
        console.log(`service<my>| getPermissions(): Enter`);
        const client = new MeClient(this.config, this.token);
        return client.getAllowed(workspace);
    }

    async getUser(): Promise<User> {
        console.log(`service<my>| getUser(): Enter`);
        const client = new MeClient(this.config, this.token);
        const data = await client.get();
        return {
            id: data.id,
            avatar: data.avatar,
            dob: data.dob,
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            mobile: data.mobile,
            createdOn: data.createdOn,
            lastUpdatedOn: data.lastUpdatedOn,
        };
    }

    private async getWorkspaces(
        user: User,
        assumedWorkspaceId?: string,
    ): Promise<Workspace | undefined> {
        console.log(`service<my>| getWorkspaces(): Enter`);
        const client = new MeClient(this.config, this.token);
        const data: any[] = await client.getWorkspaces();
        const filteredData = data.filter((x) => x);

        const workspaceId = localStorage.getItem('sessionWorkspace');
        const incomingId = localStorage.getItem('incomingWorkspace');
        const sessionWorkspace = filteredData.find((w) => w.id === workspaceId);
        const masterWorkspace = filteredData.find(
            (w) => w.isMasterWorkspace === true,
        );
        const foundWorkspace = filteredData.find(
            (x) => x.account.admin === user.email,
        );
        const incomingWorkspace = filteredData.find((w) => w.id === incomingId);
        const assumedWorkspace = filteredData.find(
            (w) => w.id === assumedWorkspaceId,
        );

        if (filteredData.length > 0) {
            const result = WorkspaceService.convert(
                assumedWorkspace ||
                    incomingWorkspace ||
                    sessionWorkspace ||
                    foundWorkspace ||
                    masterWorkspace ||
                    filteredData[0],
            );
            console.log(
                `service<my>| getWorkspaces(): $result = ${JSON.stringify(
                    result,
                )}`,
            );

            if (incomingWorkspace) {
                localStorage.removeItem('incomingWorkspace');
            }

            return result;
        } else {
            return undefined;
        }
    }

    async getWorkspaceOptions(): Promise<Workspace[]> {
        console.log(`service<my>| getWorkspaceOptions(): Enter`);
        const client = new MeClient(this.config, this.token);
        const data: any[] = await client.getWorkspaces();

        return data;
    }

    async setup(): Promise<UserProfileInterface> {
        console.log(`service<my>| setup(): Enter`);

        const assumedSession = sessionStorage.getItem('assumedSession');
        const sessionWorkspace = sessionStorage.getItem('sessionWorkspace');

        let assumedUser;

        if (assumedSession) {
            const session = JSON.parse(assumedSession);
            // const sessionEmail = localStorage.getItem('sessionEmail');
            console.log(
                `service<my>| setup(): $session = ${JSON.stringify(session)}`,
            );

            assumedUser = session[0];
        }

        const user = await this.getUser();
        const workspace = await this.getWorkspaces(
            assumedUser?.id ? assumedUser.id : user,
            sessionWorkspace || '',
        );
        const permissions: Permission[] = [];

        if (workspace?.id) {
            const acl = await this.getPermissions(
                sessionWorkspace || workspace.id,
            );
            permissions.push(
                ...acl
                    .filter((x) => x)
                    .map((a) => {
                        return {
                            application: a.split(':')[0],
                            module: a.split(':')[1],
                            action: a.split(':')[2],
                        } as Permission;
                    }),
            );

            return {
                permissions: permissions,
                user: assumedUser || user,
                workspace: workspace,
            };
        } else throw new Error('Unable to load profile');
    }

    async switch(workspace: Workspace): Promise<UserProfileInterface> {
        console.log(`service<my>| switch(): Enter`);
        const user = await this.getUser();
        const permissions: Permission[] = [];

        if (workspace.id) {
            const acl = await this.getPermissions(workspace.id);
            permissions.push(
                ...acl.map(
                    (a): Permission => ({
                        application: a.split(':')[0],
                        module: a.split(':')[1],
                        action: a.split(':')[2],
                    }),
                ),
            );

            localStorage.setItem('sessionWorkspace', workspace.id);

            return {
                permissions: permissions,
                user: user,
                workspace: workspace,
            };
        } else throw new Error('Unable to load profile');
    }

    async inviteMember(workspace: string, params: any) {
        const client = new MeClient(this.config, this.token);

        return await client.inviteUser(workspace, params);
    }
}
