import { useQuery } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import useTemplateService from './use-template-service';

export default function useTemplates(enabled = false) {
    const context = useAppContext();
    const { service } = useTemplateService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: ['template', { workspace: context.workspace?.id }],
        queryFn: async () => {
            if (!context.workspace?.id) return [];
            return await service.list(context.workspace.id);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch template information: ${error.message}`,
            });
        },
        enabled: enabled,
    });
}
