import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { NominatedListRequest } from '../../domain/zod/nominated-list.model';
import { ApiServer } from './api.server';

export class NominatedListApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'purchases', 'nominatedlist');
    }

    async list(workspace: string) {
        console.log(`client<nominatedlist>| list(): Enter`);
        console.log(`client<nominatedlist>| list(): $workspace = ${workspace}`);

        return super.get('list', workspace);
    }

    async create(workspace: string, params: NominatedListRequest) {
        console.log(`client<nominatedlist>| create(): Enter`);
        console.log(
            `client<nominatedlist>| create(): $workspace = ${workspace}`,
        );

        return super.post('create', workspace, params);
    }

    async update(
        workspace: string,
        item: number,
        params: NominatedListRequest,
    ) {
        console.log(`client<nominatedlist>| update(): Enter`);
        console.log(
            `client<nominatedlist>| update(): $workspace = ${workspace}`,
        );
        console.log(`client<nominatedlist>| update(): $item = ${item}`);

        return super.patch('update', workspace, item, params);
    }

    async delete(workspace: string, item: number) {
        console.log(`client<nominatedlist>| delete(): Enter`);
        console.log(
            `client<nominatedlist>| delete(): $workspace = ${workspace}`,
        );
        console.log(`client<nominatedlist>| delete(): $item = ${item}`);

        return super.delete('delete', workspace, item);
    }
}
