import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PurchaseOrder } from '../../../domain/models/purchase.model';
import { usePurchases } from '../../../infrastructure/hooks/api/purchases/use-purchases';
import { getPOStatus } from '../../../infrastructure/utils/purchase-order-labeler';
import PurchasesLayout from './purchases.layout';
import { Link, Text, Tooltip } from '@ianneo/ui-library';
import { QuestionCircleOutlined } from '@ant-design/icons';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';

interface PurchasesComponentProps {}

const PurchasesComponent: React.FC<PurchasesComponentProps> = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const supplier = queryParams.get('supplier');
    const navigate = useNavigate();
    const workspace = useAppContext().workspace;
    const [open, setOpen] = useState(false);

    const { data } = usePurchases();

    const constructInternalOrderText = useCallback((id?: string) => {
        return `Internal Order - ${id?.slice(0, 6)}`;
    }, []);

    const columns = useMemo(
        () => [
            {
                title: t('purchase:listing.poNumber'),
                dataIndex: 'externalDataId',
                render: (_: any, item: PurchaseOrder) => (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px',
                        }}
                    >
                        <Link onClick={() => navigate(`/purchases/${item.id}`)}>
                            {item.externalDataId
                                ? item.externalDataId
                                : constructInternalOrderText(item.id)}
                        </Link>

                        {!item.externalDataId && (
                            <Tooltip title={item.id}>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        )}
                    </div>
                ),
            },
            {
                title: t('purchase:listing.supplier'),
                dataIndex: ['supplier', 'seller', 'name'],
            },
            {
                title: t('purchase:listing.currency'),
                dataIndex: 'currency',
            },
            {
                title: t('purchase:listing.status'),
                dataIndex: 'status',
                render: (_: any, item: PurchaseOrder) => (
                    <Text>{getPOStatus(item.status)}</Text>
                ),
            },
            {
                title: t('common:updatedOn'),
                render: (_: any, item: PurchaseOrder) =>
                    item.lastUpdatedOn
                        ? new Date(item.lastUpdatedOn)
                              .toLocaleString()
                              .slice(0, 10)
                        : 'Pending',
            },
            {
                title: t('common:actions'),
                dataIndex: 'actions',
                render: (_: any, item: PurchaseOrder) => (
                    <Link onClick={() => navigate(`/purchases/${item.id}`)}>
                        View
                    </Link>
                ),
            },
        ],
        [navigate, t, constructInternalOrderText],
    );

    useEffect(() => {
        if (supplier) {
            setOpen(true);
        }
    }, [supplier]);

    return (
        <PurchasesLayout
            data={data || []}
            columns={columns}
            open={open}
            setOpen={setOpen}
            supplier={supplier || ''}
            isCaseDisplayed={workspace?.isCaseDisplayed || false}
        />
    );
};

export default PurchasesComponent;
