import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    Composition,
    UpdateProduct,
} from '../../../../domain/models/product.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useProductService } from './use-product-service';

export interface LinkProductMaterialParams {
    id: string;
    linkMaterial: UpdateProduct;
    delegateId?: string;
}

export interface LinkProductMaterial {
    components: Composition[];
    name: string;
}

export function useLinkProductMaterial() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useProductService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({
            id,
            linkMaterial,
            delegateId,
        }: LinkProductMaterialParams) => {
            if (!context.workspace?.id || !id) return;

            await service.update(
                delegateId || context.workspace.id,
                id,
                linkMaterial,
            );
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: [
                    'product',
                    {
                        workspace: args.delegateId || context.workspace?.id,
                        id: args.id,
                    },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Product material linked successfully',
                title: 'Link Product Material',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to link product material: ${error.message}`,
                title: 'Link Product Material',
            });
        },
    });
}
