import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { AddWorkspaceRequest } from '../../domain/services/account.service';
import { ApiServer } from './api.server';

export class AccountApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'account');
    }

    async addWorkspace(account: string, args: AddWorkspaceRequest) {
        console.log(`client<account>| addWorkspace(): Enter`);
        console.log(`client<account>| addWorkspace(): $account = ${account}`);

        return await super.post('addWorkspace', account, args);
    }
}
