import {
    Form,
    FormItem,
    InputNumber,
    Modal,
    Select,
    useForm,
    Cascader,
} from '@ianneo/ui-library';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18n } from '../../../../../domain/models/i18n.model';
import { ProductMaterial } from '../../../../../domain/models/material.model';
import { Rule } from '../../../../../domain/models/purchase.model';
import countries from '../../../../../infrastructure/config/data/countries.json';
import { useMaterialCategories } from '../../../../../infrastructure/hooks/api/materials/use-material-categories';
import { useMaterialService } from '../../../../../infrastructure/hooks/api/materials/use-material-service';
import { UpdateProductMaterial } from '../../../../../infrastructure/hooks/api/materials/use-update-material';

export interface MaterialCategoryChildren {
    category: string;
    createdOn: Date;
    description: i18n;
    id: string;
    lastUpdatedOn: Date;
    name: i18n;
    rules: Rule[];
    unit: string;
}

interface AddMaterialBreakdownProps {
    data: ProductMaterial;
    open: boolean;
    setOpen: (open: boolean) => void;
    update: (args: UpdateProductMaterial) => void;
}

interface Option {
    value: string | number;
    label: string;
    children?: Option[];
    isLeaf?: boolean;
    loading?: boolean;
}

export default function AddMaterialBreakdownV2({
    data,
    open,
    setOpen,
    update,
}: AddMaterialBreakdownProps) {
    const { t } = useTranslation();
    const [options, setOptions] = useState<Option[]>([]);
    const { service } = useMaterialService();

    const { data: materials } = useMaterialCategories();

    useEffect(() => {
        if (materials) {
            const response = Object.entries(materials).map(([key, value]) => {
                return {
                    value: key,
                    label: value.name?.find((x: any) => x.locale === 'en')
                        ?.text,
                    isLeaf: false,
                };
            });

            setOptions(response);
        }
    }, [materials]);

    const [form] = useForm();

    const submit = async () => {
        try {
            await form.validateFields();
            const { material, origin, weight } = form.getFieldsValue(true);

            // This is done because antd cascader will just append the id to the end of the array.
            // example: [1,2,3,4] => 4
            const materialId = material[material.length - 1];

            await update({
                ...data,
                breakdowns: [
                    ...(data.breakdowns || []),
                    {
                        material: materialId,
                        origin: origin,
                        weight: weight,
                    },
                ],
                material: data.material?.id || '',
            });

            reset();
        } catch (err) {
            return;
        }
    };

    const reset = () => {
        form.resetFields();
        setOpen(false);
    };

    const loadData = useCallback(
        async (selectedOptions: any) => {
            const targetOption = selectedOptions[selectedOptions.length - 1];

            const response: MaterialCategoryChildren[] =
                await service.getCategory(targetOption.value);

            targetOption.loading = true;

            // load options lazily
            setTimeout(() => {
                targetOption.loading = false;
                targetOption.children = response
                    .filter((x) => {
                        const found = !data.breakdowns?.some((y) => {
                            return y.material?.id === x.id;
                        });

                        return found;
                    })
                    .map((x) => ({
                        label:
                            x.name?.locales?.find((x) => x.localeName === 'en')
                                ?.text || '',
                        value: x.id,
                    }));

                setOptions([...options]);
            }, 500);
        },
        [data.breakdowns, options, service],
    );

    return (
        <>
            <Modal
                open={open}
                title={t('material:detail.breakdownTitle')}
                cancelFn={reset}
                okFn={submit}
            >
                <Form form={form}>
                    <FormItem
                        label={t('material:modal.breakdown.material')}
                        name="material"
                    >
                        <Cascader
                            options={options}
                            changeOnSelect
                            loadData={loadData}
                            style={{ width: '100%' }}
                        />
                    </FormItem>

                    <FormItem
                        label={t('material:modal.breakdown.origin')}
                        name="origin"
                    >
                        <Select
                            showSearch
                            filterOption={(input, option) => {
                                return (
                                    option?.key
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0 ||
                                    option?.title
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                );
                            }}
                            options={countries.map((x) => ({
                                label: x.name,
                                value: x['alpha-2'],
                            }))}
                        />
                    </FormItem>

                    <FormItem
                        label={t('material:modal.breakdown.weight')}
                        name="weight"
                        rules={[
                            {
                                pattern: /^\d*\.?\d*$/,
                                message:
                                    'Please enter a value between 0 and 100',
                            },
                            {
                                validator: async (_, value) => {
                                    const currentWeight =
                                        data.breakdowns?.reduce(
                                            (a, b) => a + (b?.weight || 0),
                                            0,
                                        ) || 0;

                                    if (currentWeight + value <= 100) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject();
                                    }
                                },
                                message:
                                    'Existing breakdowns cumulative sum cannot be more than 100',
                            },
                        ]}
                    >
                        <InputNumber />
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
}
