import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAppContext from '../../use-context.hook';
import { useFileService } from '../file/use-files-service';
import { useAlertContext } from '../../use-alert.hook';
import { FileService } from '../../../../domain/services/file.service';
import { DocumentUpload } from '../../../../app/pages-v2/Orders/documents';

interface UploadOrderDocumentInterface {
    documents: DocumentUpload[];
    delegateId?: string;
}

const uploadDocument = async (
    workspace: string,
    files: DocumentUpload[],
    service: FileService,
) => {
    const documents: any = [];
    await Promise.all(
        files.map(async (file) => {
            const token = await service.upload(workspace, 'salesOrder', {
                filename: file.file[0].name,
                contentType: file.file[0].type || '',
            });

            await axios.put(token.token, file.file[0].originFileObj, {
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': file.file[0].type || '',
                },
            });

            documents.push({
                id: file.docId ? file.docId : null,
                type: file.document,
                file: {
                    assetName: token.attachment.assetName,
                    contentType: file.file[0].type,
                    originalName: file.file[0].name,
                    autoResign: false,
                    container: 'temporary',
                },
                certificateNumber: file.certificateNumber,
            });
        }),
    );

    return documents;
};

/**
 * Upload certification API which will be updated when any of the parameters in the [] array changes
 * Also will set the refresh state to true which will trigger the useEffect hook to fetch the documents
 */
export function useUploadOrderDocuments() {
    const context = useAppContext();
    const { service: fileService } = useFileService();
    const { setAlert } = useAlertContext();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            documents,
            delegateId,
        }: UploadOrderDocumentInterface) => {
            if (!context.workspace?.id) return;

            return await uploadDocument(
                delegateId || context.workspace.id,
                documents,
                fileService,
            );
        },
        onSuccess: () => {
            client.invalidateQueries({
                queryKey: ['companyDocuments', { type: context.workspace?.id }],
            });

            setAlert({
                type: 'success',
                message: 'Documents uploaded successfully',
                title: 'Upload Company Documents',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to upload documents: ${error.message}`,
                title: 'Upload Company Documents',
            });
        },
    });
}
