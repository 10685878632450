import { useQuery } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import { useLinkService } from './use-links-service';
import { PurchaseOrderLink } from '../../../../domain/models/purchase.model';

export function useBatchLinksBySO(salesOrders: string[]) {
    const { service } = useLinkService();
    const { setAlert } = useAlertContext();

    return useQuery<PurchaseOrderLink[]>({
        queryKey: ['links-batch', ...salesOrders],
        queryFn: async () => {
            return await service.listBatch({
                orderIds: salesOrders,
            });
        },
        onError: (err) => {
            setAlert({
                type: 'error',
                message: 'Failed to batch links',
                title: 'Batch Links',
            });
        },
    });
}
