import {
    Adaptation,
    TemplateClient,
} from '../../infrastructure/clients/template.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';

export class TemplateService {
    private server: TemplateClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new TemplateClient(config, token);
    }

    async get(workspace: string, template: string) {
        console.log(`service<template>| get(): Enter`);
        console.log(`service<template>| get(): $workspace = ${workspace}`);
        console.log(`service<template>| get(): $template = ${template}`);

        return await this.server.get(workspace, template);
    }

    async list(workspace: string) {
        console.log(`service<template>| list(): Enter`);

        return await this.server.list(workspace);
    }

    async create(workspace: string, template: string, data: Adaptation) {
        console.log(`service<template>| create(): Enter`);
        console.log(`service<template>| create(): $workspace = ${workspace}`);
        console.log(`service<template>| create(): $template = ${template}`);
        console.log(`service<template>| create(): $data = ${data}`);

        return await this.server.create(workspace, template, data);
    }

    async update(workspace: string, template: string, data: Adaptation) {
        console.log(`service<template>| update(): Enter`);
        console.log(`service<template>| update(): $workspace = ${workspace}`);
        console.log(`service<template>| update(): $template = ${template}`);
        console.log(`service<template>| update(): $data = ${data}`);

        return await this.server.update(workspace, template, data);
    }

    async getParams(template: string) {
        console.log(`service<template>| getParams(): Enter`);
        console.log(`service<template>| getParams(): $template = ${template}`);

        return await this.server.getParams(template);
    }

    async delete(workspace: string, template: string) {
        console.log(`service<template>| delete(): Enter`);
        console.log(`service<template>| delete(): $workspace = ${workspace}`);
        console.log(`service<template>| delete(): $template = ${template}`);

        return await this.server.delete(workspace, template);
    }
}
