import { useQuery } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import useTemplateService from './use-template-service';

export default function useTemplate(id?: string, enabled = false) {
    const context = useAppContext();
    const { service } = useTemplateService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'template',
            { workspace: context.workspace?.id, template: id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !id) return {};
            return await service.get(context.workspace.id, id);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch template information: ${error.message}`,
            });
        },
        enabled: enabled,
    });
}
