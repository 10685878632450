import { ArrowRightOutlined } from '@ant-design/icons';
import {
    Link,
    List,
    ListItem,
    ListItemMeta,
    Title,
    Card as UICard,
} from '@ianneo/ui-library';
import 'leaflet/dist/leaflet.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useOrders } from '../../../infrastructure/hooks/api/order/use-orders';
import { usePurchases } from '../../../infrastructure/hooks/api/purchases/use-purchases';
import { Calendar } from '../../components/Calendar/calendar';
import LeafletMap from '../../components/LeafletMap/leaflet-map';
import RadialMap from '../../components/RadialMap';
import Activities from './activities';

interface DashboardLayoutProps {}

const DashboardLayout: React.FC<DashboardLayoutProps> = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { data: orders } = useOrders();
    const { data: purchaseOrders } = usePurchases();

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                }}
            >
                <Title style={{ alignSelf: 'unset' }}>
                    {t('dashboard:greeting')}
                </Title>

                <div style={{ display: 'flex', gap: '16px', height: '80dvh' }}>
                    <div style={{ flex: '0 1 25%' }}>
                        <UICard
                            title={t('dashboard:recentActivity')}
                            style={{
                                flex: 1,
                                overflow: 'auto',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            bodyStyle={{
                                display: 'flex',
                                flex: 1,
                                overflow: 'auto',
                                padding: '8px',
                            }}
                        >
                            <Activities />
                        </UICard>
                    </div>

                    <div
                        style={{
                            flex: '0 1 75%',
                            flexDirection: 'column',
                            gap: '16px',
                            display: 'flex',
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                maxHeight: '50%',
                                flexDirection: 'row',
                                display: 'flex',
                                gap: '16px',
                            }}
                        >
                            <UICard
                                title={t('dashboard:supplierMap')}
                                style={{
                                    flexBasis: 0,
                                    flex: 1,
                                    flexShrink: 0,
                                }}
                                bodyStyle={{
                                    maxWidth: '100%',
                                    overflow: 'auto',
                                    height: '80%',
                                    maxHeight: '80%',
                                }}
                            >
                                <LeafletMap />
                            </UICard>

                            <UICard
                                title={t('dashboard:deliveryCalendar')}
                                style={{
                                    flexBasis: 0,
                                    flex: 1,
                                    flexShrink: 0,
                                }}
                                bodyStyle={{
                                    maxWidth: '100%',
                                    overflow: 'auto',
                                    height: '80%',
                                    maxHeight: '80%',
                                }}
                            >
                                <Calendar />
                            </UICard>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                gap: '16px',
                                justifyContent: 'space-between',
                                maxHeight: '47.5%',
                                height: '47.5%',
                            }}
                        >
                            <UICard
                                title={t('dashboard:receivedOrders')}
                                style={{
                                    flexBasis: 0,
                                    flex: 1,
                                    flexShrink: 0,
                                    // minWidth: '1vw',
                                }}
                                bodyStyle={{
                                    maxWidth: '100%',
                                    overflow: 'auto',
                                    height: '100%',
                                }}
                            >
                                <List
                                    dataSource={orders}
                                    style={{
                                        width: '100%',
                                        maxHeight: '80%',
                                        overflow: 'auto',
                                    }}
                                    renderItem={(item, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                style={{
                                                    backgroundColor: '#f2f2f2',
                                                }}
                                            >
                                                <ListItemMeta
                                                    style={{ padding: '8px' }}
                                                    title={
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'space-between',
                                                                paddingRight:
                                                                    '8px',
                                                            }}
                                                        >
                                                            <Link
                                                                onClick={() => {
                                                                    navigate(
                                                                        `/orders/${item.id}`,
                                                                    );
                                                                }}
                                                            >
                                                                {item.poExternalRefId ||
                                                                    item.purchaseOrderId}
                                                            </Link>

                                                            <ArrowRightOutlined
                                                                style={{
                                                                    color: '#972D47',
                                                                }}
                                                                onClick={() => {
                                                                    navigate(
                                                                        `/orders/${item.id}`,
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                ></ListItemMeta>
                                            </ListItem>
                                        );
                                    }}
                                ></List>
                            </UICard>

                            <UICard
                                title={t('dashboard:purchaseOrderProgress')}
                                style={{ flexBasis: 0, flex: 1, flexShrink: 0 }}
                                bodyStyle={{
                                    maxWidth: '100%',
                                    overflow: 'auto',
                                    height: '100%',
                                }}
                            >
                                <List
                                    dataSource={purchaseOrders}
                                    style={{
                                        width: '100%',
                                        maxHeight: '80%',
                                        overflow: 'auto',
                                    }}
                                    renderItem={(item, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                style={{
                                                    backgroundColor: '#f2f2f2',
                                                }}
                                            >
                                                <ListItemMeta
                                                    style={{ padding: '8px' }}
                                                    title={
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'space-between',
                                                                paddingRight:
                                                                    '8px',
                                                            }}
                                                        >
                                                            <Link
                                                                onClick={() => {
                                                                    navigate(
                                                                        `/purchases/${item.id}`,
                                                                    );
                                                                }}
                                                            >
                                                                {item.externalDataId ||
                                                                    item.id}
                                                            </Link>

                                                            <ArrowRightOutlined
                                                                style={{
                                                                    color: '#972D47',
                                                                }}
                                                                onClick={() => {
                                                                    navigate(
                                                                        `/purchases/${item.id}`,
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                ></ListItemMeta>
                                            </ListItem>
                                        );
                                    }}
                                ></List>
                            </UICard>

                            <UICard
                                title={t('dashboard:supplierNetwork')}
                                style={{ flexBasis: 0, flex: 1, flexShrink: 0 }}
                                bodyStyle={{
                                    maxWidth: '100%',
                                    overflow: 'auto',
                                    height: '100%',
                                    maxHeight: '100%',
                                }}
                            >
                                <RadialMap />
                            </UICard>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DashboardLayout;
