import { useState } from 'react';
import ProductLayout from './products.layout';

interface ProductComponentProps {}

const ProductComponent: React.FC<ProductComponentProps> = () => {
    const [open, setOpen] = useState(false);

    return <ProductLayout open={open} setOpen={setOpen} />;
};

export default ProductComponent;
