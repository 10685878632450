import { Permission } from '../../domain/models/permission.model';

export function validatePermissions(
    checkPermission: Permission,
    permissions?: Permission[],
): boolean {
    return (
        permissions?.some(
            (permission) =>
                permission.action === checkPermission.action &&
                permission.module === checkPermission.module &&
                permission.application === checkPermission.application,
        ) || false
    );
}
