import {
    AddTable,
    Form,
    FormItem,
    Input,
    Select,
    StepModal,
    useForm,
} from '@ianneo/ui-library';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupplyChainNodeType } from '../../../../domain/enums/supplier-node.enum';
import { SupportDocType } from '../../../../domain/enums/support-doctype.enum';
import {
    Ruleset,
    SupportDocument,
} from '../../../../domain/models/ruleset.model';
import { useUpdateRuleset } from '../../../../infrastructure/hooks/api/rulesets/use-update-ruleset';
import { getDocumentValue } from '../../../../infrastructure/utils/document-type-labeler';
import { getProcessValue } from '../../../../infrastructure/utils/process-labeler';

interface EditRulesetComponentProps {
    data: Ruleset;
    open: boolean;
    setOpen: (open: boolean) => void;
    selected?: string;
}

const EditRulesetComponent: React.FC<EditRulesetComponentProps> = ({
    data,
    open,
    setOpen,
    selected,
}) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const [selectedTypes, setSelectedTypes] = useState<SupportDocType[]>([]);

    const defaultRules = [
        { required: true, message: t('material:modal.create.errorMsg') },
    ];

    const { mutateAsync } = useUpdateRuleset();

    const close = () => {
        setOpen(false);
        form.resetFields();
    };

    const submit = async () => {
        const values = form.getFieldsValue(true);
        const ruleset = {
            code: values.code,
            name: {
                locales: [{ localeName: 'en', text: values.name }],
            },
            description: {
                locales: [{ localeName: 'en', text: values.description }],
            },
            documentation: [
                ...values.items.map((x: any) => {
                    return {
                        document: x.document,
                        appliesTo: x.appliesTo,
                        isRequired: true,
                        submissionLeadTime: 0,
                    } as SupportDocument;
                }),
            ],
        };

        await mutateAsync({ id: data.id || '', ruleset, delegateId: selected });
        close();
    };

    const updateColumns = useMemo(
        () => [
            {
                title: t('ruleset:detail.documentType'),
                dataIndex: 'document',
                component: (
                    <Select
                        options={Object.values(SupportDocType).map((x) => ({
                            label: getDocumentValue(x),
                            value: x,
                            key: x,
                            disabled:
                                selectedTypes.findIndex((y) => {
                                    return y === x;
                                }) !== -1,
                        }))}
                        onChange={(value) => {
                            setSelectedTypes((prev) => {
                                return [...prev, value];
                            });
                        }}
                    />
                ),
                required: true,
                rules: [
                    {
                        required: true,
                        message: 'Please select document type',
                    },
                ],
            },
            {
                title: t('ruleset:detail.appliesTo'),
                dataIndex: 'appliesTo',
                component: (
                    <Select
                        options={Object.values(SupplyChainNodeType).map(
                            (x) => ({
                                label: getProcessValue(x),
                                value: x,
                                key: x,
                            }),
                        )}
                        mode="multiple"
                    />
                ),
                rules: [],
            },
        ],
        [t, selectedTypes],
    );

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                name:
                    typeof data.name === 'object'
                        ? data.name.locales
                            ? data.name.locales[0]?.text
                            : ''
                        : typeof data.name === 'string'
                        ? JSON.parse(data.name).locales[0].text
                        : '',
                code: data.code,
                description:
                    typeof data.description === 'object'
                        ? data.description?.locales
                            ? data.description.locales[0]?.text
                            : ''
                        : typeof data.description === 'string'
                        ? JSON.parse(data.description).locales[0].text
                        : '',
                items: data.documentation,
            });

            setSelectedTypes(
                data.documentation?.map((x) => x.document) as SupportDocType[],
            );
        }
    }, [data, form, open]);

    return (
        <>
            <StepModal
                title={t('ruleset:modal.edit.title')}
                open={open}
                okFn={submit}
                cancelFn={close}
                width={'60vw'}
                stepContent={[
                    {
                        title: t('ruleset:listing.steps.required'),
                        content: (
                            <>
                                <Form form={form} style={{ marginTop: '16px' }}>
                                    <FormItem
                                        label={t('ruleset:detail.name')}
                                        name={'name'}
                                        required
                                        rules={defaultRules}
                                    >
                                        <Input disabled />
                                    </FormItem>

                                    <FormItem
                                        label={t('ruleset:detail.code')}
                                        name="code"
                                        required
                                        rules={defaultRules}
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t('ruleset:detail.description')}
                                        name="description"
                                        required
                                        rules={defaultRules}
                                    >
                                        <Input />
                                    </FormItem>
                                </Form>
                            </>
                        ),
                    },
                    {
                        title: t('ruleset:listing.steps.rulesetDetails'),
                        content: (
                            <>
                                <AddTable
                                    columns={updateColumns}
                                    dataSource={data.documentation}
                                    form={form}
                                />
                            </>
                        ),
                    },
                ]}
            ></StepModal>
        </>
    );
};

export default EditRulesetComponent;
