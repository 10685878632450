import { useQuery } from '@tanstack/react-query';
import { PurchaseOrderVersion } from '../../../../domain/models/purchase.model';
import { PurchaseService } from '../../../../domain/services/purchase.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseService } from './use-purchase-service';

interface UsePurchaseInterface {
    id?: string;
    customWorkspaceId?: string;
    delegateId?: string;
    enabled?: boolean;
}

const getPurchase = async (
    service: PurchaseService,
    workspace: string,
    id: string,
    customId?: string,
): Promise<PurchaseOrderVersion> => {
    const response = await service.get(
        customId ? customId : workspace,
        id,
        '1',
    );

    return response;
};

export function usePurchase({
    id,
    customWorkspaceId,
    delegateId,
    enabled = true,
}: UsePurchaseInterface) {
    const context = useAppContext();
    const { service } = usePurchaseService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'purchase',
            { workspace: delegateId || context.workspace?.id, id: id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !id) return {};

            return await getPurchase(
                service,
                delegateId || context.workspace.id,
                id,
                customWorkspaceId,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch purchase information: ${error.message}`,
            });
        },
        enabled: enabled,
    });
}
