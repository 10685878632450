import { EditOutlined } from '@ant-design/icons';
import { InfoCard } from '@ianneo/component-library';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UnitType } from '../../../../domain/enums/unit-type.enum';
import { useMaterial } from '../../../../infrastructure/hooks/api/materials/use-material';
import {
    UpdateProductMaterial,
    useUpdateMaterial,
} from '../../../../infrastructure/hooks/api/materials/use-update-material';
import { getUnitValue } from '../../../../infrastructure/utils/unit-type-labeler';
import AddMaterialBreakdown from './add-breakdown/add-material-breakdown';
import MaterialBreakdown from './breakdown/material-breakdown';
import { useDownloadFile } from '../../../../infrastructure/hooks/api/file/use-download-file';
import useVerifyAdmin from '../../../../infrastructure/hooks/use-verify-admin';
import {
    Button,
    Card,
    DescriptionItems,
    Descriptions,
    Form,
    FormItem,
    Gallery,
    Input,
    Modal,
    RadioGroup,
    Select,
    Subtitle,
    Text,
    Title,
    useForm,
} from '@ianneo/ui-library';

interface MaterialDetailComponentProps {}

const MaterialDetailComponent: React.FC<MaterialDetailComponentProps> = () => {
    const { id, delegateId } = useParams();
    const { data } = useMaterial({ id, delegateId });
    const { isAdmin } = useVerifyAdmin();

    const { mutateAsync: update } = useUpdateMaterial();
    const { mutateAsync: downloadFile } = useDownloadFile();

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [addMode, setAddMode] = useState(false);
    const [images, setImages] = useState<string[]>([]);
    const [form] = useForm();

    const [menu, setMenu] = useState('detail');
    const menuOptions = useMemo(
        () => [
            { label: t('material:detail.menu.detail'), value: 'detail' },
            { label: t('material:detail.menu.breakdown'), value: 'breakdown' },
        ],
        [t],
    );

    const descriptions = useMemo(
        () => [
            { label: t('material:detail.name'), value: data?.name, span: 1.5 },
            {
                label: t('material:detail.description'),
                value: data?.description,
                span: 1.5,
            },
            {
                label: t('material:detail.category'),
                value: data?.material?.category,
                span: 1.5,
            },
            {
                label: t('material:detail.baseMaterial'),
                value: data?.material?.name?.locales?.find(
                    (x) => x.localeName === 'en',
                )?.text,
                span: 1.5,
            },
            {
                label: t('material:detail.dateCreated'),
                value: data?.createdOn
                    ? new Date(data.createdOn).toLocaleString().slice(0, 10)
                    : '',
                span: 1.5,
            },
            {
                label: t('material:detail.lastUpdated'),
                value: data?.lastUpdatedOn
                    ? new Date(data.lastUpdatedOn).toLocaleString().slice(0, 10)
                    : '',
                span: 1.5,
            },
            {
                label: t('material:detail.unit'),
                value: data?.unit ? getUnitValue(data.unit) : '',
                span: 1.5,
            },
        ],
        [t, data],
    );

    const onSubmit = async () => {
        await form.validateFields();

        const values = form.getFieldsValue(true);
        await update({ id: data?.id || '', args: values, delegateId });

        setOpen(false);
    };

    const onAddBreakdown = async (args: UpdateProductMaterial) => {
        await form.validateFields();

        await update({ id: data?.id || '', args, delegateId });

        setAddMode(false);
    };

    const getImages = useCallback(async () => {
        const promises = (data?.pictures || []).map(async (x) => {
            const res = await downloadFile({ attachment: x });
            return res?.token;
        });

        const response = await Promise.all(promises);

        setImages(response as string[]);
    }, [downloadFile, data?.pictures]);

    useEffect(() => {
        form.setFieldsValue({
            name: data?.name,
            description: data?.description,
            unit: data?.unit,
        });
    }, [form, data]);

    useEffect(() => {
        getImages();
    }, [data, getImages]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '8px',
                            backgroundColor: '#972d47',
                            minHeight: '40px',
                            minWidth: '8px',
                        }}
                    ></div>
                    <Title>{t('material:name')}</Title>
                    <Subtitle>{t('material:description')}</Subtitle>
                </div>

                <InfoCard
                    infoTitle={data?.name}
                    information={
                        <Text style={{ alignSelf: 'baseline' }}>
                            {data?.description}
                        </Text>
                    }
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                >
                    <RadioGroup
                        optionType="button"
                        options={menuOptions}
                        defaultValue={menu}
                        onChange={(e) => setMenu(e.target.value)}
                    />

                    {menu === 'detail' ? (
                        <>
                            <Card>
                                <div style={{ display: 'flex', gap: '32px' }}>
                                    <Gallery images={images}></Gallery>

                                    <Descriptions>
                                        {descriptions.map((x, index) => {
                                            return (
                                                <DescriptionItems
                                                    key={index}
                                                    label={x.label}
                                                    span={x.span}
                                                >
                                                    {x.value}
                                                </DescriptionItems>
                                            );
                                        })}
                                    </Descriptions>

                                    <Button
                                        btntype="Default"
                                        onClick={() => setOpen(true)}
                                        icon={<EditOutlined />}
                                        hidden={isAdmin}
                                    >
                                        {t('common:edit')}
                                    </Button>
                                </div>
                            </Card>
                        </>
                    ) : (
                        <MaterialBreakdown
                            material={data || {}}
                            setOpen={setAddMode}
                            update={onAddBreakdown}
                        />
                    )}
                </div>
            </div>

            {open ? (
                <Modal
                    title={t('material:modal.edit.title')}
                    open={open}
                    okFn={() => onSubmit()}
                    cancelFn={() => setOpen(false)}
                >
                    <Form
                        form={form}
                        wrapperCol={{ span: 18 }}
                        labelAlign="right"
                        labelWrap={true}
                        labelCol={{ span: 6 }}
                    >
                        <FormItem
                            name="name"
                            label={t('material:modal.edit.name') || ''}
                        >
                            <Input />
                        </FormItem>

                        <FormItem
                            label={t('material:modal.edit.description')}
                            name="description"
                        >
                            <Input />
                        </FormItem>

                        <FormItem
                            label={t('material:modal.edit.unit')}
                            name="unit"
                        >
                            <Select
                                options={Object.values(UnitType).map((x) => ({
                                    key: x,
                                    value: x,
                                    label: getUnitValue(x),
                                }))}
                                getPopupContainer={undefined}
                            />
                        </FormItem>
                    </Form>
                </Modal>
            ) : null}

            <AddMaterialBreakdown
                data={data || {}}
                open={addMode}
                setOpen={setAddMode}
                update={onAddBreakdown}
            />
        </>
    );
};

export default MaterialDetailComponent;
