import {
    Button,
    Card,
    ColumnsType,
    DatePicker,
    Form,
    FormItem,
    Link,
    Modal,
    Switch,
    Table,
    useForm,
} from '@ianneo/ui-library';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { SalesOrder } from '../../../../domain/models/sales-order.model';
import { useUpdateOrderShipped } from '../../../../infrastructure/hooks/api/order/use-update-order-shipped';

interface OrderShipmentsProps {
    selected?: string;
    open: boolean;
    order: SalesOrder;
    setOpen: (args: boolean) => void;
}

const OrderShipments: React.FC<OrderShipmentsProps> = ({
    selected,
    open,
    order,
    setOpen,
}) => {
    const [form] = useForm();
    const [addMode, setAddMode] = useState(false);
    const [validationEnabled, setValidationEnabled] = useState(false);
    const { mutateAsync } = useUpdateOrderShipped();
    const [shippedDates, setShippedDates] = useState<Date[]>([]);
    const [removedDates, setRemovedDates] = useState<Date[]>([]);

    const reset = (close: boolean) => {
        setOpen(close);
        setAddMode(false);
        setRemovedDates([]);
        setShippedDates([]);
        form.resetFields();
    };

    const submit = async () => {
        const consolidatedShippedDates = shippedDates.filter(
            (x) => !removedDates.includes(x),
        );

        await mutateAsync({
            id: order.id || '',
            data: {
                shippedOn: consolidatedShippedDates.map((x) =>
                    moment(x).format(),
                ),
            },
            workspaceId: selected,
        });

        form.resetFields();
        setAddMode(false);

        reset(false);
    };

    const columns = useMemo(() => {
        const items: ColumnsType<any> = [
            {
                title: 'Shipment Count',
                dataIndex: 'id',
                render: (_, x) => {
                    return <>#{x.id}</>;
                },
            },
            {
                title: 'Shipment Date',
                dataIndex: 'shippedOn',
                render: (_, x) => {
                    const date = moment(x.shippedOn).format().split('T')[0];
                    return <>{date}</>;
                },
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                render: (_, x) => {
                    return (
                        <Link
                            onClick={() => {
                                setRemovedDates((prev) => [
                                    ...prev,
                                    x.shippedOn,
                                ]);

                                setShippedDates((prev) =>
                                    prev.filter((y) => y !== x.shippedOn),
                                );
                            }}
                        >
                            Remove
                        </Link>
                    );
                },
            },
        ];

        return items;
    }, []);

    useEffect(() => {
        setShippedDates(order.shippedOn || []);
    }, [order.shippedOn, open]);

    return (
        <>
            <Modal
                open={open}
                cancelFn={() => reset(false)}
                okFn={submit}
                title="Shipment Management"
                width={1000}
            >
                <Table
                    showColumns={false}
                    showFilter={false}
                    showSearch={false}
                    columns={columns}
                    rootClassName="max-h-[300px]"
                    key="shippedOn"
                    className="max-h-[300px]"
                    dataSource={shippedDates.map((x, index) => {
                        return {
                            id: index + 1,
                            shippedOn: x,
                        };
                    })}
                    pagination={{
                        pageSize: 5,
                    }}
                    footer={() => (
                        <Link onClick={() => setAddMode((prev) => !prev)}>
                            Add
                        </Link>
                    )}
                />

                <Card style={{ marginTop: '12px' }} hidden={!addMode}>
                    <Form form={form}>
                        <FormItem label="Shipment Date" name="shippedDate">
                            <DatePicker
                                style={{ width: '100%' }}
                                disabledDate={(date) => {
                                    const existingDates = shippedDates.map(
                                        (x) => {
                                            return moment(x)
                                                .format()
                                                .split('T')[0];
                                        },
                                    );

                                    const dateString = date
                                        .format()
                                        .split('T')[0];

                                    const disabled =
                                        existingDates.some((x) => {
                                            const shippedString = moment(x)
                                                .format()
                                                .split('T')[0];

                                            return dateString === shippedString;
                                        }) || false;

                                    return (
                                        (moment() >= date &&
                                            validationEnabled) ||
                                        disabled
                                    );
                                }}
                            />
                        </FormItem>

                        <FormItem label="Validation Enabled">
                            <Switch
                                checked={validationEnabled}
                                onClick={(checked) => {
                                    setValidationEnabled(checked);

                                    if (
                                        form.getFieldValue('shippedDate') <
                                            moment() &&
                                        checked
                                    ) {
                                        form.setFieldsValue({
                                            shippedDate: null,
                                        });
                                    }
                                }}
                            />
                        </FormItem>

                        <div
                            style={{
                                display: 'flex',
                                gap: '12px',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button
                                btntype="Close"
                                onClick={() => setAddMode(false)}
                            >
                                Cancel
                            </Button>

                            <Button
                                btntype="Submit"
                                onClick={() => {
                                    const newDate: moment.Moment =
                                        form.getFieldValue('shippedDate');

                                    setShippedDates((prev) => [
                                        ...prev,
                                        newDate.toDate(),
                                    ]);

                                    setAddMode(false);
                                    form.resetFields();
                                }}
                            >
                                Confirm
                            </Button>
                        </div>
                    </Form>
                </Card>
            </Modal>
        </>
    );
};

export default OrderShipments;
