import { useQuery } from '@tanstack/react-query';
import { Ruleset } from '../../../../domain/models/ruleset.model';
import { RulesetService } from '../../../../domain/services/ruleset.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useRulesetService } from './use-ruleset-service';

const getRulesets = async (
    service: RulesetService,
    id: string,
): Promise<Ruleset[]> => {
    const response = await service.list(id);

    return response;
};

export function useRulesets(workspaceId?: string) {
    const context = useAppContext();
    const { service } = useRulesetService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'rulesets',
            { workspace: workspaceId || context.workspace?.id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id) return;
            return await getRulesets(
                service,
                workspaceId || context.workspace.id,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch rulesets: ${error.message}`,
            });
        },
    });
}
