import { Modal, Select, Text } from '@ianneo/ui-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Workspace } from '../../../domain/models/workspace.model';
import { useGetWorkspaces } from '../../../infrastructure/hooks/api/me/use-get-workspaces';
import { useSwitchWorkspace } from '../../../infrastructure/hooks/api/me/use-switch-workspace';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';

interface SwitchWorkspaceProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export default function SwitchWorkspace({
    open,
    setOpen,
}: SwitchWorkspaceProps) {
    const { t } = useTranslation();
    const context = useAppContext();

    const [selected, setSelected] = useState<React.Key>('');
    const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace>({});

    const { data: workspaces } = useGetWorkspaces();

    const unique = workspaces
        ?.filter((x) => x)
        ?.filter(
            (obj, index) =>
                workspaces.findIndex((item) => item.id === obj.id) === index,
        );

    const { mutateAsync: switchWorkspace } = useSwitchWorkspace();

    const reset = () => {
        setSelected('');
        setSelectedWorkspace({});
        setOpen(false);
    };

    const submit = async () => {
        if (!selectedWorkspace) return;

        await switchWorkspace({ workspace: selectedWorkspace });

        reset();
    };

    useEffect(() => {
        setSelected(context.workspace?.id || '');
    }, [context.workspace?.id, open]);

    return (
        <Modal
            title={t('common:workspace.header')}
            open={open}
            cancelFn={reset}
            okFn={submit}
        >
            <div style={{ display: 'flex', gap: '12px' }}>
                <Text
                    style={{
                        flex: 1,
                        maxWidth: '30%',
                        width: '30%',
                    }}
                >
                    {t('common:workspace.selectAccount')}:{' '}
                </Text>

                <Select
                    options={unique?.map((x) => ({
                        label: x.displayName,
                        value: x.id,
                    }))}
                    allowClear={false}
                    style={{ flex: 3, maxWidth: '70%', width: '70%' }}
                    value={selected}
                    onChange={(item) => {
                        setSelected(item);
                        setSelectedWorkspace(
                            workspaces?.find((x) => x.id === item) || {},
                        );
                    }}
                ></Select>
            </div>
        </Modal>
    );
}
