import { StepModal, UploadFile } from '@ianneo/ui-library';
import { useCallback, useEffect, useState } from 'react';
import { SalesOrder } from '../../../../domain/models/sales-order.model';
import { Workspace } from '../../../../domain/models/workspace.model';
import { useWorkspaceService } from '../../../../infrastructure/hooks/api/workspaces/use-workspace-service';
import { useAlertContext } from '../../../../infrastructure/hooks/use-alert.hook';
import ImportDownloadTemplate from '../../../components/Import/import-download-template';
import ImportUploadTemplate from '../../../components/Import/import-upload-template';
import {
    ImportErrorDialog,
    ImportErrorMessage,
} from '../../Purchases/import-batch-purchase-order-v2/import-error-dialog';
import { useImportActionTemplate } from './import-action-template';
import { useImportTemplate } from './import-default-template';

interface Props {
    order: SalesOrder;
    onClose: () => void;
}

export const OrderImportCascade = ({ order, onClose }: Props) => {
    const { setAlert } = useAlertContext();
    const [loaderType, setLoaderType] = useState(0);
    const [errors, setErrors] = useState<ImportErrorMessage[]>([]);

    const { service: workspaceService } = useWorkspaceService();

    const { submit: importActionTemplate } = useImportActionTemplate();
    const { submit: importTemplate } = useImportTemplate();

    const [files, setFiles] = useState<UploadFile[]>([]);

    const Document =
        loaderType === 1
            ? require('./action_template.xlsx')
            : require('./default_template.xlsx');

    const getWorkspace = useCallback(async () => {
        const workspace = await workspaceService.get(
            order.customer?.buyer?.id || '',
            '1',
        );

        setLoaderType(workspace?.supplyChainLoaderType || 0);
    }, [order.customer?.buyer?.id, workspaceService]);

    const reset = () => {
        onClose();
        setFiles([]);
        setErrors([]);
    };

    const submit = async () => {
        const parentWorkspaceId = order.customer?.buyer?.id || '';
        const file = files?.[0]?.originFileObj;

        const parentWorkspace: Workspace = await workspaceService.get(
            parentWorkspaceId,
            '1',
        );

        if (!file) {
            setAlert({
                message: 'Please upload a file',
                type: 'error',
                title: 'Import Error',
            });

            return;
        }

        try {
            if (loaderType === 1) {
                return await importActionTemplate(
                    parentWorkspace.id || '',
                    order.purchaseOrderId || '',
                    file,
                );
            }

            await importTemplate(
                parentWorkspace.id || '',
                order.purchaseOrderId || '',
                file,
            );

            reset();
        } catch (err) {
            if (err instanceof Error) {
                setAlert({
                    message: err.message,
                    type: 'error',
                    title: 'Import Error',
                });
            } else if (err instanceof Array) {
                const error = err as ImportErrorMessage[];

                setErrors(error);
                return;
            }
        }
    };

    useEffect(() => {
        getWorkspace();
    }, [getWorkspace]);

    return (
        <>
            <StepModal
                title="Import Purchase Order"
                open
                width={'60vw'}
                cancelFn={reset}
                okFn={submit}
                bodyStyle={{
                    height: '30vh',
                }}
                stepContent={[
                    {
                        title: 'Download Template',
                        content: (
                            <ImportDownloadTemplate
                                type={'supply_chain'}
                                extension={'xlsx'}
                                document={Document}
                                fileName={
                                    loaderType === 1
                                        ? 'Production Process Mapping File.xlsx'
                                        : 'supply_chain_template.xlsx'
                                }
                            />
                        ),
                    },
                    {
                        title: 'Import Purchase Order',
                        content: (
                            <ImportUploadTemplate
                                files={files}
                                setFiles={setFiles}
                            />
                        ),
                    },
                ]}
            ></StepModal>

            <ImportErrorDialog errors={errors} setErrors={setErrors} />
        </>
    );
};
