/**
 * @enum
 * @name CompanyDocumentType
 * @description The type documents allowed in this service
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */
export enum CompanyDocumentType {
    AUDIT_REPORTS = 'AUDIT_REPORTS',
    BANK_CERTIFICATE = 'BANK_CERTIFICATE',
    BUSINESS_REGISTRATION = 'BUSINESS_REGISTRATION',
    CERTIFICATION_AWARD = 'CERTIFICATION_AWARD',
    DISTRIBUTION_LICENSE = 'DISTRIBUTION_LICENSE',
    EXPORT_LICENSE = 'EXPORT_LICENSE',
    IMPORT_LICENSE = 'IMPORT_LICENSE',
    PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
    TAX_PAYMENT = 'TAX_PAYMENT',
    OTHER = 'OTHER',
    FACTORY_PROFILE = 'FACTORY_PROFILE',
    GEO_TAG = 'GEO_TAG',
}
export const CompanyDocumentTypeLabeler = {
    [CompanyDocumentType.AUDIT_REPORTS]: 'Audit Reports',
    [CompanyDocumentType.BANK_CERTIFICATE]: 'Bank Certificate',
    [CompanyDocumentType.BUSINESS_REGISTRATION]: 'Business Registration',
    [CompanyDocumentType.CERTIFICATION_AWARD]: 'Certification Award',
    [CompanyDocumentType.DISTRIBUTION_LICENSE]: 'Distribution License',
    [CompanyDocumentType.EXPORT_LICENSE]: 'Export License',
    [CompanyDocumentType.IMPORT_LICENSE]: 'Import License',
    [CompanyDocumentType.PROOF_OF_ADDRESS]: 'Proof of Address',
    [CompanyDocumentType.TAX_PAYMENT]: 'Tax Payment',
    [CompanyDocumentType.OTHER]: 'Other',
    [CompanyDocumentType.FACTORY_PROFILE]: 'Factory Profile',
    [CompanyDocumentType.GEO_TAG]: 'Geo Tag',
};
