import {
    DeleteOutlined,
    DownloadOutlined,
    FileAddOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import {
    CollapsibleDropdown,
    Link,
    Popconfirm,
    Select,
    Subtitle,
    Table,
    Text,
    Title,
} from '@ianneo/ui-library';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { utils, writeFileXLSX } from 'xlsx';
import { ProductMaterial } from '../../../domain/models/material.model';
import { useDeleteMaterial } from '../../../infrastructure/hooks/api/materials/use-delete-materials';
import { useMaterials } from '../../../infrastructure/hooks/api/materials/use-materials';
import useToggle from '../../../infrastructure/hooks/use-toggle';
import useVerifyAdmin from '../../../infrastructure/hooks/use-verify-admin';
import { getUnitValue } from '../../../infrastructure/utils/unit-type-labeler';
import { useGetDelegations } from '../../_api/delegations/hooks/useGetDelegations';
import Import from '../../components/Import/import';
import AddMaterial from './add-material';

interface MaterialsLayoutProps {}

const MaterialsLayout: React.FC<MaterialsLayoutProps> = (args) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { delegations } = useGetDelegations();
    const [selected, setSelected] = useState(0);
    const { data } = useMaterials(
        delegations?.find((x) => x.id === selected)?.workspace?.id,
    );
    const [open, setOpen] = useState(false);
    const { mutateAsync: remove } = useDeleteMaterial();

    const exportMaterials = useCallback(() => {
        const ws = utils.json_to_sheet(data || []);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Data');
        writeFileXLSX(wb, `Materials-${new Date().getTime()}.xlsx`);
    }, [data]);

    const { status: importMode, toggle } = useToggle();
    const { isAdmin } = useVerifyAdmin();

    const items = useMemo(() => {
        const items = [
            {
                label: t('user:listing.user.importBtn'),
                key: 'import',
                icon: <FileAddOutlined />,
                onClick: () => toggle(),
            },
            {
                label: t('common:export'),
                key: 'export',
                icon: <DownloadOutlined />,
                onClick: async () => exportMaterials(),
            },
            {
                label: t('common:addNew'),
                key: 'create',
                icon: <PlusOutlined />,
                onClick: () => setOpen(true),
            },
        ];

        if (isAdmin) {
            items.splice(0, 1);
            items.splice(1, 2);
        }

        return items;
    }, [t, toggle, exportMaterials, isAdmin]);

    const columns = useMemo(() => {
        const delegateId = delegations?.find((x) => x.id === selected)
            ?.workspace?.id;

        const col = [
            {
                title: t('material:listing.name'),
                dataIndex: 'name',
                render: (_: any, item: ProductMaterial) => (
                    <Link
                        onClick={() =>
                            navigate(
                                delegateId
                                    ? `/materials/${item.id}/${delegateId}`
                                    : `/materials/${item.id}`,
                            )
                        }
                    >
                        {item.name}
                    </Link>
                ),
            },
            {
                title: t('material:listing.description'),
                dataIndex: 'description',
            },
            {
                title: t('material:listing.unit'),
                dataIndex: 'unit',
                render: (_: any, item: ProductMaterial) => (
                    <Text>{getUnitValue(item.unit)}</Text>
                ),
            },
            {
                title: t('common:updatedOn'),
                dataIndex: 'lastUpdatedOn',
                render: (_: any, item: ProductMaterial) => {
                    return (
                        <>
                            {item?.lastUpdatedOn
                                ? new Date(item.lastUpdatedOn)
                                      .toISOString()
                                      .slice(0, 10)
                                : ''}
                        </>
                    );
                },
            },
            {
                title: t('common:actions'),
                dataIndex: 'actions',
                render: (_: any, item: ProductMaterial) => {
                    return (
                        <Popconfirm
                            title="Confirm to delete?"
                            submit={async () =>
                                remove({
                                    id: item.id || '',
                                    delegateId: delegations?.find(
                                        (x) => x.id === selected,
                                    )?.workspace?.id,
                                })
                            }
                            disabled={isAdmin}
                        >
                            <DeleteOutlined style={{ color: 'red' }} />
                        </Popconfirm>
                    );
                },
            },
        ];

        if (isAdmin) {
            col.pop();
        }

        return col;
    }, [navigate, t, remove, isAdmin, delegations, selected]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    marginBottom: '24px',
                }}
            >
                <div
                    style={{
                        width: '8px',
                        backgroundColor: '#972d47',
                        minHeight: '40px',
                        minWidth: '8px',
                    }}
                ></div>
                <Title>{t('material:name')}</Title>
                <Subtitle>{t('material:description')}</Subtitle>
            </div>

            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                    height: '100dch',
                    flex: 1,
                }}
            >
                <Table
                    dataSource={data}
                    columns={columns}
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                    additionalFilters={
                        <div
                            style={{
                                display:
                                    !delegations || delegations.length === 0
                                        ? 'none'
                                        : 'flex',
                                gap: '8px',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ flex: 1 }}>For Company:</span>
                            <Select
                                options={delegations?.map((x) => {
                                    return {
                                        label: x.workspace?.company?.name,
                                        value: x.id,
                                    };
                                })}
                                style={{
                                    maxWidth: '200px',
                                    width: '200px',
                                    flex: 3,
                                }}
                                onChange={(value) => {
                                    setSelected(value);
                                }}
                                placeholder="None"
                                suffixIcon={null}
                                getPopupContainer={undefined}
                            ></Select>
                        </div>
                    }
                    actions={
                        <CollapsibleDropdown
                            menu={{
                                items,
                            }}
                        ></CollapsibleDropdown>
                    }
                />
            </div>

            <AddMaterial open={open} setOpen={setOpen} />

            <Import type="materials" open={importMode} setOpen={toggle} />
        </>
    );
};

export default MaterialsLayout;
