import { DownloadOutlined } from '@ant-design/icons';
import {
    Card,
    CollapsibleDropdown,
    Link,
    Table,
    Text,
} from '@ianneo/ui-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDocument } from '../../../../domain/models/company-doc.model';
import { Supplier } from '../../../../domain/models/supplier.model';
import useDownloadDocument from '../../../../infrastructure/hooks/api/company/documents/use-download-document';
import useDownloadDocuments from '../../../../infrastructure/hooks/api/company/documents/use-download-documents';
import { useSolicitationDocuments } from '../../../../infrastructure/hooks/api/solicitation/use-soliciation-documents';

interface PartnerDocumentComponentProps {
    partner?: Supplier;
}

const PartnerDocumentComponent: React.FC<PartnerDocumentComponentProps> = (
    props,
) => {
    const { t } = useTranslation();
    const { data } = useSolicitationDocuments({
        partner: props.partner?.seller?.id,
    });
    const [selectedRows, setSelectedRows] = useState<CompanyDocument[]>([]);

    const downloadDocument = useDownloadDocument();
    const downloadDocuments = useDownloadDocuments();

    const rowSelection = {
        selectedRows,
        onChange: (_: React.Key[], selected: any) => {
            setSelectedRows(selected);
        },
    };

    const onDownload = () => {
        downloadDocuments({ documents: selectedRows });
    };

    const columns = [
        {
            title: t('company:documents.docName'),
            dataIndex: 'file',
            render: (_: any, item: CompanyDocument) => (
                <Text>{item?.file?.originalName}</Text>
            ),
        },
        { title: t('company:documents.type'), dataIndex: 'nature' },
        { title: t('company:documents.verifiedOn'), dataIndex: 'verifiedOn' },
        {
            title: t('common:updatedOn'),
            dataIndex: 'lastUpdatedOn',
            render: (_: string, record: CompanyDocument) => {
                return (
                    <Text>
                        {record.lastUpdatedOn
                            ? new Date(record.lastUpdatedOn)
                                  .toISOString()
                                  .slice(0, 10)
                            : ''}
                    </Text>
                );
            },
        },
        {
            title: t('common:actions'),
            render: (_: any, item: CompanyDocument) => (
                // Trigger the download document for an individual document
                <Link
                    onClick={async () =>
                        await downloadDocument({ document: item })
                    }
                >
                    {t('common:download')}
                </Link>
            ),
        },
    ];

    return (
        <>
            <Card>
                <Table
                    columns={columns}
                    dataSource={data || []}
                    rowKey="id"
                    rowSelection={rowSelection}
                    scroll={{ x: 'max-content' }}
                    actions={
                        <CollapsibleDropdown
                            menu={{
                                items: [
                                    {
                                        label: t('common:download'),
                                        key: 'download',
                                        icon: <DownloadOutlined />,
                                        onClick: async () => onDownload(),
                                    },
                                ],
                            }}
                        ></CollapsibleDropdown>
                    }
                />
            </Card>
        </>
    );
};

export default PartnerDocumentComponent;
