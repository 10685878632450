import { useMemo } from 'react';
import { SolicitationService } from '../../../../domain/services/solicitation-service';
import useAppConfig from '../../use-config.hook';
import useAppContext from '../../use-context.hook';

export function useSolicitationService() {
    const config = useAppConfig();
    const context = useAppContext();

    const service = useMemo(() => {
        if (context.token) {
            return new SolicitationService(config, context.token);
        } else {
            throw new Error('Session Expired');
        }
    }, [config, context.token]);

    return { service };
}
