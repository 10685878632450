import { Modal, Table } from '@ianneo/ui-library';
import { useMemo } from 'react';

interface Props {
    errors: ImportErrorMessage[];
    setErrors: (errors: ImportErrorMessage[]) => void;
}

export interface ImportErrorMessage {
    rowNum: number;
    message: string;
}

export const ImportErrorDialog = ({ errors, setErrors }: Props) => {
    const columns = useMemo(
        () => [
            {
                title: 'Row No.',
                render: (_: unknown, item: ImportErrorMessage) => item.rowNum,
            },
            {
                title: 'Error Message',
                render: (_: unknown, item: ImportErrorMessage) => item.message,
            },
        ],
        [],
    );

    return (
        <Modal
            title="Import Errors"
            open={errors.length > 0}
            onOk={() => setErrors([])}
            cancelFn={() => setErrors([])}
            width={'60vw'}
        >
            <Table
                showFilter={false}
                showSearch={false}
                showColumns={false}
                dataSource={errors}
                columns={columns}
            />
        </Modal>
    );
};
