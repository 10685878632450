import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useRoleService } from './use-role-service';

export interface UpdateRolePermissionParams {
    id: string;
    args: {
        items: UpdateRolePermissionItem[];
    };
    delegateId?: string;
}

export interface UpdateRolePermissionItem {
    application: string;
    objectCode: string;
    scope: string;
}

export function useUpdateRolePermissions() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useRoleService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({
            id,
            args,
            delegateId,
        }: UpdateRolePermissionParams) => {
            if (!context.workspace?.id) return;
            await service.updatePermissions(
                delegateId || context.workspace.id,
                id,
                args,
            );
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: [
                    'role',
                    {
                        workspace: args.delegateId || context.workspace?.id,
                        id: args.id,
                    },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Role updated successfully',
                title: 'Update role',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to update role permissions: ${error.message}`,
                title: 'Update Role Permissions',
            });
        },
    });
}
