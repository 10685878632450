import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { CompanyDocument } from '../../../../../domain/models/company-doc.model';
import { useAlertContext } from '../../../use-alert.hook';
import useAppContext from '../../../use-context.hook';
import { useFileService } from '../../file/use-files-service';

interface DownloadDocumentInterface {
    documents: CompanyDocument[];
}

export default function useDownloadDocuments() {
    const context = useAppContext();
    const { service } = useFileService();
    const { setAlert } = useAlertContext();

    const downloadDocument = async ({
        documents,
    }: DownloadDocumentInterface) => {
        try {
            const links: any[] = [];

            await Promise.all(
                documents.map(async (doc: CompanyDocument) => {
                    const token = await service.download(
                        context.workspace?.id || '',
                        doc.file,
                    );

                    const response = await axios.get(token.token, {
                        responseType: 'blob',
                    });

                    links.push({
                        url: response.data,
                        name: token.attachment.originalName,
                    });
                }),
            );

            const zip = new JSZip();
            links.forEach((link) => {
                zip.file(link.name, link.url);
            });

            zip.generateAsync({ type: 'blob' }).then((zipFile) => {
                const currentDate = new Date().getTime();
                const fileName = `documents-${currentDate}.zip`;
                return saveAs(zipFile, fileName);
            });
        } catch (err) {
            const error = err as Error;

            setAlert({
                type: 'error',
                title: 'Download Certificate',
                message: `Failed to download certificate: ${error.message}`,
            });
        }
    };

    return downloadDocument;
}
