import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Adaptation } from '../../../clients/template.client';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import useTemplateService from './use-template-service';

export interface CreateTemplateRequest {
    template: string;
    adaptation: Adaptation;
}

export function useCreateTemplate() {
    const context = useAppContext();
    const { service } = useTemplateService();
    const client = useQueryClient();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ template, adaptation }: CreateTemplateRequest) => {
            if (!context.workspace?.id) return;

            await service.create(context.workspace.id, template, adaptation);
        },
        onSuccess: (_, { template }) => {
            client.invalidateQueries({
                queryKey: ['template'],
            });

            setAlert({
                type: 'success',
                message: `Template ${template} created successfully`,
                title: 'Create Template',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to create template: ${error.message}`,
                title: 'Create Template',
            });
        },
    });
}
