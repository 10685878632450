import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { SupportDocType } from '../../../../domain/enums/support-doctype.enum';
import { SalesOrderDocument } from '../../../../domain/models/sales-order.model';
import { FileService } from '../../../../domain/services/file.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useFileService } from '../file/use-files-service';
import { UploadFile } from '@ianneo/ui-library';

export interface UploadDocumentsInterface {
    type: SupportDocType | string;
    certificateNumber?: string;
    files: UploadFile[];
}

const uploadDocument = async (
    service: FileService,
    workspaceId: string,
    type: SupportDocType | string,
    files: UploadFile[],
    certificateNumber?: string,
): Promise<SalesOrderDocument[]> => {
    const response = await Promise.all(
        files.map(async (file) => {
            const token = await service.upload(workspaceId, 'salesOrder', {
                filename: file.name,
                contentType: file.type || '',
            });

            await axios.put(token.token, file.originFileObj, {
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': file.type || '',
                },
            });

            return {
                type,
                file: {
                    assetName: token.attachment.assetName,
                    container: 'temporary',
                    contentType: file.type || '',
                    originalName: file.originFileObj?.name,
                },
                certificateNumber,
            } as SalesOrderDocument;
        }),
    );

    return response;
};

/**
 * Upload certification API which will be updated when any of the parameters in the [] array changes
 * Also will set the refresh state to true which will trigger the useEffect hook to fetch the documents
 */
export function useUploadPurchaseFiles() {
    const context = useAppContext();
    const { service } = useFileService();
    const { setAlert } = useAlertContext();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            type,
            files,
            certificateNumber,
        }: UploadDocumentsInterface) => {
            if (!context.workspace?.id) return;

            return await uploadDocument(
                service,
                context.workspace.id,
                type,
                files,
                certificateNumber,
            );
        },
        onSuccess: () => {
            client.invalidateQueries({
                queryKey: ['companyDocuments', { type: context.workspace?.id }],
            });

            setAlert({
                type: 'success',
                message: 'Documents uploaded successfully',
                title: 'Upload Purchase Documents',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to upload documents: ${error.message}`,
                title: 'Upload Purchase Documents',
            });
        },
    });
}
