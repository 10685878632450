import {
    Button,
    Card,
    Content,
    Form,
    FormItem,
    Header,
    Input,
    Layout,
    useForm,
} from '@ianneo/ui-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useConsumeSession } from '../../../infrastructure/hooks/api/admin/use-consume-session';
import NavBar from '../../components/NavBar/nav-bar.component';
import SwitchLanguage from '../../components/SwitchLanguage/switch-language';
import SwitchWorkspace from '../../components/SwitchWorkspace/switch-workspace';

export default function AdminAssume() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [roleOpen, setRoleOpen] = useState(false);
    const [lngOpen, setLngOpen] = useState(false);
    const [form] = useForm();

    const { mutateAsync: consume } = useConsumeSession();

    const submit = async () => {
        try {
            await form.validateFields();

            const response = await consume({
                code: form.getFieldValue('accessCode'),
            });

            sessionStorage.setItem(
                'assumedSession',
                JSON.stringify(response.emulateAsUser),
            );

            navigate(0);
        } catch (err) {
            return;
        }
    };

    const cancel = () => {
        navigate('/');
        localStorage.removeItem('session');
    };

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>
                <Header
                    style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 2px #ddd',
                        position: 'fixed',
                        display: 'flex',
                        zIndex: 1,
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            style={{ height: '35px' }}
                            src="https://hk.jobsdb.com/en-hk/wp-content/uploads/sites/2/2021/01/vVYMIxN.png"
                            alt="Application Logo"
                            aria-label="dashboard"
                        />

                        <NavBar
                            setOpen={setRoleOpen}
                            setChangeLngMode={setLngOpen}
                            displayDropdown={false}
                        />
                    </div>
                </Header>

                <Layout
                    hasSider={true}
                    style={{
                        backgroundColor: '#EEEEEE',
                        marginTop: 64,
                    }}
                >
                    <Content
                        style={{
                            padding: '50px 50px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Card
                            title="Assume Role"
                            style={{ display: 'flex', flexDirection: 'column' }}
                            bodyStyle={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Form form={form}>
                                <FormItem
                                    label="Access Code"
                                    name="accessCode"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter your access code',
                                        },
                                    ]}
                                >
                                    <Input />
                                </FormItem>
                            </Form>

                            <div
                                style={{
                                    display: 'flex',
                                    alignSelf: 'flex-end',
                                    gap: '12px',
                                }}
                            >
                                <Button onClick={cancel} btntype="Close">
                                    {t('common:cancel')}
                                </Button>

                                <Button onClick={submit} btntype="Submit">
                                    {t('common:submit')}
                                </Button>
                            </div>
                        </Card>
                    </Content>
                </Layout>
            </Layout>

            <SwitchWorkspace open={roleOpen} setOpen={setRoleOpen} />

            <SwitchLanguage open={lngOpen} setOpen={setLngOpen} />
        </>
    );
}
