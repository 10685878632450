import { AdminApiClient } from '../../infrastructure/clients/admin.server';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';

export class AdminService {
    private server: AdminApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new AdminApiClient(config, token);
    }

    async consumeSession(session: string, code: string) {
        console.log(`service<admin>| consume(): Enter`);
        console.log(`service<admin>| consume(): $session = ${session}`);

        return await this.server.consumeSession(session, code);
    }
}
