import { UploadFile } from '@ianneo/ui-library';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { ImportBatchParams } from '../../../../domain/services/file.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useFileService } from './use-files-service';

interface UseImportBatchProps {
    file: UploadFile;
    type: string;
    params: ImportBatchParams;
}

export function useImportBatch() {
    const context = useAppContext();
    const { service } = useFileService();
    const { setAlert } = useAlertContext();
    const [reset, setReset] = useState(false);
    const client = useQueryClient();

    useEffect(() => {
        if (reset) {
            // This is to refresh the cache after 10 seconds where the processor will process all the input.
            const timer = setTimeout(() => {
                client.invalidateQueries({
                    queryKey: ['members'],
                });

                client.invalidateQueries({
                    queryKey: ['products'],
                });

                client.invalidateQueries({
                    queryKey: ['partners'],
                });

                client.invalidateQueries({
                    queryKey: ['materials'],
                });

                setReset(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [client, reset]);

    return useMutation({
        mutationFn: async ({ file, type, params }: UseImportBatchProps) => {
            if (!context.workspace?.id || !file || !type || !params) return;

            const { token } = await service.batch(
                context.workspace.id,
                type,
                params,
            );

            await axios.put(token, file.originFileObj, {
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': file.type || '',
                },
            });

            setReset(true);
        },
        onSuccess: () => {
            setAlert({
                message:
                    'Successfully uploaded batch file, please wait for the system to process the file and refresh the page.',
                type: 'success',
                title: 'Import Batch',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                message: `Failed to import batch file: ${error.message}`,
                type: 'error',
                title: 'Import Batch',
            });
        },
    });
}
