import { useQuery } from '@tanstack/react-query';
import { ProductMaterial } from '../../../../domain/models/material.model';
import { MaterialService } from '../../../../domain/services/material.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useMaterialService } from './use-material-service';

interface UseMaterialInterface {
    id?: string;
    delegateId?: string;
}

const getMaterial = async (
    service: MaterialService,
    workspace: string,
    id: string,
): Promise<ProductMaterial> => {
    const response = await service.get(workspace, id);

    return response;
};

export function useMaterial({ id, delegateId }: UseMaterialInterface) {
    const context = useAppContext();
    const { service } = useMaterialService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'material',
            { workspace: delegateId || context.workspace?.id, id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !id) return;
            return await getMaterial(
                service,
                delegateId || context.workspace.id,
                id,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch material information: ${error.message}`,
            });
        },
    });
}
