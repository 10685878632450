import {
    DeleteOutlined,
    FileAddOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import {
    CollapsibleDropdown,
    Link,
    Popconfirm,
    Select,
    Subtitle,
    Table,
    Text,
    Title,
} from '@ianneo/ui-library';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ProductCategory } from '../../../domain/models/category.model';
import { ProductVersion } from '../../../domain/models/product.model';
import { useDeleteProduct } from '../../../infrastructure/hooks/api/products/use-delete-product';
import { useProducts } from '../../../infrastructure/hooks/api/products/use-products';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';
import useToggle from '../../../infrastructure/hooks/use-toggle';
import useVerifyAdmin from '../../../infrastructure/hooks/use-verify-admin';
import { useGetDelegations } from '../../_api/delegations/hooks/useGetDelegations';
import Import from '../../components/Import/import';
import AddProduct from './add-product';

interface ProductLayoutProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const ProductLayout: React.FC<ProductLayoutProps> = (args) => {
    const w = useAppContext()?.workspace;
    const { t } = useTranslation();
    const { status: importMode, toggle } = useToggle();
    const { isAdmin } = useVerifyAdmin();
    const navigate = useNavigate();
    const { mutateAsync: remove } = useDeleteProduct();
    const { delegations } = useGetDelegations();
    const [selected, setSelected] = useState(0);
    const { data } = useProducts(
        delegations?.find((x) => x.id === selected)?.workspace?.id,
    );

    const getProductName = (source: string) => {
        const base = source.split('-');
        if (base.length === 2 && Number.isNaN(Number.parseInt(base[0]))) {
            return source;
        } else if (base.length === 2) {
            return base[1];
        } else {
            return source;
        }
    };

    const getProductCategory = useCallback(
        (category: ProductCategory, isPrimary?: boolean) => {
            console.log(
                `getProductCategory().category: ${JSON.stringify(category)}`,
            );

            function format(source: string) {
                const parts = source.trim().split('_-');
                let content: string;
                if (parts.length === 2) {
                    content = parts[1];
                } else {
                    content = parts[0];
                }
                return content
                    .trim()
                    .split(' ')
                    .map(
                        (t) =>
                            t.slice(0, 1).toUpperCase() +
                            t.slice(1).toLowerCase(),
                    )
                    .join(' ')
                    .split('_')
                    .map(
                        (t) =>
                            t.slice(0, 1).toUpperCase() +
                            t.slice(1).toLowerCase(),
                    )
                    .join(' ');
            }

            const code = category?.code?.split('::_-_') || [];

            if (w?.supplyChainLoaderType === 1) {
                if (code.length === 2) {
                    if (isPrimary) {
                        return format(code[0]);
                    } else {
                        return format(code[1]);
                    }
                } else {
                    return format(code[0]);
                }
            } else {
                if (code.length === 2) {
                    if (isPrimary) {
                        return format(code[0]);
                    } else {
                        return format(code[1]);
                    }
                } else {
                    return format(code[0]);
                }
            }
        },
        [w?.supplyChainLoaderType],
    );

    const columns = useMemo(
        () => [
            {
                title: t('product:listing.name'),
                dataIndex: ['product', 'name'],
                render: (_: any, item: ProductVersion) => {
                    const delegateId = delegations?.find(
                        (x) => x.id === selected,
                    )?.workspace?.id;
                    return (
                        <Link
                            onClick={() =>
                                navigate(
                                    delegateId
                                        ? `/products/${item.product?.id}/${delegateId}`
                                        : `/products/${item.product?.id}`,
                                )
                            }
                        >
                            {getProductName(item.product?.name || '')}
                        </Link>
                    );
                },
            },
            {
                title: t('product:listing.active'),
                dataIndex: 'isActive',
                render: (_: any, item: ProductVersion) => {
                    return (
                        <Text>
                            {item.product?.isActive ? 'Active' : 'Inactive'}
                        </Text>
                    );
                },
            },
            {
                title: t('product:listing.code'),
                dataIndex: ['product', 'code'],
            },
            {
                title: t('product:listing.hsCode'),
                dataIndex: ['product', 'hsCode'],
            },
            {
                title: t('product:listing.category'),
                render: (_: any, item: ProductVersion) => {
                    return (
                        <Text>
                            {getProductCategory(
                                item.product?.category || {},
                                true,
                            )}
                        </Text>
                    );
                },
            },
            {
                title: t('product:listing.subCategory'),
                dataIndex: ['category', 'code'],
                render: (_: any, item: ProductVersion) => {
                    return (
                        <Text>
                            {getProductCategory(
                                item.product?.category || {},
                                false,
                            )}
                        </Text>
                    );
                },
            },
            { title: t('product:listing.weight'), dataIndex: 'weight' },
            {
                title: t('product:listing.measureValue'),
                dataIndex: ['product', 'measureValue'],
            },
            {
                title: t('common:actions'),
                dataIndex: 'actions',
                render: (_: any, item: ProductVersion) => {
                    return (
                        <>
                            <Popconfirm
                                title="Confirm to delete?"
                                submit={async () =>
                                    await remove({
                                        id: item.product?.id,
                                        workspaceId: delegations?.find(
                                            (x) => x.id === selected,
                                        )?.workspace?.id,
                                    })
                                }
                            >
                                <DeleteOutlined style={{ color: 'red' }} />
                            </Popconfirm>
                        </>
                    );
                },
            },
        ],
        [navigate, t, remove, delegations, selected, getProductCategory],
    );

    const actions = useMemo(() => {
        const columns: any = [];

        if (!isAdmin) {
            columns.push({
                key: 'add',
                icon: <FileAddOutlined />,
                label: t('user:listing.user.importBtn'),
                onClick: () => toggle(),
            });

            columns.push({
                key: 'import',
                icon: <PlusOutlined />,
                label: t('product:addText'),
                onClick: () => args.setOpen(true),
            });
        }

        return columns;
    }, [toggle, t, isAdmin, args]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    marginBottom: '24px',
                }}
            >
                <div
                    style={{
                        width: '8px',
                        backgroundColor: '#972d47',
                        minHeight: '40px',
                        minWidth: '8px',
                    }}
                ></div>
                <Title>{t('product:name')}</Title>
                <Subtitle>{t('product:description')}</Subtitle>
            </div>

            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                    height: '100dch',
                    flex: 1,
                }}
            >
                <Table
                    dataSource={data}
                    columns={columns}
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                    additionalFilters={
                        <div
                            style={{
                                display:
                                    !delegations || delegations.length === 0
                                        ? 'none'
                                        : 'flex',
                                gap: '8px',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ flex: 1 }}>For Company:</span>
                            <Select
                                options={delegations?.map((x) => {
                                    return {
                                        label: x.workspace?.company?.name,
                                        value: x.id,
                                    };
                                })}
                                style={{
                                    maxWidth: '200px',
                                    width: '200px',
                                    flex: 3,
                                }}
                                onChange={(value) => {
                                    setSelected(value);
                                }}
                                placeholder="None"
                                suffixIcon={null}
                                getPopupContainer={undefined}
                            ></Select>
                        </div>
                    }
                    actions={
                        <CollapsibleDropdown
                            menu={{
                                items: actions,
                            }}
                        ></CollapsibleDropdown>
                    }
                />
            </div>

            <AddProduct open={args.open} setOpen={args.setOpen} />

            <Import type="products" open={importMode} setOpen={toggle} />
        </>
    );
};

export default ProductLayout;
