import { useEffect, useRef, useState } from 'react';
import VisGraph, { GraphData, Network, Options } from 'react-vis-graph-wrapper';
// import { useDatabricksSuppliers } from '../../../infrastructure/hooks/api/external/databricks/use-databrick-suppliers';

interface RadialMapComponentProps {}

const NodeColor = {
    main: 'lightblue',
    company: 'lightgreen',
    factory: 'orange',
};

const RadialMapComponent: React.FC<RadialMapComponentProps> = () => {
    // const { data: databricksData } = useDatabricksSuppliers();
    const [data, setData] = useState<any>([]);
    const ref = useRef<HTMLDivElement>(null);
    const graph: GraphData = {
        nodes: data.map((node: any) => {
            let color: string = '';

            if (node.id === 0) {
                color = NodeColor.main;
            } else if (
                node.CUS_CODE !== node.SUP_CODE &&
                node.FAC_CODE === node.SUP_CODE
            ) {
                color = NodeColor.company;
            } else {
                color = NodeColor.factory;
            }

            return {
                id: node.id,
                label: node.CUST_CODE.substring(0, 6),
                title: node.CUST_CODE,
                size: 100,
                color: color,
            };
        }),
        edges: data
            .filter((x: any) => x.id !== 0)
            .map((node: any) => ({
                from: 0,
                to: node.id,
            })),
    };

    const options: Options = {
        edges: {
            color: '#000000',
        },
    };

    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === ref.current) {
                    setWidth(entry.contentRect.width);
                    setHeight(entry.contentRect.height);
                }
            }
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        const mainNode = {
            id: 0,
            CUST_CODE: 'GARMIN',
            FAC_CODE: 'GARMIN',
            SUP_CODE: 'GARMIN',
            color: 'lightblue',
        };

        // Uncomment this once the supplier query is working.
        // if (databricksData) {
        //     const columns = databricksData?.manifest?.schema?.columns;
        //     const dataArray = databricksData?.result?.data_array;

        //     const mergedData = dataArray.map((row, index) => {
        //         const mergedRow: {
        //             [key: string]: string | number;
        //             id: number;
        //         } = {
        //             id: index + 1,
        //         };
        //         columns.forEach((column, columnIndex) => {
        //             mergedRow[column.name] = row[columnIndex];
        //         });
        //         return mergedRow;
        //     });

        //     setData([mainNode, ...mergedData]);
        // }

        // Remove this once integration with the databricks API is done.
        const jsonObject = {
            statement_id: '01ee006b-c6e1-1467-a802-52477555b37e',
            status: {
                state: 'SUCCEEDED',
            },
            manifest: {
                format: 'JSON_ARRAY',
                schema: {
                    column_count: 5,
                    columns: [
                        {
                            name: 'CUST_CODE',
                            type_text: 'STRING',
                            type_name: 'STRING',
                            position: 0,
                        },
                        {
                            name: 'FAC_CODE',
                            type_text: 'STRING',
                            type_name: 'STRING',
                            position: 1,
                        },
                        {
                            name: 'SUP_CODE',
                            type_text: 'STRING',
                            type_name: 'STRING',
                            position: 2,
                        },
                        {
                            name: 'UPDDAT',
                            type_text: 'TIMESTAMP',
                            type_name: 'TIMESTAMP',
                            position: 3,
                        },
                        {
                            name: 'extraction_date',
                            type_text: 'DATE',
                            type_name: 'DATE',
                            position: 4,
                        },
                    ],
                },
                total_chunk_count: 1,
                chunks: [
                    {
                        chunk_index: 0,
                        row_offset: 0,
                        row_count: 6,
                    },
                ],
                total_row_count: 6,
            },
            result: {
                chunk_index: 0,
                row_offset: 0,
                row_count: 6,
                data_array: [
                    [
                        'SIHB',
                        'GARMIN',
                        'GARMIN',
                        '2023-06-01T03:20:07.000+08:00',
                        '2023-06-01',
                    ],
                    [
                        'LMGL',
                        'GARMIN',
                        'GARMIN',
                        '2023-06-01T03:20:07.000+08:00',
                        '2023-06-01',
                    ],
                    [
                        'MIOX',
                        'GARMIN',
                        'GARMIN',
                        '2023-06-01T03:20:07.000+08:00',
                        '2023-06-01',
                    ],
                    [
                        'GBGR',
                        'GARMIN',
                        'GARMIN',
                        '2023-06-01T03:20:07.000+08:00',
                        '2023-06-01',
                    ],
                    [
                        'PRII',
                        'GARMIN',
                        'LFX',
                        '2023-06-01T03:20:07.000+08:00',
                        '2023-06-01',
                    ],
                    [
                        'CTCO',
                        'GARMIN',
                        'GARMIN',
                        '2023-06-01T03:20:07.000+08:00',
                        '2023-06-01',
                    ],
                ],
            },
        };
        const columns = jsonObject.manifest.schema.columns;
        const data = jsonObject.result.data_array;

        // Remove this once integration with the databricks API is done.
        const mergedData = data.map((row, index) => {
            const mergedRow: { [key: string]: string | number; id: number } = {
                id: index + 1,
            };
            columns.forEach((column, columnIndex) => {
                mergedRow[column.name] = row[columnIndex];
            });
            return mergedRow;
        });

        setData([mainNode, ...mergedData]);
    }, []);

    return (
        <>
            <div
                ref={ref}
                style={{ flex: 1, position: 'relative', height: '100%' }}
            >
                <VisGraph
                    style={{ width: width, height: height }}
                    graph={graph}
                    options={options}
                    ref={(network: Network) => {
                        //  if you want access to vis.js network api you can set the state in a parent component using this property
                        if (network) {
                            network.moveTo({
                                scale: 0.6,
                                position: { x: 0, y: 100 },
                            });
                        }
                    }}
                />
            </div>
        </>
    );
};

export default RadialMapComponent;
