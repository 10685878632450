import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePurchase } from '../../../../infrastructure/hooks/api/purchases/use-purchase';
import { usePurchaseTrace } from '../../../../infrastructure/hooks/api/purchases/use-purchase-trace';
import PurchaseDetailLayout from './purchase-detail.layout';
import { usePurchaseReverse } from '../../../../infrastructure/hooks/api/purchases/use-purchase-reverse';
import useAppContext from '../../../../infrastructure/hooks/use-context.hook';

interface PurchaseDetailComponentProps {}

const PurchaseDetailComponent: React.FC<PurchaseDetailComponentProps> = () => {
    const { t } = useTranslation();
    const { id, delegateId } = useParams();
    const { data } = usePurchase({ id, delegateId });
    const { data: trace } = usePurchaseTrace({ id });
    const workspace = useAppContext().workspace;
    const { data: tier } = usePurchaseReverse(id || '');

    const [menu, setMenu] = useState<
        'overview' | 'cascade_tracing' | 'documents' | 'monitor' | 'track_trace'
    >('overview');
    const menuOptions = useMemo(() => {
        const options = [
            { label: t('purchase:detail.menu.overview'), value: 'overview' },
            {
                label: t('purchase:detail.menu.cascadeTracing'),
                value: 'cascade_tracing',
            },
            { label: t('purchase:detail.menu.documents'), value: 'documents' },
            { label: t('purchase:detail.menu.monitor'), value: 'monitor' },
            {
                label: t('purchase:detail.menu.trackAndTrace'),
                value: 'track_trace',
            },
        ];

        if (!data?.owner?.rules || data.owner.rules.length === 0) {
            options.splice(2, 1);
        }

        console.log(options);

        return options;
    }, [t, data]);

    return (
        <PurchaseDetailLayout
            data={data || {}}
            trace={trace || []}
            tier={tier || []}
            menu={menu}
            menuOptions={menuOptions}
            setMenu={setMenu}
            isCaseDisplayed={workspace?.isCaseDisplayed || false}
        />
    );
};

export default PurchaseDetailComponent;
