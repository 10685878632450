import { useQuery } from '@tanstack/react-query';
import { Award } from '../../../../domain/models/award.modal';
import { AwardService } from '../../../../domain/services/award.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useAwardService } from './use-award-service';

const getAwards = async (
    service: AwardService,
    id: string,
): Promise<Award[]> => {
    const response = await service.list(id);

    return response;
};

export function useAwards() {
    const context = useAppContext();
    const { service } = useAwardService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: ['companyAwards', { type: context.workspace?.id }],
        queryFn: async () => {
            if (!context.workspace?.id) return;
            return await getAwards(service, context.workspace.id);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch company documents information: ${error.message}`,
            });
        },
    });
}
