import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
    AddTable,
    CollapsibleDropdown,
    Form,
    FormItem,
    Input,
    Link,
    Popconfirm,
    Select,
    StepModal,
    Subtitle,
    Table,
    Text,
    Title,
    useForm,
} from '@ianneo/ui-library';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SupplyChainNodeType } from '../../../domain/enums/supplier-node.enum';
import { SupportDocType } from '../../../domain/enums/support-doctype.enum';
import { Permission } from '../../../domain/models/permission.model';
import { Ruleset, SupportDocument } from '../../../domain/models/ruleset.model';
import { useCreateRuleset } from '../../../infrastructure/hooks/api/rulesets/use-create-ruleset';
import { useDeleteRuleset } from '../../../infrastructure/hooks/api/rulesets/use-delete-ruleset';
import { useRulesets } from '../../../infrastructure/hooks/api/rulesets/use-rulesets';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';
import useVerifyAdmin from '../../../infrastructure/hooks/use-verify-admin';
import { getDocumentValue } from '../../../infrastructure/utils/document-type-labeler';
import { getProcessValue } from '../../../infrastructure/utils/process-labeler';
import { useGetDelegations } from '../../_api/delegations/hooks/useGetDelegations';
import { validatePermissions } from '../../utils/check-permissions';
import { DelegationComponent } from '../Partners/add-partner/delegations.component';
import EditRuleset from './edit-ruleset';

interface RulesetsLayoutProps {}

const RulesetsLayout: React.FC<RulesetsLayoutProps> = (args) => {
    const context = useAppContext();
    const navigate = useNavigate();
    const [allowAdd, setAllowAdd] = useState(false);
    const { t } = useTranslation();
    const [form] = useForm();
    const [documentation, setDocumentation] = useState<SupportDocument[]>([]);
    const { isAdmin } = useVerifyAdmin();
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [allowEdit, setAllowEdit] = useState(false);
    const [allowDelete, setAllowDelete] = useState(false);
    const [selectedRuleset, setSelectedRuleset] = useState<Ruleset>({});

    const { delegations } = useGetDelegations();
    const [selected, setSelected] = useState(0);
    const [onBehalfOf, setOnBehalfOf] = useState(0);

    const { data } = useRulesets(
        delegations?.find((x) => x.id === selected)?.workspace?.id,
    );

    const { mutateAsync: deleteRecord } = useDeleteRuleset();

    const defaultRules = useMemo(
        () => [
            { required: true, message: t('material:modal.create.errorMsg') },
        ],
        [t],
    );

    const { mutateAsync } = useCreateRuleset();
    const items = useMemo(() => {
        const items = [];
        if (!isAdmin) {
            items.push({
                label: t('ruleset:listing.addBtn'),
                icon: <PlusOutlined />,
                onClick: () => setOpen(true),
                key: 'add',
                disabled: !allowAdd,
            });
        }

        return items;
    }, [t, isAdmin, allowAdd]);

    const columns = useMemo(() => {
        const col = [
            {
                title: t('ruleset:listing.name'),
                dataIndex: 'name',
                render: (_: any, item: Ruleset) => {
                    const delegateId = delegations?.find(
                        (x) => x.id === selected,
                    )?.workspace?.id;

                    return (
                        <Link
                            onClick={() =>
                                navigate(
                                    delegateId
                                        ? `/rulesets/${item.id}/${delegateId}`
                                        : `/rulesets/${item.id}`,
                                )
                            }
                        >
                            {typeof item.name === 'object'
                                ? item?.name?.locales
                                    ? item.name.locales[0]?.text
                                    : ''
                                : typeof item.name === 'string'
                                ? JSON.parse(item.name).locales[0].text
                                : ''}
                        </Link>
                    );
                },
            },
            {
                title: t('ruleset:listing.description'),
                dataIndex: 'description',
                render: (_: any, item: Ruleset) => (
                    <Text>
                        {typeof item.description === 'object'
                            ? item.description?.locales
                                ? item.description.locales[0]?.text
                                : ''
                            : typeof item.description === 'string'
                            ? JSON.parse(item.description).locales[0].text
                            : ''}
                    </Text>
                ),
            },
            { title: t('ruleset:listing.code'), dataIndex: 'code' },
            {
                title: t('ruleset:listing.documentCount'),
                render: (_: any, item: Ruleset) => (
                    <Text>{item.documentation?.length}</Text>
                ),
            },
            {
                title: t('common:createdOn'),
                render: (_: any, item: Ruleset) => (
                    <Text>
                        {item.createdOn
                            ? new Date(item.createdOn)
                                  .toISOString()
                                  .slice(0, 10)
                            : ''}
                    </Text>
                ),
            },
            {
                title: t('common:actions'),
                render: (_: any, item: Ruleset) => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '8px',
                        }}
                    >
                        <EditOutlined
                            hidden={!allowEdit || isAdmin}
                            onClick={() => {
                                setSelectedRuleset(item);
                                setEdit(true);
                            }}
                        />

                        <Popconfirm
                            title="Confirm to delete?"
                            submit={async () =>
                                deleteRecord({
                                    id: item.id || '',
                                    delegateId: delegations.find(
                                        (x) => x.id === selected,
                                    )?.workspace?.id,
                                })
                            }
                            disabled={!allowDelete || isAdmin}
                        >
                            <DeleteOutlined style={{ color: 'red' }} />
                        </Popconfirm>
                    </div>
                ),
            },
        ];

        if (isAdmin) {
            col.pop();
        }

        return col;
    }, [
        t,
        navigate,
        deleteRecord,
        allowDelete,
        allowEdit,
        isAdmin,
        selected,
        delegations,
    ]);

    const addColumns = useMemo(
        () => [
            {
                title: t('ruleset:modal.add.documentType'),
                dataIndex: 'document',
                component: (
                    <Select
                        options={Object.values(SupportDocType).map((x) => ({
                            label: getDocumentValue(x),
                            value: x,
                            key: x,
                        }))}
                    />
                ),
                rules: [
                    {
                        required: true,
                        message: 'Please select document type',
                    },
                ],
                required: true,
            },
            {
                title: t('ruleset:modal.add.appliesTo'),
                dataIndex: 'appliesTo',
                component: (
                    <Select
                        options={Object.values(SupplyChainNodeType).map(
                            (x) => ({
                                label: getProcessValue(x),
                                value: x,
                                key: x,
                            }),
                        )}
                        mode="multiple"
                    />
                ),
                required: true,
                width: '300px',
                rules: defaultRules,
            },
        ],
        [t, defaultRules],
    );

    const close = () => {
        setOpen(false);
        setDocumentation([]);
        form.resetFields();
    };

    useEffect(() => {
        const addPermission: Permission = {
            application: 'io.lfx.t4s.workspace',
            module: 'ruleset',
            action: 'create',
        };

        if (validatePermissions(addPermission, context.permissions)) {
            setAllowAdd(true);
        }
    }, [context.permissions]);

    useEffect(() => {
        if (context.permissions) {
            const editPermission: Permission = {
                application: 'io.lfx.t4s.workspace',
                module: 'ruleset',
                action: 'update',
            };

            setAllowEdit(
                validatePermissions(editPermission, context.permissions),
            );

            const deletePermission = {
                application: 'io.lfx.t4s.workspace',
                module: 'ruleset',
                action: 'remove',
            };

            setAllowDelete(
                validatePermissions(deletePermission, context.permissions),
            );
        }
    }, [context.permissions]);

    const submit = async () => {
        const values = form.getFieldsValue(true);
        const ruleset = {
            code: values.code,
            name: {
                locales: [{ localeName: 'en', text: values.name }],
            },
            description: {
                locales: [{ localeName: 'en', text: values.description }],
            },
            documentation: values.items.map((item: any) => {
                return {
                    document: item.document,
                    appliesTo: item.appliesTo,
                    isRequired: true,
                    submissionLeadTime: 0,
                } as SupportDocument;
            }),
        };

        await mutateAsync({
            ruleset,
            workspaceId: delegations.find((x) => x.id === onBehalfOf)?.workspace
                ?.id,
        });
        close();
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    marginBottom: '24px',
                }}
            >
                <div
                    style={{
                        width: '8px',
                        backgroundColor: '#972d47',
                        minHeight: '40px',
                        minWidth: '8px',
                    }}
                ></div>
                <Title>{t('ruleset:name')}</Title>
                <Subtitle>{t('ruleset:description')}</Subtitle>
            </div>

            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                    height: '100dch',
                    flex: 1,
                }}
            >
                <Table
                    dataSource={data}
                    columns={columns}
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                    additionalFilters={
                        <div
                            style={{
                                display:
                                    !delegations || delegations.length === 0
                                        ? 'none'
                                        : 'flex',
                                gap: '8px',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ flex: 1 }}>For Company:</span>
                            <Select
                                options={delegations?.map((x) => {
                                    return {
                                        label: x.workspace?.company?.name,
                                        value: x.id,
                                    };
                                })}
                                style={{
                                    maxWidth: '200px',
                                    width: '200px',
                                    flex: 3,
                                }}
                                onChange={(value) => {
                                    setSelected(value);
                                }}
                                placeholder="None"
                                suffixIcon={null}
                                getPopupContainer={undefined}
                            ></Select>
                        </div>
                    }
                    actions={
                        <CollapsibleDropdown
                            menu={{
                                items,
                            }}
                        />
                    }
                />
            </div>

            <StepModal
                title={t('ruleset:listing.addBtn')}
                open={open}
                okFn={submit}
                closeIcon={false}
                cancelFn={close}
                width={'60vw'}
                form={form}
                closable={false}
                stepContent={[
                    {
                        title: 'On Behalf Of.',
                        content: (
                            <DelegationComponent
                                form={form}
                                onBehalfOf={onBehalfOf}
                                setOnBehalfOf={setOnBehalfOf}
                            />
                        ),
                    },
                    {
                        title: t('ruleset:listing.steps.required'),
                        content: (
                            <>
                                <Form form={form}>
                                    <FormItem
                                        label={t('ruleset:modal.add.name')}
                                        name={'name'}
                                        rules={defaultRules}
                                        required
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t('ruleset:modal.add.code')}
                                        name="code"
                                        required
                                        rules={defaultRules}
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t(
                                            'ruleset:modal.add.description',
                                        )}
                                        name="description"
                                        required
                                        rules={defaultRules}
                                    >
                                        <Input />
                                    </FormItem>
                                </Form>
                            </>
                        ),
                    },
                    {
                        title: t('ruleset:listing.steps.rulesetDetails'),
                        content: (
                            <>
                                <AddTable
                                    columns={addColumns}
                                    dataSource={documentation}
                                    form={form}
                                    scroll={{ x: 'max-content' }}
                                />
                            </>
                        ),
                    },
                ]}
            ></StepModal>

            {edit ? (
                <EditRuleset
                    data={selectedRuleset}
                    open={edit}
                    setOpen={setEdit}
                    selected={
                        delegations.find((x) => x.id === selected)?.workspace
                            ?.id || ''
                    }
                />
            ) : null}
        </>
    );
};

export default RulesetsLayout;
