import { useMutation } from '@tanstack/react-query';
import useAppContext from '../../use-context.hook';
import { useLinkService } from './use-links-service';
import { useAlertContext } from '../../use-alert.hook';

export interface DeleteLinkParams {
    id: string;
    purchaseOrderIds: string[];
    workspaceId?: string;
    diversion?: string;
}

export function useDeleteLink() {
    const context = useAppContext();
    const { setAlert } = useAlertContext();
    const { service } = useLinkService();

    return useMutation({
        mutationFn: async ({
            id,
            purchaseOrderIds,
            workspaceId,
            diversion = '0',
        }: DeleteLinkParams) => {
            if (!context.workspace?.id) return;

            await Promise.all(
                purchaseOrderIds.map(async (x) => {
                    await service.delete(
                        workspaceId || context.workspace?.id || '',
                        id,
                        x,
                        diversion,
                    );
                }),
            );
        },
        onSuccess: (_, { id, workspaceId }) => {
            // client.invalidateQueries({
            //     queryKey: [
            //         'links',
            //         { workspace: workspaceId || context.workspace?.id, id },
            //     ],
            // });
            // setAlert({
            //     type: 'success',
            //     message: 'Link deleted successfully',
            //     title: 'Delete Link',
            // });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to delete link: ${error.message}`,
                title: 'Delete Link',
            });
        },
    });
}
