import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserLayout from './user.layout';

const UserComponent: React.FC<{}> = () => {
    const { t } = useTranslation();
    const [menu, setMenu] = useState<'users' | 'roles' | 'permissions'>(
        'users',
    );
    const menuOptions = [
        { label: t('user:menu.users'), value: 'users' },
        { label: t('user:menu.roles'), value: 'roles' },
    ];

    return (
        <UserLayout menu={menu} menuOptions={menuOptions} setMenu={setMenu} />
    );
};

export default UserComponent;
