import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { Workspace } from '../../domain/models/workspace.model';
import { ApiServer } from './api.server';

export interface Template {
    id?: number;
    channel?: string;
    code?: string;
    content?: string;
    createdOn?: Date;
    description?: string;
    lastUpdatedOn?: Date;
    locale?: string;
    name?: string;
    subject?: string;
    adaptation?: Adaptation;
}

export interface Adaptation {
    id?: string;
    channel?: string;
    code?: string;
    content?: string;
    createdOn?: Date;
    deletedOn?: Date;
    description?: string;
    lastUpdatedOn?: Date;
    locale?: string;
    name?: string;
    subject?: string;
    workspace?: Workspace;
}
export class TemplateClient extends ApiServer<Template> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'template');
    }

    async get(workspace: string, template: string): Promise<Template> {
        console.log(`service<template>| get(): Enter`);
        console.log(`service<template>| get(): $workspace = ${workspace}`);
        console.log(`service<template>| get(): $template = ${template}`);

        return super.get('get', workspace, template) as Promise<Template>;
    }

    async list(workspace: string): Promise<Template[]> {
        console.log(`service<template>| list(): Enter`);
        console.log(`service<template>| list(): $workspace = ${workspace}`);

        return super.get('list', workspace) as Promise<Template[]>;
    }

    async create(workspace: string, template: string, data: Adaptation) {
        console.log(`service<template>| create(): Enter`);
        console.log(`service<template>| create(): $workspace = ${workspace}`);
        console.log(`service<template>| create(): $template = ${template}`);
        console.log(`service<template>| create(): $data = ${data}`);

        return super.post('create', workspace, template, data);
    }

    async update(workspace: string, template: string, data: Adaptation) {
        console.log(`service<template>| update(): Enter`);
        console.log(`service<template>| update(): $workspace = ${workspace}`);
        console.log(`service<template>| update(): $template = ${template}`);
        console.log(`service<template>| update(): $data = ${data}`);

        return super.put('update', workspace, template, data);
    }

    async getParams(template: string): Promise<any> {
        console.log(`service<template>| getParams(): Enter`);
        console.log(`service<template>| getParams(): $template = ${template}`);

        return super.get('getParams', template);
    }

    async delete(workspace: string, template: string) {
        console.log(`service<template>| delete(): Enter`);
        console.log(`service<template>| delete(): $workspace = ${workspace}`);
        console.log(`service<template>| delete(): $template = ${template}`);

        return super.delete('delete', workspace, template);
    }
}
