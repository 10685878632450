import {
    Button,
    Collapse,
    CollapsePanel,
    ITableProps,
    Progress,
    Table,
    Text,
} from '@ianneo/ui-library';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportDocType } from '../../../domain/enums/support-doctype.enum';
import { CompanyDocument } from '../../../domain/models/company-doc.model';
import {
    PurchaseDocumentTrace,
    PurchaseOrderVersion,
} from '../../../domain/models/purchase.model';
import { Ruleset } from '../../../domain/models/ruleset.model';
import {
    SalesOrder,
    SalesOrderDocument,
} from '../../../domain/models/sales-order.model';
import { Attachment } from '../../../domain/types/attachment.type';
import { useCompanyService } from '../../../infrastructure/hooks/api/company/use-company-service';
import { useOrder } from '../../../infrastructure/hooks/api/order/use-order';
import { useOrderSearch } from '../../../infrastructure/hooks/api/order/use-order-search';
import { useDownloadPurchaseDocuments } from '../../../infrastructure/hooks/api/purchases/use-download-purchase-documents';
import { usePurchaseTrace } from '../../../infrastructure/hooks/api/purchases/use-purchase-trace';
import { getDocumentValue } from '../../../infrastructure/utils/document-type-labeler';
import { PurchaseCompanyDocumentsComponent } from './purchase-company-documents.component';
import { PurchaseMaterialDocuments } from './purchase-materials-documents.component';
import { PurchaseSupportDocs } from './purchase-support-docs.component';

interface PurchaseDocumentsProps {
    data: PurchaseOrderVersion;
}

export interface PurchaseDocumentItem {
    id: string | number;
    documents: SalesOrderDocument[];
    tier: number;
    workspaceId: string;
    poNumber: string;
    buyer: string;
    supplier: string;
    supplierWorkspaceId: string;
    rules: Ruleset[];
    origin: SalesOrder | PurchaseDocumentTrace;
    purchaseProcesses?: string[];
}

export const PurchaseDocuments: React.FC<PurchaseDocumentsProps> = ({
    data,
}) => {
    const { t } = useTranslation();
    const { data: salesOrder } = useOrder({ id: data.owner?.id || '' });
    const { data: traces } = usePurchaseTrace({ id: data.owner?.id || '' });
    const { data: traceOrders } = useOrderSearch({
        ids: (traces || [])?.map((x) => x.salesOrder) || [],
    });

    const [percentage, setPercentage] = useState(0);

    const [documents, setDocuments] = useState<PurchaseDocumentItem[][]>([]);
    const [companyDocuments, setCompanyDocuments] = useState<
        Record<string, CompanyDocument[]>
    >({});

    const { service: companyService } = useCompanyService();
    const fetchPartnerDocuments = useCallback(
        async (partner: string, workspaceId?: string) => {
            if (!workspaceId) return;

            const response = (await companyService.solicitDocs(
                workspaceId,
                partner,
            )) as CompanyDocument[];

            setCompanyDocuments((prev) => ({
                ...prev,
                [partner]: response,
            }));
        },
        [companyService, setCompanyDocuments],
    );

    const { mutateAsync } = useDownloadPurchaseDocuments();

    const downloadFiles = useCallback(async () => {
        const files: Attachment[] = [];

        const clonedDocuments = _.cloneDeep(documents);

        clonedDocuments.forEach((doc, index) => {
            doc.forEach((d) => {
                d.documents.forEach((f) => {
                    f.file.originalName = `Tier ${index + 1} - ${
                        f.file.originalName
                    }`;
                    files.push(f.file);
                });

                companyDocuments[d.supplierWorkspaceId || ''].forEach((f) => {
                    const clonedFile = _.cloneDeep(f.file);

                    clonedFile.originalName = `Tier ${index + 1} - ${
                        f.file.originalName
                    }`;

                    files.push(clonedFile);
                });
            });
        });

        await mutateAsync({ files });
    }, [documents, companyDocuments, mutateAsync]);

    const documentTypes = useMemo(() => {
        const consolidatedDocumentTypes: Set<SupportDocType> = new Set();

        data.owner?.rules?.forEach((x) => {
            x.documentation?.forEach((y) => {
                if (!y.document) return;
                consolidatedDocumentTypes.add(y.document);
            });
        });

        traceOrders?.forEach((x) => {
            x.rules?.forEach((y) => {
                y.documentation?.forEach((z) => {
                    if (!z.document) return;
                    consolidatedDocumentTypes.add(z.document);
                });
            });

            x.documents?.forEach((y) => {
                if (!y.type) return;
                consolidatedDocumentTypes.add(y.type);
            });
        });

        salesOrder?.documents?.forEach((x) => {
            consolidatedDocumentTypes.add(x.type);
        });

        return Array.from(consolidatedDocumentTypes);
    }, [data, traceOrders, salesOrder]);

    const columns = useMemo(() => {
        const cols: ITableProps<PurchaseDocumentItem>['columns'] = [
            {
                title: t('purchase:detail.documents.poNumber'),
                dataIndex: 'poNumber',
            },
            {
                title: t('purchase:detail.documents.buyer'),
                dataIndex: 'buyer',
            },
            {
                title: t('purchase:detail.documents.supplier'),
                dataIndex: 'supplier',
            },
            {
                title: t('purchase:detail.documents.company'),
                dataIndex: 'company',
                render: (_, item, index) => {
                    return (
                        <PurchaseCompanyDocumentsComponent
                            item={item}
                            documents={companyDocuments}
                            key={index}
                        />
                    );
                },
            },
            {
                title: t('purchase:detail.documents.supportingDocuments'),
                dataIndex: 'supportingDocuments',
                render: (_, item) => {
                    return (
                        <PurchaseMaterialDocuments
                            order={
                                traces?.find((x) => x.salesOrder === item.id) ||
                                data
                            }
                            documentItem={item}
                        />
                    );
                },
            },
        ];

        documentTypes.forEach((x) => {
            cols.push({
                title: getDocumentValue(x),
                dataIndex: x,
                render: (_, item) => {
                    return (
                        <PurchaseSupportDocs
                            type={x}
                            item={item}
                            purchaseOrder={data}
                        />
                    );
                },
            });
        });

        return cols;
    }, [t, documentTypes, companyDocuments, data, traces]);

    useEffect(() => {
        const consolidatedDocuments: PurchaseDocumentItem[] = [];

        consolidatedDocuments.push({
            id: salesOrder?.id || '',
            documents: salesOrder?.documents || [],
            workspaceId: salesOrder?.customer?.buyer?.id || '',
            tier: 0,
            poNumber: salesOrder?.poExternalRefId || '',
            buyer: salesOrder?.customer?.buyer?.name || '',
            supplier: salesOrder?.customer?.owner?.name || '',
            supplierWorkspaceId: salesOrder?.workspace?.id || '',
            rules: salesOrder?.rules || [],
            origin: salesOrder || {},
            purchaseProcesses: data?.owner?.purchaseProcesses || [],
        });

        traceOrders?.forEach((x) => {
            const trace = traces?.find((y) => y.salesOrder === x.id);

            consolidatedDocuments.push({
                id: x?.id || 0,
                documents: x?.documents || [],
                workspaceId: x?.workspace?.id || '',
                tier: trace?.tier || 0,
                poNumber: trace?.externalDataId || '',
                buyer: x?.customer?.buyer?.name || '',
                supplier: x?.customer?.owner?.name || '',
                supplierWorkspaceId: x.workspace?.id || '',
                rules: x?.rules || [],
                origin: x,
                purchaseProcesses: trace?.purchaseProcesses || [],
            });
        });

        // Make an array of array based off their tiers
        const tiers: PurchaseDocumentItem[][] = [];
        consolidatedDocuments.forEach((x) => {
            const { tier } = x;

            if (!tiers[tier]) {
                tiers[tier] = [];
            }

            tiers[tier].push(x);
        });

        setDocuments(tiers);
    }, [salesOrder, traceOrders, traces, data.owner?.purchaseProcesses]);

    useEffect(() => {
        if (!salesOrder) return;
        fetchPartnerDocuments(
            salesOrder.workspace?.id || '',
            salesOrder.customer?.owner?.id || '',
        );

        traceOrders?.forEach((x) => {
            fetchPartnerDocuments(
                x.workspace?.id || '',
                x.customer?.owner?.id || '',
            );
        });
    }, [salesOrder, traceOrders, fetchPartnerDocuments]);

    useEffect(() => {
        const consolidatedArray = [salesOrder, ...(traceOrders || [])];
        let totalDocuments = 0;
        let uploadedDocuments = 0;

        consolidatedArray.forEach((item) => {
            const operations = item?.purchaseProcesses || [];
            const clonedRules = _.cloneDeep(item?.rules);

            clonedRules?.forEach((rule) => {
                // This will check if the rules of the sales order has
                // any documents that are already uploaded of a specific type.
                const rules = rule.documentation?.filter((r) => {
                    return item?.documents?.find((i) => r.document === i.type);
                });

                // Check if the sales order has any rules that are applicable for the current workspace.
                const totalRequiredDocuments = rule.documentation?.filter((x) =>
                    x.appliesTo?.some((appliesTo) =>
                        operations.includes(appliesTo),
                    ),
                );

                // Setting the total amount of required document count
                totalDocuments += totalRequiredDocuments?.length || 0;

                // Iterate over the required documents and find if any of the uploaded ones
                // match the required ones.
                totalRequiredDocuments?.forEach((x) => {
                    const file = rules?.find((y) => x.document === y.document);

                    if (file) {
                        uploadedDocuments += 1;
                    }
                });
            });
        });

        const percentage = Math.round(
            (uploadedDocuments / totalDocuments) * 100,
        );

        setPercentage(percentage);
    }, [salesOrder, traceOrders]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                }}
            >
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '40%',
                            alignItems: 'center',
                        }}
                    >
                        <Text
                            disabled
                            style={{ flex: '1 0 40%', color: 'grey' }}
                        >
                            {t(
                                'purchase:detail.documents.documentCompleteness',
                            )}
                        </Text>

                        <Progress
                            percent={percentage}
                            style={{ flex: '1 0 60%' }}
                        />
                    </div>

                    <Button onClick={downloadFiles}>Download All</Button>
                </div>

                <Collapse>
                    {documents.map((x, index: number) => {
                        return (
                            <CollapsePanel
                                header={`Tier ${index + 1}`}
                                key={`tier-${index}`}
                            >
                                <Table
                                    rowKey="id"
                                    columns={columns}
                                    dataSource={x}
                                    scroll={{ x: 'max-content' }}
                                    pagination={false}
                                    showColumns={false}
                                    showFilter={false}
                                    showSearch={false}
                                />
                            </CollapsePanel>
                        );
                    })}
                </Collapse>
            </div>
        </>
    );
};
