import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import { InfoCard } from '@ianneo/component-library';
import {
    Button,
    Card,
    DescriptionItems,
    Descriptions,
    Popover,
    RadioGroup,
    Subtitle,
    Text,
    Title,
} from '@ianneo/ui-library';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Company } from '../../../../domain/models/company.model';
import { usePartnerV2 } from '../../../../infrastructure/hooks/api/partner/use-partner-v2';
import { useWorkspace } from '../../../../infrastructure/hooks/api/workspaces/use-workspace';
import useToggle from '../../../../infrastructure/hooks/use-toggle';
import useVerifyAdmin from '../../../../infrastructure/hooks/use-verify-admin';
import PartnerDocumentComponent from '../partner-documents/partner-documents.component';
import { AddPartnerNominatedListModal } from './add-partner-nominated-list-modal';

interface PartnerDetailLayoutProps {
    menu: 'details' | 'documents';
    setMenu: (args: 'details' | 'documents') => void;
    menuOptions: { label: string; value: string }[];
}

export interface DescripionItemType {
    label: string;
    value?: string | number | boolean;
    span?: number;
}

const PartnerDetailLayout: React.FC<PartnerDetailLayoutProps> = (args) => {
    const { id, delegateId } = useParams();
    const { t } = useTranslation();
    const { isAdmin } = useVerifyAdmin();
    const { status: open, toggle } = useToggle();
    const { data } = usePartnerV2(id || '', delegateId || '');

    const { data: workspace } = useWorkspace({
        id: data?.seller?.id || '',
        enabled: !!data?.seller?.id,
    });

    const company: Company = workspace?.company;
    const account = workspace?.account;

    const descriptions: DescripionItemType[] = useMemo(
        () => [
            { label: t('company:details.entityName'), value: company?.name },
            { label: t('company:details.higgId'), value: company?.higg },
            { label: t('company:details.companyUrl'), value: company?.website },
            {
                label: t('company:details.lfSupplierCode'),
                value: company?.lfSupplierCode,
            },
            { label: t('company:details.capacity'), value: company?.capacity },
            { label: t('company:details.oarId'), value: company?.oar },
            {
                label: t('company:details.contactBusiness'),
                value: company?.contactBusiness?.phone,
            },
            {
                label: t('company:details.contactCompliance'),
                value: company?.contactCompliance?.phone,
            },
            {
                label: t('company:details.complianceLevel'),
                value: company?.complianceLevel,
            },
            {
                label: t('company:details.contactEmail'),
                value: account?.admins?.[0] || account?.admin,
            },
            {
                label: t('company:details.complianceRating'),
                value: company?.complianceRating,
            },
            {
                label: t('company:details.complianceExpiresOn'),
                value: company?.complianceExpiresOn
                    ? moment(company?.complianceExpiresOn).format('DD/MM/YYYY')
                    : '',
            },
            { label: t('company:details.country'), value: company?.country },
            { label: t('company:details.dbaName'), value: company?.dbaName },
            {
                label: t('company:details.dbaRegistration'),
                value: company?.dbaRegistration,
            },
            {
                label: t('company:details.dbaReference'),
                value: company?.dbaReference,
            },
            {
                label: t('company:details.employees'),
                value: company?.employees,
            },
            { label: t('company:details.gln'), value: company?.gln },
            { label: t('company:details.phoneNumber'), value: company?.phone },
            { label: t('company:details.city'), value: company?.city },
            { label: t('company:details.duns'), value: company?.duns },
            {
                label: t('company:details.address'),
                value: company?.addressLine1,
            },
            {
                label: t('company:details.brNo'),
                value: company?.registrationNumber,
            },
            { label: t('company:details.vatId'), value: company?.vat },
            { label: t('company:details.zhdcId'), value: company?.zdhcId },
            {
                label: t('company:details.memberSince'),
                value: company?.createdOn
                    ? new Date(company?.createdOn).toLocaleDateString()
                    : undefined,
            },
        ],
        [company, t, account],
    );

    console.log(`page<partner-details>: $company = ${JSON.stringify(company)}`);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '36px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                        marginBottom: '24px',
                    }}
                >
                    <div
                        style={{
                            width: '8px',
                            backgroundColor: '#972d47',
                            minHeight: '40px',
                            minWidth: '8px',
                        }}
                    ></div>
                    <Title>{t('partner:name')}</Title>
                    <Subtitle>{t('partner:description')}</Subtitle>
                </div>

                <InfoCard
                    showIcon
                    tags={
                        company?.supplyChainNodeType?.map((x: any) =>
                            x.toString(),
                        ) || []
                    }
                    infoTitle={
                        <div
                            style={{
                                display: 'flex',
                                gap: '12px',
                                alignItems: 'center',
                            }}
                        >
                            {company?.name}
                            {company?.isProfileCompleted === true ? (
                                <Popover
                                    style={{ color: 'green' }}
                                    placement="right"
                                    content={
                                        <Text style={{ color: 'green' }}>
                                            {t('company:activated')}
                                        </Text>
                                    }
                                >
                                    <CheckCircleOutlined
                                        style={{ color: 'green' }}
                                    />
                                </Popover>
                            ) : (
                                <Popover
                                    placement="right"
                                    trigger={'click'}
                                    content={
                                        <Text style={{ color: 'grey' }}>
                                            {t('company:notActivated')}
                                        </Text>
                                    }
                                >
                                    <CloseCircleOutlined
                                        style={{ color: 'grey' }}
                                    />
                                </Popover>
                            )}
                        </div>
                    }
                    information={
                        <>
                            <Text>
                                {t('partner:detail.supplierCode')}:{' '}
                                {company?.lfSupplierCode}
                            </Text>
                        </>
                    }
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <RadioGroup
                            options={args.menuOptions}
                            defaultValue={args.menu}
                            onChange={(e) => args.setMenu(e.target.value)}
                            optionType="button"
                        />

                        <div hidden={isAdmin}>
                            <Button icon={<PlusOutlined />} onClick={toggle}>
                                {t('partner:detail.nominated.button')}
                            </Button>
                        </div>
                    </div>

                    {args.menu === 'details' ? (
                        <Card>
                            <Descriptions>
                                {descriptions.map((x, i) => (
                                    <DescriptionItems
                                        key={i}
                                        label={x.label}
                                        span={x.span}
                                    >
                                        {x.value}
                                    </DescriptionItems>
                                ))}
                            </Descriptions>
                        </Card>
                    ) : (
                        <PartnerDocumentComponent partner={data} />
                    )}
                </div>
            </div>

            <AddPartnerNominatedListModal
                id={data?.id || ''}
                open={open}
                toggle={toggle}
            />
        </>
    );
};

export default PartnerDetailLayout;
