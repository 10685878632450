export enum SupplyChainNodeType {
    AGRICULTURE = 'AGRICULTURE',
    AGENT = 'AGENT',
    ALUMINIUM_EXTRUSION = 'ALUMINIUM_EXTRUSION',
    ASSEMBLY = 'ASSEMBLY',
    BEAM_HOUSE = 'BEAM_HOUSE',
    BRAND = 'BRAND',
    BUTTON_SUPPLIER = 'BUTTON_SUPPLIER',
    CARE_LABEL_SUPPLIER = 'CARE_LABEL_SUPPLIER',
    CASTING = 'CASTING',
    CELLULOSE_SUPPLIER = 'CELLULOSE_SUPPLIER',
    CHEMICAL_SUPPLIER_FABRIC_PROCESSING = 'CHEMICAL_SUPPLIER_FABRIC_PROCESSING',
    CHEMICAL_SUPPLIER_RAW_MATERIAL_PROCESSING = 'CHEMICAL_SUPPLIER_RAW_MATERIAL_PROCESSING',
    CHEMICAL_SUPPLIER_RECYCLER = 'CHEMICAL_SUPPLIER_RECYCLER',
    CHEMICAL_SUPPLIER_YARN_PROCESSING = 'CHEMICAL_SUPPLIER_YARN_PROCESSING',
    COMBING_MILL = 'COMBING_MILL',
    CRUSTING_TANNERY = 'CRUSTING_TANNERY',
    CUSHION_SUPPLIER = 'CUSHION_SUPPLIER',
    CUT_MAKE_TRIM = 'CUT_MAKE_TRIM',
    DISTRIBUTOR = 'DISTRIBUTOR',
    ELASTIC_RIBBON_SUPPLIER = 'ELASTIC_RIBBON_SUPPLIER',
    ELASTICS_AND_RIBBON_SUPPLIER = 'ELASTICS_AND_RIBBON_SUPPLIER',
    EMBROIDERER = 'EMBROIDERER',
    FABRIC_COATING = 'FABRIC_COATING',
    FABRIC_DYEING = 'FABRIC_DYEING',
    FABRIC_FINISHING = 'FABRIC_FINISHING',
    FABRIC_MILL = 'FABRIC_MILL',
    FABRIC_PRINTING = 'FABRIC_PRINTING',
    FABRIC_TRADING = 'FABRIC_TRADING',
    FARM = 'FARM',
    FIBER_PRODUCER = 'FIBER_PRODUCER',
    FILAMENT_PRODUCTION = 'FILAMENT_PRODUCTION',
    FINISHED_GOODS_FACTORY = 'FINISHED_GOODS_FACTORY',
    FINISHED_GOODS_TRADER = 'FINISHED_GOODS_TRADER',
    FOAM_SUPPLIER = 'FOAM_SUPPLIER',
    FULFILLMENT_CENTER = 'FULFILLMENT_CENTER',
    GARMENT_CHEMCIAL_SUPPLIER = 'GARMENT_CHEMCIAL_SUPPLIER',
    GARMENT_FINISHING = 'GARMENT_FINISHING',
    GARMENT_PRINTER = 'GARMENT_PRINTER',
    GINNIMG_MILL = 'GINNIMG_MILL',
    HANGTAG_SUPPLIER = 'HANGTAG_SUPPLIER',
    INJECTION_MOLD_FACTORY = 'INJECTION_MOLD_FACTORY',
    KNITTING_MILL = 'KNITTING_MILL',
    LABEL_SUPPLIER = 'LABEL_SUPPLIER',
    LASER_PROCESSING = 'LASER_PROCESSING',
    LACES_SUPPLIER = 'LACES_SUPPLIER',
    LAUNDRY = 'LAUNDRY',
    LEATHER_CHEMICAL_SUPPLIER = 'LEATHER_CHEMICAL_SUPPLIER',
    LEATHER_FINISHIHG = 'LEATHER_FINISHIHG',
    LEATHER_TANNER = 'LEATHER_TANNER',
    LEATHER_TRADER = 'LEATHER_TRADER',
    LINING_SUPPLIER = 'LINING_SUPPLIER',
    MATERIAL_CALENDERING = 'MATERIAL_CALENDERING',
    METAL_GOODS_FACTORY = 'METAL_GOODS_FACTORY',
    MINE = 'MINE',
    OTHERS = 'OTHERS',
    PACKAGING_MATERIAL_SUPPLIER = 'PACKAGING_MATERIAL_SUPPLIER',
    PACKAGING_SUPPLIER = 'PACKAGING_SUPPLIER',
    PATTERN_MAKING = 'PATTERN_MAKING',
    PATCH_SUPPLIER = 'PATCH_SUPPLIER',
    PLATING = 'PLATING',
    POCKET_SUPPLIER = 'POCKET_SUPPLIER',
    POLISHING = 'POLISHING',
    POLYMER_SOURCE = 'POLYMER_SOURCE',
    POLYMERIZATION = 'POLYMERIZATION',
    POLYMER_REACTION = 'POLYMER_REACTION',
    PRINTER = 'PRINTER',
    QUALITY_ASSURANCE = 'QUALITY_ASSURANCE',
    RANCH = 'RANCH',
    RAW_MATERIAL_PRODUCER = 'RAW_MATERIAL_PRODUCER',
    RAW_MATERIAL_PROCESSING = 'RAW_MATERIAL_PROCESSING',
    RAW_MATERIAL_SUPPLIER = 'RAW_MATERIAL_SUPPLIER',
    RAW_MATERIAL_TRADING = 'RAW_MATERIAL_TRADING',
    RECYCLING_FACILITY = 'RECYCLING_FACILITY',
    REFINERY = 'REFINERY',
    RETAILER = 'RETAILER',
    RIVET_SUPPLIER = 'RIVET_SUPPLIER',
    SEWING = 'SEWING',
    SLAUTHER_HOUSE = 'SLAUTHER_HOUSE',
    SOLE_SUPPLIER = 'SOLE_SUPPLIER',
    SOURCING_AGENCY = 'SOURCING_AGENCY',
    SPINNING_MILL = 'SPINNING_MILL',
    THREAD_SUPPLIER = 'THREAD_SUPPLIER',
    TOP_MAKING = 'TOP_MAKING',
    TANNERY = 'TANNERY',
    WET_BLUE_TANNERY = 'WET_BLUE_TANNERY',
    WET_WHITE_TANNERY = 'WET_WHITE_TANNERY',
    WEAVING_MILL = 'WEAVING_MILL',
    WHOLESALER = 'WHOLESALER',
    YARN_DYEING = 'YARN_DYEING',
    YARN_SPINNER = 'YARN_SPINNER',
    YARN_TRADING = 'YARN_TRADING',
    ZIPPER_SUPPLIER = 'ZIPPER_SUPPLIER',
    ZIPPER_TRADING = 'ZIPPER_TRADING',
}
