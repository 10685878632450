import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAppContext from '../../use-context.hook';
import { useOrderService } from './use-order-service';
import { useAlertContext } from '../../use-alert.hook';

type UpdateOrderShipped = {
    shippedOn?: Date[] | moment.Moment[] | any;
    externalDataId?: string;
    shipToAddress?: string;
};

export function useUpdateOrderShipped() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useOrderService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({
            id,
            data,
            workspaceId,
        }: {
            id: string;
            data: UpdateOrderShipped;
            workspaceId?: string;
        }) => {
            if (!context.workspace?.id) return;
            return await service.shipped(
                workspaceId || context.workspace.id,
                id,
                data,
            );
        },
        onSuccess: () => {
            setAlert({
                type: 'success',
                message: 'Order shipped successfully',
            });
            client.invalidateQueries({
                queryKey: ['order'],
            });

            client.invalidateQueries({
                queryKey: ['orders'],
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to ship order: ${error.message}`,
            });
        },
    });
}
