import { useQuery } from '@tanstack/react-query';
import { SolicitationService } from '../../../../domain/services/solicitation-service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useSolicitationService } from './use-solicitation-service';

interface UseSolicitationDocumentsInterface {
    partner?: string;
}

const getDocuments = async (
    service: SolicitationService,
    workspace: string,
    partner: string,
): Promise<any[]> => {
    console.log('getting response');
    const response = await service.getDocuments(workspace, partner);
    console.log('getting response');

    return response;
};

export function useSolicitationDocuments(
    args: UseSolicitationDocumentsInterface,
) {
    const context = useAppContext();
    const { service } = useSolicitationService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'solicitation',
            { workspace: context.workspace?.id, partner: args.partner },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !args.partner) return [];

            return await getDocuments(
                service,
                context.workspace.id,
                args.partner,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch solicitation documents: ${error.message}`,
                title: 'Solicitation Error',
            });
        },
    });
}
