export enum UnitType {
    PIECES = 'pcs',
    BALES = 'bales',
    YARDS = 'yd',
    METERS = 'm',
    KG = 'kg',
    TONS = 'tons',
    DOZENS = 'dozens',
    POUNDS = 'lbs',
    STONE = 'stone',
    SETS = 'sets',
    INCHES = 'in',
    GRAMS = 'g',
}
