import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CompanyDocument } from '../../../../../domain/models/company-doc.model';
import { CompanyDocumentService } from '../../../../../domain/services/company-docs.service';
import { FileService } from '../../../../../domain/services/file.service';
import { useAlertContext } from '../../../use-alert.hook';
import { useFileService } from '../../file/use-files-service';
import { useCompanyDocumentService } from './use-document-service';
import useAppContext from '../../../use-context.hook';
import { UploadFile } from '@ianneo/ui-library';

export interface UploadDocumentsInterface {
    workspaceId?: string;
    documents: CompanyDocument[];
    files: UploadFile[];
}

const uploadDocument = async (
    fileService: FileService,
    documentService: CompanyDocumentService,
    workspaceId: string,
    documents: CompanyDocument[],
    files: UploadFile[],
) => {
    const response = await Promise.all(
        documents.map(async (document) => {
            const file = files.find(
                (file) => file.name === document.file?.originalName,
            );

            if (file && file.originFileObj) {
                const token = await fileService.upload(
                    workspaceId,
                    'document',
                    {
                        filename: document.file?.assetName || '',
                        contentType: document.file?.contentType || '',
                    },
                );

                await axios.put(token.token, file.originFileObj, {
                    headers: {
                        'x-ms-blob-type': 'BlockBlob',
                        'Content-Type': document.file?.contentType || '',
                    },
                });

                delete document.id;

                await documentService.create(workspaceId, {
                    ...document,
                    file: {
                        ...document.file,
                        assetName: token.attachment.assetName,
                    },
                });
            }
        }),
    );

    return response;
};

/**
 * Upload certification API which will be updated when any of the parameters in the [] array changes
 * Also will set the refresh state to true which will trigger the useEffect hook to fetch the documents
 */
export function useUploadDocuments() {
    const context = useAppContext();
    const { service: fileService } = useFileService();
    const { service: documentService } = useCompanyDocumentService();
    const { setAlert } = useAlertContext();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            documents,
            files,
            workspaceId,
        }: UploadDocumentsInterface) => {
            if (!context.workspace?.id) return;

            await uploadDocument(
                fileService,
                documentService,
                workspaceId ? workspaceId : context.workspace.id,
                documents,
                files,
            );
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: [
                    'companyDocuments',
                    {
                        type: args.workspaceId
                            ? args.workspaceId
                            : context.workspace?.id,
                    },
                ],
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to upload documents: ${error.message}`,
                title: 'Upload Company Documents',
            });
        },
    });
}
