import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Card,
    CollapsibleDropdown,
    Popconfirm,
    Table,
    Text,
} from '@ianneo/ui-library';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Breakdown,
    ProductMaterial,
} from '../../../../../domain/models/material.model';
import countries from '../../../../../infrastructure/config/data/countries.json';
import { UpdateProductMaterial } from '../../../../../infrastructure/hooks/api/materials/use-update-material';
import useVerifyAdmin from '../../../../../infrastructure/hooks/use-verify-admin';

interface MaterialBreakdownProps {
    material: ProductMaterial;
    setOpen: (open: boolean) => void;
    update: (args: UpdateProductMaterial) => void;
}

export default function MaterialBreakdown({
    material,
    setOpen,
    update,
}: MaterialBreakdownProps) {
    const { t } = useTranslation();
    const [data, setData] = useState<Breakdown[]>([]);
    const { isAdmin } = useVerifyAdmin();

    const remove = useCallback(
        async (id: number) => {
            const breakdowns = material.breakdowns?.filter((x) => x.id !== id);

            await update({
                ...material,
                breakdowns,
                material: material.material?.id || '',
            });
        },
        [material, update],
    );

    const columns = useMemo(
        () => [
            {
                title: t('material:detail.name'),
                dataIndex: 'material',
                render: (_: any, item: Breakdown) => {
                    return item.material?.name?.locales?.find(
                        (x) => x.localeName === 'en',
                    )?.text;
                },
            },
            {
                title: t('material:detail.origin'),
                dataIndex: 'origin',
                render: (_: any, item: Breakdown) => (
                    <Text>
                        {
                            countries.find((x) => x['alpha-2'] === item.origin)
                                ?.name
                        }
                    </Text>
                ),
            },
            { title: t('material:detail.weight'), dataIndex: 'weight' },
            {
                title: t('material:detail.percentage'),
                dataIndex: 'percentage',
                render: (_: any, item: Breakdown) => {
                    return <Text>{item.percentage}%</Text>;
                },
            },
            {
                title: t('common:actions'),
                dataIndex: 'actions',
                render: (_: any, item: Breakdown) => {
                    return (
                        <Popconfirm
                            title="Confirm to delete?"
                            submit={() => remove(item.id || 0)}
                        >
                            <DeleteOutlined style={{ color: 'red' }} />
                        </Popconfirm>
                    );
                },
            },
        ],
        [t, remove],
    );

    const actions = useMemo(() => {
        const columns: any = [];

        if (!isAdmin) {
            columns.push({
                key: '1',
                label: t('common:addNew'),
                icon: <PlusOutlined />,
                onClick: () => setOpen(true),
            });
        }

        return columns;
    }, [isAdmin, setOpen, t]);

    function calculatePercentage(items: Breakdown[]): Breakdown[] {
        const totalWeight = items.reduce(
            (acc, item) => acc + (Number(item.weight) || 0),
            0,
        );

        return items.map((item) => {
            const percentage = +(
                (Number(item.weight || 0) / Number(totalWeight)) *
                100
            ).toFixed(2);
            return { ...item, percentage: percentage };
        });
    }

    useEffect(() => {
        if (material) {
            setData(calculatePercentage(material.breakdowns || []));
        }
    }, [material]);

    return (
        <>
            <Card>
                <Table
                    columns={columns}
                    dataSource={data}
                    scroll={{ x: 'max-content' }}
                    showFilter={false}
                    actions={
                        <CollapsibleDropdown
                            menu={{
                                items: actions,
                            }}
                        ></CollapsibleDropdown>
                    }
                />
            </Card>
        </>
    );
}
