import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { NominatedListRequest } from '../../../../domain/zod/nominated-list.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useNominatedListService } from './use-nominated-list.service';

interface NominatedListUpdateRequest {
    id: number;
    args: NominatedListRequest;
    delegateId?: string;
}

export function useUpdateNominatedList() {
    const context = useAppContext();
    const client = useQueryClient();

    const { service } = useNominatedListService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({
            id,
            args,
            delegateId,
        }: NominatedListUpdateRequest) => {
            if (!args || !context.workspace?.id) return;
            return await service.update(
                delegateId || context.workspace.id,
                id,
                args,
            );
        },
        onSuccess: (_, { delegateId }) => {
            setAlert({
                type: 'success',
                title: 'Nominated List',
                message: 'Successfully updated nominated list',
            });

            client.invalidateQueries({
                queryKey: [
                    'nominated-list',
                    { workspace: delegateId || context.workspace?.id },
                ],
            });
        },
        onError: (err) => {
            const error = err as Error;

            if (err instanceof AxiosError) {
                if (err.response?.status === 403) {
                    setAlert({
                        type: 'error',
                        title: 'Nominated List',
                        message:
                            'You do not have permission to update nominated list',
                    });

                    return;
                }
            }

            setAlert({
                type: 'error',
                title: 'Internal Server Error',
                message: `Failed to update nominated list: ${error.message}`,
            });
        },
    });
}
