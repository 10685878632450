import { useQuery } from '@tanstack/react-query';
import { ProductVersion } from '../../../../domain/models/product.model';
import { ProductService } from '../../../../domain/services/product.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useProductService } from './use-product-service';

const getProducts = async (
    service: ProductService,
    id: string,
): Promise<ProductVersion[]> => {
    const response = await service.list(id);

    return response;
};

export function useProducts(
    workspaceId?: string,
    delegateWorkspaceId?: string,
) {
    const context = useAppContext();
    const { service } = useProductService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'products',
            { workspace: workspaceId ? workspaceId : context.workspace?.id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id) return;
            return await getProducts(
                service,
                workspaceId ? workspaceId : context.workspace.id,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch products information: ${error.message}`,
            });
        },
    });
}
