import { DownloadOutlined } from '@ant-design/icons';
import { Button, Text } from '@ianneo/ui-library';
import { useTranslation } from 'react-i18next';

interface ImportDownloadTemplateProps {
    extension: 'csv' | 'xlsx';
    type: string;
    document: any;
    fileName?: string;
}

export default function ImportDownloadTemplate({
    extension,
    type,
    document,
    fileName,
}: ImportDownloadTemplateProps) {
    const { t } = useTranslation();

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Text>{t('user:listing.user.importDownloadDescription')}</Text>

                <Button
                    download={
                        fileName
                            ? `${fileName}.${extension}`
                            : `${type}_template.${extension}`
                    }
                    href={document}
                    icon={<DownloadOutlined />}
                >
                    {t('user:listing.user.importDownloadBtn')}
                </Button>
            </div>
        </>
    );
}
