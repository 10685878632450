import { useNavigate } from "react-router-dom";
import { Company } from "../../../domain/models/company.model";
import { CompanyService } from "../../../domain/services/company.service";
import { useAlertContext } from "../../../infrastructure/hooks/use-alert.hook";
import useAppConfig from "../../../infrastructure/hooks/use-config.hook";
import useAppContext from "../../../infrastructure/hooks/use-context.hook";
import OnboardingComponent from "./onboarding.component";
import "./onboarding.component.css";

const OnboardingContainer: React.FC<{ header?: string }> = ({ header }) => {
    const config = useAppConfig();
    const context = useAppContext();
    const alerts = useAlertContext();
    const navigate = useNavigate();
    const service = new CompanyService(config, context?.token || "");

    const onDetailUpdated = async (request: Company) => {
        console.log(`page<company.container>| onDetailUpdated(): Enter`);
        console.log(
            `page<company.container>| onDetailUpdated(): $request = ${JSON.stringify(
                request
            )}`
        );

        try {
            if (context.token && context.workspace) {
                const delay = (duration: number) =>
                    new Promise((resolve) => setTimeout(resolve, duration));

                const response = await service.update(
                    context.workspace.id || "",
                    request
                );

                context!.workspace.company = response;

                console.log(
                    `page<company.container>| onDetailUpdate(): $response = ${JSON.stringify(
                        response
                    )}`
                );

                await delay(200);
                navigate(0);
            }
        } catch (ex) {
            alerts.setAlert({
                type: "error",
                title: "Failed to update company details",
                message: (ex as Error).message,
                timeout: 3000,
            });
        }
    };

    return (
        <>
            <OnboardingComponent
                data={context.workspace?.company}
                header={header}
                update={onDetailUpdated}
            />
        </>
    );
};

export default OnboardingContainer;
