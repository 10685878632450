import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProductMaterial } from '../../../../domain/models/material.model';
import { MaterialService } from '../../../../domain/services/material.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useMaterialService } from './use-material-service';

interface CreateParams {
    material: ProductMaterial;
    workspaceId?: string;
    delegateId?: string;
}

const createMaterial = async (
    service: MaterialService,
    id: string,
    material: ProductMaterial,
) => await service.create(id, material);

export function useCreateMaterial() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useMaterialService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({
            workspaceId,
            material,
            delegateId,
        }: CreateParams) => {
            if (!context.workspace?.id) return;

            return await createMaterial(
                service,
                workspaceId || context.workspace.id,
                material,
            );
        },
        onSuccess: (_, { workspaceId }) => {
            client.invalidateQueries({
                queryKey: [
                    'materials',
                    { type: workspaceId || context.workspace?.id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Material created successfully',
                title: 'Create Material',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to create material: ${error.message}`,
                title: 'Create Material',
            });
        },
    });
}
