import { useQuery } from '@tanstack/react-query';
import { ProductMaterial } from '../../../../domain/models/material.model';
import { PurchaseService } from '../../../../domain/services/purchase.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseService } from './use-purchase-service';

const getPurchaseItems = async (
    service: PurchaseService,
    id: string,
): Promise<ProductMaterial[]> => {
    const response = await service.listItems(id);

    return response;
};

export function usePurchaseItmes(workspaceId?: string) {
    const context = useAppContext();
    const { service } = usePurchaseService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'purchases-items',
            { type: workspaceId || context.workspace?.id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id) return;
            return await getPurchaseItems(
                service,
                workspaceId || context.workspace.id,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch purchases items: ${error.message}`,
            });
        },
    });
}
