import { CompanyDocumentApiClient } from '../../infrastructure/clients/document.client';
import { UpdateCompanyDocumentParams } from '../../infrastructure/hooks/api/company/documents/use-edit-document';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { CompanyDocument } from '../models/company-doc.model';

/**
 * @class
 * @name CompanyDocumentService
 * @description The company document service to load and get docs
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */
export class CompanyDocumentService {
    private server: CompanyDocumentApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new CompanyDocumentApiClient(config, token);
    }

    private convert(source: any) {
        console.log(`service<company-doc>| convert(): Enter`);
        console.log(
            `service<company-doc>| convert(): $source = ${JSON.stringify(
                source,
            )}`,
        );
        const result: CompanyDocument = {
            id: source.id,
            comment: source.comment,
            file: source.file,
            lastUpdatedOn: source.lastUpdatedOn,
            nature: source.nature,
            status: source.status,
            verifiedOn: source.verifiedOn,
        };
        return result;
    }

    private async isDuplicate(workspace: string, document: CompanyDocument) {
        console.log(`service<company-doc>| isDuplicate(): Enter`);
        console.log(
            `service<company-doc>| isDuplicate(): $workspace = ${workspace}`,
        );
        console.log(
            `service<company-doc>| isDuplicate(): $workspace = ${JSON.stringify(
                document,
            )}`,
        );
        const docs: CompanyDocument[] = await this.server.list(workspace);
        return docs.filter((d) => d.nature === document.nature).length > 0;
    }

    async create(workspace: string, document: CompanyDocument) {
        console.log(`service<company-doc>| create(): Enter`);
        console.log(
            `service<company-doc>| create(): $workspace = ${workspace}`,
        );
        console.log(
            `service<company-doc>| create(): $document = ${JSON.stringify(
                document,
            )}`,
        );

        return this.convert(await this.server.create(workspace, document));
    }

    async list(workspace: string) {
        console.log(`service<company-doc>| list(): Enter`);
        console.log(`service<company-doc>| list(): $workspace = ${workspace}`);
        return await this.server.list(workspace);
    }

    async update(
        workspace: string,
        item: string,
        document: UpdateCompanyDocumentParams,
    ) {
        console.log(`service<company-doc>| create(): Enter`);
        console.log(
            `service<company-doc>| create(): $workspace = ${workspace}`,
        );
        console.log(
            `service<company-doc>| create(): $workspace = ${JSON.stringify(
                document,
            )}`,
        );

        return await this.server.update(workspace, item, document);
    }

    async remove(workspace: string, item: string) {
        console.log(`service<company-doc>| remove(): Enter`);
        console.log(
            `service<company-doc>| remove(): $workspace = ${workspace}`,
        );
        console.log(`service<company-doc>| remove(): $item = ${item}`);

        return await this.server.remove(workspace, item);
    }
}
