import {
    DatePicker,
    Form,
    FormItem,
    Input,
    Modal,
    Select,
    useForm,
} from '@ianneo/ui-library';
import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Award } from '../../../../domain/models/award.modal';
import { useAwardTypes } from '../../../../infrastructure/hooks/api/award/use-awards-types';
import { useUpdateAward } from '../../../../infrastructure/hooks/api/award/use-update-award';

interface EditDocumentsProps {
    open: boolean;
    data?: Award;
    toggle: () => void;
}

export default function EditCertifications({
    open,
    data,
    toggle,
}: EditDocumentsProps) {
    const { t } = useTranslation();
    const [form] = useForm();

    const { data: types } = useAwardTypes();
    const { mutateAsync: update } = useUpdateAward();

    const reset = () => {
        form.resetFields();
        toggle();
    };

    const submit = async () => {
        await update({
            id: data?.id || '',
            params: form.getFieldsValue(true) as Award,
        });

        reset();
    };

    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                ...data,
                expiryDate: data?.expiryDate ? moment(data?.expiryDate) : null,
                issuedDate: data?.issuedDate ? moment(data?.issuedDate) : null,
            });
        }
    }, [data, form, open]);

    return (
        <Modal
            title={t('company:certifications.edit.title')}
            open={open}
            okFn={submit}
            cancelFn={reset}
        >
            <Form form={form}>
                <FormItem
                    label={t('company:certifications.auditOrg')}
                    name="auditOrganization"
                >
                    <Input />
                </FormItem>

                <FormItem
                    label={t('company:certifications.certification')}
                    name={['certification', 'id']}
                >
                    <Select
                        options={Object.values(types || {}).map((x) => ({
                            label: x.name,
                            value: x.id,
                            key: x.id,
                        }))}
                    />
                </FormItem>

                <FormItem
                    label={t('company:certifications.certNo')}
                    name="certificateNumber"
                >
                    <Input />
                </FormItem>

                <FormItem
                    label={t('company:certifications.expiryDate')}
                    name="expiryDate"
                >
                    <DatePicker style={{ width: '100%' }} />
                </FormItem>

                <FormItem
                    label={t('company:certifications.issuedDate')}
                    name="issuedDate"
                >
                    <DatePicker style={{ width: '100%' }} />
                </FormItem>
            </Form>
        </Modal>
    );
}
