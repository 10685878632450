import {
    Dragger,
    Form,
    FormItem,
    Input,
    InputNumber,
    Select,
    StepModal,
    Switch,
    Text,
    UploadFile,
    useForm,
} from '@ianneo/ui-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitType } from '../../../../domain/enums/unit-type.enum';
import { CreateProduct } from '../../../../domain/models/product.model';
import { useBaseCategories } from '../../../../infrastructure/hooks/api/category/use-base-categories';
import { useCategories } from '../../../../infrastructure/hooks/api/category/use-categories';
import { useUploadImage } from '../../../../infrastructure/hooks/api/file/use-upload-image';
import { useCreateProduct } from '../../../../infrastructure/hooks/api/products/use-create-product';
import { getUnitValue } from '../../../../infrastructure/utils/unit-type-labeler';
import './add-product.component.css';
import { useGetDelegations } from '../../../_api/delegations/hooks/useGetDelegations';
import { DelegationComponent } from '../../Partners/add-partner/delegations.component';

interface AddProductComponentProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const AddProductComponent: React.FC<AddProductComponentProps> = (args) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const [base, setBase] = useState('');
    const [files, setFiles] = useState<UploadFile[]>([]);
    const { delegations } = useGetDelegations();
    const [onBehalfOf, setOnBehalfOf] = useState(0);

    const { data: categories } = useCategories(
        delegations?.find((x) => x.id === onBehalfOf)?.workspace?.id,
    );
    const { data: baseCategories } = useBaseCategories();

    const { mutateAsync: createProduct } = useCreateProduct();
    const { mutateAsync: uploadImages } = useUploadImage();

    const measureUnits = Object.values(UnitType).map((x) => ({
        key: x,
        value: x,
        label: getUnitValue(x),
    }));

    const defaultRules = [
        { required: true, message: t('product:modal.errorMsg') },
    ];

    const close = () => {
        args.setOpen(false);

        form.resetFields();
        setFiles([]);
    };

    const submit = async () => {
        try {
            await form.validateFields();

            const values = form.getFieldsValue(true);
            const workspaceId = delegations.find((x) => x.id === onBehalfOf)
                ?.workspace?.id;
            const images = await uploadImages({
                files,
                type: 'product',
                workspaceId,
            });

            const request: CreateProduct = Object.assign({}, values);
            request.pictures = images;

            await createProduct({ product: request, workspaceId });

            close();
        } catch (err) {
            return;
        }
    };

    return (
        <>
            <StepModal
                open={args.open}
                title="Add Product"
                closable={false}
                okFn={submit}
                cancelFn={close}
                form={form}
                width={'60vw'}
                subtitle={t('product:modal.subtitle') || ''}
                stepContent={[
                    {
                        title: 'On Behalf Of.',
                        content: (
                            <DelegationComponent
                                form={form}
                                onBehalfOf={onBehalfOf}
                                setOnBehalfOf={setOnBehalfOf}
                            />
                        ),
                    },
                    {
                        title: t('common:steps.required'),
                        content: (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '10px',
                                    }}
                                >
                                    <Text
                                        className="lfx-form-header"
                                        style={{
                                            marginBottom: '16px',
                                            alignSelf: 'unset',
                                        }}
                                    >
                                        {t('product:modal.description')}
                                    </Text>

                                    <Form form={form}>
                                        <FormItem
                                            label={t('product:modal.name')}
                                            name="name"
                                            rules={defaultRules}
                                            required
                                        >
                                            <Input />
                                        </FormItem>

                                        <FormItem
                                            label={t('product:create.cost')}
                                            rules={[
                                                ...defaultRules,
                                                {
                                                    pattern: /^\d*\.?\d*$/,
                                                    message:
                                                        'Please input a positive number only.',
                                                },
                                            ]}
                                            name="cost"
                                            required
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                            />
                                        </FormItem>

                                        <FormItem
                                            label={t('product:modal.category')}
                                            rules={defaultRules}
                                            name="baseCategory"
                                            required
                                        >
                                            <Select
                                                showSearch
                                                options={baseCategories?.map(
                                                    (x) => ({
                                                        key: x.id,
                                                        label: x.name?.locales?.find(
                                                            (y) =>
                                                                y.localeName ===
                                                                'en',
                                                        )?.text,
                                                        value: x.id,
                                                    }),
                                                )}
                                                onChange={(value) =>
                                                    setBase(value)
                                                }
                                            />
                                        </FormItem>

                                        <FormItem
                                            label={t(
                                                'product:modal.subCategory',
                                            )}
                                            rules={defaultRules}
                                            required
                                            name="category"
                                        >
                                            <Select
                                                showSearch
                                                options={categories
                                                    ?.filter(
                                                        (x) =>
                                                            x.base?.id === base,
                                                    )
                                                    .map((x) => ({
                                                        key: x.id,
                                                        label: x.name?.locales?.find(
                                                            (y) =>
                                                                y.localeName ===
                                                                'en',
                                                        )?.text,
                                                        value: x.id,
                                                    }))}
                                            />
                                        </FormItem>

                                        <FormItem
                                            label={t(
                                                'product:modal.measureUnit',
                                            )}
                                            rules={defaultRules}
                                            name="unit"
                                            required
                                        >
                                            <Select options={measureUnits} />
                                        </FormItem>

                                        <FormItem
                                            label={t(
                                                'product:modal.measureValue',
                                            )}
                                            rules={[
                                                ...defaultRules,
                                                {
                                                    pattern: /^\d*\.?\d*$/,
                                                    message:
                                                        'Please input a positive number only.',
                                                },
                                            ]}
                                            name="measureValue"
                                            required
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                            />
                                        </FormItem>
                                    </Form>
                                </div>
                            </>
                        ),
                    },
                    {
                        title: t('common:steps.optional'),
                        content: (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '10px',
                                    }}
                                >
                                    <Text
                                        className="lfx-form-header"
                                        style={{ marginBottom: '16px' }}
                                    >
                                        {t('product:modal.optionalDescription')}
                                    </Text>

                                    <Form form={form}>
                                        <FormItem
                                            name="isActive"
                                            label={t('product:modal.active')}
                                        >
                                            <Switch />
                                        </FormItem>

                                        <FormItem
                                            name="weight"
                                            label={t('product:modal.weight')}
                                            rules={[
                                                {
                                                    pattern: /^\d*\.?\d*$/,
                                                    message:
                                                        'Please input a positive number only.',
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        </FormItem>

                                        <FormItem
                                            name="color"
                                            label={t('product:modal.colorCode')}
                                        >
                                            <Input />
                                        </FormItem>

                                        <FormItem
                                            name="externalDataId"
                                            label={t('product:modal.lfItemNo')}
                                        >
                                            <Input />
                                        </FormItem>

                                        <FormItem
                                            name="hsCode"
                                            label={t('product:modal.hsCode')}
                                        >
                                            <Input />
                                        </FormItem>

                                        <FormItem
                                            name="sku"
                                            label={t('product:modal.sku')}
                                        >
                                            <Input />
                                        </FormItem>

                                        <FormItem
                                            name="upc"
                                            label={t('product:modal.upc')}
                                        >
                                            <Input />
                                        </FormItem>

                                        <FormItem
                                            name="code"
                                            label={t('product:modal.refNo')}
                                        >
                                            <Input />
                                        </FormItem>

                                        <FormItem
                                            name="collection"
                                            label={t(
                                                'product:modal.collection',
                                            )}
                                        >
                                            <Input />
                                        </FormItem>

                                        <FormItem label={t('common:upload')}>
                                            <Dragger
                                                fileList={files}
                                                setFiles={setFiles}
                                                className="custom-upload-css"
                                                accept="image/png,image/jpeg,image/jpg"
                                                defaultDescription="Supported file types are JPG, PNG"
                                            />
                                        </FormItem>
                                    </Form>
                                </div>
                            </>
                        ),
                    },
                ]}
            ></StepModal>
        </>
    );
};

export default AddProductComponent;
