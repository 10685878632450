import { useQuery } from '@tanstack/react-query';
import { WorkspaceService } from '../../../../domain/services/workspace.service';
import { useAlertContext } from '../../use-alert.hook';
import { useWorkspaceService } from './use-workspace-service';

interface UseWorkspaceInterface {
    id: string;
    enabled?: boolean;
}

const getWorkspace = async (service: WorkspaceService, id: string) => {
    const response = await service.get(id);

    return response;
};

export function useWorkspace({ id, enabled }: UseWorkspaceInterface) {
    const { service } = useWorkspaceService();
    const { setAlert } = useAlertContext();

    return useQuery(
        ['workspace', id],
        async () => {
            try {
                if (!id) return {};
                const response = await getWorkspace(service, id);

                return response;
            } catch (err) {
                const error = err as Error;

                setAlert({
                    type: 'error',
                    message: `Failed to fetch workspace information: ${error.message}`,
                });
            }
        },
        { enabled: enabled },
    );
}
