import { PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Link,
    Modal,
    Select,
    Table,
    Text,
} from '@ianneo/ui-library';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    PurchaseOrder,
    PurchaseOrderLink,
} from '../../../../domain/models/purchase.model';
import { SalesOrder } from '../../../../domain/models/sales-order.model';
import { useCreateLink } from '../../../../infrastructure/hooks/api/links/use-create-link';
import { useDeleteLink } from '../../../../infrastructure/hooks/api/links/use-delete-link';
import { useLinks } from '../../../../infrastructure/hooks/api/links/use-links';
import { usePurchase } from '../../../../infrastructure/hooks/api/purchases/use-purchase';
import { usePurchases } from '../../../../infrastructure/hooks/api/purchases/use-purchases';
import { useAlertContext } from '../../../../infrastructure/hooks/use-alert.hook';
import { useQueryClient } from '@tanstack/react-query';
import useAppContext from '../../../../infrastructure/hooks/use-context.hook';

interface OrderLinkComponentProps {
    selected?: string;
    open: boolean;
    order: SalesOrder;
    setOpen: (args: boolean) => void;
}

const OrderLinkComponent: React.FC<OrderLinkComponentProps> = ({
    selected,
    open,
    order,
    setOpen,
}) => {
    const { t } = useTranslation();
    const { data: purchaseOrders } = usePurchases(selected);
    const workspace = useAppContext().workspace;
    const { mutateAsync: link } = useCreateLink();
    const { mutateAsync: unlink } = useDeleteLink();
    const { setAlert } = useAlertContext();
    const client = useQueryClient();
    const context = useAppContext();

    // Fetch selected purchase order preview

    const { data: existingLinks } = useLinks({
        id: order.id || '',
        workspaceId: selected,
    });
    // State variable that contains the existing selected preview.
    const [selectedPreview, setSelectedPreview] = useState<PurchaseOrder>({});
    // Fetch list of purchase orders to be linked

    const [selectedPOs, setSelectedPOs] = useState<PurchaseOrderLink[]>(
        existingLinks || [],
    );
    const [removedPOs, setRemovedPOs] = useState<PurchaseOrderLink[]>([]);

    const { data: previewInfo } = usePurchase({
        id: selectedPreview.id || '',
        customWorkspaceId: selectedPreview.supplier?.owner?.id,
    });

    const reset = () => {
        setSelectedPreview({});
        setSelectedPOs([]);
        setRemovedPOs([]);

        setOpen(false);
    };

    const submit = async () => {
        if (!order.id) return;

        try {
            await link({
                id: order.id || '',
                orders: {
                    purchaseOrders: selectedPOs
                        .map((i) => i.owner?.id || '')
                        .filter((i) => i !== ''),
                },
                workspaceId: selected,
            });

            await unlink({
                id: order.id || '',
                purchaseOrderIds: removedPOs.map((i) => i.owner?.id || ''),
                workspaceId: selected,
            });

            setAlert({
                type: 'success',
                message: 'Order Links updated successfully',
                title: 'Update Links',
            });

            setTimeout(() => {
                client.invalidateQueries({
                    queryKey: [
                        'links',
                        {
                            workspace: selected || context.workspace?.id,
                            id: order.id,
                        },
                    ],
                });
            }, 1000);
        } catch (err) {
            setAlert({
                type: 'error',
                message: 'Failed to update links',
                title: 'Update Links',
            });
        }

        setOpen(false);
    };

    const addPo = () => {
        if (!selectedPreview.id) return;

        if (selectedPOs.find((i) => i.owner?.id === previewInfo?.owner?.id))
            return;

        setSelectedPOs([
            ...selectedPOs,
            {
                owner: {
                    id: previewInfo?.owner?.id,
                    externalDataId: previewInfo?.owner?.externalDataId,
                },
            },
        ]);
    };

    const removePO = useCallback(
        (item: PurchaseOrderLink) => {
            const removed = selectedPOs.find((x) => {
                return (
                    x.owner?.id === item.owner?.id &&
                    x.owner?.externalDataId === item.owner?.externalDataId
                );
            });

            if (removed) {
                setRemovedPOs([...removedPOs, removed]);
            }

            setSelectedPOs(
                selectedPOs.filter(
                    (x) =>
                        !(
                            x.owner?.externalDataId ===
                                item.owner?.externalDataId &&
                            x.owner?.id === item.owner?.id
                        ),
                ),
            );
        },
        [removedPOs, selectedPOs],
    );

    const getOrderTitle = useCallback(
        (item: any) => {
            console.log(`test<item>: ${JSON.stringify(item)}`);
            if (item?.externalCustomerReference && item?.externalDataId) {
                if (workspace?.isCaseDisplayed) {
                    return `${item.externalCustomerReference} - ${item.externalDataId}`;
                } else {
                    return item.externalCustomerReference;
                }
            }

            if (item?.externalCustomerReference) {
                return item.externalCustomerReference;
            }
            if (item?.externalDataId) {
                return item.externalDataId;
            }
            return `Internal Order - ${item.id?.slice(0, 6)}`;
        },
        [workspace?.isCaseDisplayed],
    );

    const purchaseOrderColumns = useMemo(
        () => [
            {
                title: t('order:modal.link.poNumber'),
                dataIndex: ['owner', 'externalDataId'],
                render: (_: any, item: PurchaseOrderLink) => {
                    console.log(`test<item-link>: ${JSON.stringify(item)}`);
                    return getOrderTitle(item.owner);
                },
            },
            {
                title: t('common:actions'),
                render: (_: any, item: PurchaseOrderLink) => (
                    <Link
                        onClick={() => {
                            removePO(item);
                        }}
                    >
                        {t('common:remove')}
                    </Link>
                ),
            },
        ],
        [t, removePO, getOrderTitle],
    );

    /**
     * Purchase preview order columns that will show the products within the order
     */
    const purchasePreviewColumns = useMemo(
        () => [
            {
                title: t('order:modal.link.productName'),
                dataIndex: ['purchaseables', 'name'],
            },
            {
                title: t('order:modal.link.description'),
                dataIndex: 'comment',
            },
            { title: t('order:modal.link.price'), dataIndex: 'ppu' },
            { title: t('order:modal.link.quantity'), dataIndex: 'quantity' },
        ],
        [t],
    );

    /**
     * adding this to make sure when the links are refreshed we also alter the POs
     */
    useEffect(() => {
        setSelectedPOs(existingLinks || []);
    }, [existingLinks]);

    return (
        <>
            <Modal
                open={open}
                // closable={false}
                title={t('order:modal.link.title')}
                width={1000}
                okFn={submit}
                cancelFn={reset}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '12px',
                        alignItems: 'center',
                        marginBottom: '18px',
                    }}
                >
                    <Text>{t('order:modal.link.selectPreview')}</Text>

                    <Select
                        options={purchaseOrders?.filter((x) => x !== null && x !== undefined).map((x) => ({
                            key: x.id,
                            value: x.id,
                            label: getOrderTitle(x),
                        }))}
                        onChange={(value) => {
                            const purchaseOrder = purchaseOrders?.find(
                                (x) => x.id === value,
                            );

                            if (!purchaseOrder) return;
                            setSelectedPreview(purchaseOrder);
                        }}
                        style={{ width: '40%' }}
                    />

                    <Button btntype="Close" onClick={addPo}>
                        <PlusOutlined />
                        {t('order:modal.link.add')}
                    </Button>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '12px',
                    }}
                >
                    <Card
                        title="Selected Orders"
                        style={{ flex: 1, maxWidth: '50%' }}
                    >
                        <Table
                            showColumns={false}
                            showFilter={false}
                            showSearch={false}
                            style={{ minHeight: 0 }}
                            scroll={{ x: 'max-content' }}
                            columns={purchaseOrderColumns}
                            dataSource={selectedPOs}
                        />
                    </Card>

                    <Card
                        title="Order Preview"
                        style={{ flex: 1, maxWidth: '50%' }}
                    >
                        <Table
                            showColumns={false}
                            showFilter={false}
                            showSearch={false}
                            style={{ minHeight: 0 }}
                            columns={purchasePreviewColumns}
                            dataSource={previewInfo?.manifest}
                            scroll={{ x: 'max-content' }}
                        />
                    </Card>
                </div>
            </Modal>
        </>
    );
};

export default OrderLinkComponent;
