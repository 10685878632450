import { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleAnalyticsTracing } from '../../../infrastructure/hooks/use-google-analytics.hook';
import useT4SAuth from '../../../infrastructure/hooks/use-t4s-auth';
import AlertStateContextProvider from '../../../infrastructure/providers/alert.context.provider';
import Layout from '../../components/Layout';
import Onboarding from '../../components/Onboarding';
import AdminAssume from '../../pages-v2/AdminAssume/admin-assume';
import Loading from '../../pages/Loading/loading.page';

export interface ProtectedRouteProps {
    component: ReactElement<any, any>;
    header: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    component,
    header,
}) => {
    // This is to initialise the google analytics tracking code
    useGoogleAnalyticsTracing();

    const { i18n } = useTranslation();

    // This is the new component that is incharge of handling the auth0 authentication
    const { isReady, isCompleted, isAdmin } = useT4SAuth();

    useEffect(() => {
        const sessionLng = localStorage.getItem('sessionLng');

        if (sessionLng) {
            i18n.changeLanguage(sessionLng);
        }
    }, [i18n]);

    if (isAdmin) {
        return (
            <AlertStateContextProvider>
                <AdminAssume />
            </AlertStateContextProvider>
        );
    }

    if (isReady && isCompleted) {
        return (
            <AlertStateContextProvider>
                <Layout components={component} header={header} />
            </AlertStateContextProvider>
        );
    } else if (isReady && !isCompleted) {
        return (
            <AlertStateContextProvider>
                <Onboarding header={header} />
            </AlertStateContextProvider>
        );
    }

    return <Loading pending={true} />;
};

export default ProtectedRoute;
