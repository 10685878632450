import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateProduct } from '../../../../domain/models/product.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useProductService } from './use-product-service';

export interface CreateProductParams {
    product: CreateProduct;
    workspaceId?: string;
    diversion?: string;
}

export function useCreateProduct() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useProductService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({
            workspaceId,
            product,
            diversion,
        }: CreateProductParams) => {
            if (!context.workspace?.id) return;

            return await service.create(
                workspaceId || context.workspace.id,
                product,
                diversion,
            );
        },
        onSuccess: (_, { workspaceId }) => {
            client.invalidateQueries({
                queryKey: [
                    'products',
                    { workspace: workspaceId || context.workspace?.id },
                ],
            });

            client.invalidateQueries({
                queryKey: ['purchases-items'],
            });

            setAlert({
                type: 'success',
                message: 'Product created successfully',
                title: 'Create Product',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to create product: ${error.message}`,
                title: 'Create Product',
            });
        },
    });
}
