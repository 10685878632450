import { DownloadOutlined } from '@ant-design/icons';
import { Link, Popover, Text } from '@ianneo/ui-library';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDocument } from '../../../domain/models/company-doc.model';
import { Attachment } from '../../../domain/types/attachment.type';
import { useDownloadFile } from '../../../infrastructure/hooks/api/file/use-download-file';
import { PurchaseDocumentItem } from './purchase-documents.component';

interface PurchaseCompanyDocumentsComponentProps {
    item: PurchaseDocumentItem;
    documents: Record<string, CompanyDocument[]>;
}

export const PurchaseCompanyDocumentsComponent: React.FC<
    PurchaseCompanyDocumentsComponentProps
> = ({ item, documents }) => {
    const { t } = useTranslation();
    const { mutateAsync } = useDownloadFile();

    const download = useCallback(
        async (file: Attachment) => {
            const response = await mutateAsync({ attachment: file });
            if (!response?.token) return;

            const fileBlob = await axios.get(response.token, {
                responseType: 'blob',
            });

            saveAs(
                fileBlob.data,
                `Tier ${item.tier + 1} - ${file.originalName}`,
            );
        },
        [item, mutateAsync],
    );

    const renderPopoverContent = useCallback(() => {
        const files = documents[item.supplierWorkspaceId] || [];

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {files.map((x) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                gap: '12px',
                                alignContent: 'center',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Text>{x.file.originalName}</Text>

                            <DownloadOutlined
                                onClick={() => download(x.file)}
                                style={{ color: '#1890ff' }}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }, [documents, item, download]);

    return (
        <>
            <Popover
                title={t('purchase:detail.documents.company')}
                trigger={['click']}
                content={renderPopoverContent()}
                arrow={false}
            >
                <Link>{t('purchase:detail.documents.documents')}</Link>
            </Popover>
        </>
    );
};
