import { EditOutlined } from '@ant-design/icons';
import { InfoCard } from '@ianneo/component-library';
import {
    Card,
    CollapsibleDropdown,
    Subtitle,
    Table,
    Tag,
    Text,
    Title,
} from '@ianneo/ui-library';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Ruleset,
    SupportDocument,
} from '../../../../domain/models/ruleset.model';
import useVerifyAdmin from '../../../../infrastructure/hooks/use-verify-admin';
import { getDocumentValue } from '../../../../infrastructure/utils/document-type-labeler';
import { getProcessValue } from '../../../../infrastructure/utils/process-labeler';
import EditRulesetComponent from '../edit-ruleset/edit-ruleset.component';
import { useParams } from 'react-router-dom';

interface RulesetDetailLayoutProps {
    data: Ruleset;
}

const RulesetDetailLayout: React.FC<RulesetDetailLayoutProps> = ({ data }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const { isAdmin } = useVerifyAdmin();
    const { delegateId } = useParams();

    console.log(data);

    const name =
        typeof data.name === 'object'
            ? data.name?.locales?.find((x) => x.localeName === 'en')?.text
            : typeof data.name === 'string'
            ? JSON.parse(data.name)?.locales?.find(
                  (x: any) => x.localeName === 'en',
              ).text
            : '';

    const description =
        typeof data.description === 'object'
            ? data.description?.locales?.find((x) => x.localeName === 'en')
                  ?.text
            : typeof data.description === 'string'
            ? JSON.parse(data.description)?.locales?.find(
                  (x: any) => x.localeName === 'en',
              ).text
            : '';

    const columns = useMemo(
        () => [
            {
                title: t('ruleset:detail.documentType'),
                dataIndex: 'document',
                render: (_: any, item: SupportDocument) => (
                    <Text>
                        {item.document ? getDocumentValue(item.document) : ''}
                    </Text>
                ),
            },
            {
                title: t('ruleset:detail.appliesTo'),
                dataIndex: 'appliesTo',
                render: (_: any, item: SupportDocument) => (
                    <Text>
                        {item.appliesTo?.map((x) => (
                            <Tag>{getProcessValue(x)}</Tag>
                        ))}
                    </Text>
                ),
            },
            {
                title: t('ruleset:detail.submissionLeadTime'),
                dataIndex: 'submissionLeadTime',
            },
            {
                title: t('ruleset:detail.required'),
                dataIndex: 'required',
                render: (_: any, item: SupportDocument) => (
                    <Text>
                        {item.isRequired
                            ? t('ruleset:detail.required')
                            : t('ruleset:detail.notRequired')}
                    </Text>
                ),
            },
        ],
        [t],
    );

    const items = useMemo(() => {
        const items = [];
        if (!isAdmin) {
            items.push({
                label: t('common:edit'),
                onClick: () => setOpen(true),
                icon: <EditOutlined />,
                key: 'edit',
            });
        }

        return items;
    }, [t, isAdmin]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '8px',
                            backgroundColor: '#972d47',
                            minHeight: '40px',
                            minWidth: '8px',
                        }}
                    ></div>
                    <Title>{t('ruleset:name')}</Title>
                    <Subtitle>{t('ruleset:description')}</Subtitle>
                </div>

                <InfoCard
                    infoTitle={<div>{name}</div>}
                    information={
                        <>
                            <Text>{description}</Text>
                        </>
                    }
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                >
                    <Card>
                        <Table
                            rowKey="id"
                            dataSource={data.documentation}
                            columns={columns}
                            scroll={{ x: 'max-content' }}
                            actions={
                                <CollapsibleDropdown
                                    menu={{
                                        items,
                                    }}
                                ></CollapsibleDropdown>
                            }
                        />
                    </Card>
                </div>
            </div>

            {open ? (
                <EditRulesetComponent
                    data={data}
                    open={open}
                    setOpen={setOpen}
                    selected={delegateId}
                />
            ) : null}
        </>
    );
};

export default RulesetDetailLayout;
