import { useQuery } from '@tanstack/react-query';
import { Role } from '../../../../domain/models/role.model';
import { WorkspaceService } from '../../../../domain/services/workspace.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useWorkspaceService } from '../workspaces/use-workspace-service';

const getRole = async (
    service: WorkspaceService,
    id: string,
    roleId: string,
): Promise<Role> => {
    const response = await service.getRoleInfo(id, roleId);

    return response;
};

export function useRole({
    id,
    delegateId,
}: {
    id: string;
    delegateId?: string;
}) {
    const context = useAppContext();
    const { service } = useWorkspaceService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'role',
            { workspace: delegateId || context.workspace?.id, id: id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !id) return {};
            return await getRole(
                service,
                delegateId || context.workspace.id,
                id,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch role: ${error.message}`,
                title: 'Fetching Role',
            });
        },
    });
}
