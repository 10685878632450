import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { NominatedListRequest } from '../../../../domain/zod/nominated-list.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useNominatedListService } from './use-nominated-list.service';

export function useCreateNominatedList() {
    const context = useAppContext();
    const client = useQueryClient();

    const { service } = useNominatedListService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({
            args,
            workspaceId,
        }: {
            args: NominatedListRequest;
            workspaceId?: string;
        }) => {
            if (!args || !context.workspace?.id) return;
            return await service.create(
                workspaceId || context.workspace.id,
                args,
            );
        },
        onSuccess: (_, { workspaceId }) => {
            setAlert({
                type: 'success',
                title: 'Nominated List',
                message: 'Successfully created nominated list',
            });

            client.invalidateQueries({
                queryKey: [
                    'nominated-list',
                    { workspace: workspaceId || context.workspace?.id },
                ],
            });
        },
        onError: (err) => {
            const error = err as Error;

            if (err instanceof AxiosError) {
                if (err.response?.status === 403) {
                    setAlert({
                        type: 'error',
                        title: 'Nominated List',
                        message:
                            'You do not have permission to create nominated list',
                    });
                    return;
                }
            }

            setAlert({
                type: 'error',
                title: 'Internal Server Error',
                message: `Failed to create nominated list: ${error.message}`,
            });
        },
    });
}
