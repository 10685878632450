import { useQuery } from '@tanstack/react-query';
import { PurchaseOrder } from '../../../../domain/models/purchase.model';
import { LinkService } from '../../../../domain/services/link.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useLinkService } from './use-links-service';

interface UseProductLinkInterface {
    id?: string;
    workspaceId?: string;
}

export interface PurchaseOrderLink {
    id?: number;
    createdOn?: Date;
    lastUpdated?: Date;
    owner: PurchaseOrder;
    salesOrder?: number;
}

const getLinks = async (
    service: LinkService,
    workspace: string,
    id: string,
): Promise<PurchaseOrderLink[]> => {
    const response = await service.get(workspace, id);

    return response;
};

export function useLinks({ id, workspaceId }: UseProductLinkInterface) {
    const context = useAppContext();
    const { service } = useLinkService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'links',
            { workspace: workspaceId || context.workspace?.id, id: id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !id) return;
            return await getLinks(
                service,
                workspaceId || context.workspace.id,
                id,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch purchase order link information: ${error.message}`,
            });
        },
    });
}
