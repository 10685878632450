import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Composition, Tag } from '../../../../domain/models/product.model';
import { Attachment } from '../../../../domain/types/attachment.type';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useProductService } from './use-product-service';

export interface UpdateProductParams {
    id: string;
    args: UpdateProduct;
    delegateId?: string;
}

export interface UpdateProduct {
    code?: string;
    cost?: number;
    collection?: string;
    color?: string;
    components?: Composition[];
    description?: string;
    hsCode?: string;
    name?: string;
    pictures?: Attachment[];
    remark?: string;
    sku?: string;
    specifications?: Tag[];
    tags?: Tag[];
    upc?: string;
    weight?: number;
}

export function useUpdateProduct() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useProductService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ id, args, delegateId }: UpdateProductParams) => {
            if (!context.workspace?.id) return;
            await service.update(delegateId || context.workspace.id, id, {
                ...args,
                cost: Number(args.cost),
                weight: Number(args.weight),
            });
        },
        onSuccess: (_, { delegateId, id }) => {
            client.invalidateQueries({
                queryKey: [
                    'product',
                    { workspace: delegateId || context.workspace?.id, id },
                ],
            });

            client.invalidateQueries({
                queryKey: [
                    'productSearch',
                    { workspace: delegateId || context.workspace?.id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Product updated successfully',
                title: 'Update Product',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to update product: ${error.message}`,
                title: 'Update Product',
            });
        },
    });
}
