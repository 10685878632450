import { useQuery } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePartnerService } from './use-partner-service';
import { Supplier } from '../../../../domain/models/supplier.model';

export function usePartnerV2(id: string, delegateId?: string) {
    const context = useAppContext();
    const { service } = usePartnerService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'partner-v2',
            { workspace: delegateId ? delegateId : context.workspace?.id, id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !id) return;

            const response = await service.getPartnerV2(
                delegateId ? delegateId : context.workspace?.id,
                id,
            );

            return response as Supplier;
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch partner information: ${error.message}`,
            });
        },
    });
}
