import axios from 'axios';
import { saveAs } from 'file-saver';
import { Award } from '../../../../domain/models/award.modal';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useFileService } from '../file/use-files-service';

interface DownloadAwardInterface {
    award: Award;
}

export default function useDownloadAward() {
    const context = useAppContext();
    const { service } = useFileService();
    const { setAlert } = useAlertContext();

    const downloadAward = async ({ award }: DownloadAwardInterface) => {
        try {
            if (context.workspace?.id) {
                const token = await service.download(
                    context.workspace.id,
                    award.documentation,
                );

                const response = await axios.get(token.token, {
                    responseType: 'blob',
                });

                saveAs(response.data, token.attachment.originalName);
            }
        } catch (err) {
            const error = err as Error;

            setAlert({
                type: 'error',
                title: 'Download Certificate',
                message: `Failed to download certificate: ${error.message}`,
            });
        }
    };

    return downloadAward;
}
