import { io } from 'socket.io-client';

const host =
    process.env.REACT_APP_WORKSPACE_SOCKET_URL ?? 'http://localhost:3001';
const path = process.env.REACT_APP_WORKSPACE_SOCKET_PATH;

console.log(`host: ${host}`);
console.log(`path: ${path}`);

export const socket = path ? io(host, { path: path }) : io(host);
