import { Dragger, UploadFile } from '@ianneo/ui-library';

interface ImportUploadTemplateProps {
    files: UploadFile[];
    setFiles: (file: UploadFile[]) => void;
}

export default function ImportUploadTemplate({
    files,
    setFiles,
}: ImportUploadTemplateProps) {
    return (
        <div style={{ height: files.length > 0 ? '80%' : '80%' }}>
            <Dragger
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
                multiple={false}
                fileList={files}
                setFiles={setFiles}
                maxCount={1}
                style={{ height: '80%' }}
                defaultDescription="Supported file types are CSV, XLSX"
            />
        </div>
    );
}
