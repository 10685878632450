import {
    AutoComplete,
    Collapse,
    CollapsePanel,
    Form,
    FormInstance,
    FormItem,
    FormList,
    Input,
    Link,
    Select,
    Text,
} from '@ianneo/ui-library';
import { useEffect, useMemo, useState } from 'react';
import { useWorkspaceByCompanyName } from '../../../../infrastructure/hooks/api/workspaces/use-workspace-by-company-name';
import { usePartners } from '../../../../infrastructure/hooks/api/partner/use-partners';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import countries from '../../../../infrastructure/config/data/countries.json';
import useAppContext from '../../../../infrastructure/hooks/use-context.hook';
import { useGetDelegations } from '../../../_api/delegations/hooks/useGetDelegations';

type Props = {
    form: FormInstance;
    search: string;
    onBehalfOf: number;
    setSearch: (search: string) => void;
};

export function useDebounce(value: string, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}

export const PartnerSelectionComponent: React.FC<Props> = ({
    form,
    search,
    onBehalfOf,
    setSearch,
}) => {
    const { t } = useTranslation();
    const context = useAppContext();
    const [partnerCount, setPartnerCount] = useState(1);

    const debouncedSearch = useDebounce(search, 500);
    const { delegations } = useGetDelegations();

    const { data: searchData } = useWorkspaceByCompanyName(
        onBehalfOf
            ? delegations.find((x) => x.id === onBehalfOf)?.workspace?.id || ''
            : context.workspace?.id || '',
        debouncedSearch,
    );
    const { data: partners } = usePartners(
        delegations.find((x) => x.id === onBehalfOf)?.workspace?.id || '',
    );

    const isAddMode = useMemo(() => {
        return search && search === 'Add New Supplier';
    }, [search]);

    const onInputChange = (value: string) => {
        // Searches for existing partner in the list
        const data = searchData?.find((x) => x.id === value);

        setSearch(data?.company?.name || value);
    };

    const supplierOptions = useMemo(() => {
        const options = searchData?.map((workspace) => {
            const existingPartner = partners?.find(
                (partner) => partner.seller?.id === workspace.id,
            );

            return {
                label: workspace.company?.name,
                value: workspace.id,
                disabled: !!existingPartner,
            };
        });

        if (options?.length === 0)
            return [
                {
                    label: '+ Add New Supplier',
                    value: 'Add New Supplier',
                },
            ];

        return options;
    }, [searchData, partners]);

    useEffect(() => {
        if (!isAddMode) {
            form.setFieldsValue({
                companyName: undefined,
                registrationNumber: undefined,
                country: undefined,
                items: undefined,
            });
        }
    }, [isAddMode, form]);

    return (
        <>
            <Form form={form}>
                <FormItem
                    label="Supplier Name"
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Please input your supplier name!',
                        },
                    ]}
                >
                    <AutoComplete
                        style={{ width: '100%' }}
                        options={supplierOptions}
                        onChange={onInputChange}
                        value={search}
                    />
                </FormItem>

                {isAddMode ? (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Text
                                style={{
                                    marginBottom: '12px',
                                    borderBottom: '1px solid #f2f2f2',
                                    fontWeight: 600,
                                }}
                            >
                                Company Information
                            </Text>
                        </div>

                        <FormItem label="Company Name" name="companyName">
                            <Input />
                        </FormItem>

                        <FormItem
                            label="Registration No."
                            name="registrationNumber"
                        >
                            <Input />
                        </FormItem>
                        <FormItem label="Country" name="country">
                            <Select
                                options={countries.map((x) => ({
                                    label: x.name,
                                    value: x['alpha-2'],
                                }))}
                            />
                        </FormItem>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginRight: '12px',
                            }}
                        >
                            <Text
                                style={{
                                    marginBottom: '12px',
                                    borderBottom: '1px solid #f2f2f2',
                                    fontWeight: 600,
                                }}
                            >
                                {t('partner:modal.add.subtitle')}
                            </Text>

                            <Link
                                onClick={() =>
                                    setPartnerCount(partnerCount + 1)
                                }
                            >
                                <PlusOutlined />
                                {t('common:addNew')}
                            </Link>
                        </div>

                        <FormList name="items">
                            {() => (
                                <>
                                    {Array.from(
                                        { length: partnerCount },
                                        (_, index) => (
                                            <>
                                                <Collapse>
                                                    <CollapsePanel
                                                        key={'contact' + index}
                                                        header={`Contact ${
                                                            index + 1
                                                        }`}
                                                    >
                                                        <FormItem
                                                            label={t(
                                                                'partner:modal.add.email',
                                                            )}
                                                            name={[
                                                                index,
                                                                'email',
                                                            ]}
                                                            required
                                                            rules={[
                                                                {
                                                                    required:
                                                                        true,
                                                                    message:
                                                                        'Please input your email!',
                                                                },
                                                            ]}
                                                        >
                                                            <Input />
                                                        </FormItem>

                                                        <FormItem
                                                            name={[
                                                                index,
                                                                'firstName',
                                                            ]}
                                                            label={t(
                                                                'partner:modal.add.name',
                                                            )}
                                                        >
                                                            <Input />
                                                        </FormItem>

                                                        <FormItem
                                                            label={t(
                                                                'partner:modal.add.lastName',
                                                            )}
                                                            name={[
                                                                index,
                                                                'lastName',
                                                            ]}
                                                        >
                                                            <Input />
                                                        </FormItem>
                                                    </CollapsePanel>
                                                </Collapse>
                                            </>
                                        ),
                                    )}
                                </>
                            )}
                        </FormList>
                    </>
                ) : (
                    <></>
                )}
            </Form>
        </>
    );
};
