import { useQuery } from '@tanstack/react-query';
import { PurchaseOrderTrace } from '../../../../domain/models/purchase.model';
import { PurchaseService } from '../../../../domain/services/purchase.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseService } from './use-purchase-service';

interface UsePurchaseTraceInterface {
    id?: string;
    delegateId?: string;
}

const getPurchaseTrace = async (
    service: PurchaseService,
    workspace: string,
    id: string,
): Promise<PurchaseOrderTrace[]> => {
    const response = await service.trace(workspace, id, '1');

    return response;
};

export function usePurchaseTrace({
    id,
    delegateId,
}: UsePurchaseTraceInterface) {
    const context = useAppContext();
    const { service } = usePurchaseService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'purchaseTrace',
            { workspace: delegateId || context.workspace?.id, id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !id) return;
            return await getPurchaseTrace(
                service,
                delegateId || context.workspace.id,
                id,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch purchase trace: ${error.message}`,
            });
        },
    });
}
