import { InfoCircleOutlined } from '@ant-design/icons';
import { InfoCard } from '@ianneo/component-library';
import {
    Card,
    Popover,
    RadioGroup,
    Subtitle,
    Tag,
    Text,
    Title,
} from '@ianneo/ui-library';
import { Trans, useTranslation } from 'react-i18next';
import {
    PurchaseOrderTrace,
    PurchaseOrderVersion,
} from '../../../../domain/models/purchase.model';
import { PurchaseDocuments } from '../purchase-documents.component';
import PurchaseCascadeTrace from './purchase-cascade-trace';
import PurchaseMonitor from './purchase-monitor';
import PurchaseOverview from './purchase-overview';
import PurchaseTrackTrace from './purchase-track-trace';
import useAppContext from '../../../../infrastructure/hooks/use-context.hook';

interface PurchaseDetailLayoutProps {
    data: PurchaseOrderVersion;
    menu:
        | 'overview'
        | 'cascade_tracing'
        | 'documents'
        | 'monitor'
        | 'track_trace';
    trace: PurchaseOrderTrace[];
    tier: any[];
    menuOptions: { label: string; value: string }[];
    setMenu: (
        value:
            | 'overview'
            | 'cascade_tracing'
            | 'documents'
            | 'monitor'
            | 'track_trace',
    ) => void;
    isCaseDisplayed?: boolean;
}

const PurchaseDetailLayout: React.FC<PurchaseDetailLayoutProps> = ({
    data,
    trace,
    menu,
    setMenu,
    menuOptions,
    tier,
    isCaseDisplayed,
}) => {
    const w = useAppContext()?.workspace || {};
    const { t } = useTranslation();
    const content = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text>
                <Trans
                    i18nKey={
                        data.owner?.status
                            ? 'purchase:deliveredStatus'
                            : 'purchase:pendingStatus'
                    }
                ></Trans>
            </Text>
        </div>
    );

    const getTitle = () => {
        if (
            data.owner?.externalCustomerReference &&
            data.owner?.externalDataId
        ) {
            if (isCaseDisplayed) {
                return `${data.owner?.externalCustomerReference} - ${data.owner?.externalDataId}`;
            } else {
                return data.owner?.externalCustomerReference;
            }       
        }

        if (data.owner?.externalCustomerReference) {
            return `${data.owner?.externalCustomerReference}`;
        }

        if (data.owner?.externalDataId) {
            return data.owner?.externalDataId;
        }
        return `Internal Order - ${data.owner?.id?.slice(0, 6)}`;
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '36px',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                        marginBottom: '24px',
                    }}
                >
                    <div
                        style={{
                            width: '8px',
                            backgroundColor: '#972d47',
                            minHeight: '40px',
                            minWidth: '8px',
                        }}
                    ></div>
                    <Title>{t('purchase:name')}</Title>
                    <Subtitle>{t('purchase:description')}</Subtitle>
                </div>

                <InfoCard
                    infoTitle={getTitle()}
                    information={
                        w.supplyChainLoaderType === 0 ? (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '12px',
                                    }}
                                >
                                    <Text>{data.owner?.status}</Text>
                                    <Popover
                                        trigger="hover"
                                        content={content}
                                        placement="right"
                                        overlayInnerStyle={{ padding: '0px' }}
                                    >
                                        <InfoCircleOutlined
                                            disabled
                                            style={{ color: 'grey' }}
                                        />
                                    </Popover>
                                </div>

                                <div style={{ display: 'flex', marginTop: '4px' }}>
                                    {data.owner?.purchaseProcesses?.map((x) => {
                                        return <Tag>{x}</Tag>;
                                    })}
                                </div>
                            </>
                        ) : <></>
                    }
                />

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '24px',
                    }}
                >
                    <RadioGroup
                        options={menuOptions}
                        defaultValue={menu}
                        onChange={(e) => setMenu(e.target.value)}
                        optionType="button"
                    />

                    <Card>
                        {menu === 'overview' ? (
                            <PurchaseOverview
                                data={data}
                                trace={trace}
                                tier={tier}
                            />
                        ) : null}

                        {menu === 'cascade_tracing' ? (
                            <PurchaseCascadeTrace />
                        ) : null}

                        {menu === 'documents' ? (
                            <PurchaseDocuments data={data} />
                        ) : null}

                        {menu === 'monitor' ? (
                            <PurchaseMonitor data={data} />
                        ) : null}

                        {menu === 'track_trace' ? <PurchaseTrackTrace /> : null}
                    </Card>
                </div>
            </div>
        </>
    );
};

export default PurchaseDetailLayout;
