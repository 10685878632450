export enum MaterialType {
    CHEMICALS = 'CHEMICALS',
    FINISHED_GOODS = 'FINISHED_GOODS',
    GENERAL_PRODUCT_MATERIAL = 'GENERAL_PRODUCT_MATERIAL',
    MAN_MADE_CELLULOSICS = 'MAN_MADE_CELLULOSICS',
    METAL = 'METAL',
    NATURAL_FABRIC = 'FABRIC',
    NATRUAL_LEATHER = 'LEATHER',
    OTHER_LEATHERS = 'OTHER_LEATHERS',
    PLASTIC = 'PLASTIC',
    RECYCLED_FABRIC = 'RECYCLED_FABRIC',
    RECYCLED_METAL = 'RECYCLED_METAL',
    SYNTHETIC_FABRIC = 'SYNTHETIC_FABRIC',
    SYNTHETIC_LEATHER = 'SYNTHETIC_LEATHER',
    TIMBER = 'TIMBER',
}

export const getMaterialValue = (value: MaterialType | undefined) => {
    switch (value) {
        case MaterialType.CHEMICALS:
            return 'Chemical';
        case MaterialType.FINISHED_GOODS:
            return 'Finished Goods';
        case MaterialType.GENERAL_PRODUCT_MATERIAL:
            return 'General Product Material';
        case MaterialType.MAN_MADE_CELLULOSICS:
            return 'Man Made Cellulosics';
        case MaterialType.METAL:
            return 'Metal';
        case MaterialType.NATURAL_FABRIC:
            return 'Fabric';
        case MaterialType.NATRUAL_LEATHER:
            return 'Leather';
        case MaterialType.OTHER_LEATHERS:
            return 'Other Leathers';
        case MaterialType.PLASTIC:
            return 'Plastic';
        case MaterialType.RECYCLED_FABRIC:
            return 'Recycled Fabric';
        case MaterialType.RECYCLED_METAL:
            return 'Recycled Metal';
        case MaterialType.SYNTHETIC_FABRIC:
            return 'Synthetic Fabric';
        case MaterialType.SYNTHETIC_LEATHER:
            return 'Synthetic Leather';
        case MaterialType.TIMBER:
            return 'Timber';
        default:
            return value ? value : 'N/A';
    }
};
