import { useQuery } from '@tanstack/react-query';
import { PurchaseDocumentTrace } from '../../../../domain/models/purchase.model';
import { OrderService } from '../../../../domain/services/order.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseService } from '../purchases/use-purchase-service';
import { useOrderService } from './use-order-service';

interface UseOrderSearchInterface {
    ids: number[];
    delegateId?: string;
}

const getOrder = async (
    service: OrderService,
    ids: number[],
): Promise<PurchaseDocumentTrace[]> => {
    return await service.search(ids);
};

export function useOrderSearch({ ids, delegateId }: UseOrderSearchInterface) {
    const context = useAppContext();
    const { service } = useOrderService();
    const { service: purchaseService } = usePurchaseService();
    const { setAlert } = useAlertContext();

    return useQuery<PurchaseDocumentTrace[]>({
        queryKey: [
            'orderSearch',
            { workspace: delegateId || context.workspace?.id, ids },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !(ids.length > 0)) return [];

            const orders = await getOrder(service, ids);

            const data = await Promise.allSettled(
                orders.map(async (order) => {
                    const purchase = await purchaseService.get(
                        order.customer?.buyer?.id || '',
                        order.purchaseOrderId || '',
                        '1',
                    );

                    return {
                        ...order,
                        purchaseProcesses: purchase.owner?.purchaseProcesses,
                        rules: purchase.owner?.rules,
                    };
                }),
            );

            const response = data
                .filter((x) => x.status === 'fulfilled')
                .map((x) => {
                    const resp =
                        x as PromiseFulfilledResult<PurchaseDocumentTrace>;

                    return resp.value;
                });

            return response;
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch order search information: ${error.message}`,
            });
        },
    });
}
