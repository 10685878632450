import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { Supplier } from '../../domain/models/supplier.model';
import { ApiServer } from './api.server';

/**
 * @class
 * @name SupplierApiClient
 * @description the api client for connecting for supplier data
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */
export class SupplierApiClient extends ApiServer<Supplier> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'purchases', 'supplier');
    }

    delete(workspace: string, id: string) {
        console.log(`client<supplier>| delete(): Enter`);
        console.log(`client<supplier>| delete(): $id = ${id}`);
        console.log(`client<supplier>| delete(): $id = ${JSON.stringify(id)}`);
        return super.delete('remove', workspace, id);
    }

    get(workspace: string, id: string) {
        console.log(`client<supplier>| load(): Enter`);
        console.log(`client<supplier>| load(): $workspace = ${workspace}`);
        console.log(`client<supplier>| load(): $id = ${id}`);
        return super.get('get', workspace, id);
    }

    list(workspace: string, diversion: string = '0') {
        console.log(`client<supplier>| list(): Enter`);
        console.log(`client<supplier>| list(): $workspace = ${workspace}`);
        console.log(`client<supplier>| list(): $diversion = ${diversion}`);

        return super.get('list', workspace, diversion);
    }

    update(workspace: string, id: string, supplier: any) {
        console.log(`client<supplier>| list(): Enter`);
        console.log(`client<supplier>| list(): $workspace = ${workspace}`);
        console.log(`client<supplier>| list(): $id = ${id}`);
        console.log(
            `client<supplier>| list(): $id = ${JSON.stringify(supplier)}`,
        );
        return super.patch('update', workspace, id, supplier);
    }
}
