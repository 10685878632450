import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import LayoutComponent from './layout.component';

export interface LayoutContainerProps {
    components: ReactNode;
    children?: never[];
    header?: string;
}

const LayoutContainer: React.FC<LayoutContainerProps> = ({
    components,
    children,
    header,
}) => {
    const navigate = useNavigate();

    const onNavigate = (event: string) => {
        console.log(`component<layout-container>| onNavigate(): Enter`);
        // const target = event.currentTarget.ariaLabel;
        console.log(
            `component<layout-container>| onNavigate(): $target = ${JSON.stringify(
                event,
            )}`,
        );
        switch (event) {
            case 'dashboard':
                navigate('/');
                break;
            case 'users':
                navigate('/users');
                break;
            case 'company':
                navigate('/company');
                break;
            case 'products':
                navigate('/products');
                break;
            case 'partners':
                navigate('/partners');
                break;
            case 'materials':
                navigate('/materials');
                break;
            case 'orders':
                navigate('/orders');
                break;
            case 'rulesets':
                navigate('/rulesets');
                break;
            case 'purchases':
                navigate('/purchases');
                break;
        }
    };

    return (
        <LayoutComponent
            children={children}
            components={components}
            header={header}
            onNavigate={onNavigate}
        />
    );
};

export default LayoutContainer;
