import { EditOutlined } from '@ant-design/icons';
import {
    Button,
    DescriptionItems,
    Descriptions,
    Form,
    FormItem,
    Gallery,
    Input,
    InputNumber,
    Modal,
    Select,
    Tag,
    useForm,
} from '@ianneo/ui-library';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductVersion } from '../../../../../domain/models/product.model';
import { useUpdateProduct } from '../../../../../infrastructure/hooks/api/products/use-update-product';
import { useAlertContext } from '../../../../../infrastructure/hooks/use-alert.hook';
import useVerifyAdmin from '../../../../../infrastructure/hooks/use-verify-admin';
import { useParams } from 'react-router-dom';
import { ProductCategory } from '../../../../../domain/models/category.model';
import useAppContext from '../../../../../infrastructure/hooks/use-context.hook';

interface ProductOverviewComponentProps {
    data: ProductVersion;
    images?: string[];
}

const ProductOverviewComponent: React.FC<ProductOverviewComponentProps> = (
    args,
) => {
    const w = useAppContext()?.workspace;
    const { delegateId } = useParams();
    const { t } = useTranslation();
    const [form] = useForm();
    const [open, setOpen] = useState(false);
    const { setAlert } = useAlertContext();
    const { mutateAsync } = useUpdateProduct();
    const { isAdmin } = useVerifyAdmin();

    const defaultRules = [
        { required: true, message: 'This field is required' },
    ];

    const getProductCategory = useCallback(
        (category: ProductCategory, isPrimary?: boolean) => {
            console.log(
                `getProductCategory().category: ${JSON.stringify(category)}`,
            );

            function format(source: string) {
                const parts = source?.trim()?.split('_-') || [];
                let content: string;
                if (parts.length === 2) {
                    content = parts[1];
                } else {
                    content = parts[0];
                }
                return content
                    ?.trim()
                    .split(' ')
                    .map(
                        (t) =>
                            t.slice(0, 1).toUpperCase() +
                            t.slice(1).toLowerCase(),
                    )
                    .join(' ')
                    .split('_')
                    .map(
                        (t) =>
                            t.slice(0, 1).toUpperCase() +
                            t.slice(1).toLowerCase(),
                    )
                    .join(' ');
            }

            const code = category?.code?.split('::_-_') || [];

            if (w?.supplyChainLoaderType === 1) {
                if (code.length === 2) {
                    if (isPrimary) {
                        return format(code[0]);
                    } else {
                        return format(code[1]);
                    }
                } else {
                    return format(code[0]);
                }
            } else {
                if (code.length === 2) {
                    if (isPrimary) {
                        return format(code[0]);
                    } else {
                        return format(code[1]);
                    }
                } else {
                    return format(code[0]);
                }
            }
        },
        [w?.supplyChainLoaderType],
    );

    const versionInfo = args.data;
    const product = args.data.product;
    const descriptions = useMemo(
        () => [
            {
                label: t('product:detail.name'),
                value: product?.name,
                span: 1.5,
            },
            {
                label: t('product:detail.status'),
                value: product?.isActive ? 'Active' : 'Inactive',
                span: 1.5,
            },
            {
                label: t('product:detail.productCode'),
                value: product?.code,
                span: 1.5,
            },
            { label: t('product:detail.sku'), value: product?.sku, span: 1.5 },
            {
                label: t('product:detail.collection'),
                value: product?.collection,
                span: 1.5,
            },
            { label: t('product:detail.upc'), value: product?.upc, span: 1.5 },
            {
                label: t('product:detail.category'),
                // value: w?.supplyChainLoaderType === 1 ? getProductCategory(product?.category || {}, true) : product?.category?.base?.code,
                value: getProductCategory(product?.category || {}, true),
                span: 1.5,
            },
            { label: t('product:detail.lastOrderDate'), value: '', span: 1.5 },
            {
                label: t('product:detail.subCategory'),
                // value: w?.supplyChainLoaderType === 1 ? getProductCategory(product?.category || {}) : product?.category?.code,
                value: getProductCategory(product?.category || {}),
                span: 1.5,
            },
            {
                label: t('product:detail.dateCreated'),
                value: product?.createdOn
                    ? new Date(product.createdOn).toLocaleString().slice(0, 10)
                    : '',
                span: 1.5,
            },
            {
                label: t('product:detail.cost'),
                value: versionInfo.cost?.toString(),
                span: 1.5,
            },
            {
                label: t('product:detail.measureValue'),
                value: versionInfo.product?.measureValue?.toString(),
                span: 1.5,
            },
            {
                label: t('product:detail.updatedOn'),
                value: product?.lastUpdatedOn
                    ? new Date(product.lastUpdatedOn)
                          .toLocaleString()
                          .slice(0, 10)
                    : '',
                span: 1.5,
            },
            {
                label: t('product:detail.weight'),
                value: versionInfo.weight?.toString(),
                span: 1.5,
            },
            {
                label: t('product:detail.reference'),
                value: versionInfo.product?.code,
                span: 1.5,
            },
            {
                label: t('product:detail.remarks'),
                value: product?.remark,
                span: 1.5,
            },
            {
                label: t('product:detail.tags'),
                value: product?.tags?.map((x) => <Tag>{x.label}</Tag>),
                span: 1.5,
            },
        ],
        [product, versionInfo, t, getProductCategory],
    );

    useEffect(() => {
        form.setFieldsValue({
            name: args.data.product?.name,
            sku: args.data.product?.sku,
            weight: args.data.weight ?? 0.0,
            collection: args.data.product?.collection,
            cost: args.data.cost ?? 0.0,
            remark: args.data.product?.remark,
            tags: args.data.product?.tags?.map((x) => x.label),
        });
    }, [open, form, args.data]);

    return (
        <>
            <div style={{ display: 'flex', gap: '32px' }}>
                <Gallery images={args.images}></Gallery>

                <Descriptions>
                    {descriptions.map((x, i) => (
                        <DescriptionItems key={i} label={x.label} span={x.span}>
                            {x.value}
                        </DescriptionItems>
                    ))}
                </Descriptions>

                <div hidden={isAdmin}>
                    <Button
                        onClick={() => setOpen(true)}
                        icon={<EditOutlined />}
                    >
                        {t('common:edit')}
                    </Button>
                </div>
            </div>

            <Modal
                title={t('product:detail.editModalTitle')}
                open={open}
                okFn={async () => {
                    try {
                        await form.validateFields();
                        const values = await form.getFieldsValue(true);

                        await mutateAsync({
                            id: product?.id || '',
                            args: {
                                ...values,
                                components: args.data.compositions,
                                tags: values.tags.map((x: any) => ({
                                    label: x,
                                    value: x,
                                })),
                            },
                            delegateId,
                        });

                        setOpen(false);
                        form.resetFields();
                    } catch (err) {
                        setAlert({
                            type: 'error',
                            message:
                                t('product:detail.editModalErrorMsg') || '',
                            title: 'Update Product',
                        });
                    }
                }}
                cancelFn={() => setOpen(false)}
            >
                <Form form={form}>
                    <FormItem
                        name="weight"
                        label="Weight"
                        required
                        rules={[
                            ...defaultRules,
                            {
                                pattern: /^\d*\.?\d*$/,
                                message: 'Please input a positive number only.',
                            },
                        ]}
                    >
                        <InputNumber />
                    </FormItem>

                    <FormItem
                        name="cost"
                        required
                        rules={[
                            ...defaultRules,
                            {
                                pattern: /^\d*\.?\d*$/,
                                message: 'Please input a positive number only.',
                            },
                        ]}
                        label={t('product:detail.cost')}
                    >
                        <InputNumber step="0.001" />
                    </FormItem>

                    <FormItem name="sku" label={t('product:detail.sku')}>
                        <Input />
                    </FormItem>

                    <FormItem
                        name="collection"
                        label={t('product:detail.collection')}
                    >
                        <Input />
                    </FormItem>

                    <FormItem name="remark" label={t('product:detail.remarks')}>
                        <Input />
                    </FormItem>

                    <FormItem name="tags" label={t('product:detail.tags')}>
                        <Select mode="tags" />
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
};

export default ProductOverviewComponent;
