import {
    CloseOutlined,
    LogoutOutlined,
    SwapOutlined,
    UserOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Dropdown, Space, Text, Tooltip } from '@ianneo/ui-library';
import { CSSProperties, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Permission } from '../../../domain/models/permission.model';
import useAppConfig from '../../../infrastructure/hooks/use-config.hook';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';
import { Notifications } from './notifications.component';

export interface CompanyDropdownProps {
    displayDropdown?: boolean;
    openLng: () => void;
    openSwitch: () => void;
}

const logoTextStyles: CSSProperties = {
    alignSelf: 'auto',
    lineHeight: 'normal',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
};

export const CompanyDropdown: React.FC<CompanyDropdownProps> = ({
    displayDropdown = true,
    openLng,
    openSwitch,
}) => {
    const { t } = useTranslation();
    const context = useAppContext();
    const config = useAppConfig();
    const navigate = useNavigate();
    const isAssumedSession = sessionStorage.getItem('assumedSession');
    const auth0 = useAuth0();

    const logout = useCallback(async () => {
        await auth0.logout({ returnTo: config.webRootUrl });

        sessionStorage.clear();
        localStorage.clear();
    }, [auth0, config]);

    const onCloseAssume = useCallback(() => {
        sessionStorage.removeItem('assumedSession');
        sessionStorage.removeItem('session');
        sessionStorage.removeItem('sessionWorkspace');
        sessionStorage.removeItem('sessionEmail');

        navigate(0);
    }, [navigate]);

    const companyOptions = useMemo(() => {
        const items = [
            {
                label: (
                    <Text onClick={() => navigate('/company')}>
                        {t('common:header.company')}
                    </Text>
                ),
                key: 'company',
            },
            {
                label: (
                    <Text onClick={() => navigate('/partners')}>
                        {t('common:header.partners')}
                    </Text>
                ),
                key: 'partners',
            },
            {
                label: (
                    <Text onClick={() => navigate('/nominated-list')}>
                        {t('common:header.nominatedList')}
                    </Text>
                ),
                key: 'nominatedlist',
            },
        ];

        if (context.workspace?.account?.admin === context.user?.email) {
            items.push({
                label: (
                    <Text onClick={() => navigate('/workspaces')}>
                        {t('common:header.workspaces')}
                    </Text>
                ),
                key: 'workspaces',
            });
        }

        const allowUsers: Permission = {
            application: 'io.lfx.t4s.workspace',
            module: 'user',
            action: 'read',
        };
        console.log(
            `permissions (show): ${JSON.stringify(context.permissions)}`,
        );

        const right = context.permissions?.some(
            (p) =>
                p.application === allowUsers.application &&
                p.module === allowUsers.module &&
                p.action === allowUsers.action,
        );
        console.log(`permissions (right): ${JSON.stringify(right)}`);

        if (right) {
            items.push({
                label: (
                    <Text onClick={() => navigate('/users')}>
                        {t('common:header.users')}
                    </Text>
                ),
                key: 'users',
            });
        }

        return items;
    }, [
        context.workspace?.account?.admin,
        navigate,
        t,
        context.user?.email,
        context.permissions,
    ]);

    const userOptions = useMemo(() => {
        const options = [
            {
                label: t('common:profile.switchRole'),
                key: 'switchRole',
                icon: <UserSwitchOutlined />,
                onClick: () => openSwitch(),
            },
            {
                label: t('common:profile.switchLanguage'),
                key: 'language',
                icon: <SwapOutlined />,
                onClick: () => openLng(),
            },
            {
                label: t('common:profile.logout'),
                key: 'logout',
                icon: <LogoutOutlined />,
                onClick: () => logout(),
            },
        ];

        if (isAssumedSession) {
            options.push({
                label: t('common:profile.closeAssume'),
                key: 'closeAssume',
                icon: <CloseOutlined />,
                onClick: () => onCloseAssume(),
            });
        }

        return options;
    }, [t, isAssumedSession, logout, openLng, openSwitch, onCloseAssume]);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                justifyContent: 'flex-end',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                }}
            >
                <Notifications />

                {displayDropdown ? (
                    <>
                        <Dropdown
                            menu={{ items: companyOptions }}
                            className="company-dropdown"
                        >
                            <Space
                                style={{
                                    maxWidth: '10vw',
                                    overflow: 'hidden',
                                }}
                            >
                                <Text style={logoTextStyles}>
                                    {context.workspace?.company?.name}
                                </Text>
                            </Space>
                        </Dropdown>
                    </>
                ) : (
                    <>
                        <Tooltip
                            arrow={false}
                            title={context.workspace?.company?.name}
                        >
                            <Space
                                style={{
                                    maxWidth: '100%',
                                    overflow: 'hidden',
                                }}
                            >
                                <Text style={logoTextStyles}>
                                    {context.workspace?.company?.name}
                                </Text>
                            </Space>
                        </Tooltip>
                    </>
                )}

                <Dropdown menu={{ items: userOptions }}>
                    <div
                        style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Avatar
                            src={context.user?.avatar?.url ?? undefined}
                            icon={
                                context.user?.avatar?.url ? undefined : (
                                    <UserOutlined />
                                )
                            }
                        ></Avatar>

                        <Text style={{ maxWidth: '10vw', overflow: 'hidden' }}>
                            {`${context.user?.firstName ?? ''} ${
                                context.user?.lastName || ''
                            }`}
                        </Text>
                    </div>
                </Dropdown>
            </div>
        </div>
    );
};
