import { SolicitationApiClient } from '../../infrastructure/clients/solications.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';

export class SolicitationService {
    private server: SolicitationApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new SolicitationApiClient(config, token);
    }

    async getDocuments(workspace: string, partner: string): Promise<any[]> {
        console.log(`service<solicitation>| get(): Enter`);
        console.log(`service<solicitation>| get(): $workspace = ${workspace}`);
        console.log(`service<solicitation>| get(): $partner = ${partner}`);

        return await this.server.getDocuments(workspace, partner);
    }
}
