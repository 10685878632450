import {
    Button,
    Card,
    Checkbox,
    Col,
    Content,
    Form,
    FormItem,
    Header,
    Input,
    InputNumber,
    Layout,
    Link,
    Row,
    Select,
    TextArea,
    useForm,
} from '@ianneo/ui-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupplyChainNodeType } from '../../../domain/enums/supplier-node.enum';
import { Company } from '../../../domain/models/company.model';
import useToggle from '../../../infrastructure/hooks/use-toggle';
import { CompanyDropdown } from '../Layout/company-dropdown.component';
import SwitchLanguage from '../SwitchLanguage/switch-language';
import SwitchWorkspace from '../SwitchWorkspace/switch-workspace';
import OnboardingContact from './onboarding-contact/onboarding-contact';
import { getProcessValue } from '../../../infrastructure/utils/process-labeler';

export interface LayoutCompProps {
    header?: string;
    data?: Company;
    update: (args: Company) => void;
}

const OnboardingComponent: React.FC<LayoutCompProps> = ({
    header,
    data,
    update,
}) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const [open, setOpen] = useState(false);
    const [contactField, setContactField] = useState('');
    const countries: any[] = require('./../../../infrastructure/config/data/countries.json');

    const { status: roleStatus, toggle: toggleRole } = useToggle();
    const { status: lngStatus, toggle: toggleLng } = useToggle();

    const onSubmit = async () => {
        try {
            const values = form.getFieldsValue(true);
            await form.validateFields();

            await update(values);
        } catch (err) {
            return;
        }
    };

    const openContactModal = (field: string) => {
        setOpen(true);
        setContactField(field);
    };

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                name: data?.name,
                supplyChainNodeType: data?.supplyChainNodeType,
                country: data?.country?.toLocaleUpperCase(),
                registrationNumber: data?.registrationNumber,
                addressLine1: data?.addressLine1,
                phone: data?.phone,
                introduction: data?.introduction,
                city: data?.city,
                state: data?.state,
                postCode: data?.postCode,
                website: data?.website,
                gln: data?.gln,
                higg: data?.higg,
                duns: data?.duns,
                oar: data?.oar,
                vat: data?.vat,
                capacity: data?.capacity,
                dbaName: data?.dbaName,
                dbaRegistration: data?.dbaRegistration,
                dbaReference: data?.dbaReference,
                employees: data?.employees,
                isFinanceEnabled: data?.isFinanceEnabled,
                contactBusiness: data?.contactBusiness,
                contactCompliance: data?.contactCompliance,
            });
        }
    }, [form, data]);

    return (
        <>
            <Layout style={{ minHeight: '100vh' }}>
                <Header
                    style={{
                        backgroundColor: 'white',
                        boxShadow: '0px 1px 2px #ddd',
                        position: 'fixed',
                        display: 'flex',
                        zIndex: 1,
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            style={{ height: '35px' }}
                            src="https://hk.jobsdb.com/en-hk/wp-content/uploads/sites/2/2021/01/vVYMIxN.png"
                            alt="Application Logo"
                            aria-label="dashboard"
                        />

                        <CompanyDropdown
                            openLng={toggleLng}
                            openSwitch={toggleRole}
                            displayDropdown={false}
                        />
                    </div>
                </Header>

                <Layout
                    hasSider={true}
                    style={{
                        backgroundColor: '#EEEEEE',
                        marginTop: 64,
                    }}
                >
                    <Content
                        style={{
                            padding: '50px 50px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Card
                            bordered={false}
                            style={{ width: '100%' }}
                            title={t('onboarding:requiredHeader')}
                        >
                            <Form
                                form={form}
                                layout="horizontal"
                                labelCol={{ span: 10 }}
                                labelAlign="right"
                            >
                                <Row className="edit-detail-modal-row">
                                    <Col span={12}>
                                        <FormItem
                                            label={t('onboarding:companyName')}
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please input your Company Name!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            label={t(
                                                'onboarding:facilityProcess',
                                            )}
                                            name="supplyChainNodeType"
                                            style={{ justifyContent: 'right' }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select your facility process!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                mode="tags"
                                                options={Object.values(
                                                    SupplyChainNodeType,
                                                ).map((x) => ({
                                                    label: getProcessValue(x),
                                                    value: x,
                                                    key: x,
                                                }))}
                                            ></Select>
                                        </FormItem>
                                    </Col>
                                </Row>

                                <Row className="edit-detail-modal-row">
                                    <Col span={12}>
                                        <FormItem
                                            label={t('onboarding:country')}
                                            name="country"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select your residing country!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                showSearch={true}
                                                filterOption={(
                                                    input,
                                                    option,
                                                ) => {
                                                    return (
                                                        option?.key
                                                            .toLowerCase()
                                                            .indexOf(
                                                                input.toLowerCase(),
                                                            ) >= 0 ||
                                                        option?.title
                                                            .toLowerCase()
                                                            .indexOf(
                                                                input.toLowerCase(),
                                                            ) >= 0
                                                    );
                                                }}
                                                options={countries.map((x) => ({
                                                    label: x.name,
                                                    key: x['alpha-2'],
                                                    value: x['alpha-2'],
                                                }))}
                                            ></Select>
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            label={t(
                                                'onboarding:businessRegistrationNo',
                                            )}
                                            name="registrationNumber"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select your registration number!',
                                                },
                                                {
                                                    pattern:
                                                        /^[a-zA-Z0-9\s.,-]*$/,
                                                    message:
                                                        'Please enter a valid input, no special characters allowed!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                </Row>

                                <Row className="edit-detail-modal-row">
                                    <Col span={12}>
                                        <FormItem
                                            label={t('onboarding:address')}
                                            name="addressLine1"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please insert your company address!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            label={t('onboarding:telNo')}
                                            name="phone"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please insert your telephone number!',
                                                },
                                                {
                                                    pattern:
                                                        /^\+?[ 1-9][0-9]{7,14}$/,
                                                    message:
                                                        'Please enter a valid phone number, include country code!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                </Row>

                                <Row className="edit-detail-modal-row">
                                    <Col span={12}>
                                        <FormItem
                                            label={t('onboarding:about')}
                                            name="introduction"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please insert your introduction!',
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                maxLength={100}
                                                showCount
                                            />
                                        </FormItem>
                                    </Col>

                                    <Col
                                        span={12}
                                        style={{
                                            paddingRight: '20px',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <FormItem
                                            label={t('onboarding:city')}
                                            name="city"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please insert your city!',
                                                },
                                                {
                                                    pattern:
                                                        /^[a-zA-Z0-9\s.,-]*$/,
                                                    message:
                                                        'Please enter a valid input, no special characters allowed!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </FormItem>

                                        <FormItem
                                            label={t('onboarding:state')}
                                            name="state"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please insert your state!',
                                                },
                                                {
                                                    pattern:
                                                        /^[a-zA-Z0-9\s.,-]*$/,
                                                    message:
                                                        'Please enter a valid input, no special characters allowed!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </FormItem>

                                        <FormItem
                                            label={t('onboarding:zipCode')}
                                            name="postCode"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please insert your post code!',
                                                },
                                                {
                                                    pattern:
                                                        /^[a-zA-Z0-9\s.,-]*$/,
                                                    message:
                                                        'Please enter a valid input, no special characters allowed!',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                        <Card
                            bordered={false}
                            style={{ marginTop: '24px', width: '100%' }}
                            title="Optional Fields"
                        >
                            <Form
                                form={form}
                                layout="horizontal"
                                labelCol={{ span: 10 }}
                                labelAlign="right"
                            >
                                <Row className="edit-detail-modal-row">
                                    <Col span={12}>
                                        <FormItem
                                            label={t('onboarding:website')}
                                            name="website"
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            label={t('onboarding:gln')}
                                            name="gln"
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                </Row>

                                <Row className="edit-detail-modal-row">
                                    <Col span={12}>
                                        <FormItem
                                            label={t('onboarding:higgId')}
                                            name="higg"
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            label={t('onboarding:duns')}
                                            name="duns"
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                </Row>

                                <Row className="edit-detail-modal-row">
                                    <Col span={12}>
                                        <FormItem
                                            label={t('onboarding:oarId')}
                                            name="oar"
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            label={t('onboarding:vatId')}
                                            name="vat"
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                </Row>

                                <Row className="edit-detail-modal-row">
                                    <Col span={12}>
                                        <FormItem
                                            label={t(
                                                'company:details.contactCompliance',
                                            )}
                                        >
                                            <Link
                                                onClick={() =>
                                                    openContactModal(
                                                        'contactCompliance',
                                                    )
                                                }
                                            >
                                                {t(
                                                    'onboarding:contactCompliance',
                                                )}
                                            </Link>
                                        </FormItem>
                                    </Col>

                                    <Col span={12}>
                                        <FormItem
                                            label={t(
                                                'company:details.contactBusiness',
                                            )}
                                        >
                                            <Link
                                                onClick={() =>
                                                    openContactModal(
                                                        'contactBusiness',
                                                    )
                                                }
                                            >
                                                {t(
                                                    'onboarding:contactBusiness',
                                                )}
                                            </Link>
                                        </FormItem>
                                    </Col>
                                </Row>

                                <Row className="edit-detail-modal-row">
                                    <Col span={12}>
                                        <FormItem
                                            label={t(
                                                'company:details.capacity',
                                            )}
                                            name="capacity"
                                            rules={[
                                                {
                                                    pattern: /^\d*\.?\d*$/,
                                                    message:
                                                        'Please input a positive number only.',
                                                },
                                            ]}
                                        >
                                            <InputNumber />
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            label={t('company:details.dbaName')}
                                            name="dbaName"
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                </Row>

                                <Row className="edit-detail-modal-row">
                                    <Col span={12}>
                                        <FormItem
                                            label={t(
                                                'company:details.dbaRegistration',
                                            )}
                                            name="dbaRegistration"
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            label={t(
                                                'company:details.dbaReference',
                                            )}
                                            name="dbaReference"
                                        >
                                            <Input />
                                        </FormItem>
                                    </Col>
                                </Row>

                                <Row className="edit-detail-modal-row">
                                    <Col span={12}>
                                        <FormItem
                                            label={t(
                                                'company:details.employees',
                                            )}
                                            name="employees"
                                            rules={[
                                                {
                                                    pattern: /^\d*\.?\d*$/,
                                                    message:
                                                        'Please input a positive number only.',
                                                },
                                            ]}
                                        >
                                            <InputNumber />
                                        </FormItem>
                                    </Col>
                                    <Col span={12}>
                                        <FormItem
                                            label={t(
                                                'company:details.isFinanceEnabled',
                                            )}
                                            name="isFinanceEnabled"
                                            valuePropName="checked"
                                        >
                                            <Checkbox />
                                        </FormItem>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>

                        <Button
                            btntype="Default"
                            style={{
                                width: '10%',
                                marginTop: '24px',
                                alignSelf: 'center',
                            }}
                            onClick={onSubmit}
                        >
                            {t('onboarding:submit')}
                        </Button>
                    </Content>
                </Layout>
            </Layout>

            <OnboardingContact
                open={open}
                setOpen={setOpen}
                form={form}
                name={contactField}
            />

            <SwitchWorkspace open={roleStatus} setOpen={toggleRole} />

            <SwitchLanguage open={lngStatus} setOpen={toggleLng} />
        </>
    );
};

export default OnboardingComponent;
