import { useEffect, useState } from 'react';
import {
    Circle,
    LayerGroup,
    LayersControl,
    MapContainer,
    Popup,
    TileLayer,
} from 'react-leaflet';
import { usePartners } from '../../../infrastructure/hooks/api/partner/use-partners';
import countryGeolocations from './country-lat-long.json';

interface CountryInfo {
    country: string;
    supplierCount: number;
    latitude: number;
    longitude: number;
}

export default function LeafletMap() {
    // API Data
    const { data } = usePartners();

    // Internal state values
    const maxRadius = 1000000;
    const [countryData, setCountryData] = useState<CountryInfo[]>([]);

    const totalSupplierCount = countryData.reduce(
        (sum, d) => sum + d.supplierCount,
        0,
    );
    const scalingFactor = maxRadius / totalSupplierCount;

    useEffect(() => {
        const values: { [key: string]: number } = {};
        const countryData: CountryInfo[] = [];

        // Filters out any suppliers that don't have a country and then counts the number of suppliers per country
        data?.filter((x) => x.seller?.country).forEach((x) => {
            const country = x.seller?.country || '';

            values[country] = (values[country] || 0) + 1;
        });

        Object.entries(values).forEach(([key, value]) => {
            // Find the country in the countryGeolocations.json file
            const country = countryGeolocations.find(
                (x) => x.alpha2.toLocaleLowerCase() === key.toLocaleLowerCase(),
            );

            // If the country is found, add it to the countryData array
            if (country) {
                countryData.push({
                    latitude: country.latitude,
                    longitude: country.longitude,
                    country: country.country,
                    supplierCount: value,
                });
            }
        });

        setCountryData(countryData || []);
    }, [data]);

    return (
        <>
            <MapContainer
                center={[35.86166, 104.195397]}
                zoom={3}
                scrollWheelZoom={false}
                attributionControl={false}
            >
                <TileLayer
                    attribution='&amp;copy <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LayersControl position="topright">
                    <LayersControl.Overlay checked name="Bubble">
                        <LayerGroup>
                            {countryData.map((d) => (
                                <Circle
                                    center={[d.latitude, d.longitude]}
                                    radius={scalingFactor * d.supplierCount}
                                >
                                    <Popup>{d.country}</Popup>
                                </Circle>
                            ))}
                        </LayerGroup>
                    </LayersControl.Overlay>
                </LayersControl>
            </MapContainer>
        </>
    );
}
