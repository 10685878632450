import { Uploadable } from './../types/uploadable.type';
import { FileApiClient } from '../../infrastructure/clients/file.client';
import { Attachment } from '../types/attachment.type';
import { ServiceConfigInterface } from './../interfaces/config.context.interface';
/**
 * @class
 * @name AwardService
 * @description The award service to get awards
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */

export interface AttachmentResultObject {
    attachment: Attachment;
    token: string;
}

export interface ImportBatchParams {
    contentType: string;
    filename: string;
}

export class FileService {
    private server: FileApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new FileApiClient(config, token);
    }

    async download(
        workspace: string,
        attachment: Attachment,
    ): Promise<AttachmentResultObject> {
        console.log(`service<file>| download(): Enter`);
        console.log(`service<file>| download(): $workspace = ${workspace}`);

        return await this.server.download(workspace, attachment);
    }

    async upload(
        workspace: string,
        type: string,
        file: Uploadable,
    ): Promise<AttachmentResultObject> {
        console.log(`service<file>| upload(): Enter`);
        console.log(`service<file>| upload(): $workspace = ${workspace}`);

        return await this.server.upload(workspace, type, file);
    }

    async batch(
        workspace: string,
        type: string, // if user batch then "user"
        params: ImportBatchParams,
    ): Promise<AttachmentResultObject> {
        console.log(`service<file>| batch(): Enter`);
        console.log(`service<file>| batch(): $workspace = ${workspace}`);

        return await this.server.batch(workspace, type, params);
    }
}
