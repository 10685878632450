import { Spin, Tooltip } from '@ianneo/ui-library';
import { toPng } from 'html-to-image';
import { DownloadIcon } from 'lucide-react';
import { useState } from 'react';
import {
    ControlButton,
    getRectOfNodes,
    getTransformForBounds,
    useReactFlow,
} from 'reactflow';

function downloadImage(dataUrl: any) {
    const a = document.createElement('a');

    a.setAttribute('download', 'reactflow.png');
    a.setAttribute('href', dataUrl);
    a.click();
}

export const ReactFlowDownloader = () => {
    const { getNodes } = useReactFlow();
    const [loading, setLoading] = useState(false);

    const delay = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));

    const download = async () => {
        setLoading(true);

        await delay(1000);
        const nodeBounds = getRectOfNodes(getNodes());

        const transform = getTransformForBounds(
            nodeBounds,
            nodeBounds.width,
            nodeBounds.height,
            0.5,
            5,
        );

        const png = await toPng(
            document.querySelector('.react-flow__viewport') as HTMLElement,
            {
                backgroundColor: '#1a365d',
                width: nodeBounds.width,
                height: nodeBounds.height,
                style: {
                    width: nodeBounds.width.toString(),
                    height: nodeBounds.height.toString(),
                    transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
                },
            },
        );

        await downloadImage(png);

        setLoading(false);
    };

    return (
        <>
            <ControlButton onClick={download}>
                <Tooltip title="Download Cascade">
                    {loading ? <Spin size="small" /> : <DownloadIcon />}
                </Tooltip>
            </ControlButton>
        </>
    );
};
