import {
    AddTable,
    Form,
    FormItem,
    IAddTableProps,
    Input,
    InputNumber,
    Modal,
    RadioGroup,
    Select,
    useForm,
} from '@ianneo/ui-library';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderNatureType } from '../../../../../domain/enums/order-nature.enum';
import { SupplyChainNodeType } from '../../../../../domain/enums/supplier-node.enum';
import { UnitType } from '../../../../../domain/enums/unit-type.enum';
import {
    PurchaseOrderItem,
    PurchaseOrderVersion,
} from '../../../../../domain/models/purchase.model';
import currencies from '../../../../../infrastructure/config/data/currency.json';
import { usePurchaseItmes } from '../../../../../infrastructure/hooks/api/purchases/use-purchase-items';
import { useUpdatePurchase } from '../../../../../infrastructure/hooks/api/purchases/use-update-purchase';
import { useRulesets } from '../../../../../infrastructure/hooks/api/rulesets/use-rulesets';
import useAppContext from '../../../../../infrastructure/hooks/use-context.hook';
import { getOrderNature } from '../../../../../infrastructure/utils/order-nature-labeler';
import { getProcessValue } from '../../../../../infrastructure/utils/process-labeler';
import { getUnitValue } from '../../../../../infrastructure/utils/unit-type-labeler';
import MultiDatePicker from '../../../../components/MultiDatePicker';
import '../../purchase.css';
import { useParams } from 'react-router-dom';

interface EditPurchaseComponentProps {
    data: PurchaseOrderVersion;
    open: boolean;
    setOpen: (value: boolean) => void;
}

const EditPurchaseComponent: React.FC<EditPurchaseComponentProps> = ({
    data,
    open,
    setOpen,
}) => {
    const { t } = useTranslation();
    const context = useAppContext();
    const { delegateId } = useParams();
    const [form] = useForm();
    const [showRules, setShowRules] = useState(false);
    const [shippedDate, setShippedDate] = useState<moment.Moment[]>([]);

    const { mutateAsync: update } = useUpdatePurchase();
    const { data: rules } = useRulesets(delegateId);
    const { data: purchaseItems } = usePurchaseItmes(delegateId);
    const [menu, setMenu] = useState('values');
    const options = [
        { label: 'Values', value: 'values' },
        { label: 'Items', value: 'items' },
    ];

    const handleDateSelection = (dates: moment.Moment[]) => {
        setShippedDate(dates);
    };

    const columns: IAddTableProps<PurchaseOrderItem>['columns'] = useMemo(
        () => [
            {
                title: t('purchase:modal.add.productName'),
                dataIndex: ['purchaseables', 'id'],
                key: 'productName',
                width: '30%',
                component: (
                    <Select
                        listHeight={150}
                        options={purchaseItems?.map((x) => ({
                            key: x?.id,
                            label: x?.name,
                            value: x?.id,
                        }))}
                    />
                ),
                rules: [
                    {
                        required: true,
                        message: 'Please input a product name.',
                    },
                ],
                required: true,
            },
            {
                title: t('purchase:modal.add.quantity'),
                dataIndex: 'quantity',
                key: 'quantity',
                width: '15%',
                component: <InputNumber />,
                rules: [
                    {
                        pattern: /^\d*\.?\d*$/,
                        message: 'Please input a positive number only.',
                    },
                ],
                required: true,
            },
            {
                title: t('purchase:modal.add.unit'),
                dataIndex: 'unit',
                key: 'unit',
                width: '10%',
                component: (
                    <Select
                        options={Object.values(UnitType).map((x) => ({
                            key: x,
                            label: getUnitValue(x),
                            value: x,
                        }))}
                    />
                ),
                skipFilter: true,
                rules: [
                    {
                        required: true,
                        message: 'Please input a unit.',
                    },
                ],
                required: true,
            },
            {
                title: t('purchase:modal.add.unitPrice'),
                dataIndex: 'ppu',
                key: 'ppu',
                width: '15%',
                component: <InputNumber />,
                rules: [
                    {
                        pattern: /^\d*\.?\d*$/,
                        message: 'Please input a positive number only.',
                    },
                    {
                        required: true,
                        message: 'Please input a unit price.',
                    },
                ],
                required: true,
            },
            {
                title: t('purchase:modal.add.comment'),
                dataIndex: 'comment',
                key: 'comment',
                width: '30%',
                component: <Input />,
                rules: [],
                required: false,
            },
        ],
        [purchaseItems, t],
    );

    const reset = () => {
        form.resetFields();
        setOpen(false);
    };

    const submit = async () => {
        try {
            await form.validateFields();

            const values = form.getFieldsValue(true);
            await update({
                data: {
                    currency: values.currency,
                    shippedOn: shippedDate
                        .filter((x) => x !== null)
                        .filter((x) => x.isValid())
                        .map((x) => x.format()),
                    shipToAddress: values?.shipToAddress || null,
                    rules: values.rules ? [values.rules] : data.owner?.rules,
                    items: values.items?.map((x: any) => ({
                        ...x,
                        material: x.purchaseables.id,
                    })),
                    externalDataId: values.externalDataId,
                    purchaseProcesses: values.purchaseProcesses,
                    orderNature: values.orderNature || null,
                    externalCustomerReference:
                        values.externalCustomerReference || null,
                },
                id: data.owner?.id || '',
                workspace: data.owner?.workspace?.id,
            });

            reset();
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        setShowRules(
            data.owner?.rules?.some(
                (x) => x.workspace?.id === context.workspace?.id,
            ) || true,
        );
    }, [data, context.workspace?.id]);

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                currency: data.owner?.currency,
                rules: data.owner?.rules?.[0]?.id,
                supplier: data.owner?.supplier?.id,
                externalDataId: data.owner?.externalDataId,
                shipToAddress: data.owner?.shipToAddress,
                items: data?.manifest,
                purchaseProcesses: data.owner?.purchaseProcesses,
                orderNature: data.owner?.orderNature || undefined,
                externalCustomerReference:
                    data.owner?.externalCustomerReference || undefined,
            });

            setShippedDate((data.owner?.shippedOn || []).map((x) => moment(x)));
        }
    }, [form, data, open]);

    return (
        <>
            <Modal
                title="Edit Purchase"
                open={open}
                // closable={false}
                width="50vw"
                okFn={submit}
                cancelFn={reset}
            >
                <RadioGroup
                    options={options.map((x) => x)}
                    value={menu}
                    style={{ marginBottom: '12px' }}
                    onChange={(e) => setMenu(e.target.value)}
                    optionType="button"
                />

                {menu === 'values' ? (
                    <>
                        <Form form={form}>
                            <FormItem
                                label={t('purchase:modal.add.refNo')}
                                name="externalDataId"
                                help={false}
                            >
                                <Input />
                            </FormItem>

                            <FormItem
                                label={t('purchase:detail.overview.currency')}
                                name="currency"
                            >
                                <Select
                                    options={currencies.map((currency) => ({
                                        value: currency,
                                        label: currency,
                                    }))}
                                ></Select>
                            </FormItem>

                            {showRules ? (
                                <FormItem
                                    label={t('purchase:detail.overview.rules')}
                                    name="rules"
                                >
                                    <Select
                                        options={rules?.map((x) => ({
                                            value: x.id,
                                            label: x.code,
                                        }))}
                                    />
                                </FormItem>
                            ) : (
                                <></>
                            )}

                            <FormItem
                                label={t('purchase:detail.overview.shippedOn')}
                            >
                                <MultiDatePicker
                                    value={shippedDate}
                                    onChange={handleDateSelection}
                                />
                            </FormItem>

                            <FormItem
                                label={t(
                                    'purchase:detail.overview.shipToAddress',
                                )}
                                name="shipToAddress"
                            >
                                <Input />
                            </FormItem>

                            <FormItem
                                label={t(
                                    'purchase:detail.overview.purchaseProcesses',
                                )}
                                name="purchaseProcesses"
                            >
                                <Select
                                    options={Object.values(
                                        SupplyChainNodeType,
                                    ).map((x) => ({
                                        label: getProcessValue(x),
                                        key: x,
                                        value: x,
                                    }))}
                                    mode="tags"
                                />
                            </FormItem>

                            <FormItem
                                label={t(
                                    'purchase:detail.overview.orderNature',
                                )}
                                name="orderNature"
                            >
                                <Select
                                    options={Object.values(OrderNatureType).map(
                                        (x) => ({
                                            label: getOrderNature(x),
                                            key: x,
                                            value: x,
                                        }),
                                    )}
                                />
                            </FormItem>

                            <FormItem
                                label="External Customer Reference"
                                name="externalCustomerReference"
                            >
                                <Input />
                            </FormItem>
                        </Form>
                    </>
                ) : null}

                {menu === 'items' ? (
                    <>
                        <AddTable
                            form={form}
                            columns={columns}
                            scroll={{ x: 'max-content' }}
                            className="add-table custom-table"
                        />
                    </>
                ) : null}
            </Modal>
        </>
    );
};

export default EditPurchaseComponent;
