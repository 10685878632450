import { RulesetApiClient } from '../../infrastructure/clients/ruleset.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { Ruleset } from '../models/ruleset.model';

/**
 * @class
 * @name RulesetsService
 * @description The rulesets service to setup rules
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */
export class RulesetService {
    private server: RulesetApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new RulesetApiClient(config, token);
    }

    async list(workspace: string) {
        console.log(`service<ruleset>| list(): Enter`);
        console.log(`service<ruleset>| list(): $workspace = ${workspace}`);

        const result: any[] = await this.server.list(workspace);
        return result.map((r) => this.convert(r));
    }

    async delete(workspace: string, item: string) {
        console.log(`service<ruleset>| delete(): Enter`);
        console.log(`service<ruleset>| delete(): $workspace = ${workspace}`);
        return await this.server.delete(workspace, item);
    }

    async create(workspace: string, ruleset: Ruleset) {
        console.log(`service<ruleset>| create(): Enter`);
        console.log(`service<ruleset>| create(): $workspace = ${workspace}`);
        return await this.server.create(workspace, ruleset);
    }

    async get(workspace: string, item: string) {
        console.log(`service<ruleset>| get(): Enter`);
        console.log(`service<ruleset>| get(): $workspace = ${workspace}`);
        return this.convert(await this.server.get(workspace, item));
    }

    async update(workspace: string, item: string, ruleset: Ruleset) {
        console.log(`service<ruleset>| update(): Enter`);
        console.log(`service<ruleset>| update(): $workspace = ${workspace}`);
        return await this.server.update(workspace, item, ruleset);
    }

    private convert(source: any): Ruleset {
        const result: Ruleset = {
            id: source.id,
            appliesTo: source.appliesTo,
            code: source.code,
            createdOn: source.createdOn,
            description: source.description,
            documentation: source.documentation,
            lastUpdatedOn: source.lastUpdatedOn,
            name: source.name,
            targets: source.targets,
        };

        return result;
    }
}
