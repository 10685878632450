import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useRoleService } from './use-role-service';

export interface UpdateRoleParams {
    id: string;
    args: {
        members: UpdateMemberParam[];
    };
    workspaceId?: string;
}

export interface UpdateMemberParam {
    email?: string;
    title?: string;
}

export function useUpdateRole() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useRoleService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ id, args, workspaceId }: UpdateRoleParams) => {
            if (!context.workspace?.id) return;
            await service.updateRole(
                workspaceId || context.workspace.id,
                id,
                args,
            );
        },
        onSuccess: (_, { workspaceId }) => {
            client.invalidateQueries({
                queryKey: [
                    'roles',
                    { type: workspaceId || context.workspace?.id },
                ],
            });

            client.invalidateQueries({
                queryKey: [
                    'members',
                    { type: workspaceId || context.workspace?.id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Role updated successfully',
                title: 'Update role',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to update role: ${error.message}`,
                title: 'Update role',
            });
        },
    });
}
