import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseService } from './use-purchase-service';
import { OrderNatureType } from '../../../../domain/enums/order-nature.enum';

export interface UpdatePurchaseParams {
    id: string;
    data: PurchaseOrderUpdateDataObject;
    workspace?: string;
}

export interface PurchaseOrderUpdateDataObject {
    currency?: string;
    externalDataId?: string;
    items?: {
        comment?: string;
        material?: string;
        ppu?: number;
        quantity?: number;
    }[];
    rules?: string[];
    shippedOn?: any[] | null;
    shipToAddress?: string;
    purchaseProcesses?: string[];
    orderNature?: OrderNatureType;
    externalCustomerReference?: string;
}

export function useUpdatePurchase() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = usePurchaseService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async (args: UpdatePurchaseParams) => {
            if (!context.workspace?.id || !args.id) return;
            await service.update(
                args.workspace ?? context.workspace.id,
                args.id,
                args.data,
            );
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: [
                    'purchase',
                    { workspace: context.workspace?.id, id: args.id },
                ],
            });

            client.invalidateQueries({
                queryKey: ['purchases', { type: context.workspace?.id }],
            });

            client.invalidateQueries({
                queryKey: [
                    'order',
                    { workspace: context.workspace?.id, id: args.id },
                ],
            });

            client.invalidateQueries({
                queryKey: ['orderSearch'],
            });

            client.invalidateQueries({
                queryKey: [
                    'purchaseTrace',
                    { workspace: context.workspace?.id, id: args.id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Purchase order updated successfully',
                title: 'Update Purchase',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to update purchase order: ${error.message}`,
                title: 'Update Purchase',
            });
        },
    });
}
