import {
    Affix,
    Alert,
    Content,
    Header,
    Layout,
    Menu,
    Text,
} from '@ianneo/ui-library';
import React, {
    CSSProperties,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AlertMessage } from '../../../domain/interfaces/alert.interface';
import AppContext from '../../../domain/models/app.context.model';
import { useAlertContext } from '../../../infrastructure/hooks/use-alert.hook';
import useToggle from '../../../infrastructure/hooks/use-toggle';
import SwitchLanguage from '../SwitchLanguage/switch-language';
import SwitchWorkspace from '../SwitchWorkspace/switch-workspace';
import { CompanyDropdown } from './company-dropdown.component';
import './layout.component.css';

export interface LayoutCompProps {
    components: ReactNode;
    children?: never[];
    header?: string | undefined;
    onNavigate: (event: any) => void;
}

const headerStyles: CSSProperties = {
    background: 'white',
    boxShadow: '0px 1px 2px #ddd',
    display: 'flex',
    overflow: 'hidden',
    maxWidth: '100%',
    width: '100%',
    marginBottom: '1px',
};

const logoTextStyles: CSSProperties = {
    alignSelf: 'auto',
    lineHeight: 'normal',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
};

const imageStyles: CSSProperties = {
    height: '45px',
};

const LayoutComponent: React.FC<LayoutCompProps> = ({
    components,
    onNavigate,
}) => {
    const { t } = useTranslation();
    const { alert, setAlert } = useAlertContext();
    const context = useContext(AppContext);
    const navigate = useNavigate();

    const [isAlertUp, setIsAlertUp] = useState<boolean>(false);
    const { status: openWorkspace, toggle: toggleWorkspace } = useToggle();
    const { status: openLanguage, toggle: toggleLanguage } = useToggle();

    const menuOptions = useMemo(
        () => [
            {
                label: t('common:header.dashboard'),
                key: 'dashboard',
            },
            {
                label: t('common:header.products'),
                key: 'products',
            },
            {
                label: t('common:header.rulesets'),
                key: 'rulesets',
            },
            {
                label: t('common:header.salesOrders'),
                key: 'salesOrders',
                children: [
                    {
                        label: t('common:header.receivedOrders'),
                        key: 'orders',
                    },
                    {
                        label: t('common:header.purchaseOrders'),
                        key: 'purchases',
                    },
                ],
            },
            {
                label: t('common:header.components'),
                key: 'materials',
            },
        ],
        [t],
    );

    const getAlert = (alert: AlertMessage) => {
        console.log(
            `component<layout>| getAlert(): $alert = ${JSON.stringify(alert)}`,
        );
        return (
            <Affix offsetBottom={15} className="lfx-affix">
                <Alert
                    className="alert-box"
                    type={alert.type}
                    message={alert.title}
                    description={alert.message}
                    showIcon
                    closable={true}
                />
            </Affix>
        );
    };

    const handleMenuClick = (e: React.Key) => {
        if (e === 'salesOrders') return;
        if (e === 'dashboard') return navigate('/');

        navigate(`/${e}`);
    };

    useEffect(() => {
        if (isAlertUp === true) {
            setTimeout(() => {
                setIsAlertUp(false);
                setAlert(undefined);
            }, alert?.timeout || 3000);
        }
    }, [isAlertUp, alert, setAlert]);

    useEffect(() => {
        if (alert !== undefined) {
            setIsAlertUp(true);
        }
    }, [alert]);

    return (
        <>
            <Layout style={{ height: '100vh' }}>
                <Header style={headerStyles}>
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            maxWidth: '100%',
                            width: '100%',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                maxWidth: '169px',
                                minWidth: '169px',
                            }}
                        >
                            <img
                                alt="Application Logo"
                                src="/lf-logo.png"
                                style={imageStyles}
                            ></img>

                            <Text style={logoTextStyles}>
                                {context.workspace?.displayName}
                            </Text>
                        </div>

                        <div style={{ flex: 1, maxWidth: '45vw' }}>
                            <Menu
                                mode="horizontal"
                                selectedKeys={['']}
                                onClick={(e) => handleMenuClick(e.key)}
                                style={{
                                    width: 'calc(45vw)',
                                }}
                                items={menuOptions}
                            ></Menu>
                        </div>

                        <CompanyDropdown
                            openLng={toggleLanguage}
                            openSwitch={toggleWorkspace}
                        />
                    </div>
                </Header>

                <Layout
                    hasSider={true}
                    style={{
                        backgroundColor: '#fff',
                        overflowX: 'hidden',
                    }}
                >
                    <Content className="console">{components}</Content>
                </Layout>
            </Layout>

            {isAlertUp && alert && getAlert(alert)}

            <SwitchLanguage open={openLanguage} setOpen={toggleLanguage} />

            <SwitchWorkspace open={openWorkspace} setOpen={toggleWorkspace} />
        </>
    );
};

export default LayoutComponent;
