import { useQuery } from '@tanstack/react-query';
import { ProductCategory } from '../../../../domain/models/category.model';
import { CategoryService } from '../../../../domain/services/category.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useCategoryService } from './use-category-service';

const getBaseCategories = async (
    service: CategoryService,
): Promise<ProductCategory[]> => {
    const response = await service.listBase();

    return response;
};

export function useBaseCategories() {
    const context = useAppContext();
    const { service } = useCategoryService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: ['category-base', { type: context.workspace?.id }],
        queryFn: async () => {
            return await getBaseCategories(service);
        },
        onError: (err) => {
            setAlert({
                type: 'error',
                message: 'Failed to fetch purchases information',
            });
        },
    });
}
