import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MeService } from '../../../../domain/services/me.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useMeService } from './use-me-service';

export interface InviteMemberParams {
    params: {
        target: {
            email: string;
            firstName: string;
            lastName: string;
        };
        role: string;
    };
    workspaceId?: string;
}

const inviteMember = async (
    service: MeService,
    id: string,
    params: InviteMemberParams['params'],
) => {
    return await service.inviteMember(id, params);
};

export function useInviteMember() {
    const context = useAppContext();
    const client = useQueryClient();

    const { service } = useMeService({});
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ params, workspaceId }: InviteMemberParams) => {
            if (!context.workspace?.id || !service) return;

            await inviteMember(
                service,
                workspaceId || context.workspace.id,
                params,
            );
        },
        onSuccess: (_, { workspaceId }) => {
            client.invalidateQueries({
                queryKey: [
                    'members',
                    { type: workspaceId || context.workspace?.id },
                ],
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to invite member: ${error.message}`,
                title: 'Invite new member',
            });
        },
    });
}
