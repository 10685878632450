import { Form, FormItem, Input, Modal, useForm } from '@ianneo/ui-library';
import { decode } from 'html-entities';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { Template } from '../../../../infrastructure/clients/template.client';
import { useCreateTemplate } from '../../../../infrastructure/hooks/api/template/use-create-template';
import useTemplate from '../../../../infrastructure/hooks/api/template/use-template';
import { useUpdateTemplate } from '../../../../infrastructure/hooks/api/template/use-update-template';
import './template.css';

interface UpsertTemplateProps {
    template?: Template;
    open: boolean;
    toggle: () => void;
    setTemplate: (value?: Template) => void;
}

export const UpsertTemplate: React.FC<UpsertTemplateProps> = ({
    template,
    open,
    toggle,
    setTemplate,
}) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const [message, setMessage] = useState('');
    const title = template?.adaptation
        ? t('company:template.updateTitle')
        : t('company:template:createTitle');

    const { data } = useTemplate(template?.code?.toString() || '', true);

    const { mutateAsync: create } = useCreateTemplate();
    const { mutateAsync: update } = useUpdateTemplate();

    const reset = () => {
        setTemplate(undefined);
        toggle();
    };

    const submit = async () => {
        const values = await form.getFieldsValue(true);

        if (data instanceof Array) {
            await create({
                template: template?.code?.toString() || '',
                adaptation: {
                    ...values,
                    content: decode(`<html><body>${message}</body></html>`),
                },
            });
        } else {
            await update({
                template: template?.code?.toString() || '',
                adaptation: {
                    ...values,
                    content: decode(`<html><body>${message}</body></html>`),
                },
            });
        }

        reset();
    };

    useEffect(() => {
        if (!form) return;
        if (!open) return;

        if (data instanceof Array) {
            form.setFieldsValue({
                name: template?.name,
                subject: template?.subject,
                channel: template?.channel,
                code: template?.code,
                description: template?.description,
                content: template?.content,
            });
        } else {
            form.setFieldsValue({
                name: data?.name,
                subject: data?.subject,
                channel: data?.channel,
                code: data?.code,
                description: data?.description,
                content: data?.content,
            });
        }
    }, [data, template, form, open]);

    return (
        <Modal
            title={title}
            cancelFn={reset}
            open={open}
            okFn={submit}
            width="55vw"
        >
            <Form
                form={form}
                wrapperCol={{ span: 18 }}
                labelAlign="right"
                labelWrap={true}
                labelCol={{ span: 6 }}
            >
                <FormItem label={t('company:template.code')} name="code">
                    <Input readOnly disabled />
                </FormItem>

                <FormItem label={t('company:template.name')} name="name">
                    <Input readOnly disabled />
                </FormItem>

                <FormItem label={t('company:template.subject')} name="subject">
                    <Input />
                </FormItem>

                <FormItem label={t('company:template.channel')} name="channel">
                    <Input />
                </FormItem>

                <FormItem
                    label={t('company:template.description')}
                    name="description"
                >
                    <Input />
                </FormItem>

                <FormItem label={t('company:template.content')} name="content">
                    <ReactQuill
                        className="template-quill"
                        value={message}
                        onChange={setMessage}
                    />
                </FormItem>
            </Form>
        </Modal>
    );
};
