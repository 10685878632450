import {
    Badge,
    Calendar as AntdCalendar,
    ITableProps,
    Modal,
    Table,
} from '@ianneo/ui-library';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SalesOrder } from '../../../domain/models/sales-order.model';
import { useOrderDateSearch } from '../../../infrastructure/hooks/api/order/use-order-date-search';

interface CalendarItem {
    date: number;
    month?: number;
    order: SalesOrder;
}

export const Calendar: React.FC<{}> = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState<moment.Moment>();

    const { data } = useOrderDateSearch({
        shipFrom: moment().startOf('month').toDate(),
        shipUntil: moment().endOf('month').toDate(),
    });

    const calendarItems: CalendarItem[] = useMemo(
        () => {
            console.log(data);
            return [];
        },
        // data?.map((x) => ({
        //     date: moment(x.shippedOn).date(),
        //     month: moment(x.shippedOn).month(),
        //     order: x,
        // })) || [],
        [data],
    );

    const selectedItems = useMemo(() => {
        return calendarItems.filter(
            (x) =>
                x.date === selectedDate?.date() &&
                x.month === selectedDate?.month(),
        );
    }, [calendarItems, selectedDate]);

    const columns: ITableProps<SalesOrder>['columns'] = [
        { title: t('order:listing.soNumber'), dataIndex: 'id' },
        {
            title: t('order:listing.buyer'),
            render: (_: any, item: SalesOrder) => {
                return <>{item.customer?.buyer?.name}</>;
            },
        },
        {
            title: t('order:listing.poNumber'),
            dataIndex: 'poExternalRefId',
        },
        {
            title: t('order:listing.currency'),
            dataIndex: 'currency',
            render: (_: any, item: SalesOrder) => (
                <>{item.currency?.toLocaleUpperCase()}</>
            ),
        },
    ];

    const renderDateCell = (date: moment.Moment) => {
        const items = calendarItems.filter(
            (x) => x.date === date.date() && x.month === date.month(),
        );

        return (
            <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                {items.map((item, index) => (
                    <li key={index}>
                        <Badge
                            status="success"
                            text={'Your order has been delivered'}
                            style={{
                                width: '100%',
                                overflow: 'hidden',
                                fontSize: '12px',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        />
                    </li>
                ))}
            </ul>
        );
    };

    const renderMonthCell = (date: moment.Moment) => {
        const items = calendarItems.filter((x) => x.month === date.month());

        return (
            <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                {items.map((item, index) => (
                    <li key={index}>
                        <Badge
                            status="success"
                            text={'Your order has been delivered'}
                            style={{
                                width: '100%',
                                overflow: 'hidden',
                                fontSize: '12px',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        />
                    </li>
                ))}
            </ul>
        );
    };

    const handleSelectedDate = (date: moment.Moment) => {
        setSelectedDate(date);
        setOpen(true);
    };

    const handlePanelChange = (date: moment.Moment) => {
        setSelectedDate(date);
    };

    const reset = () => {
        setOpen(false);
        setSelectedDate(undefined);
    };

    return (
        <>
            <AntdCalendar
                style={{ height: '100%', overflow: 'auto' }}
                dateCellRender={renderDateCell}
                monthCellRender={renderMonthCell}
                onSelect={handleSelectedDate}
                onPanelChange={handlePanelChange}
            />

            <Modal title="Delivery Calender Info" open={open} cancelFn={reset}>
                <Table
                    showColumns={false}
                    showFilter={false}
                    showSearch={false}
                    scroll={{ x: 'max-content' }}
                    columns={columns}
                    rowKey={(item) => item.id}
                    dataSource={selectedItems.map((x) => x.order)}
                />
            </Modal>
        </>
    );
};
