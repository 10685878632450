import { MaterialApiClient } from '../../infrastructure/clients/material.client';
import { UpdateProductMaterial } from '../../infrastructure/hooks/api/materials/use-update-material';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { Material, ProductMaterial } from '../models/material.model';

export class MaterialService {
    private server: MaterialApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new MaterialApiClient(config, token);
    }

    async listBase() {
        console.log(`service<material>| listBase(): Enter`);

        const result: any[] = await this.server.listBase();
        return result.map((r) => this.convertBase(r));
    }

    async list(workspace: string) {
        console.log(`service<material>| list(): Enter`);
        console.log(`service<material>| list(): $workspace = ${workspace}`);

        const result: any[] = await this.server.list(workspace);
        return result.map((r) => this.convert(r));
    }

    async get(workspace: string, item: string) {
        console.log(`service<material>| get(): Enter`);
        console.log(`service<material>| get(): $workspace = ${workspace}`);
        console.log(`service<material>| get(): $item = ${item}`);

        return this.convert(await this.server.get(workspace, item));
    }

    async create(workspace: string, material: ProductMaterial) {
        console.log(`service<material>| create(): Enter`);
        console.log(`service<material>| create(): $workspace = ${workspace}`);
        console.log(
            `service<material>| create(): $document = ${JSON.stringify(
                material,
            )}`,
        );

        return this.convert(await this.server.create(workspace, material));
    }

    async update(
        workspace: string,
        item: string,
        material: UpdateProductMaterial,
    ) {
        console.log(`service<material>| update(): Enter`);
        console.log(`service<material>| update(): $workspace = ${workspace}`);
        console.log(`service<material>| update(): $item = ${item}`);
        console.log(
            `service<material>| update(): $document = ${JSON.stringify(
                material,
            )}`,
        );

        return this.server.update(workspace, item, material);
    }

    async search(filters: any) {
        console.log(`service<material>| search(): Enter`);
        console.log(
            `service<material>| search(): $filters = ${JSON.stringify(
                filters,
            )}`,
        );

        const result: any[] = await this.server.search(filters);

        return result.map((r) => this.convert(r));
    }

    async delete(workspace: string, id: string) {
        console.log(`service<material>| delete(): Enter`);
        console.log(`service<material>| delete(): $id = ${id}`);

        return this.server.delete(workspace, id);
    }

    async listCategories() {
        console.log(`service<material>| listCategories(): Enter`);

        return await this.server.listCategories();
    }

    async getCategory(id: string) {
        console.log(`service<material>| getCategory(): Enter`);
        console.log(`service<material>| getCategory(): $id = ${id}`);

        return await this.server.getCategory(id);
    }

    private convert(source: any): ProductMaterial {
        return source;
    }

    private convertBase(source: any): Material {
        const result = {
            id: source.id,
            category: source.category,
            components: source.components,
            createdOn: source.createdOn,
            lastUpdatedOn: source.lastUpdatedOn,
            name: source.name,
            rules: source.rules,
            specifications: source.specifications,
            unit: source.unit,
        } as Material;

        return result;
    }
}
