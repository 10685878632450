import { useQuery } from '@tanstack/react-query';
import { Role } from '../../../../domain/models/role.model';
import { RoleService } from '../../../../domain/services/role.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useRoleService } from './use-role-service';

const getRoles = async (service: RoleService, id: string): Promise<Role[]> => {
    const response = await service.list(id);

    return response;
};

export function useRoles(workspaceId?: string) {
    const context = useAppContext();
    const { service } = useRoleService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: ['roles', { type: workspaceId || context.workspace?.id }],
        queryFn: async () => {
            if (!context.workspace?.id) return;
            return await getRoles(service, workspaceId || context.workspace.id);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch roles information: ${error.message}`,
            });
        },
    });
}
