import { useParams } from 'react-router-dom';
import { useRuleset } from '../../../../infrastructure/hooks/api/rulesets/use-ruleset';
import RulesetDetailLayout from './ruleset-detail.layout';

interface RulesetDetailComponentProps {}

const RulesetDetailComponent: React.FC<RulesetDetailComponentProps> = () => {
    const { id, delegateId } = useParams();
    const { data } = useRuleset({ id, delegateId });

    return <RulesetDetailLayout data={data || {}} />;
};

export default RulesetDetailComponent;
