import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { UpdateRolePermissionParams } from '../hooks/api/role/use-update-role-permissions';
import { ApiServer } from './api.server';

/**
 * @class
 * @name RulesetsApiClient
 * @description The rulesets API client
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */
export class RoleApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'role');
    }

    async assignRole(workspace: string, role: string, params: any) {
        console.log(`client<role>| put(): Enter`);
        console.log(`client<role>| put(): $workspace = ${workspace}`);
        console.log(`client<role>| put(): $role= ${role}`);
        console.log(`client<role>| patch(): $data = ${JSON.stringify(params)}`);

        return super.put('put', workspace, role, params);
    }

    async delete(workspace: string, role: string) {
        console.log(`client<role>| delete(): $workspace= ${workspace}`);
        console.log(`client<role>| delete(): $role= ${role}`);

        return super.delete('delete', workspace, role);
    }

    async list(workspace: string) {
        console.log(`client<role>| list(): $workspace= ${workspace}`);

        return super.get('list', workspace);
    }

    async create(workspace: string, params: any) {
        console.log(`client<role>| create(): $workspace= ${workspace}`);
        console.log(`client<role>| create(): $data= ${JSON.stringify(params)}`);

        return super.post('create', workspace, params);
    }

    async update(workspace: string, role: string, params: any) {
        console.log(`client<role>| update(): $workspace= ${workspace}`);
        console.log(`client<role>| update(): $role= ${role}`);
        console.log(`client<role>| update(): $data= ${JSON.stringify(params)}`);

        return super.patch('update', workspace, role, params);
    }

    async get(workspace: string, role: string) {
        console.log(`client<role>| get(): $workspace= ${workspace}`);
        console.log(`client<role>| get(): $role= ${role}`);

        return super.get('get', workspace, role);
    }

    async assign(workspace: string, role: string, params: any) {
        console.log(`client<role>| assign(): $workspace= ${workspace}`);
        console.log(`client<role>| assign(): $role= ${role}`);
        console.log(`client<role>| assign(): $data= ${JSON.stringify(params)}`);

        return super.put('assign', workspace, role, params);
    }

    async unassign(workspace: string, role: string, params: any) {
        console.log(`client<role>| unassign(): $workspace= ${workspace}`);
        console.log(`client<role>| unassign(): $role= ${role}`);
        console.log(
            `client<role>| unassign(): $data= ${JSON.stringify(params)}`,
        );

        return super.put('unassign', workspace, role, params);
    }

    async updatePermissions(
        workspace: string,
        role: string,
        params: UpdateRolePermissionParams['args'],
    ) {
        console.log(
            `client<role>| updatePermissions(): $workspace= ${workspace}`,
        );
        console.log(`client<role>| updatePermissions(): $role= ${role}`);
        console.log(
            `client<role>| updatePermissions(): $data= ${JSON.stringify(
                params,
            )}`,
        );

        return super.put('updatePermissions', workspace, role, params);
    }
}
