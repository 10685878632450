import { UploadFile } from '@ianneo/component-library';
import { StepModal } from '@ianneo/ui-library';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import * as XLSX from 'xlsx';
import { OrderNatureType } from '../../../../domain/enums/order-nature.enum';
import { UnitType } from '../../../../domain/enums/unit-type.enum';
import { PurchaseOrderVersion } from '../../../../domain/models/purchase.model';
import { Workspace } from '../../../../domain/models/workspace.model';
import countries from '../../../../infrastructure/config/data/countries.json';
import { useCategoryService } from '../../../../infrastructure/hooks/api/category/use-category-service';
import { usePartnerConnect } from '../../../../infrastructure/hooks/api/partner/use-partner-create';
import { usePartnerInvite } from '../../../../infrastructure/hooks/api/partner/use-partner-invite';
import { usePartnerService } from '../../../../infrastructure/hooks/api/partner/use-partner-service';
import { useCreateProduct } from '../../../../infrastructure/hooks/api/products/use-create-product';
import { useProductService } from '../../../../infrastructure/hooks/api/products/use-product-service';
import { useCreatePurchase } from '../../../../infrastructure/hooks/api/purchases/use-create-purchase';
import { usePurchaseService } from '../../../../infrastructure/hooks/api/purchases/use-purchase-service';
import { useWorkspaceService } from '../../../../infrastructure/hooks/api/workspaces/use-workspace-service';
import { useAlertContext } from '../../../../infrastructure/hooks/use-alert.hook';
import useAppContext from '../../../../infrastructure/hooks/use-context.hook';
import { DelegationModel } from '../../../_api/delegations/delegation.model';
import { useDelegationService } from '../../../_api/delegations/hooks/useDelegationService';
import ImportDownloadTemplate from '../../../components/Import/import-download-template';
import ImportUploadTemplate from '../../../components/Import/import-upload-template';
import { ImportErrorDialog, ImportErrorMessage } from './import-error-dialog';

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    purchaseOrder: PurchaseOrderVersion;
}

const SheetName = 'Supplier Template';

interface SheetData {
    'PO Factory Name': string;
    'Main Category [Optional]': string;
    'PO Number [Optional]': string;
    'Item Number [Optional]': string;
    'Product Name': string;
    'Company Role/Purchase Process for Factory (Material Supplier)': string;
    'Factory Name (Material Supplier)': string;
    'Factory License Number (Material Supplier)': string;
    'Factory Country (Material Supplier)': string;
    'Factory Address (Material Supplier)': string;
    'Factory Contact First Name (Material Supplier) [Optional]': string;
    'Factory Contact Last Name (Material Supplier) [Optional]': string;
    'Factory Contact Email (Material Supplier) [Optional]': string;
    'Upper Tier Row Number [Optional]': string;
    __rowNum__: string;
    children: SheetData[];
    [key: string]: string | SheetData[];
}

export const ImportBatchPurchaseOrder2 = ({
    open,
    setOpen,
    purchaseOrder,
}: Props) => {
    const context = useAppContext();
    const { setAlert } = useAlertContext();
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [errors, setErrors] = useState<ImportErrorMessage[]>([]);

    const Document = require('./supply_chain_template.xlsx');

    const client = useQueryClient();

    const { service: workspaceService } = useWorkspaceService();
    const { service: productService } = useProductService();
    const { service: categoryService } = useCategoryService();
    const { service: purchaseService } = usePurchaseService();
    const { service: partnerService } = usePartnerService();
    const service = useDelegationService();

    const { mutateAsync: createProduct } = useCreateProduct();
    const { mutateAsync: invitePartner } = usePartnerInvite();
    const { mutateAsync: connectPartner } = usePartnerConnect();
    const { mutateAsync: createPurchase } = useCreatePurchase();

    const delay = (duration: number) =>
        new Promise((resolve) => setTimeout(resolve, duration));

    const reset = () => {
        setFiles([]);
        setOpen(false);
    };

    const submit = async () => {
        return new Promise((resolve, reject) => {
            const file = files[0].originFileObj;
            if (!file) {
                throw new Error('No file selected');
            }

            const fileReader = new FileReader();

            fileReader.readAsArrayBuffer(file);

            fileReader.onload = async (e) => {
                try {
                    const buffer = e.target?.result as ArrayBuffer;
                    const wb = XLSX.read(buffer, { type: 'buffer' });

                    // const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[SheetName];

                    // /* Convert array of arrays */
                    const data: SheetData[] = XLSX.utils.sheet_to_json(ws);
                    const filteredData = data
                        .filter(
                            (x: SheetData) =>
                                x['Factory Name (Material Supplier)']
                                    ?.toString()
                                    ?.trim() ||
                                x['PO Factory Name']?.toString()?.trim(),
                        )
                        .map((x) => ({
                            ...x,
                            __rowNum__: x.__rowNum__ + 1,
                        }));
                    const errorMessages: ImportErrorMessage[] = [];

                    await validateExcel(filteredData, errorMessages);

                    const nestedData = await convertToNested(
                        filteredData,
                        errorMessages,
                    );

                    if (errorMessages.length > 0) {
                        setErrors(errorMessages);
                        resolve([]);

                        return;
                    }

                    await processExcel(nestedData, purchaseOrder);

                    client.invalidateQueries({
                        queryKey: ['purchaseTrace'],
                    });

                    client.invalidateQueries({
                        queryKey: ['purchase'],
                    });

                    client.invalidateQueries({
                        queryKey: ['order'],
                    });

                    client.invalidateQueries({
                        queryKey: ['purchase-reverse'],
                    });

                    reset();
                    resolve(data);
                } catch (error) {
                    const err = error as Error;
                    setAlert({
                        title: 'Import Error',
                        message: err.message,
                        type: 'error',
                    });
                    reject(err);
                }
            };

            fileReader.onerror = (error) => {
                reject(error); // Reject the promise if there's an error
            };
        });
    };

    const validateExcel = async (
        data: SheetData[],
        errorMessages: ImportErrorMessage[],
    ) => {
        const validationErrors: ImportErrorMessage[] = [];

        data.forEach((item, index) => {
            const requiredFields = [
                'Factory Name (Material Supplier)',
                'Factory License Number (Material Supplier)',
                'Factory Country (Material Supplier)',
                'Factory Address (Material Supplier)',
                'Company Role/Purchase Process for Factory (Material Supplier)',
                'Product Name',
            ];

            requiredFields.forEach((field) => {
                if (!item[field]) {
                    validationErrors.push({
                        rowNum: index + 2,
                        message: `${field} is required`,
                    });
                }
            });
        });

        if (validationErrors.length > 0) {
            errorMessages.push(...validationErrors);
        }
    };

    const convertToNested = (
        data: SheetData[],
        errorMessages: ImportErrorMessage[],
    ) => {
        const result: SheetData[] = [];
        const lookup: { [key: string]: SheetData } = {};

        const dataWithoutUpperTier = data.filter(
            (x) => !x['Upper Tier Row Number [Optional]'],
        );

        const upperTierData = data
            .filter((x) => x['Upper Tier Row Number [Optional]'])
            .sort((a, b) => {
                const aSum =
                    Number(a['Upper Tier Row Number [Optional]']) +
                    Number(a['__rowNum__']);
                const bSum =
                    Number(b['Upper Tier Row Number [Optional]']) +
                    Number(b['__rowNum__']);
                return aSum - bSum;
            });

        for (const item of dataWithoutUpperTier) {
            const exist =
                lookup[
                    item['Factory Name (Material Supplier)']?.toString()?.trim()
                ];

            if (!exist) {
                lookup[
                    item['Factory Name (Material Supplier)']?.toString()?.trim()
                ] = {
                    ...item,
                    children: [],
                };
            }

            if (!item['PO Factory Name']) {
                lookup[
                    item['Factory Name (Material Supplier)']?.toString()?.trim()
                ] = {
                    ...item,
                    children: [],
                };
            }
        }

        for (const item of dataWithoutUpperTier) {
            if (!item['PO Factory Name']) {
                result.push(
                    lookup[
                        item['Factory Name (Material Supplier)']
                            ?.toString()
                            ?.trim()
                    ],
                );
                continue;
            }

            const parent = lookup[item['PO Factory Name']?.toString()?.trim()];
            if (parent) {
                const parentProcess =
                    parent[
                        'Company Role/Purchase Process for Factory (Material Supplier)'
                    ];
                const childProcess =
                    item[
                        'Company Role/Purchase Process for Factory (Material Supplier)'
                    ];

                if (
                    parent['Factory Name (Material Supplier)']
                        ?.toString()
                        ?.trim() ===
                        item['Factory Name (Material Supplier)']
                            ?.toString()
                            ?.trim() &&
                    parentProcess === childProcess
                ) {
                    errorMessages.push({
                        rowNum: Number(item.__rowNum__) + 1,
                        message: `Circular reference detected: ${
                            item['PO Factory Name']
                        } -> ${item['Factory Name (Material Supplier)']
                            ?.toString()
                            ?.trim()}`,
                    });
                }

                const existing = result.find(
                    (x) =>
                        x['Factory Name (Material Supplier)']
                            ?.toString()
                            ?.trim() ===
                        item['PO Factory Name']?.toString()?.trim(),
                );

                if (existing) {
                    existing.children.push({
                        ...item,
                        __rowNum__: item.__rowNum__,
                        children: [],
                    });
                } else {
                    const children = result.flatMap((x) => flatMapChildren(x));

                    const existingChild = children.find(
                        (x) =>
                            x['Factory Name (Material Supplier)']
                                ?.toString()
                                ?.trim() ===
                            item['PO Factory Name']?.toString()?.trim(),
                    );

                    if (existingChild) {
                        existingChild.children.push({
                            ...item,
                            __rowNum__: item.__rowNum__,
                            children: [],
                        });
                    }
                }
            }
        }

        const flattenedResults = result.flatMap((res) => flatMapChildren(res));

        for (const item of upperTierData) {
            const existing = flattenedResults.find(
                (x) =>
                    x.__rowNum__ === item['Upper Tier Row Number [Optional]'],
            );

            if (existing) {
                existing.children.push({
                    ...item,
                    __rowNum__: item.__rowNum__,
                    children: [],
                });
            } else {
                result.forEach((x) => {
                    findAndUpdateUpperTierRow(x, item);
                });
            }
        }

        return result;
    };

    const findAndUpdateUpperTierRow = (data: SheetData, item: SheetData) => {
        data.children.forEach((x) => {
            if (x.__rowNum__ === item['Upper Tier Row Number [Optional]']) {
                x.children.push({
                    ...item,
                    children: [],
                });
            } else {
                findAndUpdateUpperTierRow(x, item);
            }
        });
    };

    function flatMapChildren(item: SheetData) {
        const flatList: SheetData[] = [];

        function recurse(item: SheetData) {
            flatList.push(item);
            item.children.forEach((child) => recurse(child));
        }

        recurse(item);

        return flatList;
    }

    const processExcel = async (
        data: SheetData[],
        parentPurchaseOrder?: PurchaseOrderVersion,
        parentWorkspace?: Workspace,
    ) => {
        for (const item of data) {
            const factoryPartner = await findOrCreatePartner(
                parentWorkspace?.id || context.workspace?.id || '',
                item['Factory Name (Material Supplier)']?.toString()?.trim(),
                item,
                parentWorkspace || (context.workspace as Workspace),
                {
                    email: item[
                        'Factory Contact Email (Material Supplier) [Optional]'
                    ],
                    firstName:
                        item[
                            'Factory Contact First Name (Material Supplier) [Optional]'
                        ],
                    lastName:
                        item[
                            'Factory Contact Last Name (Material Supplier) [Optional]'
                        ],
                },
            );

            const category = await findOrCreateCategory(
                parentPurchaseOrder?.owner?.supplier?.seller?.id || '',
                item,
            );

            // Creates the product with category info into the Factory Workspace
            const product = await findOrCreateProduct(
                parentPurchaseOrder?.owner?.supplier?.seller?.id || '',
                item,
                category.id || '',
            );

            const purchase = await createPurchaseOrder(
                parentPurchaseOrder?.owner?.supplier?.seller?.id || '',
                factoryPartner.supplierId,
                item,
                {
                    id: product.id || '',
                    cost: product.versions?.[0].cost || 0,
                },
                parentPurchaseOrder,
            );

            if (item.children.length > 0) {
                await processExcel(
                    item.children,
                    purchase,
                    factoryPartner.seller,
                );
            }
        }
    };

    const findOrCreateCategory = async (
        workspaceId: string,
        item: SheetData,
    ) => {
        const categoryName =
            item['Main Category [Optional]'] || 'Placeholder Cat';

        let category = await categoryService.searchByCode(
            workspaceId,
            encodeURIComponent(categoryName),
        );

        if (!category?.id) {
            category = await categoryService.create(workspaceId, {
                code: categoryName,
                unit: UnitType.PIECES,
                name: {
                    locales: [{ localeName: 'en', text: categoryName }],
                },
                description: {
                    locales: [{ localeName: 'en', text: '' }],
                },
            });
        }

        return category;
    };

    const findOrCreateProduct = async (
        workspaceId: string,
        item: SheetData,
        categoryId: string,
    ) => {
        let parsedName = '';
        const orderNature = item[
            'Company Role/Purchase Process (Required)'
        ] as OrderNatureType;

        if (orderNature === OrderNatureType.PROCESSING) {
            parsedName = `Service - ${item['Product Name'].toString()}`;
        } else {
            parsedName = item['Product Name'].toString();
        }

        let product = await productService.search(
            workspaceId,
            encodeURIComponent(parsedName.toString().toLocaleUpperCase()),
        );

        if (!product) {
            product = await createProduct({
                workspaceId,
                product: {
                    category: categoryId,
                    externalDataId: item['Item Number [Optional]'],
                    name: parsedName.toLocaleUpperCase(),
                    description: '',
                    unit: UnitType.PIECES,
                },
            });

            await delay(1500);
        }

        return product;
    };

    const findPartner = async (workspaceId: string, name: string) => {
        const partners = await partnerService.list(workspaceId);

        const existingPartner = partners.find((partner) => {
            return (
                partner.seller?.companyName?.toLocaleLowerCase() ===
                name?.toLocaleLowerCase()
            );
        });

        return existingPartner
            ? {
                  ...existingPartner,
                  workspaceId: existingPartner.seller?.id || '',
                  supplierId: existingPartner.id || '',
              }
            : undefined;
    };

    const findOrCreatePartner = async (
        workspaceId: string,
        vendorName: string,
        data: SheetData,
        parentWorkspace: Workspace,
        contactInfo?: {
            email: string;
            firstName: string;
            lastName: string;
        },
    ) => {
        const existingPartner = await findPartner(workspaceId, vendorName);

        if (existingPartner) {
            return existingPartner;
        }

        // Create Partner
        const workspaces = await workspaceService.getByCompanyName(vendorName);
        const workspace = workspaces.find(
            (x) => x.company?.name === vendorName,
        );

        if (workspace) {
            // Run Connect
            const existingDelegations = (await service.list(
                workspaceId,
                '1',
            )) as DelegationModel[];

            const currentDelegation = existingDelegations.find(
                (x) => x.workspace?.id === workspace.id,
            );

            if (!currentDelegation) {
                await service.create(
                    workspaceId,
                    {
                        workspace: workspace.id,
                        delegate: workspaceId,
                    },
                    '1',
                );
            }

            await connectPartner({
                partnerWorkspace: workspace,
                supplierWorkspace: parentWorkspace,
                delegateWorkspace: parentWorkspace,
            });
        } else {
            // Run Invite
            const contact = [
                {
                    email: context.user?.email || '',
                    firstName: context.user?.firstName || '',
                    lastName: context.user?.lastName || '',
                },
            ];

            if (contactInfo?.email && contactInfo?.firstName) {
                contact.push(contactInfo);
            }

            const country = countries.find(
                (x) =>
                    x.name.toLocaleLowerCase() ===
                    data[
                        'Factory Country (Material Supplier)'
                    ].toLocaleLowerCase(),
            )?.['alpha-2'];

            await invitePartner({
                solicitation: {
                    workspaceId: workspaceId,
                    company: data['Factory Name (Material Supplier)']
                        ?.toString()
                        ?.trim(),
                    registrationNumber: data[
                        'Factory License Number (Material Supplier)'
                    ]
                        ?.toString()
                        ?.trim(),
                    country,
                    contact: contact.length > 0 ? contact : undefined,
                    isNominated: false,
                    address: data['Factory Address (Material Supplier)']
                        ?.toString()
                        ?.trim(),
                    delegate: [
                        {
                            delegate: workspaceId,
                        },
                        {
                            delegate: context.workspace?.id,
                        },
                    ],
                },
            });
        }

        await delay(1500);

        const partner = await findPartner(workspaceId, vendorName);

        if (!partner) {
            throw new Error('Failed to create partner');
        }

        return partner;
    };

    const createPurchaseOrder = async (
        workspaceId: string,
        supplierId: string,
        item: SheetData,
        product: { id: string; cost: number },
        parentPurchase?: PurchaseOrderVersion,
    ) => {
        const purchase = await createPurchase({
            supplier: supplierId,
            currency: parentPurchase?.owner?.currency || 'USD',
            items: [
                {
                    material: product.id,
                    ppu: product.cost,
                    quantity: 1,
                    unit: UnitType.PIECES,
                    comment: 'Cloned',
                },
            ],
            externalDataId: item['PO Number [Optional]'],
            rules: purchaseOrder.owner?.rules?.[0]?.id
                ? [purchaseOrder.owner?.rules?.[0]?.id]
                : [],
            purchaseProcesses:
                item[
                    'Company Role/Purchase Process for Factory (Material Supplier)'
                    // 'Company Role/Purchase Process for Factory (Material Supplier)'
                ].split(','),
            workspaceId: workspaceId,
            parentOrderReference: parentPurchase?.owner?.id,
        });

        const version = await purchaseService.get(workspaceId, purchase.id);

        return version;
    };

    return (
        <>
            <StepModal
                title="Import Purchase Order"
                open={open}
                width={'60vw'}
                cancelFn={reset}
                okFn={submit}
                bodyStyle={{
                    height: '30vh',
                }}
                stepContent={[
                    {
                        title: 'Download Template',
                        content: (
                            <ImportDownloadTemplate
                                type={'supply_chain'}
                                extension={'xlsx'}
                                document={Document}
                            />
                        ),
                    },
                    {
                        title: 'Import Purchase Order',
                        content: (
                            <ImportUploadTemplate
                                files={files}
                                setFiles={setFiles}
                            />
                        ),
                    },
                ]}
            ></StepModal>

            <ImportErrorDialog errors={errors} setErrors={setErrors} />
        </>
    );
};
