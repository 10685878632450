import { PlusOutlined } from '@ant-design/icons';
import {
    CollapsibleDropdown,
    Form,
    FormItem,
    Input,
    Modal,
    Subtitle,
    Table,
    Title,
    useForm,
} from '@ianneo/ui-library';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddWorkspace } from '../../../infrastructure/hooks/api/account/use-add-workspace';
import { useGetWorkspaces } from '../../../infrastructure/hooks/api/me/use-get-workspaces';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';
import useVerifyAdmin from '../../../infrastructure/hooks/use-verify-admin';
import { useGetDelegations } from '../../_api/delegations/hooks/useGetDelegations';

interface WorkspaceProps {}

const Workspace: React.FC<WorkspaceProps> = () => {
    const { t } = useTranslation();
    const context = useAppContext();
    const { isAdmin } = useVerifyAdmin();

    const [form] = useForm();
    const [open, setOpen] = useState(false);
    const [isMaster, setIsMaster] = useState(false);

    const { delegations } = useGetDelegations();
    const [selected] = useState(0);
    const { data: workspaces } = useGetWorkspaces(
        delegations?.find((x) => x.id === selected)?.workspace?.id,
    );
    const { mutateAsync: create } = useAddWorkspace();

    const columns = useMemo(
        () => [
            {
                title: t('workspace:listing.companyName'),
                dataIndex: ['company', 'name'],
            },
            {
                title: t('workspace:listing.displayName'),
                dataIndex: 'displayName',
            },
        ],
        [t],
    );

    const reset = () => {
        form.resetFields();
        setOpen(false);
    };

    const submit = async () => {
        await form.validateFields();
        const values = form.getFieldsValue(true);
        const workspace = workspaces?.find(
            (x) => x.id === context.workspace?.id,
        );

        const request = {
            displayName: values.displayName,
            company: {
                registrationNumber: values.registrationNumber,
            },
        };

        await create({
            account: workspace?.account?.id || '',
            params: request,
        });

        setOpen(false);
        reset();
    };

    const items = useMemo(() => {
        const items = [];

        if (!isMaster) return [];

        if (!isAdmin) {
            items.push({
                key: 'create',
                icon: <PlusOutlined />,
                label: t('workspace:listing.addBtn'),
                onClick: () => setOpen(true),
            });
        }
        return items;
    }, [t, isAdmin, isMaster]);

    useEffect(() => {
        // This is to check whether the workspace is master or not
        const workspace = workspaces?.find(
            (x) => x.id === context.workspace?.id,
        );

        setIsMaster(workspace?.account?.admin === context.user?.email);
    }, [workspaces, context]);

    useEffect(() => {
        const registrationNumber =
            context.workspace?.company?.registrationNumber;

        if (form && registrationNumber) {
            form.setFieldsValue({
                registrationNumber,
            });
        }
    }, [form, context.workspace, open]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    marginBottom: '24px',
                }}
            >
                <div
                    style={{
                        width: '8px',
                        backgroundColor: '#972d47',
                        minHeight: '40px',
                        minWidth: '8px',
                    }}
                ></div>
                <Title>{t('workspace:name')}</Title>
                <Subtitle>{t('workspace:description')}</Subtitle>
            </div>

            <Table
                columns={columns}
                dataSource={workspaces?.filter(
                    (x) =>
                        x.company?.registrationNumber ===
                        context.workspace?.company?.registrationNumber,
                )}
                // additionalFilters={
                //     <div
                //         style={{
                //             display:
                //                 !delegations || delegations.length === 0
                //                     ? 'none'
                //                     : 'flex',
                //             gap: '8px',
                //             alignItems: 'center',
                //         }}
                //     >
                //         <span style={{ flex: 1 }}>For Company:</span>
                //         <Select
                //             options={delegations?.map((x) => {
                //                 return {
                //                     label: x.workspace?.company?.name,
                //                     value: x.id,
                //                 };
                //             })}
                //             style={{
                //                 maxWidth: '200px',
                //                 width: '200px',
                //                 flex: 3,
                //             }}
                //             onChange={(value) => {
                //                 setSelected(value);
                //             }}
                //             placeholder="None"
                //             suffixIcon={null}
                //             getPopupContainer={undefined}
                //         ></Select>
                //     </div>
                // }
                actions={<CollapsibleDropdown menu={{ items }} />}
                scroll={{ x: 'max-content' }}
            />

            <Modal
                open={open}
                title={t('workspace:modal.title')}
                okFn={submit}
                cancelFn={reset}
            >
                <Form form={form}>
                    <FormItem
                        label={t('workspace:modal.registrationNumber')}
                        name="registrationNumber"
                    >
                        <Input disabled />
                    </FormItem>

                    <FormItem
                        label={t('workspace:modal.displayName')}
                        name="displayName"
                    >
                        <Input />
                    </FormItem>
                </Form>
            </Modal>
        </>
    );
};

export default Workspace;
