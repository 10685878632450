import { useQuery } from '@tanstack/react-query';
import { Workspace } from '../../../../domain/models/workspace.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useMeService } from './use-me-service';
import { MeService } from '../../../../domain/services/me.service';

const getWorkspaces = async (service: MeService): Promise<Workspace[]> => {
    const response = await service.getWorkspaceOptions();

    return response;
};

export function useGetWorkspaces(workspaceId?: string) {
    const context = useAppContext();
    const { service } = useMeService({});
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'workspaces',
            { workspace: workspaceId || context.workspace?.id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !service) return;
            return await getWorkspaces(service);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch workspaces: ${error.message}`,
            });
        },
    });
}
