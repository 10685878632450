import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import {
    Card,
    Checkbox,
    CollapsibleDropdown,
    IAddTableProps,
    Input,
    Link,
    Modal,
    Popconfirm,
    Select,
    Table,
    Text,
    UploadFile,
    UploadTable,
    useForm,
} from '@ianneo/ui-library';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    CompanyDocumentType,
    CompanyDocumentTypeLabeler,
} from '../../../../domain/enums/company-doctype.enum';
import { CompanyDocument } from '../../../../domain/models/company-doc.model';
import { useDocuments } from '../../../../infrastructure/hooks/api/company/documents/use-documents';
import useDownloadDocument from '../../../../infrastructure/hooks/api/company/documents/use-download-document';
import useDownloadDocuments from '../../../../infrastructure/hooks/api/company/documents/use-download-documents';
import { useRemoveDocument } from '../../../../infrastructure/hooks/api/company/documents/use-remove-document';
import { useUploadDocuments } from '../../../../infrastructure/hooks/api/company/documents/use-upload-document';
import useToggle from '../../../../infrastructure/hooks/use-toggle';
import useVerifyAdmin from '../../../../infrastructure/hooks/use-verify-admin';
import EditDocuments from './edit-documents';

interface DocumentsComponentProps {
    workspaceId?: string;
}

const DocumentsComponent: React.FC<DocumentsComponentProps> = (props) => {
    const { t } = useTranslation();
    const { isAdmin } = useVerifyAdmin();
    const { data } = useDocuments({ workspaceId: props.workspaceId });
    const [open, setOpen] = useState(false);
    const [form] = useForm();
    const [files, setFiles] = useState<UploadFile<any>[]>([]);
    const [selectedRows, setSelectedRows] = useState<CompanyDocument[]>([]);

    // States that are related to the edit modal component
    const { status: edit, toggle: toggleEdit } = useToggle();
    const [editingDocument, setEditingDocument] = useState<CompanyDocument>();

    const { mutateAsync } = useUploadDocuments();
    const { mutateAsync: remove } = useRemoveDocument();
    const downloadDocument = useDownloadDocument();
    const downloadDocuments = useDownloadDocuments();

    const rowSelection = {
        selectedRows,
        onChange: (_: React.Key[], selected: any) => {
            setSelectedRows(selected);
        },
    };

    const onDownload = useCallback(async () => {
        await downloadDocuments({
            documents: selectedRows.length > 0 ? selectedRows : data || [],
        });
    }, [data, downloadDocuments, selectedRows]);

    const items = useMemo(() => {
        const items = [
            {
                label: t('common:download'),
                icon: <DownloadOutlined />,
                onClick: async () => await onDownload(),
                key: 'download',
            },
        ];

        if (!isAdmin) {
            items.push({
                label: t('common:addNew'),
                icon: <PlusOutlined />,
                onClick: async () => setOpen(true),
                key: 'add-new',
            });
        }

        return items;
    }, [onDownload, t, isAdmin]);

    const columns = useMemo(() => {
        const col = [
            {
                title: t('company:documents.docName'),
                dataIndex: 'file',
                render: (_: any, item: CompanyDocument) => (
                    <Text>{item?.file?.originalName}</Text>
                ),
            },
            {
                title: t('company:documents.type'),
                dataIndex: 'nature',
                render: (_: any, item: CompanyDocument) => {
                    return CompanyDocumentTypeLabeler[item.nature];
                },
            },
            {
                title: t('company:documents.modal.isPrivate'),
                dataIndex: 'isPrivate',
                render: (_: any, item: CompanyDocument) => (
                    <Text>{item.isPrivate ? 'True' : 'False'}</Text>
                ),
            },
            {
                title: t('company:documents.verifiedOn'),
                dataIndex: 'verifiedOn',
            },
            {
                title: t('common:updatedOn'),
                dataIndex: 'lastUpdatedOn',
                render: (_: string, record: CompanyDocument) => {
                    return (
                        <Text>
                            {record.lastUpdatedOn
                                ? new Date(record.lastUpdatedOn)
                                      .toISOString()
                                      .slice(0, 10)
                                : ''}
                        </Text>
                    );
                },
            },
            {
                title: t('common:actions'),
                render: (_: any, item: CompanyDocument) => (
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <Link
                            hidden={item.verifiedOn ? true : false}
                            onClick={() => {
                                setEditingDocument(item);
                                toggleEdit();
                            }}
                        >
                            <EditOutlined />
                        </Link>

                        <Link
                            onClick={async () =>
                                await downloadDocument({ document: item })
                            }
                        >
                            <DownloadOutlined />
                        </Link>

                        <Link hidden={item.verifiedOn ? true : false}>
                            <Popconfirm
                                title={t('company:documents.delete.title')}
                                description={t(
                                    'company:documents.delete.description',
                                )}
                                submit={async () => await remove(item.id || '')}
                            >
                                <DeleteOutlined style={{ color: '#972d47' }} />
                            </Popconfirm>
                        </Link>
                    </div>
                ),
            },
        ];

        if (isAdmin) {
            col.pop();
        }

        return col;
    }, [t, remove, downloadDocument, toggleEdit, isAdmin]);

    const uploadColumns: IAddTableProps<CompanyDocument>['columns'] = useMemo(
        () => [
            {
                title: t('company:documents.docName'),
                dataIndex: ['file', 'assetName'],
                component: <Input />,
                required: true,
                rules: [
                    {
                        required: true,
                        message: 'Please input a document name',
                    },
                ],
            },
            {
                title: t('company:documents.modal.comment'),
                dataIndex: 'comment',
                component: <Input />,
                required: true,
                rules: [
                    {
                        required: true,
                        message: 'Please input a comment',
                    },
                    {
                        pattern: new RegExp('.*[^ ].*'),
                        message:
                            'Whitespace for starting / ending characters is not allowed.',
                    },
                ],
            },
            {
                title: t('company:documents.type'),
                dataIndex: 'nature',
                component: (
                    <Select
                        options={Object.values(CompanyDocumentType).map(
                            (x) => ({
                                label: x,
                                value: x,
                                key: x,
                            }),
                        )}
                    />
                ),
                required: true,
            },
            {
                title: t('company:documents.modal.isPrivate'),
                dataIndex: 'isPrivate',
                valuePropName: 'checked',
                component: <Checkbox />,
            },
        ],
        [t],
    );

    const onUpload = async (files: UploadFile<any>[]) => {
        setFiles(files);

        const values = form.getFieldsValue(true);
        const items = values.items ? values.items : [];

        const newItems: any[] = [];
        files.forEach((x) => {
            const item = items.find((y: any) => y.id === x.uid);
            if (item) return;

            const record = {
                id: x.uid,
                nature: 'AUDIT_REPORTS',
                file: {
                    assetName: x.name,
                    container: 'temporary',
                    contentType: x.type || '',
                    originalName: x.name,
                    autoResign: false,
                },
                comment: '',
            };

            newItems.push(record);
        });

        values.items = [...items, ...newItems];
    };

    const reset = () => {
        setOpen(false);
        setFiles([]);
        form.resetFields();
    };

    return (
        <>
            <Card>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey="id"
                    rowSelection={rowSelection}
                    scroll={{ x: 'max-content' }}
                    actions={
                        <CollapsibleDropdown
                            menu={{
                                items,
                            }}
                        ></CollapsibleDropdown>
                    }
                />
            </Card>

            <Modal
                title="Upload Document"
                open={open}
                width={1000}
                closable={false}
                okFn={async () => {
                    await form.validateFields();
                    const values = form.getFieldsValue(true);
                    await mutateAsync({
                        documents: values.items,
                        files,
                    });

                    reset();
                }}
                cancelFn={reset}
            >
                <UploadTable
                    form={form}
                    files={files}
                    setFiles={onUpload}
                    columns={uploadColumns}
                    scroll={{ x: 'max-content' }}
                />
            </Modal>

            <EditDocuments
                open={edit}
                toggle={toggleEdit}
                data={editingDocument}
            />
        </>
    );
};

export default DocumentsComponent;
