import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PartnerDetailLayout from './partner-detail.layout';

interface PartnerDetailComponentProps {}

const PartnerDetailComponent: React.FC<PartnerDetailComponentProps> = () => {
    const { t } = useTranslation();
    const [menu, setMenu] = useState<'details' | 'documents'>('details');

    const menuOptions = [
        { label: t('partner:detail.menu.details'), value: 'details' },
        { label: t('partner:detail.menu.documents'), value: 'documents' },
    ];

    return (
        <PartnerDetailLayout
            menu={menu}
            setMenu={setMenu}
            menuOptions={menuOptions}
        />
    );
};

export default PartnerDetailComponent;
