import { SupplyChainNodeType } from '../../domain/enums/supplier-node.enum';

/**
 * @function getProcessValue
 * @param value {SupplierChainNodeType} this is value we submit to translate
 * @returns {string}
 */

function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export const getProcessValue = (value: SupplyChainNodeType | string) => {
    switch (value) {
        case SupplyChainNodeType.AGENT:
            return 'Agent';
        case SupplyChainNodeType.AGRICULTURE:
            return 'Agriculture';
        case SupplyChainNodeType.ALUMINIUM_EXTRUSION:
            return 'Aluminium Extrusion';
        case SupplyChainNodeType.ASSEMBLY:
            return 'Assembly';
        case SupplyChainNodeType.BEAM_HOUSE:
            return 'Beam House';
        case SupplyChainNodeType.BRAND:
            return 'Brand';
        case SupplyChainNodeType.BUTTON_SUPPLIER:
            return 'Button Supplier';
        case SupplyChainNodeType.CARE_LABEL_SUPPLIER:
            return 'Care Label Supplier';
        case SupplyChainNodeType.CASTING:
            return 'Casting';
        case SupplyChainNodeType.CELLULOSE_SUPPLIER:
            return 'Cellulose Supplier';
        case SupplyChainNodeType.CHEMICAL_SUPPLIER_FABRIC_PROCESSING:
            return 'Chemical Supplier Fabric Processing';
        case SupplyChainNodeType.CHEMICAL_SUPPLIER_RAW_MATERIAL_PROCESSING:
            return 'Chemical Supplier Raw Material Processing';
        case SupplyChainNodeType.CHEMICAL_SUPPLIER_RECYCLER:
            return 'Chemical Supplier Recycler';
        case SupplyChainNodeType.CHEMICAL_SUPPLIER_YARN_PROCESSING:
            return 'Chemical Supplier Yarn Processing';
        case SupplyChainNodeType.COMBING_MILL:
            return 'Combing Mill';
        case SupplyChainNodeType.CRUSTING_TANNERY:
            return 'Crusting Tannery';
        case SupplyChainNodeType.CUSHION_SUPPLIER:
            return 'Cushion Supplier';
        case SupplyChainNodeType.CUT_MAKE_TRIM:
            return 'Cut Make Trim';
        case SupplyChainNodeType.DISTRIBUTOR:
            return 'Distributor';
        case SupplyChainNodeType.ELASTIC_RIBBON_SUPPLIER:
            return 'Elastic Ribbon Supplier';
        case SupplyChainNodeType.ELASTICS_AND_RIBBON_SUPPLIER:
            return 'Elastics and Ribbon Supplier';
        case SupplyChainNodeType.EMBROIDERER:
            return 'Embroiderer';
        case SupplyChainNodeType.FABRIC_COATING:
            return 'Fabric Coating';
        case SupplyChainNodeType.FABRIC_DYEING:
            return 'Fabric Dyeing';
        case SupplyChainNodeType.FABRIC_FINISHING:
            return 'Fabric Finishing';
        case SupplyChainNodeType.FABRIC_MILL:
            return 'Fabric Mill';
        case SupplyChainNodeType.FABRIC_PRINTING:
            return 'Fabric Printing';
        case SupplyChainNodeType.FABRIC_TRADING:
            return 'Fabric Trading';
        case SupplyChainNodeType.FARM:
            return 'Farm';
        case SupplyChainNodeType.FIBER_PRODUCER:
            return 'Fibre Producer';
        case SupplyChainNodeType.FILAMENT_PRODUCTION:
            return 'Filament Production';
        case SupplyChainNodeType.FINISHED_GOODS_FACTORY:
            return 'Finished Goods Factory';
        case SupplyChainNodeType.FINISHED_GOODS_TRADER:
            return 'Finished Goods Trader';
        case SupplyChainNodeType.FOAM_SUPPLIER:
            return 'Foam Supplier';
        case SupplyChainNodeType.FULFILLMENT_CENTER:
            return 'Fulfillment Center';
        case SupplyChainNodeType.GARMENT_CHEMCIAL_SUPPLIER:
            return 'Garment Chemical Supplier';
        case SupplyChainNodeType.GARMENT_FINISHING:
            return 'Garment Finishing';
        case SupplyChainNodeType.GARMENT_PRINTER:
            return 'Garment Printer';
        case SupplyChainNodeType.GINNIMG_MILL:
            return 'Ginning Mill';
        case SupplyChainNodeType.HANGTAG_SUPPLIER:
            return 'Hang Tag Supplier';
        case SupplyChainNodeType.INJECTION_MOLD_FACTORY:
            return 'Injection Mold Factory';
        case SupplyChainNodeType.KNITTING_MILL:
            return 'Knitting Mill';
        case SupplyChainNodeType.LABEL_SUPPLIER:
            return 'Label Supplier';
        case SupplyChainNodeType.LASER_PROCESSING:
            return 'Laser Processing';
        case SupplyChainNodeType.LACES_SUPPLIER:
            return 'Laces Supplier';
        case SupplyChainNodeType.LAUNDRY:
            return 'Laundry';
        case SupplyChainNodeType.LEATHER_CHEMICAL_SUPPLIER:
            return 'Leather Chemical Supplier';
        case SupplyChainNodeType.LEATHER_FINISHIHG:
            return 'Leather Finishing';
        case SupplyChainNodeType.LEATHER_TANNER:
            return 'Leather Tanner';
        case SupplyChainNodeType.LEATHER_TRADER:
            return 'Leather Trader';
        case SupplyChainNodeType.LINING_SUPPLIER:
            return 'Lining Supplier';
        case SupplyChainNodeType.MATERIAL_CALENDERING:
            return 'Material Calendering';
        case SupplyChainNodeType.METAL_GOODS_FACTORY:
            return 'Metal Goods Factory';
        case SupplyChainNodeType.MINE:
            return 'Mine';
        case SupplyChainNodeType.OTHERS:
            return 'Others';
        case SupplyChainNodeType.PACKAGING_MATERIAL_SUPPLIER:
            return 'Packaging Material Supplier';
        case SupplyChainNodeType.PACKAGING_SUPPLIER:
            return 'Packaging Supplier';
        case SupplyChainNodeType.PATTERN_MAKING:
            return 'Pattern Making';
        case SupplyChainNodeType.PATCH_SUPPLIER:
            return 'Patch Supplier';
        case SupplyChainNodeType.PLATING:
            return 'Plating';
        case SupplyChainNodeType.POCKET_SUPPLIER:
            return 'Pocket Supplier';
        case SupplyChainNodeType.POLISHING:
            return 'Polishing';
        case SupplyChainNodeType.POLYMER_REACTION:
            return 'Polymer Reaction';
        case SupplyChainNodeType.POLYMER_SOURCE:
            return 'Polymer Source';
        case SupplyChainNodeType.POLYMERIZATION:
            return 'Polymerization';
        case SupplyChainNodeType.PRINTER:
            return 'Printer';
        case SupplyChainNodeType.QUALITY_ASSURANCE:
            return 'Quality Assurance';
        case SupplyChainNodeType.TANNERY:
            return 'Tannery';
        case SupplyChainNodeType.RANCH:
            return 'Ranch';
        case SupplyChainNodeType.RAW_MATERIAL_PRODUCER:
            return 'Raw Material Producer';
        case SupplyChainNodeType.RAW_MATERIAL_PROCESSING:
            return 'Raw Material Processing';
        case SupplyChainNodeType.RAW_MATERIAL_SUPPLIER:
            return 'Raw Material Supplier';
        case SupplyChainNodeType.RAW_MATERIAL_TRADING:
            return 'Raw Material Trading';
        case SupplyChainNodeType.RECYCLING_FACILITY:
            return 'Recycling Facility';
        case SupplyChainNodeType.REFINERY:
            return 'Refinery';
        case SupplyChainNodeType.RETAILER:
            return 'Retailer';
        case SupplyChainNodeType.RIVET_SUPPLIER:
            return 'Rivet Supplier';
        case SupplyChainNodeType.SEWING:
            return 'Sewing';
        case SupplyChainNodeType.SLAUTHER_HOUSE:
            return 'Slaughter House';
        case SupplyChainNodeType.SOLE_SUPPLIER:
            return 'Sole Supplier';
        case SupplyChainNodeType.SOURCING_AGENCY:
            return 'Sourcing Agency';
        case SupplyChainNodeType.SPINNING_MILL:
            return 'Spinning Mill';
        case SupplyChainNodeType.THREAD_SUPPLIER:
            return 'Thread Supplier';
        case SupplyChainNodeType.TOP_MAKING:
            return 'Top Making';
        case SupplyChainNodeType.WEAVING_MILL:
            return 'Weaving Mill';
        case SupplyChainNodeType.WET_BLUE_TANNERY:
            return 'Wet Blue Tannery';
        case SupplyChainNodeType.WET_WHITE_TANNERY:
            return 'Wet White Tannery';
        case SupplyChainNodeType.WHOLESALER:
            return 'Wholesaler';
        case SupplyChainNodeType.YARN_DYEING:
            return 'Yarn Dyeing';
        case SupplyChainNodeType.YARN_SPINNER:
            return 'Yarn Spinner';
        case SupplyChainNodeType.YARN_TRADING:
            return 'Yarn Trading';
        case SupplyChainNodeType.ZIPPER_SUPPLIER:
            return 'Zipper Supplier';
        case SupplyChainNodeType.ZIPPER_TRADING:
            return 'Zipper Trading';
        default:
            return toTitleCase(value);
    }
};
