import { Modal } from '@ianneo/ui-library';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import {
    PurchaseOrderLink,
    PurchaseOrderTrace,
    PurchaseOrderVersion,
} from '../../../../../domain/models/purchase.model';
import { SalesOrder } from '../../../../../domain/models/sales-order.model';
import { useBatchLinksBySO } from '../../../../../infrastructure/hooks/api/links/use-batch-links-by-so';
import { useDeleteLink } from '../../../../../infrastructure/hooks/api/links/use-delete-link';
import { useOrderBatch } from '../../../../../infrastructure/hooks/api/order/use-order-batch';
import { useDeletePurchase } from '../../../../../infrastructure/hooks/api/purchases/use-delete-purchase';
import { useAlertContext } from '../../../../../infrastructure/hooks/use-alert.hook';

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    data?: PurchaseOrderVersion;
    traces?: PurchaseOrderTrace[];
}

interface VirtualTrace extends Omit<PurchaseOrderTrace, 'salesOrder'> {
    salesOrder?: SalesOrder;
    links?: PurchaseOrderLink[];
}

export const PurchaseCascadeClearDialog = ({
    open,
    data,
    traces,
    setOpen,
}: Props) => {
    const client = useQueryClient();
    const { setAlert } = useAlertContext();
    const { mutateAsync: deletePurchase } = useDeletePurchase();
    const { mutateAsync: unlink } = useDeleteLink();
    const { data: salesOrders } = useOrderBatch([
        data?.owner?.id || '',
        ...(traces || []).map((x) => x.id || ''),
    ]);

    const { data: links } = useBatchLinksBySO(
        (salesOrders || []).map((x) => x.id || ''),
    );

    const delay = (duration: number) =>
        new Promise((resolve) => setTimeout(resolve, duration));

    const [test, setTest] = useState<Record<string, VirtualTrace[]>>({});

    useEffect(() => {
        const record: Record<string, VirtualTrace[]> = {};

        traces?.forEach((trace) => {
            if (!trace.tier) return;

            if (!record[trace.tier]) {
                record[trace.tier] = [];
            }

            const salesOrder = salesOrders?.find(
                (x) => x.purchaseOrderId === trace.id,
            );

            const link = (links as PurchaseOrderLink[][])?.find((x) =>
                x.some((y) => y.owner?.parentOrderReference === trace.id),
            );

            record[trace.tier].push({
                ...trace,
                salesOrder: salesOrder,
                links: link,
            });
        });

        setTest(record);
    }, [traces, data, salesOrders, links]);

    const resetLinksAsync = async () => {
        const entries = Object.entries(test || {});

        if (!data) return;

        for (const [, value] of entries.reverse()) {
            for (const trace of value) {
                if (!trace.salesOrder) continue;

                if (trace.links) {
                    const updatedLinks = trace.links
                        .filter(
                            (x) => x.owner?.parentOrderReference === trace.id,
                        )
                        .map((x) => x.owner?.id || '');

                    await unlink({
                        id: trace.salesOrder.id || '',
                        purchaseOrderIds: updatedLinks,
                        workspaceId: trace.workspaceId || '',
                        diversion: '1',
                    });
                }
            }
        }

        await delay(1000);

        const tierOneSalesOrder = salesOrders?.find(
            (x) => x.purchaseOrderId === data.owner?.id,
        );

        const tierOneLinks = (links as PurchaseOrderLink[][])?.find((x) =>
            x.some((y) => y.owner?.parentOrderReference === data.owner?.id),
        );

        await unlink({
            id: tierOneSalesOrder?.id || '',
            purchaseOrderIds: tierOneLinks?.map((x) => x.owner?.id || '') || [],
            workspaceId: data.owner?.workspace?.id || '',
            diversion: '1',
        });

        for (const [, value] of entries.reverse()) {
            for (const trace of value) {
                if (!trace.salesOrder) continue;

                await deletePurchase({
                    id: trace.id || '',
                    delegateId: trace.workspaceId || '',
                    diversion: '1',
                });

                await delay(500);
            }
        }

        setAlert({
            type: 'success',
            message: 'Cascade cleared successfully, please refresh the page',
            title: 'Clear Cascade',
        });

        client.invalidateQueries({
            queryKey: ['purchaseTrace'],
        });

        client.invalidateQueries({
            queryKey: ['purchase-reverse'],
        });

        reset();
    };

    const reset = () => {
        setOpen(false);
    };

    return (
        <Modal
            title="Clear Cascade"
            open={open}
            closable={false}
            okFn={resetLinksAsync}
            cancelFn={reset}
        >
            <p>Are you sure you want to clear the cascade?</p>
            <p>*This will delete all related POs and PO Items.</p>
        </Modal>
    );
};
