import {
    CheckOutlined,
    DeleteOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import { Link, Popover, Text, Upload, UploadFile } from '@ianneo/ui-library';
import axios from 'axios';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportDocType } from '../../../domain/enums/support-doctype.enum';
import { PurchaseOrderVersion } from '../../../domain/models/purchase.model';
import { SalesOrderDocument } from '../../../domain/models/sales-order.model';
import { Attachment } from '../../../domain/types/attachment.type';
import { useDownloadFile } from '../../../infrastructure/hooks/api/file/use-download-file';
import { useAttachOrder } from '../../../infrastructure/hooks/api/order/use-order-attach';
import { useUploadPurchaseFiles } from '../../../infrastructure/hooks/api/purchases/use-upload-purchase-files';
import { getDocumentValue } from '../../../infrastructure/utils/document-type-labeler';
import { PurchaseDocumentItem } from './purchase-documents.component';

interface IPurchaseSupportDocsProps {
    purchaseOrder: PurchaseOrderVersion;
    type: SupportDocType;
    item: PurchaseDocumentItem;
}

export const PurchaseSupportDocs: React.FC<IPurchaseSupportDocsProps> = ({
    type,
    item,
    purchaseOrder,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState<UploadFile[]>([]);

    const { mutateAsync: upload } = useUploadPurchaseFiles();
    const { mutateAsync: attach } = useAttachOrder();
    const { mutateAsync } = useDownloadFile();

    const rules = useMemo(() => {
        const consolidatedRules: SupportDocType[] = [];

        item.rules.forEach((rule) => {
            rule.documentation?.forEach((documentation) => {
                if (!documentation.document) return;

                const isRequired = item.purchaseProcesses?.some((z) =>
                    documentation.appliesTo?.includes(z),
                );

                if (!isRequired) return;

                consolidatedRules.push(documentation.document);
            });
        });

        return consolidatedRules;
    }, [item.rules, item.purchaseProcesses]);

    const documents = useMemo(() => {
        return item.documents.filter((x) => x.type === type);
    }, [item, type]);

    const handlePopoverChange = (visible: boolean) => {
        if (!visible) {
            setFiles([]);
        }
    };

    const submit = useCallback(async () => {
        const documents = _.cloneDeep(item.documents);
        const uploadRsp = await upload({ type, files });

        if (uploadRsp) {
            documents.push(...uploadRsp);
        }

        setLoading(true);
        await attach({
            id: Number(item.id),
            documents: documents,
            poId: purchaseOrder?.owner?.id || '',
        });
        setLoading(false);

        setFiles([]);
    }, [attach, files, item, purchaseOrder, type, upload]);

    const remove = useCallback(
        async (file: SalesOrderDocument) => {
            const documents = _.cloneDeep(item.documents);

            const index = documents.findIndex((x) => x.id === file.id);
            if (index === -1) return;

            documents.splice(index, 1);

            await attach({
                id: Number(item.id),
                documents: documents,
                poId: purchaseOrder?.owner?.id || '',
            });
        },
        [attach, item, purchaseOrder],
    );

    const clearFile = useCallback(
        (item: UploadFile) => {
            const newFiles = files.filter((x) => x.uid !== item.uid);

            setFiles(newFiles);
        },
        [files],
    );

    const download = useCallback(
        async (file: Attachment) => {
            const response = await mutateAsync({ attachment: file });
            if (!response?.token) return;

            const fileBlob = await axios.get(response.token, {
                responseType: 'blob',
            });

            saveAs(
                fileBlob.data,
                `Tier ${item.tier + 1} - ${file.originalName}`,
            );
        },
        [item, mutateAsync],
    );

    const renderPopoverContent = useCallback(() => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '12px',
                        gap: '8px',
                    }}
                >
                    {documents.map((x) => {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    maxWidth: '350px',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '5px',
                                }}
                            >
                                <Text
                                    style={{
                                        flex: 0.9,
                                        minWidth: 0,
                                        overflowWrap: 'break-word',
                                    }}
                                >
                                    {x.file.originalName}
                                    <CheckOutlined
                                        style={{ color: '#09B142' }}
                                    />
                                </Text>

                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '5px',
                                        flex: 0.1,
                                    }}
                                >
                                    <DownloadOutlined
                                        style={{ color: '#1890ff' }}
                                        onClick={() => download(x.file)}
                                    />

                                    <DeleteOutlined
                                        style={{ color: '#CC2424' }}
                                        onClick={() => remove(x)}
                                    />
                                </div>
                            </div>
                        );
                    })}

                    {files.map((x) => {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    maxWidth: '350px',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: '5px',
                                }}
                            >
                                <Text
                                    style={{
                                        flex: 0.9,
                                        minWidth: 0,
                                        overflowWrap: 'break-word',
                                    }}
                                >
                                    {x.name}
                                </Text>

                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '5px',
                                        flex: 0.1,
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <DeleteOutlined
                                        style={{ color: '#CC2424' }}
                                        onClick={() => clearFile(x)}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '12px',
                    }}
                >
                    <Upload
                        showUploadList={false}
                        beforeUpload={() => false}
                        files={files}
                        accept="*"
                        setFiles={setFiles}
                        multiple
                    >
                        <Link>{t('purchase:detail.documents.uploadMore')}</Link>
                    </Upload>

                    <Link onClick={submit}>
                        {t('purchase:detail.documents.finish')}
                    </Link>
                </div>
            </div>
        );
    }, [files, setFiles, t, documents, submit, clearFile, remove, download]);

    return (
        <>
            <>
                <Popover
                    key={loading ? '1' : '2'}
                    title={getDocumentValue(type)}
                    trigger={['click']}
                    onOpenChange={handlePopoverChange}
                    content={renderPopoverContent()}
                    overlayClassName="purchase-document-popover-overlay"
                    arrow={false}
                >
                    {documents.length > 0 ? (
                        <Link style={{ color: '#4CA803D9' }}>
                            {t('purchase:detail.documents.uploaded')}
                        </Link>
                    ) : rules.includes(type) ? (
                        <Link style={{ color: 'red' }}>
                            {t('common:upload')}
                        </Link>
                    ) : (
                        <Link style={{ color: 'grey' }}>
                            {t('common:upload')}
                        </Link>
                    )}
                </Popover>
            </>
        </>
    );
};
