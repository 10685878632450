import { useQuery } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useMaterialService } from './use-material-service';
import { i18n } from '../../../../domain/models/i18n.model';

export interface MaterialCategory {
    MAN_MADE_CELLULOSICS: i18n;
    NATURAL_FABRIC: i18n;
    SYNTHETIC_FABRIC: i18n;
    METAL: i18n;
}

export function useMaterialCategories() {
    const context = useAppContext();
    const { service } = useMaterialService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: ['materialCategories', { workspace: context.workspace?.id }],
        queryFn: async () => {
            if (!context.workspace?.id) return;
            return (await service.listCategories()) as MaterialCategory;
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch material list: ${error.message}`,
            });
        },
    });
}
