import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SalesOrder } from '../../../domain/models/sales-order.model';
import { useOrders } from '../../../infrastructure/hooks/api/order/use-orders';
import { Documents } from './documents';
import OrderLink from './order-link';
import OrderShipments from './order-shipments';
import OrdersLayout from './orders.layout';

interface OrdersComponentProps {}

const OrdersComponent: React.FC<OrdersComponentProps> = () => {
    const { id } = useParams();
    const [open, setOpen] = useState(false);
    const [linkOpen, setLinkOpen] = useState(false);
    const [shipmentOpen, setShipmentOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<SalesOrder>({});
    const [openDoc, setOpenDoc] = useState(false);

    const { data } = useOrders();
    // const { status: openDoc, toggle: toggleDoc } = useToggle();

    useEffect(() => {
        const order = data?.find((o) => o.id?.toString() === id?.toString());

        if (order) {
            setSelectedOrder(order);
            setOpenDoc(true);
        }
    }, [data, id]);

    return (
        <>
            <OrdersLayout open={open} setOpen={setOpen} />

            {linkOpen ? (
                <OrderLink
                    open={linkOpen}
                    setOpen={setLinkOpen}
                    order={selectedOrder}
                />
            ) : null}

            {openDoc && (
                <Documents
                    open={openDoc}
                    order={selectedOrder}
                    toggle={() => setOpenDoc((prev) => !prev)}
                />
            )}

            {shipmentOpen && (
                <OrderShipments
                    open={shipmentOpen}
                    order={selectedOrder}
                    setOpen={setShipmentOpen}
                />
            )}
        </>
    );
};

export default OrdersComponent;
