import { StepModal, useForm } from '@ianneo/ui-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Solicitation } from '../../../../domain/models/solicitation.model';
import { usePartnerConnect } from '../../../../infrastructure/hooks/api/partner/use-partner-create';
import { usePartnerInvite } from '../../../../infrastructure/hooks/api/partner/use-partner-invite';
import { useWorkspaceByCompanyName } from '../../../../infrastructure/hooks/api/workspaces/use-workspace-by-company-name';
import { useWorkspaceService } from '../../../../infrastructure/hooks/api/workspaces/use-workspace-service';
import useAppContext from '../../../../infrastructure/hooks/use-context.hook';
import { useGetDelegations } from '../../../_api/delegations/hooks/useGetDelegations';
import { DelegationComponent } from './delegations.component';
import { PartnerConfirmationComponent } from './partner-confirmation.component';
import {
    PartnerSelectionComponent,
    useDebounce,
} from './partner-selection.component';

interface AddPartnerComponentProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const AddPartnerComponent: React.FC<AddPartnerComponentProps> = (args) => {
    const context = useAppContext();
    const { open } = args;
    const { t } = useTranslation();
    const [form] = useForm();
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 500);
    const [onBehalfOf, setOnBehalfOf] = useState(0);
    const { delegations } = useGetDelegations();

    const { data: workspaces } = useWorkspaceByCompanyName(
        form.getFieldValue('onBehalfOf')
            ? form.getFieldValue('onBehalfOf')
            : context.workspace?.id,
        debouncedSearch,
    );

    const { mutateAsync: invite } = usePartnerInvite();
    const { mutateAsync: connect } = usePartnerConnect();

    const { service } = useWorkspaceService();

    const submitFn = async () => {
        const values = await form.getFieldsValue(true);

        const partner = workspaces?.find((x) => x.company?.name === search);

        if (!partner && !values.companyName) {
            toast.error('Please fill up required information');
            return;
        }

        try {
            const isInviteMode = values.companyName;

            if (isInviteMode) {
                const params: Solicitation = {
                    workspaceId: onBehalfOf
                        ? delegations.find((x) => x.id === onBehalfOf)
                              ?.workspace?.id || ''
                        : context.workspace?.id,
                    company: values.companyName,
                    contact: values.items,
                    registrationNumber: values.registrationNumber,
                    country: values.country,
                    isNominated: false,
                    delegate: [
                        {
                            delegate: context.workspace?.id,
                        },
                    ],
                };

                await invite({ solicitation: { ...params } });
                args.setOpen(false);
            } else {
                const delegatedWorkspace = onBehalfOf
                    ? await service.get(
                          delegations.find((x) => x.id === onBehalfOf)
                              ?.workspace?.id || '',
                      )
                    : context.workspace;

                const params = {
                    partnerWorkspace: partner, // Compnay D
                    supplierWorkspace: delegatedWorkspace, // Company C
                    delegateWorkspace: context!.workspace, // Company B
                };

                await connect(params);
                args.setOpen(false);
            }

            toast.success('Successfully added partner');
        } catch (err) {
            toast.error('Failed to add partner');
        }
    };

    const cancelFn = () => {
        args.setOpen(false);
    };

    return (
        <>
            <StepModal
                open={open}
                title={t('partner:addTitle')}
                width="60vw"
                style={{
                    height: '60vh',
                }}
                okFn={submitFn}
                cancelFn={cancelFn}
                form={form}
                closable={false}
                stepContent={[
                    {
                        title: 'On Behalf Of.',
                        content: (
                            <DelegationComponent
                                form={form}
                                onBehalfOf={onBehalfOf}
                                setOnBehalfOf={setOnBehalfOf}
                                isAddPartner
                            />
                        ),
                    },
                    {
                        title: 'Partner Selection',
                        content: (
                            <PartnerSelectionComponent
                                form={form}
                                search={search}
                                onBehalfOf={onBehalfOf}
                                setSearch={setSearch}
                            />
                        ),
                    },
                    {
                        title: 'Confirmation',
                        content: (
                            <PartnerConfirmationComponent
                                search={search}
                                form={form}
                                onBehalfOf={onBehalfOf}
                            />
                        ),
                    },
                ]}
            ></StepModal>
        </>
    );
};

export { AddPartnerComponent };
