import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { ProductMaterial } from '../../domain/models/material.model';
import { UpdateProductMaterial } from '../hooks/api/materials/use-update-material';
import { ApiServer } from './api.server';

export class MaterialApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'products', 'materials');
    }

    listBase() {
        console.log(`client<material>| list(): Enter`);
        return super.get('listBase');
    }

    list(workspace: string) {
        console.log(`client<material>| list(): Enter`);
        console.log(`client<material>| list(): $workspace = ${workspace}`);
        return super.get('list', workspace);
    }

    create(workspace: string, material: ProductMaterial) {
        console.log(`client<material>| create(): Enter`);
        console.log(`client<material>| create(): $workspace = ${workspace}`);
        console.log(
            `client<material>| create(): $document = ${JSON.stringify(
                material,
            )}`,
        );

        return super.post('create', workspace, material);
    }

    get(workspace: string, item: string) {
        console.log(`client<material>| create(): Enter`);
        console.log(`client<material>| create(): $workspace = ${workspace}`);
        console.log(`client<material>| create(): $item = ${item}`);

        return super.get('get', workspace, item);
    }

    update(workspace: string, item: string, material: UpdateProductMaterial) {
        console.log(`client<material>| update(): Enter`);
        console.log(`client<material>| update(): $workspace = ${workspace}`);
        console.log(
            `client<material>| update(): $document = ${JSON.stringify(
                material,
            )}`,
        );

        return super.patch('update', workspace, item, material);
    }

    search(filters: any) {
        console.log(`client<material>| search(): Enter`);
        console.log(
            `client<material>| search(): $filters = ${JSON.stringify(filters)}`,
        );

        return super.post('search', filters);
    }

    delete(workspaceId: string, id: string) {
        console.log(`client<material>| delete(): Enter`);
        console.log(
            `client<material>| delete(): $workspaceId = ${workspaceId}`,
        );
        console.log(`client<material>| delete(): $id = ${id}`);

        return super.delete('delete', workspaceId, id);
    }

    listCategories() {
        console.log(`client<material>| listCategories(): Enter`);

        return super.get('listCategories');
    }

    getCategory(id: string) {
        console.log(`client<material>| getCategory(): Enter`);
        console.log(`client<material>| getCategory(): $id = ${id}`);

        return super.get('getCategory', id);
    }
}
