import {
    Form,
    FormItem,
    Input,
    Modal,
    Select,
    StepModal,
    useForm,
} from '@ianneo/ui-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateNominatedList } from '../../../infrastructure/hooks/api/nominated-list/use-create-nominated-list';
import { usePartners } from '../../../infrastructure/hooks/api/partner/use-partners';
import { DelegationComponent } from '../Partners/add-partner/delegations.component';
import { useGetDelegations } from '../../_api/delegations/hooks/useGetDelegations';

interface AddNominatedListProps {
    open: boolean;
    toggle: () => void;
}

export enum NominatedListType {
    SUPPLIER = 'supplier',
    ASSIGNED = 'assigned',
}

export const AddNominatedList = ({ open, toggle }: AddNominatedListProps) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const [suppliers, setSuppliers] = useState<string[]>([]);
    const [assigned, setAssigned] = useState<string[]>([]);
    const { delegations } = useGetDelegations();
    const [onBehalfOf, setOnBehalfOf] = useState(0);

    const { data: partners } = usePartners(
        delegations?.find((x) => x.id === onBehalfOf)?.workspace?.id,
    );
    const { mutateAsync: create } = useCreateNominatedList();

    const reset = () => {
        toggle();
        form.resetFields();
        setSuppliers([]);
        setAssigned([]);
    };

    const submit = async () => {
        try {
            await form.validateFields();
            const request = form.getFieldsValue(true);

            await create({
                args: request,
                workspaceId: delegations.find((x) => x.id === onBehalfOf)
                    ?.workspace?.id,
            });

            reset();
        } catch (err) {
            return;
        }
    };

    const handleOnChange = (type: NominatedListType, value: string[]) => {
        if (type === NominatedListType.SUPPLIER) {
            setSuppliers(value);
        } else {
            setAssigned(value);
        }
    };

    return (
        <>
            <StepModal
                open={open}
                title={t('nominatedList:modal.add.title')}
                cancelFn={reset}
                okFn={submit}
                style={{
                    height: '50vh',
                }}
                width={800}
                closable={false}
                stepContent={[
                    {
                        title: 'On Behalf Of.',
                        content: (
                            <DelegationComponent
                                form={form}
                                onBehalfOf={onBehalfOf}
                                setOnBehalfOf={setOnBehalfOf}
                            />
                        ),
                    },
                    {
                        title: 'Nominated List',
                        content: (
                            <Form form={form}>
                                <FormItem
                                    label={t('nominatedList:modal.add.name')}
                                    name="name"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please input a name for the nominated list',
                                        },
                                    ]}
                                >
                                    <Input />
                                </FormItem>

                                <FormItem
                                    label={t(
                                        'nominatedList:modal.add.suppliers',
                                    )}
                                    name="suppliers"
                                >
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        showArrow
                                        options={partners
                                            ?.map((x) => ({
                                                key: x.id,
                                                value: x.id,
                                                label: x.seller?.name,
                                            }))
                                            .filter(
                                                (x) =>
                                                    !assigned.includes(
                                                        x.value || '',
                                                    ),
                                            )}
                                        onChange={(e) =>
                                            handleOnChange(
                                                NominatedListType.SUPPLIER,
                                                e,
                                            )
                                        }
                                    />
                                </FormItem>

                                <FormItem
                                    label={t(
                                        'nominatedList:modal.add.assigned',
                                    )}
                                    name="assigned"
                                >
                                    <Select
                                        options={partners
                                            ?.map((x) => ({
                                                key: x.id,
                                                value: x.id,
                                                label: x.seller?.name,
                                            }))
                                            .filter(
                                                (x) =>
                                                    !suppliers.includes(
                                                        x.value || '',
                                                    ),
                                            )}
                                        mode="tags"
                                        onChange={(e) =>
                                            handleOnChange(
                                                NominatedListType.ASSIGNED,
                                                e,
                                            )
                                        }
                                    />
                                </FormItem>
                            </Form>
                        ),
                    },
                ]}
            ></StepModal>
            <Modal
                // open={open}
                title={t('nominatedList:modal.add.title')}
                cancelFn={reset}
                okFn={submit}
            >
                <Form form={form}></Form>
            </Modal>
        </>
    );
};
