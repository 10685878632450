import RulesetsLayout from './rulesets.layout';

interface RulesetsComponentProps {}

const RulesetsComponent: React.FC<RulesetsComponentProps> = () => {
    return (
        <>
            <RulesetsLayout />
        </>
    );
};

export default RulesetsComponent;
