import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useAwardService } from './use-award-service';
import { Award } from '../../../../domain/models/award.modal';

export interface UpdateAwardInterface {
    id: string;
    params: Award;
}

export function useUpdateAward() {
    const context = useAppContext();
    const { service } = useAwardService();
    const { setAlert } = useAlertContext();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, params }: UpdateAwardInterface) => {
            if (!id || !context.workspace?.id) return;

            await service.update(context.workspace.id, id, params);
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: ['companyAwards'],
            });

            setAlert({
                type: 'success',
                message: 'Award updated successfully',
                title: 'Update Award',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to update award: ${error.message}`,
                title: 'Update Award',
            });
        },
    });
}
