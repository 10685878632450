import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Ruleset } from '../../../../domain/models/ruleset.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useRulesetService } from './use-ruleset-service';

export interface CreateRulesetParams {
    ruleset: Ruleset;
    workspaceId?: string;
}

export function useCreateRuleset() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useRulesetService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ workspaceId, ruleset }: CreateRulesetParams) => {
            if (!context.workspace?.id) return;
            await service.create(workspaceId || context.workspace.id, ruleset);
        },
        onSuccess: (_, { workspaceId }) => {
            client.invalidateQueries({
                queryKey: [
                    'rulesets',
                    { workspace: workspaceId || context.workspace?.id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Successfully created ruleset',
                title: 'Create Ruleset',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to create ruleset: ${error.message}`,
                title: 'Create Ruleset',
            });
        },
    });
}
