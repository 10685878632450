import {
    DatePicker,
    Form,
    FormItem,
    Input,
    InputNumber,
    Link,
    Select,
    StepModal,
    useForm,
} from '@ianneo/ui-library';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Company } from '../../../../domain/models/company.model';
import { CompanyService } from '../../../../domain/services/company.service';
import { useAlertContext } from '../../../../infrastructure/hooks/use-alert.hook';
import useAppConfig from '../../../../infrastructure/hooks/use-config.hook';
import useAppContext from '../../../../infrastructure/hooks/use-context.hook';
import OnboardingContact from '../../../components/Onboarding/onboarding-contact/onboarding-contact';
import { SupplyChainNodeType } from '../../../../domain/enums/supplier-node.enum';
import { getProcessValue } from '../../../../infrastructure/utils/process-labeler';

interface EditCompanyProps {
    open: boolean;
    company: Company;
    setOpen: (value: boolean) => void;
    setCompany: (value: Company) => void;
}

export default function EditCompany(props: EditCompanyProps) {
    const { open, company, setOpen, setCompany } = props;
    const context = useAppContext();
    const config = useAppConfig();
    const { t } = useTranslation();
    const [form] = useForm();
    const service = new CompanyService(config, context?.token || '');
    const { setAlert } = useAlertContext();

    const [contactField, setContactField] = useState('');
    const [openContact, setOpenContact] = useState(false);

    const defaultRules = [
        { required: true, message: t('material:modal.create.errorMsg') },
    ];

    const openContactModal = (field: string) => {
        setOpenContact(true);
        setContactField(field);
    };

    const reset = () => {
        setOpen(false);
        form.resetFields();
    };

    const submit = async () => {
        const values = form.getFieldsValue(true);

        await onDetailUpdated({
            ...company,
            ...values,
        });

        reset();
    };

    const onDetailUpdated = async (request: Company) => {
        console.log(`page<company.container>| onDetailUpdated(): Enter`);
        console.log(
            `page<company.container>| onDetailUpdated(): $request = ${JSON.stringify(
                request,
            )}`,
        );

        try {
            if (context.token && context.workspace) {
                const delay = (duration: number) =>
                    new Promise((resolve) => setTimeout(resolve, duration));

                const response = await service.update(
                    context.workspace.id || '',
                    request,
                );

                context!.workspace.company = response;
                setCompany(response);

                console.log(
                    `page<company.container>| onDetailUpdate(): $response = ${JSON.stringify(
                        response,
                    )}`,
                );

                await delay(200);
            }
        } catch (ex) {
            setAlert({
                type: 'error',
                title: 'Failed to update company details',
                message: (ex as Error).message,
                timeout: 3000,
            });
        }
    };

    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                name: company.name,
                complianceLevel: company.complianceLevel,
                complianceRating: company.complianceRating,
                complianceExpiresOn: company.complianceExpiresOn
                    ? moment(company.complianceExpiresOn)
                    : null,
                addressLine1: company.addressLine1,
                phone: company.phone,
                city: company.city,
                website: company.website,
                gln: company.gln,
                higg: company.higg,
                duns: company.duns,
                oar: company.oar,
                vat: company.vat,
                capacity: company.capacity,
                dbaName: company.dbaName,
                employees: company.employees,
                contactBusiness: company.contactBusiness,
                contactCompliance: company.contactCompliance,
                zdhcId: company.zdhcId,
                supplyChainNodeType: company.supplyChainNodeType,
            });
        }
    }, [open, company, form]);

    return (
        <>
            <StepModal
                title={t('company:modal.edit.title')}
                open={open}
                okFn={submit}
                cancelFn={reset}
                width={1000}
                stepContent={[
                    {
                        title: t('common:steps.required'),
                        content: (
                            <>
                                <Form form={form}>
                                    <FormItem
                                        label={t('company:details.entityName')}
                                        name="name"
                                        required
                                        rules={defaultRules}
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t('company:details.address')}
                                        name="addressLine1"
                                        required
                                        rules={defaultRules}
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t('company:details.phoneNumber')}
                                        name="phone"
                                        required
                                        rules={[
                                            ...defaultRules,
                                            {
                                                pattern:
                                                    /^\+?[ 1-9][0-9]{7,14}$/,
                                                message:
                                                    'Please enter a valid phone number, include country code!',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label="Processes"
                                        name="supplyChainNodeType"
                                        required
                                        rules={defaultRules}
                                    >
                                        <Select
                                            mode="tags"
                                            placeholder="Select processes"
                                            options={Object.values(
                                                SupplyChainNodeType,
                                            ).map((x) => ({
                                                label: getProcessValue(x),
                                                value: x,
                                                key: x,
                                            }))}
                                        />
                                    </FormItem>
                                </Form>
                            </>
                        ),
                    },
                    {
                        title: t('common:steps.optional'),
                        content: (
                            <>
                                <Form form={form}>
                                    <FormItem
                                        label={t('company:details.higgId')}
                                        name="higg"
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t('company:details.companyUrl')}
                                        name="website"
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t('company:details.capacity')}
                                        name="capacity"
                                        rules={[
                                            {
                                                pattern: /^\d*\.?\d*$/,
                                                message:
                                                    'Please input a positive number only.',
                                            },
                                        ]}
                                    >
                                        <InputNumber />
                                    </FormItem>

                                    <FormItem
                                        label={t('company:details.oarId')}
                                        name="oar"
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t(
                                            'company:details.contactBusiness',
                                        )}
                                    >
                                        <Link
                                            onClick={() =>
                                                openContactModal(
                                                    'contactCompliance',
                                                )
                                            }
                                        >
                                            {t('onboarding:contactCompliance')}
                                        </Link>
                                    </FormItem>

                                    <FormItem
                                        label={t(
                                            'company:details.contactCompliance',
                                        )}
                                    >
                                        <Link
                                            onClick={() =>
                                                openContactModal(
                                                    'contactBusiness',
                                                )
                                            }
                                        >
                                            {t('onboarding:contactBusiness')}
                                        </Link>
                                    </FormItem>

                                    <FormItem
                                        label={t('company:details.dbaName')}
                                        name="dbaName"
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t('company:details.employees')}
                                        name="employees"
                                        rules={[
                                            {
                                                pattern: /^\d*\.?\d*$/,
                                                message:
                                                    'Please input a positive number only.',
                                            },
                                        ]}
                                    >
                                        <InputNumber />
                                    </FormItem>

                                    <FormItem
                                        label={t('company:details.gln')}
                                        name="gln"
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t('company:details.city')}
                                        name="city"
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t('company:details.duns')}
                                        name="duns"
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t('company:details.vatId')}
                                        name="vat"
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t('company:details.zhdcId')}
                                        name="zdhcId"
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t(
                                            'company:details.complianceLevel',
                                        )}
                                        name="complianceLevel"
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t(
                                            'company:details.complianceRating',
                                        )}
                                        name="complianceRating"
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t(
                                            'company:details.complianceExpiresOn',
                                        )}
                                        name="complianceExpiresOn"
                                    >
                                        <DatePicker style={{ width: '100%' }} />
                                    </FormItem>
                                </Form>
                            </>
                        ),
                    },
                ]}
            ></StepModal>

            <OnboardingContact
                open={openContact}
                setOpen={setOpenContact}
                form={form}
                name={contactField}
            />
        </>
    );
}
