import { ClearOutlined } from '@ant-design/icons';
import { Descriptions } from '@ianneo/component-library';
import {
    Card,
    Drawer,
    ITableProps,
    RadioGroup,
    Table,
    Text,
    Tooltip,
} from '@ianneo/ui-library';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
    Background,
    Connection,
    ControlButton,
    Controls,
    Edge,
    Node,
    Position,
    ReactFlow,
    addEdge,
    useEdgesState,
    useNodesState,
} from 'reactflow';
import { OrderNatureType } from '../../../../../domain/enums/order-nature.enum';
import { SupportDocType } from '../../../../../domain/enums/support-doctype.enum';
import { CompanyDocument } from '../../../../../domain/models/company-doc.model';
import {
    PurchaseOrderItem,
    PurchaseOrderTrace,
    PurchaseOrderVersion,
} from '../../../../../domain/models/purchase.model';
import { SalesOrderDocument } from '../../../../../domain/models/sales-order.model';
import { useCompanyService } from '../../../../../infrastructure/hooks/api/company/use-company-service';
import { useOrder } from '../../../../../infrastructure/hooks/api/order/use-order';
import { usePurchase } from '../../../../../infrastructure/hooks/api/purchases/use-purchase';
import { usePurchaseTrace } from '../../../../../infrastructure/hooks/api/purchases/use-purchase-trace';
import useAppContext from '../../../../../infrastructure/hooks/use-context.hook';
import { getDocumentValue } from '../../../../../infrastructure/utils/document-type-labeler';
import { PurchaseCascadeClearDialog } from './purchase-cascade-clear.dialog';
import { PurchaseCascadeNode } from './purchase-cascade-node';
import { ReactFlowDownloader } from './react-flow-downloader';

interface PurchaseCascadeTraceComponentProps {}

export const getCustomOrderNature = (key: string) => {
    const mapping: { [key: string]: string } = {
        [OrderNatureType.COMPONENT]: 'Component',
        [OrderNatureType.OUTSOURCE]: 'Production - Outsourcing',
        [OrderNatureType.RAW_MATERIAL]: 'Raw Materials',
        [OrderNatureType.PROCESSING]: 'Production - In-house',
    };

    return mapping[key];
};

const PurchaseCascadeTraceComponent: React.FC<
    PurchaseCascadeTraceComponentProps
> = () => {
    const { t } = useTranslation();
    const { id, delegateId } = useParams();
    const [menu, setMenu] = useState('order');
    const menuOptions = [
        { label: 'Order', value: 'order' },
        { label: 'Materials', value: 'materials' },
        { label: 'Company', value: 'company' },
    ];
    const [preview, setPreview] = useState<
        PurchaseOrderVersion & {
            workspaceId?: string;
            supplierId?: string;
            externalDataId?: string;
        }
    >({});
    const context = useAppContext();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [open, setOpen] = useState(false);
    const [root, setRoot] = useState('');
    const [parent] = useState('0');
    const flowRef = useRef<any>();

    const [isDelete, setIsDelete] = useState(false);

    const { data: purchaseOrder } = usePurchase({ id, delegateId });
    const { data: traces } = usePurchaseTrace({ id, delegateId });
    const { data: previewInfo } = usePurchase({
        id: preview.id,
        customWorkspaceId: preview?.workspaceId,
        delegateId,
    });
    const { data: order } = useOrder({
        id: purchaseOrder?.owner?.id || '',
        enabled: !!purchaseOrder?.owner?.id,
        delegateId,
    });

    const { service: companyService } = useCompanyService();
    const [companyDocuments, setCompanyDocuments] = useState<
        Record<string, CompanyDocument[]>
    >({});

    const columns = useMemo(
        () => [
            {
                title: t('purchase:detail.overview.productName'),
                dataIndex: ['purchaseables', 'name'],
            },
            {
                title: t('purchase:detail.overview.comment'),
                dataIndex: 'comment',
            },
            {
                title: t('purchase:detail.overview.ppu'),
                render: (_: any, item: PurchaseOrderItem) => (
                    <Text>
                        {item.ppu} / {item.unit}
                    </Text>
                ),
            },
            {
                title: t('purchase:detail.overview.quantity'),
                dataIndex: 'quantity',
            },
            {
                title: t('purchase:detail.overview.totalPrice'),
                render: (_: any, item: PurchaseOrderItem) => (
                    <Text>
                        {calculatePrice(item?.ppu || 0, item?.quantity || 0)}
                    </Text>
                ),
            },
        ],
        [t],
    );

    const descriptions = [
        {
            label: t('purchase:detail.overview.supplier'),
            value: previewInfo?.owner?.supplier?.seller?.name,
            span: 1,
        },
        {
            label: t('purchase:detail.overview.buyer'),
            value: previewInfo?.owner?.supplier?.owner?.name,
        },
        {
            label: t('purchase:detail.overview.currency'),
            value: previewInfo?.owner?.currency,
        },
        {
            label: t('purchase:detail.overview.cascadedTiers'),
            value: 0,
        },
        {
            label: t('purchase:detail.overview.buyDate'),
            value: previewInfo?.createdOn
                ? new Date(previewInfo.createdOn).toLocaleString().slice(0, 10)
                : 'Pending',
        },
        {
            label: t('purchase:detail.overview.template'),
            value: previewInfo?.owner?.rules
                ?.map((rule) => rule.code)
                .join(','),
        },
    ];

    const onConnect = useCallback(
        (params: Edge | Connection) => setEdges((els) => addEdge(params, els)),
        [setEdges],
    );

    const nodeTypes = useMemo(
        () => ({ traceDetails: PurchaseCascadeNode }),
        [],
    );

    const onClose = () => {
        setOpen(false);
        setPreview({});
    };

    const calculatePrice = (pricePerUnit: number, quantity: number) => {
        return pricePerUnit * quantity;
    };

    const calculateTotal = () => {
        return previewInfo?.manifest?.reduce(
            (acc, item) =>
                acc + calculatePrice(item.ppu || 0, item.quantity || 0),
            0,
        );
    };

    const fetchPartnerDocuments = useCallback(
        async (partner: string, workspaceId?: string) => {
            console.log(`page<purchase-docs>.fetchPartnerDocuments(): Enter`);
            console.log(
                `page<purchase-docs>.fetchPartnerDocuments(): $partner = ${JSON.stringify(
                    partner,
                )}`,
            );
            console.log(
                `page<purchase-docs>.fetchPartnerDocuments(): $workspaceId = ${JSON.stringify(
                    workspaceId,
                )}`,
            );
            const response = (await companyService.solicitDocs(
                workspaceId ? workspaceId : context.workspace?.id || '',
                partner,
            )) as CompanyDocument[];
            console.log(
                `page<purchase-docs>.fetchPartnerDocuments(): $response = ${JSON.stringify(
                    response,
                )}`,
            );

            setCompanyDocuments((prev) => ({
                ...prev,
                [partner]: response,
            }));
        },
        [companyService, context.workspace?.id, setCompanyDocuments],
    );

    useEffect(() => {
        if (!preview?.supplierId) return;

        fetchPartnerDocuments(preview?.supplierId || '');
    }, [preview, fetchPartnerDocuments]);

    useEffect(() => {
        console.log(companyDocuments);
    }, [companyDocuments]);

    const companyDocColumns = useMemo(
        () => [
            {
                title: t('company:documents.docName'),
                dataIndex: 'file',
                render: (_: any, item: CompanyDocument) => (
                    <Text>{item?.file?.originalName}</Text>
                ),
            },
            { title: t('company:documents.type'), dataIndex: 'nature' },
            {
                title: t('company:documents.modal.isPrivate'),
                dataIndex: 'isPrivate',
                render: (_: any, item: CompanyDocument) => (
                    <Text>{item.isPrivate ? 'True' : 'False'}</Text>
                ),
            },
            {
                title: t('company:documents.verifiedOn'),
                dataIndex: 'verifiedOn',
            },
            {
                title: t('common:updatedOn'),
                dataIndex: 'lastUpdatedOn',
                render: (_: string, record: CompanyDocument) => {
                    return (
                        <Text>
                            {record.lastUpdatedOn
                                ? new Date(record.lastUpdatedOn)
                                      .toISOString()
                                      .slice(0, 10)
                                : ''}
                        </Text>
                    );
                },
            },
        ],
        [t],
    );

    const orderDocumentColumns = () => {
        const columns: ITableProps<SalesOrderDocument>['columns'] = [
            {
                title: t('purchase:cascade.name'),
                dataIndex: ['file', 'originalName'],
                width: '50%',
            },
            {
                title: t('purchase:cascade.type'),
                dataIndex: 'type',
                render: (_: unknown, item: SalesOrderDocument) => (
                    <>{getDocumentValue(item.type)}</>
                ),
                width: '25%',
            },
            {
                title: t('purchase:cascade.certNo'),
                dataIndex: 'certificateNumber',
                width: '25%',
            },
        ];

        if (menu !== 'materials') {
            columns.pop();
        }

        return columns;
    };

    useEffect(() => {
        const nodes: Node[] = [];
        const flows = {} as Record<string, PurchaseOrderTrace[]>;

        // Initial Group - Root
        nodes.push({
            id: `master-tier`,
            data: {
                label: (
                    <Text
                        style={{
                            display: 'flex',
                            width: '100%',
                            backgroundColor: '#808A90',
                            padding: '8px',
                            paddingLeft: ' 16px',
                            color: 'white',
                        }}
                    >
                        Tier 0
                    </Text>
                ),
            },
            className: 'light-haaha',
            position: { x: 0, y: 0 },
            style: {
                width: 400,
                height: purchaseOrder?.owner?.supplierProducer ? 380 : 280,
                padding: '0',
                background: '#F2F2F2',
            },
            draggable: false,
        });

        // Initial node - Root
        nodes.push({
            id: parent || '',
            type: 'traceDetails',
            parentNode: 'master-tier',
            width: 10,
            data: {
                master: true,
                data: purchaseOrder,
                setSelected: setPreview,
                setOpen,
                count: purchaseOrder?.manifest?.length,
                purchaseId: purchaseOrder?.owner?.id,
                workspaceId:
                    delegateId || purchaseOrder?.owner?.supplier?.owner?.id,
            },
            sourcePosition: Position.Right,
            position: { x: 20, y: 50 },
            draggable: false,
        });

        // Splitting the traces into different tiers
        traces
            ?.sort((a, b) => {
                return (a.path || [])?.length - (b.path || [])?.length;
            })
            ?.forEach((trace) => {
                const isExists =
                    (flows[trace.tier || 0] || []).findIndex(
                        (x) => x.id === trace.id,
                    ) !== -1;

                if (isExists) return;

                flows[trace.tier || 0] = [
                    ...(flows[trace.tier || 0] || []),
                    trace,
                ];
            });

        console.log('my flows', flows);

        if (context.workspace?.supplyChainLoaderType === 1) {
            Object.values(flows).forEach((flow) => {
                flow = flow.sort((a, b) => {
                    if (a.orderNature && b.orderNature) {
                        const order = [
                            'COMPONENT',
                            'OUTSOURCE',
                            'RAW_MATERIALS',
                            'PROCESSING',
                        ];

                        const indexA = order.indexOf(a.orderNature);
                        const indexB = order.indexOf(b.orderNature);

                        return indexA - indexB;
                    }

                    return 0;
                });
            });

            const maxIndex = Object.keys(flows).length - 1;

            Object.entries(flows).forEach(([tier, traces], index) => {
                if (index === maxIndex) {
                    let traceMap = {} as Record<string, PurchaseOrderTrace[]>;
                    traces.forEach((trace) => {
                        const key = trace.orderNature || 'OTHERS';
                        traceMap[key] = [...(traceMap[key] || []), trace];
                    });

                    let cumulativeY = 0;

                    Object.entries(traceMap).forEach(([key, map], index) => {
                        const currentHeight = map.length * 280;

                        nodes.push({
                            id: `tier-${tier}-${index}`,
                            data: {
                                label: (
                                    <Text
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            backgroundColor: '#808A90',
                                            padding: '8px',
                                            paddingLeft: ' 16px',
                                            color: 'white',
                                        }}
                                    >
                                        {getCustomOrderNature(
                                            key as OrderNatureType,
                                        )}
                                    </Text>
                                ),
                            },
                            className: 'light',
                            position: {
                                x: 1000,
                                y: cumulativeY,
                            },
                            style: {
                                width: 400,
                                height: map.length * 250,
                                padding: '0',
                                background: '#F2F2F2',
                            },
                        });

                        cumulativeY += (currentHeight || 0) + 60;
                    });

                    return;
                } else {
                    nodes.push({
                        id: `tier-${tier}`,
                        data: {
                            label: (
                                <Text
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        backgroundColor: '#808A90',
                                        padding: '8px',
                                        paddingLeft: ' 16px',
                                        color: 'white',
                                    }}
                                >
                                    Tier {tier}
                                </Text>
                            ),
                        },
                        className: 'light',
                        position: {
                            x: 500 + 500 * index,
                            y: 0,
                        },
                        // draggable: false,
                        style: {
                            width: 400,
                            height: traces.length * 240,
                            padding: '0',
                            background: '#F2F2F2',
                        },
                    });
                }
            });

            Object.entries(flows).forEach(([tier, traces], index) => {
                if (index === maxIndex) {
                    let traceMap = {} as Record<string, PurchaseOrderTrace[]>;
                    traces.forEach((trace) => {
                        const key = trace.orderNature || 'OTHERS';
                        traceMap[key] = [...(traceMap[key] || []), trace];
                    });

                    Object.entries(traceMap).forEach(([key, map], index) => {
                        console.log(map);

                        map.forEach((trx, trxIndex) => {
                            const path = trx.path || [];
                            const source = path[path.indexOf(trx.to || 0)];
                            const isExist =
                                nodes.findIndex(
                                    (node) => node.id === source?.toString(),
                                ) !== -1;
                            if (isExist) return;

                            nodes.push({
                                id: source.toString() || '',
                                data: {
                                    label: trx,
                                    setSelected: setPreview,
                                    setOpen: setOpen,
                                    purchaseId: trx.id,
                                    workspaceId: trx.workspaceId,
                                },
                                // draggable: false,
                                sourcePosition: Position.Right,
                                targetPosition: Position.Left,
                                parentNode: `tier-${tier}-${index}`,
                                type: 'traceDetails',
                                className: 'light',
                                extent: 'parent',
                                position: {
                                    x: 20,
                                    y: 50 + 200 * trxIndex,
                                },
                            });
                        });
                    });
                } else {
                    traces.forEach((trace, yIndex) => {
                        const path = trace.path || [];
                        const source = path[path.indexOf(trace.to || 0)];
                        const isExist =
                            nodes.findIndex(
                                (node) => node.id === source?.toString(),
                            ) !== -1;
                        if (isExist) return;
                        nodes.push({
                            id: source?.toString() || '',
                            data: {
                                label: trace,
                                setSelected: setPreview,
                                setOpen: setOpen,
                                purchaseId: trace.id,
                                workspaceId: trace.workspaceId,
                                isPoFactory: true,
                            },
                            draggable: false,
                            sourcePosition: Position.Right,
                            targetPosition: Position.Left,
                            parentNode: `tier-${tier}`,
                            type: 'traceDetails',
                            className: 'light',
                            extent: 'parent',
                            position: {
                                x: 20,
                                y: 50 + 200 * yIndex,
                            },
                        });
                    });
                }
            });
        } else {
            Object.entries(flows).forEach(([tier, traces], index) => {
                nodes.push({
                    id: `tier-${tier}`,
                    data: {
                        label: (
                            <Text
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    backgroundColor: '#808A90',
                                    padding: '8px',
                                    paddingLeft: ' 16px',
                                    color: 'white',
                                }}
                            >
                                Tier {tier}
                            </Text>
                        ),
                    },
                    className: 'light',
                    position: {
                        x: 500 + 500 * index,
                        y: 0,
                    },
                    // draggable: false,
                    style: {
                        width: 400,
                        height: traces.length * 280,
                        padding: '0',
                        background: '#F2F2F2',
                    },
                });
            });

            // // Creating the nodes for each tier
            Object.entries(flows).forEach(([tier, traces]) => {
                traces.forEach((trace, yIndex) => {
                    const path = trace.path || [];
                    const source = path[path.indexOf(trace.to || 0)];

                    // debugger;
                    const isExist =
                        nodes.findIndex(
                            (node) => node.id === source?.toString(),
                        ) !== -1;

                    if (isExist) return;

                    nodes.push({
                        id: source?.toString() || '',
                        data: {
                            label: trace,
                            setSelected: setPreview,
                            setOpen: setOpen,
                            purchaseId: trace.id,
                            workspaceId: trace.workspaceId,
                        },
                        // draggable: false,
                        sourcePosition: Position.Right,
                        targetPosition: Position.Left,
                        parentNode: `tier-${tier}`,
                        type: 'traceDetails',
                        className: 'light',
                        extent: 'parent',
                        position: {
                            x: 20,
                            y: 50 + 200 * yIndex,
                        },
                    });
                });
            });
        }

        // Adding the groups of nodes

        setRoot(parent);
        setNodes(nodes);
        console.log('my nodes', nodes);
    }, [
        purchaseOrder,
        traces,
        setNodes,
        setPreview,
        parent,
        context.workspace?.supplyChainLoaderType,
        delegateId,
    ]);

    useEffect(() => {
        const edges: Edge[] = [];

        // Add the edges
        traces?.forEach((item) => {
            const path = item.path || [];
            const source = path[path.indexOf(item.to || 0) - 1];
            const target = path[path.indexOf(item.to || 0)];
            console.log('path info', path);

            item.tier === 1
                ? edges.push({
                      id: `${purchaseOrder?.id}-${item.id}`,
                      source: parent,
                      target: target.toString() || '',
                      zIndex: 1,
                  })
                : edges.push({
                      id: `${purchaseOrder?.id}-${item.id}`,
                      source: source?.toString(),
                      target: target.toString() || '',
                      zIndex: 1,
                  });
        });

        console.log('my edges', edges);

        setEdges(edges);
    }, [nodes, root, purchaseOrder, traces, setEdges, parent]);

    const getOrderTitle = useCallback((item: any) => {
        if (item.externalCustomerReference && item.externalDataId) {
            return `${item.externalCustomerReference} - ${item.externalDataId}`;
        }

        if (item.externalCustomerReference) {
            return item.externalCustomerReference;
        }
        if (item.externalDataId) {
            return item.externalDataId;
        }
        return `Internal Order - ${item.id?.slice(0, 6)}`;
    }, []);

    return (
        <>
            <div style={{ height: '400px', width: '100%' }}>
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    ref={flowRef}
                    nodeTypes={nodeTypes}
                    defaultViewport={{
                        zoom: 0.7,
                        x: 0,
                        y: 0,
                    }}
                    proOptions={{
                        hideAttribution: true,
                    }}
                >
                    <Background />
                    <Controls>
                        <ControlButton
                            // onClick={() =>
                            //     resetLinks({
                            //         workspace: context.workspace?.id || '',
                            //         item: id || '',
                            //     })
                            // }
                            onClick={() => setIsDelete(true)}
                        >
                            <Tooltip title="Clear Cascade">
                                <ClearOutlined />
                            </Tooltip>
                        </ControlButton>
                        <ReactFlowDownloader />
                    </Controls>
                </ReactFlow>
            </div>

            {open ? (
                <Drawer
                    title={getOrderTitle(preview)}
                    open={open}
                    placement="right"
                    onClose={onClose}
                    size="large"
                    bodyStyle={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '18px',
                    }}
                >
                    <Card title={t('purchase:detail.cascadeTrace.orderTitle')}>
                        <Descriptions
                            items={
                                descriptions.map((x) => ({
                                    label: x.label,
                                    value: x.value,
                                    span: x.span,
                                })) || []
                            }
                        ></Descriptions>
                    </Card>

                    <Card
                        title={t('purchase:detail.cascadeTrace.productTitle')}
                    >
                        <Table
                            style={{ minHeight: 0 }}
                            showColumns={false}
                            showFilter={false}
                            showSearch={false}
                            columns={columns}
                            dataSource={previewInfo?.manifest || []}
                            rowKey="id"
                            scroll={{ x: 'max-content' }}
                        />

                        <Text>
                            {t('purchase:detail.overview.totalPrice')}:{' '}
                            {calculateTotal()}
                        </Text>
                    </Card>

                    <Card title={'Documents'}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                            }}
                        >
                            <RadioGroup
                                options={menuOptions}
                                optionType="button"
                                value={menu}
                                onChange={(e) => setMenu(e.target.value)}
                            />

                            <Table
                                id="order-docs"
                                columns={orderDocumentColumns()}
                                scroll={{ x: 'max-content' }}
                                rowKey="id"
                                style={{
                                    display:
                                        menu !== 'company' ? 'block' : 'none',
                                }}
                                showColumns={false}
                                showFilter={false}
                                showSearch={false}
                                dataSource={order?.documents?.filter((x) => {
                                    if (menu === 'materials') {
                                        return (
                                            x.type ===
                                            SupportDocType.PRODUCT_CERTIFICATE
                                        );
                                    }

                                    return (
                                        x.type !==
                                        SupportDocType.PRODUCT_CERTIFICATE
                                    );
                                })}
                            />
                            <Table
                                id="company-docs"
                                columns={companyDocColumns}
                                rowKey="id"
                                scroll={{ x: 'max-content' }}
                                showColumns={false}
                                style={{
                                    display:
                                        menu === 'company' ? 'block' : 'none',
                                }}
                                showFilter={false}
                                showSearch={false}
                                dataSource={
                                    companyDocuments?.[
                                        preview.supplierId || ''
                                    ] || []
                                }
                            />
                        </div>
                    </Card>
                </Drawer>
            ) : null}

            {isDelete && (
                <PurchaseCascadeClearDialog
                    open={isDelete}
                    data={purchaseOrder}
                    traces={traces}
                    setOpen={setIsDelete}
                />
            )}
        </>
    );
};

export default PurchaseCascadeTraceComponent;
