import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProductMaterial } from '../../../../domain/models/material.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useMaterialService } from './use-material-service';

export interface UpdateMaterialParam {
    id?: string;
    args: UpdateProductMaterial;
    delegateId?: string;
}

export interface UpdateProductMaterial
    extends Omit<ProductMaterial, 'material'> {
    material: string;
}

export function useUpdateMaterial() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useMaterialService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ id, args, delegateId }: UpdateMaterialParam) => {
            if (!context.workspace?.id || !id) return;
            await service.update(delegateId || context.workspace.id, id, args);
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: [
                    'material',
                    {
                        workspace: args.delegateId || context.workspace?.id,
                        id: args.id,
                    },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Material updated successfully',
                title: 'Update Material',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to update material: ${error.message}`,
                title: 'Update Material',
            });
        },
    });
}
