import { useQuery } from '@tanstack/react-query';
import { ProductVersion } from '../../../../domain/models/product.model';
import { ProductService } from '../../../../domain/services/product.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useProductService } from './use-product-service';

interface UseProductInterface {
    id?: string;
    enabled?: boolean;
    delegateId?: string;
}

const getProduct = async (
    service: ProductService,
    workspace: string,
    id: string,
): Promise<ProductVersion> => {
    const response = await service.get(workspace, id);

    return response;
};

export function useProduct({ id, enabled, delegateId }: UseProductInterface) {
    const context = useAppContext();
    const { service } = useProductService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'product',
            { workspace: delegateId || context.workspace?.id, id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id || !id) return {};
            return await getProduct(
                service,
                delegateId || context.workspace.id,
                id,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch product information: ${error.message}`,
            });
        },
        enabled: enabled || true,
    });
}
