import { useQuery } from '@tanstack/react-query';
import { Feature } from '../../../../domain/models/feature.model';
import { WorkspaceService } from '../../../../domain/services/workspace.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useWorkspaceService } from './use-workspace-service';

const getFeatures = async (
    service: WorkspaceService,
    id: string,
): Promise<Feature[]> => {
    const response = await service.getFeatures();

    return response;
};

export function useFeatures(delegateId?: string) {
    const context = useAppContext();
    const { service } = useWorkspaceService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: [
            'features',
            { workspace: delegateId || context.workspace?.id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id) return;
            return await getFeatures(
                service,
                delegateId || context.workspace.id,
            );
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch features: ${error.message}`,
                title: 'Fetching Features',
            });
        },
    });
}
