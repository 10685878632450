import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useAdminService } from './use-admin-service';
import { AxiosError } from 'axios';

interface ConsumeSessionParams {
    code: string;
}

export function useConsumeSession() {
    const context = useAppContext();
    const client = useQueryClient();

    const { service } = useAdminService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ code }: ConsumeSessionParams) => {
            const session = sessionStorage.getItem('session');
            if (!code || !session) return {};

            return await service.consumeSession(session, code);
        },
        onSuccess: () => {
            client.invalidateQueries({
                queryKey: ['emulations', { workspace: context.workspace?.id }],
            });
        },
        onError: (err) => {
            if (err instanceof AxiosError) {
                if (err.response?.status === 403) {
                    setAlert({
                        type: 'error',
                        message:
                            'You do not have permission to consume a session',
                        title: 'Consume Session',
                    });

                    return;
                }
            }

            setAlert({
                type: 'error',
                message: 'Failed to consume session',
                title: 'Internal Server Error',
            });
        },
    });
}
