import { Auth0Provider } from '@auth0/auth0-react';
import AppContextProvider from '../infrastructure/providers/context.provider';
import AppRouter from './router/app.router';

import './App.css';
import 'reactflow/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ToastContainer } from 'react-toastify';

import './i18n';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5 * 60 * 1000,
            retry: false,
        },
    },
});

const App: React.FC<{}> = () => {
    useEffect(() => {
        hotjar.initialize(3314138, 6);
    }, []);

    useEffect(() => {
        window.addEventListener('error', (e) => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div',
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay',
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    return (
        <Auth0Provider
            audience={process.env.REACT_APP_AUTH0_AUDIENCE ?? 't4s_app'}
            domain={process.env.REACT_APP_AUTH0_DOMAIN ?? 'jwan.us.auth0.com'}
            clientId={
                process.env.REACT_APP_AUTH0_CLIENT ??
                'eUvGTdXNooKJ3LffyLrYqLagvZ0rpgq6'
            }
            redirectUri={window.location.origin}
        >
            <QueryClientProvider client={queryClient}>
                <AppContextProvider>
                    <AppRouter />
                </AppContextProvider>
                <ReactQueryDevtools />
            </QueryClientProvider>
            <ToastContainer autoClose={3000} pauseOnHover={false} />
        </Auth0Provider>
    );
};

export default App;
