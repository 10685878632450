import {
    Form,
    FormItem,
    Modal,
    Select,
    Subtitle,
    useForm,
} from '@ianneo/ui-library';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NominatedListRequest } from '../../../../domain/zod/nominated-list.model';
import { useNominatedList } from '../../../../infrastructure/hooks/api/nominated-list/use-nominated-list';
import { useUpdateNominatedList } from '../../../../infrastructure/hooks/api/nominated-list/use-update-nominated-list';
import { NominatedListType } from '../../NominatedList/add-nominated-list';
import { useGetDelegations } from '../../../_api/delegations/hooks/useGetDelegations';
import { useParams } from 'react-router-dom';

interface AddPartnerNominatedListModalProps {
    id: string;
    open: boolean;
    toggle: () => void;
}

export const AddPartnerNominatedListModal = ({
    id,
    open,
    toggle,
}: AddPartnerNominatedListModalProps) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const { delegations } = useGetDelegations();
    const { delegateId } = useParams();

    const delegateWorkspaceId = useMemo(
        () =>
            delegations?.find((x) => x.id === Number(delegateId))?.workspace
                ?.id,
        [delegations, delegateId],
    );

    const { data } = useNominatedList({
        workspaceId: delegateWorkspaceId,
        enabled: delegateId ? true : !!delegateWorkspaceId,
    });

    const { mutateAsync: update } = useUpdateNominatedList();
    const options = useMemo(
        () => [
            {
                label: t('nominatedList:detail.menu.supplier'),
                value: NominatedListType.SUPPLIER,
            },
            {
                label: t('nominatedList:detail.menu.assigned'),
                value: NominatedListType.ASSIGNED,
            },
        ],
        [t],
    );

    const reset = () => {
        form.resetFields();
        toggle();
    };

    const submit = async () => {
        try {
            await form.validateFields();
            const request = form.getFieldsValue(true);

            const { type, nominatedList } = request;
            const item = data?.find((x) => x.id === nominatedList);

            if (item) {
                const existingSuppliers = (item.suppliers || []).map(
                    (x) => x.id || '',
                );
                const existingAssigned = (item.assigned || []).map(
                    (x) => x.id || '',
                );

                const args: NominatedListRequest = {
                    name: item.name || '',
                    suppliers:
                        type === NominatedListType.SUPPLIER
                            ? [...existingSuppliers, id]
                            : existingSuppliers,
                    assigned:
                        type === NominatedListType.ASSIGNED
                            ? [...existingAssigned, id]
                            : existingAssigned,
                };

                await update({
                    id: nominatedList,
                    args,
                    delegateId:
                        delegations?.find((x) => x.id === Number(delegateId))
                            ?.workspace?.id || '',
                });
            }

            reset();
        } catch (err) {
            return;
        }
    };

    return (
        <>
            <Modal
                open={open}
                title={t('partner:detail.nominated.title')}
                okFn={submit}
                cancelFn={reset}
            >
                <Form form={form}>
                    <FormItem
                        label={t('partner:detail.nominated.type')}
                        name="type"
                        required
                        rules={[
                            {
                                required: true,
                                message: 'Please select a nominated type.',
                            },
                        ]}
                    >
                        <Select options={options} />
                    </FormItem>

                    <FormItem
                        label={t('partner:detail.nominated.nominatedList')}
                        name="nominatedList"
                        required
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please select a nominated list to be assigned to.',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            showArrow
                            options={data
                                ?.filter((x) => {
                                    const jointArray = [
                                        ...(x.assigned || []),
                                        ...(x.suppliers || []),
                                    ];

                                    return !jointArray.some((y) => y.id === id);
                                })
                                .map((x) => ({
                                    label: x.name,
                                    value: x.id,
                                    key: x.id,
                                }))}
                        />
                    </FormItem>
                </Form>

                <Subtitle type="secondary" style={{ fontSize: '10px' }}>
                    {t('partner:detail.nominated.subtitle')}
                </Subtitle>
            </Modal>
        </>
    );
};
