import {
    CloseOutlined,
    LogoutOutlined,
    SwapOutlined,
    UserOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Avatar,
    Button,
    Dropdown,
    Popover,
    Space,
    Text,
    Tooltip,
} from '@ianneo/ui-library';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AppContext from '../../../domain/models/app.context.model';
import { Permission } from '../../../domain/models/permission.model';
import { User } from '../../../domain/models/user.model';
import useAppConfig from '../../../infrastructure/hooks/use-config.hook';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';

export interface UserInfoCompProps {
    setOpen: (args: boolean) => void;
    setChangeLngMode: (args: boolean) => void;
    displayDropdown: boolean;
}

const UserInfoContainer: React.FC<UserInfoCompProps> = ({
    setOpen,
    setChangeLngMode,
    displayDropdown,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const context = useAppContext();
    const appContext = useContext(AppContext);
    const appConfig = useAppConfig();
    const auth0 = useAuth0();
    const isAssumedSession = sessionStorage.getItem('assumedSession');

    const [myUser, setMysUser] = useState<User>();

    const UserLogout = async () => {
        auth0.logout({ returnTo: appConfig.webRootUrl });
        // sessionStorage.clear();
    };

    const onCloseAssume = () => {
        sessionStorage.removeItem('assumedSession');
        sessionStorage.removeItem('session');

        navigate(0);
    };

    const options = useMemo(() => {
        const items = [
            {
                label: (
                    <Text onClick={() => navigate('/company')}>
                        {t('common:header.company')}
                    </Text>
                ),
                key: 'company',
            },
            {
                label: (
                    <Text onClick={() => navigate('/partners')}>
                        {t('common:header.partners')}
                    </Text>
                ),
                key: 'partners',
            },
            {
                label: (
                    <Text onClick={() => navigate('/nominated-list')}>
                        {t('common:header.nominatedList')}
                    </Text>
                ),
                key: 'nominatedlist',
            },
        ];

        if (context.workspace?.account?.admin === context.user?.email) {
            items.push({
                label: (
                    <Text onClick={() => navigate('/workspaces')}>
                        {t('common:header.workspaces')}
                    </Text>
                ),
                key: 'workspaces',
            });
        }

        const allowUsers: Permission = {
            application: 'io.lfx.t4s.workspace',
            module: 'user',
            action: 'read',
        };
        console.log(
            `permissions (show): ${JSON.stringify(context.permissions)}`,
        );

        const right = context.permissions?.some(
            (p) =>
                p.application === allowUsers.application &&
                p.module === allowUsers.module &&
                p.action === allowUsers.action,
        );
        console.log(`permissions (right): ${JSON.stringify(right)}`);

        if (right) {
            items.push({
                label: (
                    <Text onClick={() => navigate('/users')}>
                        {t('common:header.users')}
                    </Text>
                ),
                key: 'users',
            });
        }

        return items;
    }, [
        context.workspace?.account?.admin,
        navigate,
        t,
        context.user?.email,
        context.permissions,
    ]);

    // loading the data on the user
    useEffect(() => {
        setMysUser(appContext.user);
    }, [appContext.user]);

    const popOverContent = (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
                type="text"
                style={{ textAlign: 'left' }}
                icon={<UserSwitchOutlined />}
                onClick={() => setOpen(true)}
            >
                {t('common:profile.switchRole')}
            </Button>

            <Button
                type="text"
                style={{ textAlign: 'left' }}
                icon={<SwapOutlined />}
                onClick={() => setChangeLngMode(true)}
            >
                {t('common:profile.switchLanguage')}
            </Button>

            {isAssumedSession ? (
                <>
                    <Button
                        type="text"
                        style={{ textAlign: 'left' }}
                        icon={<CloseOutlined />}
                        onClick={onCloseAssume}
                    >
                        {t('common:profile.closeAssume')}
                    </Button>
                </>
            ) : (
                <></>
            )}

            <Button
                type="text"
                style={{ textAlign: 'left' }}
                icon={<LogoutOutlined />}
                onClick={UserLogout}
            >
                {t('common:profile.logout')}
            </Button>
        </div>
    );

    return (
        <>
            <div
                style={{
                    maxWidth: '30%',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
            >
                {displayDropdown ? (
                    <>
                        <Dropdown
                            menu={{ items: options }}
                            overlayStyle={{
                                maxWidth: '30%',
                                minWidth: '12%',
                            }}
                        >
                            <Space className="custom--space">
                                <Text>{context.workspace?.company?.name}</Text>
                            </Space>
                        </Dropdown>
                    </>
                ) : (
                    <>
                        <Tooltip
                            arrow={false}
                            title={context.workspace?.company?.name}
                        >
                            {context.workspace?.company?.name}
                        </Tooltip>
                    </>
                )}
            </div>

            <div>
                <Popover content={popOverContent} placement="bottomLeft">
                    <Button
                        type="text"
                        style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                        }}
                    >
                        {myUser?.avatar ? (
                            <Avatar src={myUser.avatar.url} />
                        ) : (
                            <Avatar icon={<UserOutlined />} />
                        )}
                        {`${myUser?.firstName ?? ''} ${myUser?.lastName ?? ''}`}
                    </Button>
                </Popover>
            </div>
        </>
    );
};

export default UserInfoContainer;
