import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
    CollapsibleDropdown,
    Link,
    Select,
    Subtitle,
    Table,
    Title,
} from '@ianneo/ui-library';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NominatedList as NominatedListModel } from '../../../domain/zod/nominated-list.model';
import { useDeleteNominatedList } from '../../../infrastructure/hooks/api/nominated-list/use-delete-nominated-list';
import { useNominatedList } from '../../../infrastructure/hooks/api/nominated-list/use-nominated-list';
import useToggle from '../../../infrastructure/hooks/use-toggle';
import useVerifyAdmin from '../../../infrastructure/hooks/use-verify-admin';
import { AddNominatedList } from './add-nominated-list';
import { useGetDelegations } from '../../_api/delegations/hooks/useGetDelegations';

export const NominatedList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { status: open, toggle } = useToggle();
    const { isAdmin } = useVerifyAdmin();

    const { delegations } = useGetDelegations();
    const [selected, setSelected] = useState(0);
    const { data } = useNominatedList({
        workspaceId: delegations?.find((x) => x.id === selected)?.workspace?.id,
    });
    const { mutateAsync: remove } = useDeleteNominatedList();

    const handleClick = useCallback(
        (id?: number) => {
            if (!id) return;
            navigate(
                `/nominated-list/${
                    delegations?.find((x) => x.id === selected)?.workspace?.id
                }/${id}`,
            );
        },
        [navigate, delegations, selected],
    );

    const handleDelete = useCallback(
        async (id?: number) => {
            if (!id) return;

            await remove({
                id,
                workspaceId: delegations.find((x) => x.id === selected)
                    ?.workspace?.id,
            });
        },
        [remove, selected, delegations],
    );

    const columns = useMemo(() => {
        const col = [
            {
                title: t('nominatedList:list.name'),
                dataIndex: 'name',
                width: '100%',
                render: (_: any, item: NominatedListModel) => {
                    return (
                        <Link onClick={() => handleClick(item?.id)}>
                            {item.name}
                        </Link>
                    );
                },
            },
            {
                title: t('common:actions'),
                dataIndex: 'actions',
                render: (_: any, item: NominatedListModel) => (
                    <>
                        <DeleteOutlined
                            style={{ color: 'red' }}
                            onClick={() => handleDelete(item.id)}
                        />
                    </>
                ),
            },
        ];

        if (isAdmin) {
            col.pop();
        }

        return col;
    }, [t, handleClick, handleDelete, isAdmin]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    marginBottom: '24px',
                }}
            >
                <div
                    style={{
                        width: '8px',
                        backgroundColor: '#972d47',
                        minHeight: '40px',
                        minWidth: '8px',
                    }}
                ></div>
                <Title>{t('nominatedList:title')}</Title>
                <Subtitle>{t('nominatedList:description')}</Subtitle>
            </div>

            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                    height: '100dch',
                    flex: 1,
                }}
            >
                <Table
                    dataSource={data}
                    columns={columns}
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                    additionalFilters={
                        <div
                            style={{
                                display:
                                    !delegations || delegations.length === 0
                                        ? 'none'
                                        : 'flex',
                                gap: '8px',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ flex: 1 }}>For Company:</span>
                            <Select
                                options={delegations?.map((x) => {
                                    return {
                                        label: x.workspace?.company?.name,
                                        value: x.id,
                                    };
                                })}
                                style={{
                                    maxWidth: '200px',
                                    width: '200px',
                                    flex: 3,
                                }}
                                onChange={(value) => {
                                    setSelected(value);
                                }}
                                placeholder="None"
                                suffixIcon={null}
                                getPopupContainer={undefined}
                            ></Select>
                        </div>
                    }
                    actions={
                        <CollapsibleDropdown
                            menu={{
                                items: [
                                    {
                                        label: t('nominatedList:add'),
                                        key: 'create',
                                        icon: <PlusOutlined />,
                                        onClick: () => toggle(),
                                    },
                                ],
                            }}
                        ></CollapsibleDropdown>
                    }
                />
            </div>

            <AddNominatedList open={open} toggle={toggle} />
        </>
    );
};
