import { CompanyApiClient } from '../../infrastructure/clients/company.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { Company } from '../models/company.model';
import { WorkspaceService } from './workspace.service';

export class CompanyService {
    private companies: CompanyApiClient;
    private workspaces: WorkspaceService;

    constructor(config: ServiceConfigInterface, token: string) {
        this.companies = new CompanyApiClient(config, token);
        this.workspaces = new WorkspaceService(config, token);
    }

    async update(id: string, data: Company): Promise<Company> {
        console.log(`service<company>| update(): Enter`);
        console.log(`service<company>| update(): $id = ${id}`);
        console.log(
            `service<company>| update(): $data = ${JSON.stringify(data)}`,
        );
        return this.companies.update(id, data);
    }

    async solicitDocs(workspace: string, partner: string) {
        console.log(`service<company>| solicitDocs(): Enter`);
        console.log(
            `service<company>| solicitDocs(): $workspace = ${workspace}`,
        );
        console.log(`service<company>| solicitDocs(): $partner = ${partner}`);

        return this.companies.solicitDocs(workspace, partner);
    }
}
