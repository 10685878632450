import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { ImportBatchParams } from '../../domain/services/file.service';
import { Attachment } from '../../domain/types/attachment.type';
import { Uploadable } from '../../domain/types/uploadable.type';
import { ApiServer } from './api.server';

/**
 * @class
 * @name FileApiClient
 * @description The file API client for upload and download control
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */
export class FileApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'file');
    }

    /**
     * @method upload
     * @description Get SAS for uploading files
     * @param workspace {string} the workspace ID
     * @param assetType {string} the type of document to upload (e.g. Business Registration), without space
     * @param data {Uploadable} the model to upload for SAS}
     * @return {any}
     */
    upload(workspace: string, assetType: string, data: Uploadable) {
        console.log(`client<file>| upload(): Enter`);
        console.log(`client<file>| upload(): $workspace = ${workspace}`);
        console.log(`client<file>| upload(): $assetType = ${assetType}`);
        console.log(`client<file>| upload(): $data = ${JSON.stringify(data)}`);
        return super.post('upload', workspace, assetType, data);
    }

    /**
     * @method download
     * @description Get SAS for downloading files
     * @param workspace {string} the workspace ID
     * @param data {Downloadable} the model to load the SAS data
     * @returns {any}
     */
    download(workspace: string, data: Attachment) {
        console.log(`client<file>| download(): Enter`);
        console.log(`client<file>| download(): $workspace = ${workspace}`);
        console.log(
            `client<file>| download(): $data = ${JSON.stringify(data)}`,
        );
        return super.post('download', workspace, data);
    }

    batch(workspace: string, type: string, params: ImportBatchParams) {
        console.log(`client<file>| batch(): Enter`);
        console.log(`client<file>| batch(): $workspace = ${workspace}`);
        console.log(`client<file>| batch(): $fileType = ${type}`);
        return super.post('batch', workspace, type, params);
    }
}
