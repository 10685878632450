import { SupportDocType } from '../../domain/enums/support-doctype.enum';

export const getDocumentValue = (value: SupportDocType) => {
    switch (value) {
        case SupportDocType.AUDIT_REPORT:
            return 'Audit Reports';
        case SupportDocType.BUSINESS_REGISTRATION:
            return 'Business License';
        case SupportDocType.BILL_OF_LADING:
            return 'Bill of Lading';
        case SupportDocType.CERTIFICATE_OF_ORIGIN:
            return 'Certificate of Origin';
        case SupportDocType.CERTIFICATION_RECORD:
            return 'Certification Record';
        case SupportDocType.COMMERCIAL_INVOICE:
            return 'Commercial Invoice';
        case SupportDocType.CONTRACT:
            return 'Contract';
        case SupportDocType.CUSTOM_CLEARANCE:
            return 'Custom Clearance';
        case SupportDocType.DELIVERY_NOTE:
            return 'Delivery Note';
        case SupportDocType.INVOICE:
            return 'Invoice';
        case SupportDocType.MAP_SCREENSHOT:
            return 'Map Screen Shot';
        case SupportDocType.PACKING_LIST:
            return 'Packing List';
        case SupportDocType.PAYMENT_VOUCHER:
            return 'Payment Voucher';
        case SupportDocType.PRODUCTION_RECORD:
            return 'Production Record';
        case SupportDocType.PRODUCT_SPECIFICATIONS:
            return 'Product Specifications';
        case SupportDocType.TRANPORTATION_INVOICE:
            return 'Transportation Invoice';
        case SupportDocType.WAREHOUSE_RECORD:
            return 'Warehouse Record';
        case SupportDocType.PURCHASE_CONTRACT:
            return 'Purchase Contract';
        case SupportDocType.OTHER:
            return 'Other';
        case SupportDocType.SEGREGATION_SUPPORT:
            return 'Segregation Support';
        case SupportDocType.PRODUCT_CERTIFICATE:
            return 'Product Certificate';
        case SupportDocType.RAW_MATERIAL_PROCESSING:
            return 'Raw Material Processing';
        case SupportDocType.BILL_OF_MATERIAL:
            return 'Bill of Material';
        case SupportDocType.PROOF_OF_PAYMENT:
            return 'Proof of Payment';
        default:
            return value;
    }
};
