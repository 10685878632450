import { User } from '@auth0/auth0-react';
import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { ApiServer } from './api.server';

export class UserApiClient extends ApiServer<User> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'user');
    }

    get(workspace: string, id: string) {
        console.log(`client<user>| get(): Enter`);
        console.log(`client<user>| get(): $workspace = ${workspace}`);
        console.log(`client<user>| get(): $id = ${id}`);
        return super.get('search-id', workspace, id);
    }

    list(workspace: string) {
        console.log(`client<user>| list(): Enter`);
        console.log(`client<user>| list(): $workspace = ${workspace}`);
        return super.get('list', workspace);
    }
}
