import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Company from '../pages-v2/Company';
import Dashboard from '../pages-v2/Dashboard';
import Materials from '../pages-v2/Materials';
import MaterialDetail from '../pages-v2/Materials/material-detail';
import Orders from '../pages-v2/Orders';
import Partners from '../pages-v2/Partners';
import PartnerDetail from '../pages-v2/Partners/partner-detail';
import Products from '../pages-v2/Products';
import ProductDetail from '../pages-v2/Products/product-detail';
import Purchases from '../pages-v2/Purchases';
import PurchaseDetail from '../pages-v2/Purchases/purchase-detail';
import Rulesets from '../pages-v2/Rulesets';
import RulesetDetail from '../pages-v2/Rulesets/ruleset-detail';
import UserComponent from '../pages-v2/Users/user.component';
import ProtectedRoute from './ProtectedRoute/protected.route';
import Workspace from '../pages-v2/Workspace/workspace';
import { NominatedList } from '../pages-v2/NominatedList/nominated-list';
import { NominatedListDetail } from '../pages-v2/NominatedList/Detail/nominated-list-detail';

interface AppRouterProps {}

const AppRouter: React.FC<AppRouterProps> = () => {
    const { isLoading, loginWithRedirect, user } = useAuth0();

    useEffect(() => {
        (async function login() {
            if (!isLoading && !user) {
                await loginWithRedirect();
            }
        })();
    }, [isLoading, user, loginWithRedirect]);

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        <ProtectedRoute
                            component={<Dashboard />}
                            header="Dashboard"
                        />
                    }
                />
                <Route
                    path="/company"
                    element={
                        <ProtectedRoute
                            component={<Company />}
                            header="Company"
                        />
                    }
                />

                <Route
                    path="/partners"
                    element={
                        <ProtectedRoute
                            component={<Partners />}
                            header="Partners"
                        />
                    }
                />

                <Route
                    path="partners/:id"
                    element={
                        <ProtectedRoute
                            component={<PartnerDetail />}
                            header="Partner Detail"
                        />
                    }
                />

                <Route
                    path="partners/:id/:delegateId"
                    element={
                        <ProtectedRoute
                            component={<PartnerDetail />}
                            header="Partner Detail"
                        />
                    }
                />

                <Route
                    path="/products"
                    element={
                        <ProtectedRoute
                            component={<Products />}
                            header="Products"
                        />
                    }
                />

                <Route
                    path="/products/:id"
                    element={
                        <ProtectedRoute
                            component={<ProductDetail />}
                            header="Product Detail"
                        />
                    }
                />

                <Route
                    path="/products/:id/:delegateId"
                    element={
                        <ProtectedRoute
                            component={<ProductDetail />}
                            header="Product Detail"
                        />
                    }
                />

                <Route
                    path="/materials"
                    element={
                        <ProtectedRoute
                            header="Materials"
                            component={<Materials />}
                        />
                    }
                />

                <Route
                    path="/materials/:id"
                    element={
                        <ProtectedRoute
                            header="Material Details"
                            component={<MaterialDetail />}
                        />
                    }
                />

                <Route
                    path="/materials/:id/:delegateId"
                    element={
                        <ProtectedRoute
                            header="Material Details"
                            component={<MaterialDetail />}
                        />
                    }
                />

                <Route
                    path="/orders"
                    element={
                        <ProtectedRoute
                            component={<Orders />}
                            header="Orders"
                        />
                    }
                />

                <Route
                    path="/orders/:id"
                    element={
                        <ProtectedRoute
                            component={<Orders />}
                            header="Orders"
                        />
                    }
                />

                <Route
                    path="/rulesets"
                    element={
                        <ProtectedRoute
                            component={<Rulesets />}
                            header="Rulesets"
                        />
                    }
                />

                <Route
                    path="/rulesets/:id"
                    element={
                        <ProtectedRoute
                            component={<RulesetDetail />}
                            header="Rulesets"
                        />
                    }
                />

                <Route
                    path="/rulesets/:id/:delegateId"
                    element={
                        <ProtectedRoute
                            component={<RulesetDetail />}
                            header="Rulesets"
                        />
                    }
                />

                <Route
                    path="/purchases"
                    element={
                        <ProtectedRoute
                            header="Purchases"
                            component={<Purchases />}
                        />
                    }
                />

                <Route
                    path="/purchases/:id"
                    element={
                        <ProtectedRoute
                            header="Purchase Detail"
                            component={<PurchaseDetail />}
                        />
                    }
                />

                <Route
                    path="/purchases/:id/:delegateId"
                    element={
                        <ProtectedRoute
                            header="Purchase Detail"
                            component={<PurchaseDetail />}
                        />
                    }
                />

                <Route
                    path="/users"
                    element={
                        <ProtectedRoute
                            header="Invite New User"
                            component={<UserComponent />}
                        />
                    }
                />

                <Route
                    path="/workspaces"
                    element={
                        <ProtectedRoute
                            header="Workspaces"
                            component={<Workspace />}
                        />
                    }
                />

                <Route
                    path="/nominated-list"
                    element={
                        <ProtectedRoute
                            component={<NominatedList />}
                            header="NominatedList"
                        />
                    }
                ></Route>

                <Route
                    path="/nominated-list/:workspace/:id"
                    element={
                        <ProtectedRoute
                            component={<NominatedListDetail />}
                            header=""
                        />
                    }
                ></Route>
            </Routes>
        </BrowserRouter>
    );
};

export default AppRouter;
