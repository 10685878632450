import { useQuery } from '@tanstack/react-query';
import {
    SalesOrder,
    SalesOrderSearchRequest,
} from '../../../../domain/models/sales-order.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useOrderService } from './use-order-service';

export function useOrderDateSearch(request: SalesOrderSearchRequest) {
    const context = useAppContext();
    const { service } = useOrderService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: ['orderDateSearch'],
        queryFn: async (): Promise<SalesOrder[]> => {
            if (!context.workspace?.id) return [];

            return await service.searchWithDate(context.workspace.id, request);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch order search information: ${error.message}`,
                title: 'Internal Server Error',
            });
        },
    });
}
