import {
    CompositionCreateData,
    UpdateProduct,
} from './../models/product.model';
import { ProductApiClient } from '../../infrastructure/clients/product.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import {
    CreateProduct,
    Product,
    ProductVersion,
} from '../models/product.model';

/**
 * @class
 * @name ProductService
 * @description The award service to get awards
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */
export class ProductService {
    private server: ProductApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new ProductApiClient(config, token);
    }

    async create(
        workspace: string,
        product: CreateProduct,
        diversion: string = '0',
    ) {
        console.log(`service<award>| create(): Enter`);
        console.log(`service<award>| create(): $workspace = ${workspace}`);
        console.log(
            `service<award>| create(): $document = ${JSON.stringify(product)}`,
        );
        console.log(`service<award>| create(): $diversion = ${diversion}`);

        return await this.server.create(workspace, diversion, product);
    }

    async get(workspace: string, item: string, diversion: string = '0') {
        console.log(`service<product>| get(): Enter`);
        console.log(`service<product>| get(): $workspace = ${workspace}`);
        console.log(`service<product>| get(): $item = ${item}`);
        console.log(`service<product>| get(): $diversion = ${diversion}`);

        return this.convertSingleProduct(
            await this.server.get(workspace, item, diversion),
        );
    }

    async list(workspace: string) {
        console.log(`service<product>| list(): Enter`);
        console.log(`service<product>| list(): $workspace = ${workspace}`);
        const result: any[] = await this.server.list(workspace);
        return result.map((r) => this.convert(r));
    }

    async listVersion(workspace: string, version: string) {
        console.log(`service<product>| listVersion(): Enter`);
        console.log(
            `service<product>| listVersion(): $workspace = ${workspace}`,
        );
        const result: any[] = await this.server.listVersion(workspace, version);
        return result.map((r) => this.convert(r));
    }

    async update(workspace: string, item: string, product: UpdateProduct) {
        console.log(`service<product>| update(): Enter`);
        console.log(`service<product>| update(): $workspace = ${workspace}`);
        console.log(`service<product>| update(): $item = ${item}`);
        console.log(
            `service<product>| update(): $product = ${JSON.stringify(product)}`,
        );
        return this.server.update(workspace, item, product);
    }

    async compose(
        workspace: string,
        item: string,
        material: CompositionCreateData,
    ) {
        console.log(`service<product>| compose(): Enter`);
        console.log(`service<product>| compose(): $workspace = ${workspace}`);
        return this.server.compose(workspace, item, material);
    }

    async delete(workspace: string, item: string) {
        console.log(`service<product>| delete(): Enter`);
        console.log(`service<product>| delete(): $workspace = ${workspace}`);
        console.log(`service<product>| delete(): $item = ${item}`);
        return this.server.delete(workspace, item);
    }

    async search(
        workspace: string,
        name: string,
        diversion: string = '0',
    ): Promise<Product> {
        console.log(`service<product>| search(): Enter`);
        console.log(`service<product>| search(): $workspace = ${workspace}`);
        console.log(`service<product>| search(): $name = ${name}`);
        console.log(`service<product>| search(): $diversion = ${diversion}`);

        return await this.server.search(workspace, name, diversion);
    }

    private convert(source: any): Product {
        console.log(`service<product>| convert(): Enter`);
        console.log(
            `service<product>| convert(): $source = ${JSON.stringify(source)}`,
        );
        const result: ProductVersion = {
            id: source.id,
            cost: source.cost,
            createdOn: source.createdOn,
            compositions: source.compositions,
            label: source.label,
            product: source.product,
            released: source.released,
            weight: source.weight,
        };

        return result;
    }

    private convertSingleProduct(source: any): ProductVersion {
        console.log(`service<product>| convertSingleProduct(): Enter`);
        console.log(
            `service<product>| convertSingleProduct(): $source = ${JSON.stringify(
                source,
            )}`,
        );
        const result: ProductVersion = {
            id: source[0].id,
            cost: source[0].cost,
            createdOn: source[0].createdOn,
            compositions: source[0].compositions,
            label: source[0].label,
            released: source[0].released,
            weight: source[0].weight,
            // product: {
            //     id: source[0].product.id,
            //     category: source[0].product.category,
            //     code: source[0].product.code,
            //     collection: source[0].product.collection,
            //     color: source[0].product.color,
            //     createdOn: source[0].product.createdOn,
            //     description: source[0].product.description,
            //     externalDataId: source[0].product.externalDataId,
            //     hsCode: source[0].product.hsCode,
            //     isActive: source[0].product.isActive,
            //     lastUpdatedOn: source[0].product.lastUpdatedOn,
            //     name: source[0].product.name,
            //     pictures: source[0].product.pictures,
            //     remark: source[0].product.remark,
            //     sku: source[0].product.sku,
            //     specifications: source[0].product.specifications,
            //     tags: source[0].product.tags,
            //     upc: source[0].product.upc,
            //     workspace: source[0].product.workspace,
            // },
            product: source[0].product,
        };

        return result;
    }
}
