import { RoleApiClient } from '../../infrastructure/clients/role.client';
import { UpdateRolePermissionParams } from '../../infrastructure/hooks/api/role/use-update-role-permissions';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import { Role } from '../models/role.model';

export class RoleService {
    private server: RoleApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new RoleApiClient(config, token);
    }

    async updateRole(workspace: string, role: string, params: any) {
        console.log(`service<role>| updateRole(): Enter`);
        console.log(`service<role>| updateRole(): $workspace = ${workspace}`);
        console.log(`service<role>| updateRole(): $role = ${role}`);
        console.log(
            `service<product>| updateRole(): $params = ${JSON.stringify(
                params,
            )}`,
        );
        return this.server.assignRole(workspace, role, params);
    }

    async deleteRole(workspace: string, role: string) {
        console.log(`service<role>| deleteRole(): Enter`);
        console.log(`service<role>| deleteRole(): $workspace = ${workspace}`);
        console.log(`service<role>| deleteRole(): $role = ${role}`);

        return this.server.delete(workspace, role);
    }

    async list(workspace: string) {
        console.log(`service<role>| list(): Enter`);
        console.log(`service<role>| list(): $workspace = ${workspace}`);

        return this.convertList(await this.server.list(workspace));
    }

    async create(workspace: string, params: any) {
        console.log(`service<role>| create(): Enter`);
        console.log(`service<role>| create(): $workspace = ${workspace}`);
        console.log(
            `service<role>| create(): $params = ${JSON.stringify(params)}`,
        );

        return this.server.create(workspace, params);
    }

    async get(workspace: string, id: string) {
        console.log(`service<role>| get(): Enter`);
        console.log(`service<role>| get(): $workspace = ${workspace}`);
        console.log(`service<role>| get(): $id = ${id}`);

        return this.convert(await this.server.get(workspace, id));
    }

    async assign(workspace: string, id: string, args: any) {
        console.log(`service<role>| assign(): Enter`);
        console.log(`service<role>| assign(): $workspace = ${workspace}`);
        console.log(`service<role>| assign(): $id = ${id}`);
        console.log(`service<role>| assign(): $args = ${JSON.stringify(args)}`);

        return await this.server.assign(workspace, id, args);
    }

    async unassign(workspace: string, id: string, args: any) {
        console.log(`service<role>| unassign(): Enter`);
        console.log(`service<role>| unassign(): $workspace = ${workspace}`);
        console.log(`service<role>| unassign(): $id = ${id}`);
        console.log(
            `service<role>| unassign(): $args = ${JSON.stringify(args)}`,
        );

        return await this.server.unassign(workspace, id, args);
    }

    async updatePermissions(
        workspace: string,
        id: string,
        args: UpdateRolePermissionParams['args'],
    ) {
        console.log(`service<role>| updatePermissions(): Enter`);
        console.log(
            `service<role>| updatePermissions(): $workspace = ${workspace}`,
        );
        console.log(`service<role>| updatePermissions(): $id = ${id}`);
        console.log(
            `service<role>| updatePermissions(): $args = ${JSON.stringify(
                args,
            )}`,
        );

        return await this.server.updatePermissions(workspace, id, args);
    }

    private convert(data: any): Role {
        return {
            id: data.id,
            code: data.code,
            createdOn: data.createdOn,
            description: data.description,
            enabled: data.enabled,
            members: data.members,
            name: data.name,
            permissions: data.permissions,
            workspace: data.workspace,
            lastUpdatedOn: data.lastUpdatedOn,
        };
    }

    private convertList(data: any): Role[] {
        return data.map((item: any) => ({
            id: item.id,
            code: item.code,
            createdOn: item.createdOn,
            description: item.description,
            enabled: item.enabled,
            name: item.name,
            lastUpdatedOn: item.lastUpdatedOn,
        }));
    }
}
