import {
    CheckOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
} from '@ant-design/icons';
import {
    Card,
    ColumnsType,
    Popconfirm,
    Space,
    Table,
} from '@ianneo/ui-library';
import { useCallback, useMemo, useState } from 'react';
import { Template as TemplateModel } from '../../../../infrastructure/clients/template.client';
import { useDeleteTemplate } from '../../../../infrastructure/hooks/api/template/use-delete-template';
import useTemplates from '../../../../infrastructure/hooks/api/template/use-templates';
import useToggle from '../../../../infrastructure/hooks/use-toggle';
import { UpsertTemplate } from './upsert-template.component';
import { useTranslation } from 'react-i18next';
import useVerifyAdmin from '../../../../infrastructure/hooks/use-verify-admin';

export const Template: React.FC<{}> = () => {
    const { t } = useTranslation();
    const { data: baseTemplate } = useTemplates(true);
    const [selected, setSelected] = useState<TemplateModel>();
    const { status, toggle } = useToggle();
    const { isAdmin } = useVerifyAdmin();

    const { mutateAsync: remove } = useDeleteTemplate();

    const deleteAdaptation = useCallback(
        async (id: string) => {
            await remove(id);
        },
        [remove],
    );

    const columns: ColumnsType<TemplateModel> = useMemo(() => {
        const col = [
            {
                title: t('company:template.name'),
                dataIndex: 'name',
                width: '20%',
            },
            {
                title: t('company:template.description'),
                dataIndex: 'description',
                width: '40%',
            },
            {
                title: t('company:template.adaptation'),
                dataIndex: 'adaptation',
                width: '10%',
                render: (_: unknown, item: TemplateModel) => {
                    return item.adaptation ? (
                        <CheckOutlined />
                    ) : (
                        <CloseOutlined />
                    );
                },
            },
            {
                title: t('common:actions'),
                dataIndex: 'actions',
                fixed: 'right',
                width: '10%',
                render: (_: unknown, item: TemplateModel) => (
                    <Space>
                        <Popconfirm
                            title="Delete Adaptation"
                            submit={() => deleteAdaptation(item.code || '')}
                            disabled={!item.adaptation}
                        >
                            <DeleteOutlined style={{ color: '#972D47' }} />
                        </Popconfirm>

                        <EditOutlined
                            onClick={() => {
                                setSelected(item);
                                toggle();
                            }}
                            style={{ color: '#972D47' }}
                        />
                    </Space>
                ),
            },
        ];

        if (isAdmin) {
            col.pop();
        }

        return col as any;
    }, [toggle, deleteAdaptation, t, isAdmin]);

    return (
        <>
            <Card>
                <Table
                    dataSource={baseTemplate}
                    columns={columns}
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                    size="small"
                />
            </Card>

            <UpsertTemplate
                open={status}
                toggle={toggle}
                template={selected}
                setTemplate={setSelected}
            />
        </>
    );
};
