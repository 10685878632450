export enum SupportDocType {
    AUDIT_REPORT = 'AUDIT_REPORT',
    BUSINESS_REGISTRATION = 'BUSINESS_REGISTRATION',
    BILL_OF_LADING = 'BILL_OF_LADING',
    CERTIFICATE_OF_ORIGIN = 'CERTIFICATE_OF_ORIGIN',
    CERTIFICATION_RECORD = 'CERTIFICATION_RECORD',
    PRODUCT_CERTIFICATE = 'PRODUCT_CERTIFICATE',
    COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE',
    CONTRACT = 'CONTRACT',
    CUSTOM_CLEARANCE = 'CUSTOM_CLEARANCE',
    DELIVERY_NOTE = 'DELIVERY_NOTE',
    INVOICE = 'INVOICE',
    MAP_SCREENSHOT = 'MAP_SCREENSHOT',
    PACKING_LIST = 'PACKING_LIST',
    PAYMENT_VOUCHER = 'PAYMENT_VOUCHER',
    PURCHASE_CONTRACT = 'PURCHASE_CONTRACT',
    PRODUCTION_RECORD = 'PRODUCTION_RECORD',
    PRODUCT_SPECIFICATIONS = 'PRODUCT_SPECIFICATIONS',
    TRANPORTATION_INVOICE = 'TRANPORTATION_INVOICE',
    WAREHOUSE_RECORD = 'WAREHOUSE_RECORD',
    OTHER = 'OTHER',
    SEGREGATION_SUPPORT = 'SEGREGATION_SUPPORT',
    RAW_MATERIAL_PROCESSING = 'RAW_MATERIAL_PROCESSING',
    BILL_OF_MATERIAL = 'BILL_OF_MATERIAL',
    PROOF_OF_PAYMENT = 'PROOF_OF_PAYMENT',
}
