import {
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import {
    Card,
    CollapsibleDropdown,
    DatePicker,
    Input,
    Link,
    Modal,
    Popconfirm,
    Select,
    Table,
    Text,
    UploadFile,
    UploadTable,
    useForm,
} from '@ianneo/ui-library';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Award } from '../../../../domain/models/award.modal';
import { useAwards } from '../../../../infrastructure/hooks/api/award/use-awards';
import { useAwardTypes } from '../../../../infrastructure/hooks/api/award/use-awards-types';
import { useDeleteAward } from '../../../../infrastructure/hooks/api/award/use-delete-award';
import useDownloadAward from '../../../../infrastructure/hooks/api/award/use-download-award';
import useDownloadAwards from '../../../../infrastructure/hooks/api/award/use-download-awards';
import { useUploadAward } from '../../../../infrastructure/hooks/api/award/use-upload-award';
import useToggle from '../../../../infrastructure/hooks/use-toggle';
import useVerifyAdmin from '../../../../infrastructure/hooks/use-verify-admin';
import EditCertifications from './edit-certifications';

interface CertificationsComponentProps {}

const CertificationsComponent: React.FC<CertificationsComponentProps> = () => {
    const { t } = useTranslation();
    const { isAdmin } = useVerifyAdmin();
    const { data } = useAwards();
    const { data: types } = useAwardTypes();
    const [open, setOpen] = useState(false);
    const [form] = useForm();
    const [files, setFiles] = useState<UploadFile<any>[]>([]);
    const [selectedRows, setSelectedRows] = useState<Award[]>([]);

    const downloadCert = useDownloadAward();
    const downloadCerts = useDownloadAwards();
    const { mutateAsync } = useUploadAward();
    const { mutateAsync: remove } = useDeleteAward();

    // States that are related to the edit modal component
    const { status: edit, toggle: toggleEdit } = useToggle();
    const [editingDocument, setEditingDocument] = useState<Award>();

    const rowSelection = {
        selectedRows,
        onChange: (_: React.Key[], selected: any) => {
            setSelectedRows(selected);
        },
    };

    const onDownload = useCallback(() => {
        downloadCerts({
            awards: selectedRows.length > 0 ? selectedRows : data || [],
        });
    }, [downloadCerts, data, selectedRows]);

    const columns = useMemo(() => {
        const col = [
            {
                title: t('company:certifications.certName'),
                dataIndex: ['certification', 'name'],
            },
            {
                title: t('company:certifications.type'),
                dataIndex: ['certification', 'certificationCode'],
            },
            {
                title: t('company:certifications.standard'),
                dataIndex: ['certification', 'certifyBody'],
            },
            { title: t('company:certifications.issuedBy'), dataIndex: '' },
            {
                title: t('company:certifications.status'),
                dataIndex: 'verifiedOn',
                render: (_: string, record: Award) => (
                    <Text>
                        {record.verifiedOn ? 'Verified' : 'Not Verified'}
                    </Text>
                ),
            },
            {
                title: t('company:certifications.issuedDate'),
                dataIndex: 'issuedDate',
                render: (_: string, record: Award) => {
                    return (
                        <Text>
                            {record.issuedDate
                                ? new Date(record.issuedDate)
                                      .toISOString()
                                      .slice(0, 10)
                                : ''}
                        </Text>
                    );
                },
            },
            {
                title: t('company:certifications.expiryDate'),
                dataIndex: 'expiryDate',
                render: (_: string, record: Award) => {
                    return (
                        <Text>
                            {record.expiryDate
                                ? new Date(record.expiryDate)
                                      .toISOString()
                                      .slice(0, 10)
                                : ''}
                        </Text>
                    );
                },
            },
            { title: t('company:certifications.docNo'), dataIndex: '' },
            {
                title: t('company:certifications.certNo'),
                dataIndex: 'certificateNumber',
            },
            {
                title: t('common:updatedOn'),
                dataIndex: 'lastUpdatedOn',
                render: (_: string, record: Award) => {
                    return (
                        <Text>
                            {record.lastUpdatedOn
                                ? new Date(record.lastUpdatedOn)
                                      .toISOString()
                                      .slice(0, 10)
                                : ''}
                        </Text>
                    );
                },
            },
            {
                title: t('common:actions'),
                render: (_: any, item: Award) => (
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <Link
                            hidden={item.verifiedOn ? true : false}
                            onClick={() => {
                                setEditingDocument(item);
                                toggleEdit();
                            }}
                        >
                            <EditOutlined />
                        </Link>

                        <Link
                            onClick={async () =>
                                await downloadCert({ award: item })
                            }
                        >
                            <DownloadOutlined />
                        </Link>

                        <Link hidden={item.verifiedOn ? true : false}>
                            <Popconfirm
                                title={t('company:certifications.delete.title')}
                                description={t(
                                    'company:certifications.delete.description',
                                )}
                                submit={async () => await remove(item.id || '')}
                            >
                                <DeleteOutlined style={{ color: '#972d47' }} />
                            </Popconfirm>
                        </Link>
                    </div>
                ),
            },
        ];

        if (isAdmin) {
            col.pop();
        }
        return col;
    }, [t, downloadCert, toggleEdit, remove, isAdmin]);

    const uploadColumns = useMemo(
        () => [
            {
                title: t('company:certifications.certName'),
                dataIndex: ['documentation', 'assetName'],
                component: <Input />,
                required: true,
                rules: [
                    {
                        required: true,
                        message: 'Please enter the certification name',
                    },
                ],
            },
            {
                title: t('company:certifications.auditOrg'),
                dataIndex: 'auditOrganization',
                component: <Input />,
                required: true,
                rules: [
                    {
                        required: true,
                        message: 'Please enter the audit organization',
                    },
                ],
            },
            {
                title: t('company:certifications.certification'),
                dataIndex: ['certification', 'id'],
                component: (
                    <Select
                        options={Object.values(types || {}).map((x) => ({
                            label: x.name,
                            value: x.id,
                            key: x.id,
                        }))}
                    />
                ),
            },
            {
                title: t('company:certifications.certNo'),
                dataIndex: 'certificateNumber',
                component: <Input />,
                required: true,
                rules: [
                    {
                        required: true,
                        message: 'Please enter the certification number',
                    },
                ],
            },
            {
                title: t('company:certifications.expiryDate'),
                dataIndex: 'expiryDate',
                component: <DatePicker />,
                required: true,
                rules: [
                    {
                        validator: (_: unknown, value: any) => {
                            if (!value)
                                return Promise.reject(
                                    'Please enter the expiry date',
                                );
                            return Promise.resolve();
                        },
                    },
                ],
            },
            {
                title: t('company:certifications.issuedDate'),
                dataIndex: 'issuedDate',
                component: <DatePicker />,
                required: true,
                rules: [
                    {
                        validator: (_: unknown, value: any) => {
                            if (!value)
                                return Promise.reject(
                                    'Please enter the issued date',
                                );
                            return Promise.resolve();
                        },
                    },
                ],
            },
        ],
        [t, types],
    );

    const onUpload = (files: UploadFile<any>[]) => {
        setFiles(files);

        const values = form.getFieldsValue(true);
        const items = values.items ? values.items : [];

        const newItems: any[] = [];
        files.forEach((x) => {
            const item = items.find((y: any) => y.id === x.uid);
            if (item) return;

            const record: Award = {
                id: x.uid,
                certification: { id: types?.[0]?.id },
                documentation: {
                    assetName: x.name,
                    container: 'temporary',
                    contentType: x.type || '',
                    originalName: x.name,
                    autoResign: false,
                },
            };

            newItems.push(record);
        });

        values.items = [...items, ...newItems];
    };

    const reset = () => {
        setOpen(false);
        setFiles([]);
        form.resetFields();
    };

    const items = useMemo(() => {
        const items = [
            {
                label: t('common:download'),
                icon: <DownloadOutlined />,
                onClick: async () => await onDownload(),
                key: 'download',
            },
        ];

        if (!isAdmin) {
            items.push({
                label: t('common:addNew'),
                icon: <PlusOutlined />,
                onClick: async () => setOpen(true),
                key: 'add-new',
            });
        }

        return items;
    }, [onDownload, t, isAdmin]);

    return (
        <>
            <Card>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                    rowSelection={rowSelection}
                    actions={[
                        <CollapsibleDropdown
                            menu={{
                                items,
                            }}
                        ></CollapsibleDropdown>,
                    ]}
                />
            </Card>

            <Modal
                title="Upload Document"
                open={open}
                width={1000}
                closable={false}
                cancelFn={reset}
                okFn={async () => {
                    try {
                        await form.validateFields();
                        const values = form.getFieldsValue(true);
                        await mutateAsync({
                            awards: values.items,
                            files,
                        });

                        reset();
                    } catch (err) {}
                }}
            >
                <UploadTable
                    form={form}
                    files={files}
                    setFiles={onUpload}
                    columns={uploadColumns}
                    accept="*"
                />
            </Modal>

            <EditCertifications
                open={edit}
                toggle={toggleEdit}
                data={editingDocument}
            />
        </>
    );
};

export default CertificationsComponent;
