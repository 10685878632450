import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Adaptation } from '../../../clients/template.client';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import useTemplateService from './use-template-service';

export interface UpdateTemplateRequest {
    template: string;
    adaptation: Adaptation;
}

export function useUpdateTemplate() {
    const context = useAppContext();
    const { service } = useTemplateService();
    const client = useQueryClient();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ template, adaptation }: UpdateTemplateRequest) => {
            if (!context.workspace?.id) return;

            await service.update(context.workspace.id, template, adaptation);
        },
        onSuccess: (_, { template }) => {
            client.invalidateQueries({
                queryKey: ['template'],
            });

            setAlert({
                type: 'success',
                message: `Template ${template} updated successfully`,
                title: 'Update Template',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to update template: ${error.message}`,
                title: 'Update Template',
            });
        },
    });
}
