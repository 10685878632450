import { useMutation, useQueryClient } from '@tanstack/react-query';
import { i18n } from '../../../../domain/models/i18n.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useRoleService } from './use-role-service';

export interface CreateRoleParams {
    code: string;
    name: i18n;
    description: i18n;
}

export function useCreateRole() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useRoleService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({
            args,
            workspaceId,
        }: {
            args: CreateRoleParams;
            workspaceId?: string;
        }) => {
            if (!context.workspace?.id) return;
            await service.create(workspaceId || context.workspace.id, args);
        },
        onSuccess: (_, { workspaceId }) => {
            client.invalidateQueries({
                queryKey: [
                    'roles',
                    { type: workspaceId || context.workspace?.id },
                ],
            });

            client.invalidateQueries({
                queryKey: [
                    'members',
                    { type: workspaceId || context.workspace?.id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Role created successfully',
                title: 'Create role',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to update role: ${error.message}`,
                title: 'Update role',
            });
        },
    });
}
