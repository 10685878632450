import { NominatedListApiClient } from '../../infrastructure/clients/nominated-list.client';
import { ServiceConfigInterface } from '../interfaces/config.context.interface';
import {
    NominatedList,
    NominatedListRequest,
} from '../zod/nominated-list.model';

export class NominatedListService {
    private server: NominatedListApiClient;

    constructor(config: ServiceConfigInterface, token: string) {
        this.server = new NominatedListApiClient(config, token);
    }

    async list(workspace: string): Promise<NominatedList[]> {
        console.log(`service<nominatedlist>| list(): Enter`);
        console.log('service<nominatedlist>| list(): $workspace =', workspace);

        return this.server.list(workspace);
    }

    async create(
        workspace: string,
        params: NominatedListRequest,
    ): Promise<NominatedList> {
        console.log(`service<nominatedlist>| create(): Enter`);
        console.log(
            'service<nominatedlist>| create(): $workspace =',
            workspace,
        );
        console.log('service<nominatedlist>| create(): $params =', params);

        return this.server.create(workspace, params);
    }

    async update(
        workspace: string,
        item: number,
        params: NominatedListRequest,
    ): Promise<NominatedList> {
        console.log(`service<nominatedlist>| update(): Enter`);
        console.log(
            'service<nominatedlist>| update(): $workspace =',
            workspace,
        );
        console.log('service<nominatedlist>| update(): $item =', item);
        console.log('service<nominatedlist>| update(): $params =', params);

        return this.server.update(workspace, item, params);
    }

    async delete(workspace: string, item: number): Promise<void> {
        console.log(`service<nominatedlist>| delete(): Enter`);
        console.log(
            'service<nominatedlist>| delete(): $workspace =',
            workspace,
        );
        console.log('service<nominatedlist>| delete(): $item =', item);

        return this.server.delete(workspace, item);
    }
}
