import {
    DescriptionItems,
    Descriptions,
    FormInstance,
} from '@ianneo/ui-library';
import { useMemo } from 'react';
import { useWorkspaceByCompanyName } from '../../../../infrastructure/hooks/api/workspaces/use-workspace-by-company-name';
import countries from '../../../../infrastructure/config/data/countries.json';
import useAppContext from '../../../../infrastructure/hooks/use-context.hook';
import { useGetDelegations } from '../../../_api/delegations/hooks/useGetDelegations';

type Props = {
    search: string;
    form: FormInstance;
    onBehalfOf: number;
};

export const PartnerConfirmationComponent: React.FC<Props> = ({
    form,
    search,
    onBehalfOf,
}) => {
    const context = useAppContext();
    const { delegations } = useGetDelegations();
    const { data: workspaces } = useWorkspaceByCompanyName(
        form.getFieldValue('onBehalfOf')
            ? form.getFieldValue('onBehalfOf')
            : context.workspace?.id,
        search,
    );

    const existingWorkspace = useMemo(() => {
        return workspaces?.find((x) => x.company?.name === search);
    }, [workspaces, search]);

    const descriptions = useMemo(() => {
        const items = [
            {
                label: 'On Behalf Of.',
                value: onBehalfOf
                    ? delegations.find((x) => x.id === onBehalfOf)?.workspace
                          ?.company?.name
                    : '-',
                span: 3,
            },
            {
                label: 'Assign as My Company Delegate',
                value: form.getFieldValue('assignPersonal') ? 'Yes' : 'No',
                span: 3,
            },
        ];

        if (existingWorkspace) {
            items.push({
                label: 'Existing Workspace',
                value: existingWorkspace?.company?.name || '',
                span: 3,
            });
        } else {
            items.push({
                label: "New Company's Name",
                value: form.getFieldValue('companyName'),
                span: 3,
            });

            items.push({
                label: 'Registration No.',
                value: form.getFieldValue('registrationNumber'),
                span: 3,
            });

            items.push({
                label: 'Country of Operations',
                value:
                    countries.find(
                        (x) => x['alpha-2'] === form.getFieldValue('country'),
                    )?.name || '',
                span: 3,
            });

            items.push({
                label: 'Primary Contact',
                value:
                    (form.getFieldValue('items')?.[0]?.firstName || '') +
                    ' ' +
                    (form.getFieldValue('items')?.[0]?.lastName || ''),
                span: 3,
            });

            items.push({
                label: 'Primary Contact Email',
                value: form.getFieldValue('items')?.[0]?.email || '',
                span: 3,
            });
        }

        return items;
    }, [form, existingWorkspace, onBehalfOf, delegations]);

    return (
        <div>
            <Descriptions>
                {descriptions.map((x, index) => {
                    return (
                        <DescriptionItems
                            key={index}
                            label={x.label}
                            span={x.span}
                        >
                            {x.value}
                        </DescriptionItems>
                    );
                })}
            </Descriptions>
        </div>
    );
};
