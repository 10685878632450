import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Ruleset } from '../../../../domain/models/ruleset.model';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useRulesetService } from './use-ruleset-service';

export interface UpdateRulesetParams {
    id: string;
    ruleset: Ruleset;
    delegateId?: string;
}

export function useUpdateRuleset() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useRulesetService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({
            id,
            ruleset,
            delegateId,
        }: UpdateRulesetParams) => {
            if (!context.workspace?.id || !id) return;
            await service.update(
                delegateId || context.workspace.id,
                id,
                ruleset,
            );
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: [
                    'rulesets',
                    {
                        workspace: args.delegateId || context.workspace?.id,
                        id: args.id,
                    },
                ],
            });

            client.invalidateQueries({
                queryKey: [
                    'rulesets',
                    { workspace: args.delegateId || context.workspace?.id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Ruleset updated successfully',
                title: 'Update Ruleset',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to update ruleset: ${error.message}`,
                title: 'Update Ruleset',
            });
        },
    });
}
