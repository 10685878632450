import { OrderNatureType } from '../../domain/enums/order-nature.enum';

export const getOrderNature = (value: OrderNatureType | undefined) => {
    console.log(`$value (get): ${JSON.stringify(value)}`);
    switch (value) {
        case OrderNatureType.COMPONENT:
            return 'Component';
        case OrderNatureType.PROCESSING:
            return 'Processing Service';
        case OrderNatureType.OUTSOURCE:
            return 'Outsource Service';
        case OrderNatureType.RAW_MATERIAL:
            return 'Raw Material';
        default:
            return 'N/A';
    }
};
