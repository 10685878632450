import { Card, Tag, Text } from '@ianneo/ui-library';
import { useCallback, useMemo } from 'react';
import { Handle, Position } from 'reactflow';
import countries from '../../../../../infrastructure/config/data/countries.json';
import { usePurchase } from '../../../../../infrastructure/hooks/api/purchases/use-purchase';
import useAppContext from '../../../../../infrastructure/hooks/use-context.hook';
import { getOrderNature } from '../../../../../infrastructure/utils/order-nature-labeler';
import { getCustomOrderNature } from './purchase-cascade-trace.component';

interface Props {
    data: {
        purchaseId: string;
        workspaceId: string;
        isPoFactory: boolean;
        setOpen: (value: boolean) => void;
        setSelected: (value: any) => void;
    };
}

export const PurchaseCascadeNode = ({ data }: Props) => {
    const context = useAppContext();
    const loader = context.workspace?.supplyChainLoaderType;
    const { setOpen, setSelected, purchaseId, workspaceId } = data;

    const { data: item } = usePurchase({
        id: data.purchaseId,
        customWorkspaceId: data.workspaceId,
    });

    const country = countries.find(
        (x) => x['alpha-2'] === item?.owner?.supplier?.seller?.country,
    );

    const getDefaultLabel = useCallback(() => {
        const externalCustomerReference =
            item?.owner?.externalCustomerReference;
        const externalDataId = item?.owner?.externalDataId;
        const purchaseId = item?.owner?.id;

        if (data.isPoFactory) {
            return 'PO Factory';
        }

        if (externalCustomerReference && externalDataId) {
            return `${externalCustomerReference} - ${externalDataId}`;
        }

        if (externalCustomerReference) {
            return externalCustomerReference;
        }

        if (externalDataId) {
            return externalDataId;
        }

        return `Internal Order - ${purchaseId?.slice(0, 6)}`;
    }, [item, data.isPoFactory]);

    const productName = useMemo(() => {
        return item?.manifest?.[0]?.purchaseables?.name;
    }, [item]);

    const getLabel = () => {
        if (loader === 0) {
            return getDefaultLabel();
        }

        if (loader === 1) {
            return item?.owner?.orderNature
                ? getCustomOrderNature(item.owner.orderNature)
                : getDefaultLabel();
        }
    };

    const handleView = () => {
        setOpen(true);
        setSelected({
            id: purchaseId,
            workspaceId: workspaceId,
            supplierId: item?.owner?.supplier?.seller?.id,
            externalDataId: item?.owner?.externalDataId,
            externalCustomerReference: item?.owner?.externalCustomerReference,
        });
    };

    return (
        <div
            style={{
                width: '360px',
            }}
        >
            <Handle type="target" position={Position.Left} />

            <Card
                title={getLabel()}
                style={{
                    borderRadius: '16px',
                }}
                extra={
                    <span
                        onClick={handleView}
                        style={{
                            cursor: 'pointer',
                            color: '#1890ff',
                        }}
                    >
                        View
                    </span>
                }
            >
                {loader === 0 && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                        }}
                    >
                        <Text>
                            Supplier Name: {item?.owner?.supplier?.seller?.name}
                        </Text>
                        <Text>Product: {productName}</Text>
                        {item?.owner?.orderNature && (
                            <Text>
                                Order Nature:{' '}
                                {getOrderNature(item.owner.orderNature)}
                            </Text>
                        )}

                        <Text>
                            {item?.owner?.purchaseProcesses &&
                                item.owner.purchaseProcesses?.length > 0 &&
                                item.owner.purchaseProcesses.map((x: any) => (
                                    <Tag>{x}</Tag>
                                ))}
                        </Text>
                    </div>
                )}

                {loader === 1 && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '4px',
                        }}
                    >
                        <Text>
                            Factory Name: {item?.owner?.supplier?.seller?.name}
                        </Text>
                        <Text>
                            Factory Number:{' '}
                            {item?.owner?.supplier?.seller?.registrationNumber}
                        </Text>
                        {country && (
                            <Text>
                                Country: {country?.name} ({country?.['alpha-2']}
                                )
                            </Text>
                        )}
                        <Text>Product: {productName}</Text>
                    </div>
                )}
            </Card>

            <Handle type="source" position={Position.Right} />
        </div>
    );
};
