import {
    Checkbox,
    Form,
    FormInstance,
    FormItem,
    Select,
} from '@ianneo/ui-library';
import { useState } from 'react';
import { useGetDelegations } from '../../../_api/delegations/hooks/useGetDelegations';

type Props = {
    form: FormInstance;
    onBehalfOf: number;
    setOnBehalfOf: (value: number) => void;
    isAddPartner?: boolean;
};

export const DelegationComponent: React.FC<Props> = ({
    form,
    onBehalfOf,
    setOnBehalfOf,
    isAddPartner = false,
}) => {
    const { delegations } = useGetDelegations();

    const [disabled, setDisabled] = useState(false);

    const handleSelectionChange = (value: number) => {
        if (value) {
            setOnBehalfOf(value);
        } else {
            setOnBehalfOf(0);
            setDisabled(false);
        }
    };

    return (
        <Form form={form}>
            <FormItem label="On Behalf Of" name="onBehalfOf">
                <Select
                    onChange={handleSelectionChange}
                    options={delegations?.map((delegation) => ({
                        label: delegation.workspace?.company?.name,
                        // value: delegation.workspace?.id,
                        value: delegation.id,
                    }))}
                    value={onBehalfOf}
                />
            </FormItem>

            {isAddPartner && (
                <div
                    style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                    }}
                >
                    <span>Assign as My Company Delegate: </span>
                    <FormItem
                        style={{
                            alignItems: 'center',
                            marginBottom: 0,
                        }}
                        valuePropName="checked"
                        name="assignPersonal"
                    >
                        <Checkbox disabled={disabled} />
                    </FormItem>
                </div>
            )}
        </Form>
    );
};
