import { UnitType } from '../../domain/enums/unit-type.enum';

export const getUnitValue = (value: UnitType | undefined) => {
    console.log(`$value (get): ${JSON.stringify(value)}`);
    switch (value) {
        case UnitType.PIECES:
            return 'Pieces';
        case UnitType.YARDS:
            return 'Yards';
        case UnitType.METERS:
            return 'Meters';
        case UnitType.KG:
            return 'Kg';
        case UnitType.TONS:
            return 'Tons';
        case UnitType.DOZENS:
            return 'Dozens';
        case UnitType.GRAMS:
            return 'Grams';
        case UnitType.BALES:
            return 'Bales';
        case UnitType.POUNDS:
            return 'Pounds';
        case UnitType.STONE:
            return 'Stone';
        case UnitType.SETS:
            return 'Sets';
        case UnitType.INCHES:
            return 'Inches';
        default:
            return 'N/A';
    }
};
