import axios from 'axios';
import { saveAs } from 'file-saver';
import { CompanyDocument } from '../../../../../domain/models/company-doc.model';
import { useAlertContext } from '../../../use-alert.hook';
import useAppContext from '../../../use-context.hook';
import { useFileService } from '../../file/use-files-service';

interface DownloadDocumentInterface {
    document: CompanyDocument;
}

export default function useDownloadDocument() {
    const context = useAppContext();
    const { service } = useFileService();
    const { setAlert } = useAlertContext();

    const downloadDocument = async ({
        document,
    }: DownloadDocumentInterface) => {
        try {
            if (context.workspace?.id) {
                const token = await service.download(
                    context.workspace.id,
                    document.file,
                );

                const response = await axios.get(token.token, {
                    responseType: 'blob',
                });

                saveAs(response.data, token.attachment.originalName);
            }
        } catch (err) {
            const error = err as Error;

            setAlert({
                type: 'error',
                title: 'Download Certificate',
                message: `Failed to download certificate: ${error.message}`,
            });
        }
    };

    return downloadDocument;
}
