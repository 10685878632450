import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SupportDocType } from '../../../../domain/enums/support-doctype.enum';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useOrderService } from './use-order-service';

export interface UploadDocumentsInterface {
    id: number;
    documents: {
        type: SupportDocType;
        file: {
            assetName: string;
            container: string;
            contentType: string;
            originalName: string;
        };
    }[];
    poId: string;
}

export function useAttachOrder() {
    const context = useAppContext();
    const { service } = useOrderService();
    const { setAlert } = useAlertContext();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({ id, documents }: UploadDocumentsInterface) => {
            if (!context.workspace?.id) return;

            return await service.attach(context.workspace.id, id.toString(), {
                documents,
            });
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: ['orderSearch'],
            });

            client.invalidateQueries({
                queryKey: [
                    'order',
                    { workspace: context.workspace?.id, id: args.poId },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Order attached successfully',
                title: 'Attach Order',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to attach order: ${error.message}`,
                title: 'Attach Order',
            });
        },
    });
}
