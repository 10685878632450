import {
    Link,
    Progress,
    Select,
    Subtitle,
    Table,
    Text,
    Title,
} from '@ianneo/ui-library';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SupportDocType } from '../../../domain/enums/support-doctype.enum';
import { SalesOrder } from '../../../domain/models/sales-order.model';
import { useOrders } from '../../../infrastructure/hooks/api/order/use-orders';
import { useGetDelegations } from '../../_api/delegations/hooks/useGetDelegations';
import { Documents } from './documents';
import OrderLink from './order-link';
import OrderShipments from './order-shipments';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';
import { OrderImportCascade } from './order-import-cascade';

interface OrderLayoutProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const OrdersLayout: React.FC<OrderLayoutProps> = (args) => {
    const { t } = useTranslation();
    const { delegations } = useGetDelegations();
    const workspace = useAppContext().workspace;
    const [selected, setSelected] = useState(0);
    const [linkOpen, setLinkOpen] = useState(false);
    const [shipmentOpen, setShipmentOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<SalesOrder>({});
    const [openDoc, setOpenDoc] = useState(false);
    const [cascadeOpen, setCascadeOpen] = useState(false);

    const delegateId = delegations?.find((x) => x.id === selected)?.workspace
        ?.id;

    const { data } = useOrders(delegateId);

    const getOrderTitle = useCallback(
        (item: SalesOrder) => {
            if (item.externalCustomerReference && item.externalDataId) {
                if (workspace?.isCaseDisplayed) {
                    return `${item.externalCustomerReference} - ${item.externalDataId}`;
                } else {
                    return item.externalCustomerReference;
                }
            }

            if (item.externalCustomerReference) {
                return item.externalCustomerReference;
            }
            if (item.externalDataId) {
                return item.externalDataId;
            }
            return `Internal Order - ${item.purchaseOrderId?.slice(0, 6)}`;
        },
        [workspace?.isCaseDisplayed],
    );

    const columns = useMemo(
        () => [
            { title: t('order:listing.soNumber'), dataIndex: 'id' },
            {
                title: t('order:listing.poNumber'),
                render: (_: any, item: SalesOrder) => {
                    return getOrderTitle(item);
                },
            },
            {
                title: t('order:listing.currency'),
                dataIndex: 'currency',
                render: (_: any, item: SalesOrder) => (
                    <Text>{item.currency?.toLocaleUpperCase()}</Text>
                ),
            },
            {
                title: t('order:listing.fileCompletion'),
                render: (_: any, item: SalesOrder) => {
                    let totalDocuments = 0;
                    let uploadedDocuments = 0;

                    const purchase = item.purchaseOrder;

                    const rules =
                        purchase?.purchaseProcesses &&
                        purchase?.purchaseProcesses.length > 0
                            ? purchase?.purchaseProcesses
                            : purchase?.supplier?.seller?.processes ?? [];

                    const existingRules: SupportDocType[] = [];

                    purchase?.rules?.forEach((rule) => {
                        rule.documentation?.forEach((doc) => {
                            // let found = false;
                            let found = doc.appliesTo?.some((applyTo) =>
                                rules?.some((r) => r === applyTo),
                            );

                            if (found) {
                                totalDocuments++;
                            }
                        });

                        item.documents?.forEach((doc) => {
                            if (existingRules.includes(doc.type)) return;

                            const document = rule.documentation?.find(
                                (x) => x.document === doc.type,
                            );

                            let found =
                                document?.appliesTo?.some((applyTo) =>
                                    rules?.some((r) => r === applyTo),
                                ) || false;

                            if (found) {
                                existingRules.push(doc.type);
                                // existingRules.push(rule);
                                uploadedDocuments++;
                            }
                        });
                    });

                    return (
                        <Progress
                            percent={Math.round(
                                (uploadedDocuments / totalDocuments) * 100,
                            )}
                        />
                    );
                },
            },
            {
                title: t('order:listing.buyer'),
                render: (_: any, item: SalesOrder) => {
                    return <>{item.customer?.buyer?.name}</>;
                },
            },
            {
                title: t('order:listing.linkedPOs'),
                render: (_: any, item: SalesOrder) => {
                    return (
                        <>
                            <Link
                                onClick={() => {
                                    setLinkOpen(true);
                                    setSelectedOrder(item);
                                }}
                            >
                                {t('order:listing.linkPO')}
                            </Link>
                        </>
                    );
                },
            },
            {
                title: t('order:listing.documents'),
                render: (_: any, item: SalesOrder) => {
                    return (
                        <Link
                            onClick={() => {
                                setOpenDoc(true);
                                setSelectedOrder(item);
                            }}
                        >
                            {t('common:upload')}
                        </Link>
                    );
                },
            },
            {
                title: t('order:listing.shipments'),
                render: (_: any, item: SalesOrder) => {
                    return (
                        <Link
                            onClick={() => {
                                setShipmentOpen(true);
                                setSelectedOrder(item);
                            }}
                        >
                            {t('order:listing.shipments')}
                        </Link>
                    );
                },
            },
            {
                title: 'Import Cascade',
                render: (_: any, item: SalesOrder) => {
                    return (item?.links || []).length > 0 ? (
                        <Link
                            disabled
                            style={{
                                color: 'red',
                            }}
                        >
                            Uploaded
                        </Link>
                    ) : (
                        <Link
                            onClick={() => {
                                setCascadeOpen(true);
                                setSelectedOrder(item);
                            }}
                        >
                            Import
                        </Link>
                    );
                },
            },
            {
                title: t('common:updatedOn'),
                render: (_: any, item: SalesOrder) => {
                    return (
                        <>
                            {item.lastUpdatedOn
                                ? new Date(item.lastUpdatedOn)
                                      .toISOString()
                                      .slice(0, 10)
                                : ''}
                        </>
                    );
                },
            },
        ],
        [t, getOrderTitle],
    );

    function sortByID(array: SalesOrder[]) {
        return array.sort((a, b) => Number(a.id || 0) - Number(b.id || 0));
    }

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    marginBottom: '24px',
                }}
            >
                <div
                    style={{
                        width: '8px',
                        backgroundColor: '#972d47',
                        minHeight: '40px',
                        minWidth: '8px',
                    }}
                ></div>
                <Title>{t('order:name')}</Title>
                <Subtitle>{t('order:description')}</Subtitle>
            </div>

            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                    height: '100dch',
                    flex: 1,
                }}
            >
                <Table
                    dataSource={sortByID(data || [])}
                    columns={columns}
                    additionalFilters={
                        <div
                            style={{
                                display:
                                    !delegations || delegations.length === 0
                                        ? 'none'
                                        : 'flex',
                                gap: '8px',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ flex: 1 }}>For Company:</span>
                            <Select
                                options={delegations?.map((x) => {
                                    return {
                                        label: x.workspace?.company?.name,
                                        value: x.id,
                                    };
                                })}
                                style={{
                                    maxWidth: '200px',
                                    width: '200px',
                                    flex: 3,
                                }}
                                onChange={(value) => {
                                    setSelected(value);
                                }}
                                placeholder="None"
                                suffixIcon={null}
                                getPopupContainer={undefined}
                            ></Select>
                        </div>
                    }
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                />
            </div>

            {linkOpen ? (
                <OrderLink
                    selected={
                        delegations.find((x) => x.id === selected)?.workspace
                            ?.id
                    }
                    open={linkOpen}
                    setOpen={setLinkOpen}
                    order={selectedOrder}
                />
            ) : null}

            {openDoc && (
                <Documents
                    selected={
                        delegations.find((x) => x.id === selected)?.workspace
                            ?.id
                    }
                    open={openDoc}
                    order={selectedOrder}
                    delegateId={delegateId}
                    toggle={() => setOpenDoc((prev) => !prev)}
                />
            )}

            {shipmentOpen && (
                <OrderShipments
                    selected={
                        delegations.find((x) => x.id === selected)?.workspace
                            ?.id
                    }
                    open={shipmentOpen}
                    order={selectedOrder}
                    setOpen={setShipmentOpen}
                />
            )}

            {cascadeOpen && (
                <OrderImportCascade
                    order={selectedOrder}
                    onClose={() => setCascadeOpen(false)}
                />
            )}
        </>
    );
};

export default OrdersLayout;
