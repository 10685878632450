import React from 'react';
import ReactDOM from 'react-dom/client';
import '@ianneo/component-library/build/index.esm.css';
import { ConfigProvider } from '@ianneo/ui-library';
import '@ianneo/ui-library/dist/index.css';
import 'react-quill/dist/quill.snow.css';
import App from './app/App';
import './index.css';
import reportWebVitals from './reportWebVitals';

// moment().tz("Asia/Singapore").format();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <React.StrictMode>
        <ConfigProvider
            theme={{
                token: {
                    fontSize: 14,
                    fontSizeHeading1: 28,
                },
            }}
        >
            <App />
        </ConfigProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
