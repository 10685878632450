import {
    CheckOutlined,
    FileAddOutlined,
    PlusOutlined,
    UpSquareOutlined,
} from '@ant-design/icons';
import {
    CollapsibleDropdown,
    Link,
    Select,
    Subtitle,
    Table,
    Tag,
    Title,
    Tooltip,
} from '@ianneo/ui-library';
import { LinkIcon } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Supplier } from '../../../domain/models/supplier.model';
import { usePartners } from '../../../infrastructure/hooks/api/partner/use-partners';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';
import useToggle from '../../../infrastructure/hooks/use-toggle';
import useVerifyAdmin from '../../../infrastructure/hooks/use-verify-admin';
import { useCreateDelegation } from '../../_api/delegations/hooks/useCreateDelegation';
import { useGetDelegations } from '../../_api/delegations/hooks/useGetDelegations';
import Import from '../../components/Import/import';
import { AddPartnerComponent } from './add-partner';

interface PartnerLayoutProps {}

const PartnerLayout: React.FC<PartnerLayoutProps> = () => {
    const { t } = useTranslation();
    const context = useAppContext();
    const { isAdmin } = useVerifyAdmin();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const { status: importMode, toggle } = useToggle();
    const [selected, setSelected] = useState(0);

    const { delegations } = useGetDelegations();
    const { mutateAsync } = useCreateDelegation();
    const delegateId = delegations?.find((x) => x.id === selected)?.workspace
        ?.id;

    // Partner Listing data from hook.
    const { data } = usePartners(delegateId);

    const assignAsDelegate = useCallback(
        async (id?: string) => {
            await mutateAsync({
                workspace: id,
                delegate: delegateId || context.workspace?.id,
            });
        },
        [mutateAsync, context.workspace?.id, delegateId],
    );

    const columns = useMemo(() => {
        const col = [
            {
                title: t('partner:listing.table.company'),
                dataIndex: ['seller', 'name'],
                render: (_: any, item: Supplier) => {
                    const delegateId = delegations?.find(
                        (x) => x.id === selected,
                    )?.workspace?.id;
                    return (
                        <Link
                            onClick={() =>
                                navigate(
                                    delegateId
                                        ? `/partners/${item.id}/${delegateId}`
                                        : `/partners/${item.id}`,
                                )
                            }
                        >
                            {item.seller?.name}{' '}
                            {item.isNominated ? <CheckOutlined /> : null}
                        </Link>
                    );
                },
            },
            {
                title: t('partner:listing.table.process'),
                dataIndex: ['seller', 'processes'],
                render: (_: any, item: Supplier) => {
                    return (
                        <>
                            {item.seller?.processes?.map((process) => (
                                <Tag>{process}</Tag>
                            ))}
                        </>
                    );
                },
            },
            {
                title: t('partner:listing.table.facilities'),
                dataIndex: ['seller', 'facilities'],
                render: () => <>-</>,
            },
            {
                title: t('partner:listing.table.country'),
                dataIndex: ['company', 'company', 'country'],
            },
            {
                title: 'Engaged Date',
                dataIndex: ['createdOn'],
                render: (_: any, item: Supplier) => (
                    <>
                        {item.createdOn
                            ? new Date(item.createdOn).toLocaleDateString()
                            : '-'}
                    </>
                ),
            },
            {
                title: t('partner:listing.table.activatedOn'),
                dataIndex: ['activatedOn'],
                render: (_: any, item: Supplier) => (
                    <>
                        {item.activatedOn
                            ? new Date(item.activatedOn).toLocaleDateString()
                            : '-'}
                    </>
                ),
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                render: (_: any, item: Supplier) => (
                    <div
                        style={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                        }}
                    >
                        <Tooltip title="Raise Order">
                            <Link
                                onClick={() =>
                                    navigate(`/purchases?supplier=${item.id}`)
                                }
                            >
                                <UpSquareOutlined />
                            </Link>
                        </Tooltip>

                        <Tooltip title="Assign as Delegate">
                            <LinkIcon
                                onClick={() =>
                                    assignAsDelegate(item.seller?.id)
                                }
                                style={{
                                    height: '14px',
                                    width: '14px',
                                }}
                            />
                        </Tooltip>
                    </div>
                ),
            },
        ];
        if (isAdmin) {
            col.pop();
        }

        return col;
    }, [navigate, t, isAdmin, selected, delegations, assignAsDelegate]);

    const items = useMemo(() => {
        const items = [];

        if (!isAdmin) {
            items.push({
                label: t('user:listing.user.importBtn'),
                key: 'import',
                icon: <FileAddOutlined />,
                onClick: () => toggle(),
            });

            items.push({
                label: t('common:addNew'),
                key: 'create',
                icon: <PlusOutlined />,
                onClick: () => setOpen(true),
            });
        }

        return items;
    }, [t, toggle, isAdmin]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    marginBottom: '24px',
                }}
            >
                <div
                    style={{
                        width: '8px',
                        backgroundColor: '#972d47',
                        minHeight: '40px',
                        minWidth: '8px',
                    }}
                ></div>
                <Title>{t('partner:name')}</Title>
                <Subtitle>{t('partner:description')}</Subtitle>
            </div>

            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                    height: '100dch',
                    flex: 1,
                }}
            >
                <Table
                    dataSource={data}
                    columns={columns}
                    rowKey="id"
                    additionalFilters={
                        <div
                            style={{
                                display:
                                    !delegations || delegations.length === 0
                                        ? 'none'
                                        : 'flex',
                                gap: '8px',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ flex: 1 }}>For Company:</span>
                            <Select
                                options={delegations?.map((x) => {
                                    return {
                                        label: x.workspace?.company?.name,
                                        value: x.id,
                                    };
                                })}
                                style={{
                                    maxWidth: '200px',
                                    width: '200px',
                                    flex: 3,
                                }}
                                onChange={(value) => {
                                    setSelected(value);
                                }}
                                placeholder="None"
                                suffixIcon={null}
                                getPopupContainer={undefined}
                            ></Select>
                        </div>
                    }
                    scroll={{ x: 'max-content' }}
                    actions={
                        <CollapsibleDropdown
                            menu={{
                                items,
                            }}
                        />
                    }
                />
            </div>

            {open ? (
                <AddPartnerComponent open={open} setOpen={setOpen} />
            ) : null}

            <Import type="suppliers" open={importMode} setOpen={toggle} />
        </>
    );
};

export default PartnerLayout;
