import { Calendar, Select, Tag } from '@ianneo/ui-library';
import moment, { Moment } from 'moment';
import { useCallback, useMemo } from 'react';

interface MultiDatePickerProps {
    onChange: (date: Moment[]) => any;
    format?: string;
    value: Moment[];
}

function toValidArray(value: any) {
    const arr = Array.isArray(value) ? value : [value];
    return arr.filter((e) => e != null && e !== undefined); // must be ==
}

const MultiDatePicker: React.FC<MultiDatePickerProps> = ({
    value,
    onChange,
    format = 'DD/MM/YYYY',
}) => {
    const arrValues: moment.Moment[] = useMemo(
        () => toValidArray(value),
        [value],
    );

    const handleSelect = useCallback(
        (selected: moment.Moment, selectInfo: any) => {
            if (selectInfo.source !== 'date') {
                return;
            }

            if (
                arrValues.some(
                    (e) =>
                        e.date() === selected.date() &&
                        e.month() === selected.month() &&
                        e.year() === selected.year(),
                )
            ) {
                const selectedDate = selected.format('DD/MM/YYYY');
                const cloned = arrValues.filter(
                    (x) => x.format('DD/MM/YYYY') !== selectedDate,
                );

                onChange(cloned);
            } else {
                const temp = [...arrValues, selected];
                onChange(temp);
            }
        },
        [arrValues, onChange],
    );

    const renderTag = useCallback(
        ({ value, onClose }: any) => {
            return (
                <Tag onClose={onClose} closable>
                    {value?.format(format)}
                </Tag>
            );
        },
        [format],
    );

    const customRenderDate = useCallback(
        (current: moment.Moment) => {
            if (
                arrValues.some(
                    (e) =>
                        e.date() === current.date() &&
                        e.month() === current.month() &&
                        e.year() === current.year(),
                )
            ) {
                return (
                    <div
                        className={'selectedDate'}
                        style={{ borderColor: 'blue' }}
                    >
                        {current.format('DD')}
                    </div>
                );
            }

            return <div>{current.format('DD')}</div>;
        },
        [arrValues],
    );

    const onDeselect = useCallback(
        (oldSelect: any) => {
            console.log(oldSelect);
            const newVal = arrValues.filter(
                (e) =>
                    !(
                        e.date() === oldSelect.date() &&
                        e.month() === oldSelect.month() &&
                        e.year() === oldSelect.year()
                    ),
            );
            onChange(newVal);
        },
        [arrValues, onChange],
    );

    return (
        <>
            <Select
                className="select"
                mode="tags"
                value={arrValues.map((x) => {
                    return {
                        value: x,
                    };
                })}
                tagRender={(props) => renderTag(props)}
                onDeselect={onDeselect}
                onClear={() => onChange([])}
                dropdownRender={() => {
                    return (
                        <div className={'calendar'}>
                            <Calendar
                                fullscreen={false}
                                dateFullCellRender={customRenderDate}
                                onSelect={handleSelect}
                            />
                        </div>
                    );
                }}
            />
        </>
    );
};

export default MultiDatePicker;
