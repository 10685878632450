import { Card, RadioGroup, Subtitle, Title } from '@ianneo/ui-library';
import RoleList from './role-list';
import UserList from './user-list';
import { useTranslation } from 'react-i18next';

interface UserLayoutProps {
    menu: 'users' | 'roles' | 'permissions';
    menuOptions: { label: string; value: string }[];
    setMenu: (args: 'users' | 'roles' | 'permissions') => void;
}

const UserLayout: React.FC<UserLayoutProps> = (props) => {
    const { t } = useTranslation();
    const onRadioChange = (value: any) => {
        props.setMenu(value.target.value);
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    marginBottom: '24px',
                }}
            >
                <div
                    style={{
                        width: '8px',
                        backgroundColor: '#972d47',
                        minHeight: '40px',
                        minWidth: '8px',
                    }}
                ></div>
                <Title>{t('user:name')}</Title>
                <Subtitle>{t('user:description')}</Subtitle>
            </div>

            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                    height: '100dch',
                    flex: 1,
                }}
            >
                <RadioGroup
                    options={props.menuOptions}
                    defaultValue={props.menu}
                    onChange={onRadioChange}
                    optionType="button"
                />

                <Card style={{ flex: 1 }}>
                    {props.menu === 'users' ? <UserList /> : null}
                    {props.menu === 'roles' ? <RoleList /> : null}
                </Card>
            </div>
        </>
    );
};

export default UserLayout;
