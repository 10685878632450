import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAppContext from '../../use-context.hook';
import { useMaterialService } from './use-material-service';
import { useAlertContext } from '../../use-alert.hook';

export function useDeleteMaterial() {
    const context = useAppContext();
    const client = useQueryClient();
    const { service } = useMaterialService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({
            id,
            delegateId,
        }: {
            id: string;
            delegateId?: string;
        }) => {
            if (!context.workspace?.id || !id) return;
            await service.delete(delegateId || context.workspace.id, id);
        },
        onSuccess: (_, { delegateId }) => {
            client.invalidateQueries({
                queryKey: [
                    'materials',
                    { type: delegateId || context.workspace?.id },
                ],
            });

            setAlert({
                type: 'success',
                message: 'Material deleted successfully',
                title: 'Delete Material',
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to delete material: ${error.message}`,
                title: 'Delete Material',
            });
        },
    });
}
