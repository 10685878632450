import {
    DeleteOutlined,
    FileAddOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import {
    CollapsibleDropdown,
    Form,
    FormItem,
    Input,
    Select,
    StepModal,
    Table,
    Text,
    useForm,
} from '@ianneo/ui-library';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Member } from '../../../../domain/models/members.model';
import { useInviteMember } from '../../../../infrastructure/hooks/api/me/use-invite-member';
import { useRoles } from '../../../../infrastructure/hooks/api/role/use-roles';
import {
    UpdateMemberParam,
    useUpdateRole,
} from '../../../../infrastructure/hooks/api/role/use-update-role';
import { useMembers } from '../../../../infrastructure/hooks/api/workspaces/use-members';
import useVerifyAdmin from '../../../../infrastructure/hooks/use-verify-admin';
import { useGetDelegations } from '../../../_api/delegations/hooks/useGetDelegations';
import Import from '../../../components/Import/import';
import { DelegationComponent } from '../../Partners/add-partner/delegations.component';

interface UserListComponentProps {}

const UserListComponent: React.FC<UserListComponentProps> = () => {
    // react-i18next translation hook
    const { t } = useTranslation();

    // States of the current component
    const [form] = useForm();
    const [open, setOpen] = useState(false);
    const [importMode, setImportMode] = useState(false);

    // Fetching data from hooks
    // const { data } = useMembers();

    const { delegations } = useGetDelegations();
    const [selected, setSelected] = useState(0);
    const [onBehalfOf, setOnBehalfOf] = useState(0);
    const { data } = useMembers(
        delegations?.find((x) => x.id === selected)?.workspace?.id,
    );
    const { data: roleData } = useRoles(
        delegations?.find((x) => x.id === onBehalfOf)?.workspace?.id,
    );

    // Mutation hooks
    const { mutateAsync: invite } = useInviteMember();
    const { mutateAsync: remove } = useUpdateRole();

    const { isAdmin } = useVerifyAdmin();

    // Component functions
    const onInvite = async () => {
        await form.validateFields();

        const args = form.getFieldsValue(true);
        const request = {
            target: {
                email: args.email,
                firstName: args.firstName,
                lastName: args.lastName,
            },
            role: args.role,
        };

        await invite({
            params: request,
            workspaceId: delegations?.find((x) => x.id === onBehalfOf)
                ?.workspace?.id,
        });

        reset();
    };

    const reset = () => {
        setOpen(false);
        form.resetFields();
    };

    const onRemove = useCallback(
        async (args: Member) => {
            const request: UpdateMemberParam[] =
                data
                    ?.filter((x) => x.roles?.[0].id === args.roles?.[0].id)
                    ?.filter((x) => x.user?.email === args.user?.email)
                    .map((x) => ({ email: x.user?.email, title: x.title })) ||
                [];

            await remove({
                id: args.roles?.[0].id || '',
                args: {
                    members: request,
                },
                workspaceId: delegations?.find((x) => x.id === selected)
                    ?.workspace?.id,
            });
        },
        [data, remove, delegations, selected],
    );

    const columns = useMemo(() => {
        const col = [
            {
                title: t('user:listing.user.email'),
                dataIndex: ['user', 'email'],
            },
            { title: t('user:listing.user.title'), dataIndex: 'title' },
            {
                title: t('user:listing.user.role'),
                dataIndex: ['roles', 0, 'code'],
                render: (_: any, item: Member) => {
                    const roles = item.roles
                        ?.filter((role: any) => role.code !== undefined)
                        ?.map((role: any) => {
                            return role.code;
                        })
                        .join(',');
                    return (
                        <Text>{(roles || []).length > 0 ? roles : 'N/A'}</Text>
                    );
                },
            },
            {
                title: t('common:actions'),
                dataIndex: 'actions',
                render: (_: any, item: any) => (
                    <DeleteOutlined
                        style={{ color: '#972d47' }}
                        onClick={() => onRemove(item)}
                    />
                ),
            },
        ];

        if (isAdmin) {
            col.pop();
        }

        return col;
    }, [onRemove, t, isAdmin]);

    const items = useMemo(() => {
        const items = [];
        if (!isAdmin) {
            items.push({
                label: t('user:listing.user.importBtn'),
                onClick: () => setImportMode(true),
                icon: <FileAddOutlined />,
                key: 'import',
            });

            items.push({
                label: 'Add User',
                onClick: () => setOpen(true),
                icon: <UserAddOutlined />,
                key: 'add',
            });
        }

        return items;
    }, [t, isAdmin]);

    return (
        <>
            <Table
                dataSource={data || []}
                columns={columns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                additionalFilters={
                    <div
                        style={{
                            display:
                                !delegations || delegations.length === 0
                                    ? 'none'
                                    : 'flex',
                            gap: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <span style={{ flex: 1 }}>For Company:</span>
                        <Select
                            options={delegations?.map((x) => {
                                return {
                                    label: x.workspace?.company?.name,
                                    value: x.id,
                                };
                            })}
                            style={{
                                maxWidth: '200px',
                                width: '200px',
                                flex: 3,
                            }}
                            onChange={(value) => {
                                setSelected(value);
                            }}
                            placeholder="None"
                            suffixIcon={null}
                            getPopupContainer={undefined}
                        ></Select>
                    </div>
                }
                actions={<CollapsibleDropdown menu={{ items }} />}
            />

            <StepModal
                open={open}
                title={t('user:modal.invite.title')}
                closable={false}
                okFn={onInvite}
                cancelFn={reset}
                width={800}
                stepContent={[
                    {
                        title: 'On Behalf Of.',
                        content: (
                            <DelegationComponent
                                form={form}
                                onBehalfOf={onBehalfOf}
                                setOnBehalfOf={setOnBehalfOf}
                            />
                        ),
                    },
                    {
                        title: 'Nominated List',
                        content: (
                            <>
                                <Form form={form}>
                                    <FormItem
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                type: 'email',
                                                message:
                                                    'Please enter a valid email address',
                                            },
                                        ]}
                                        label={t('user:modal.invite.email')}
                                    >
                                        <Input placeholder="Email" />
                                    </FormItem>

                                    <FormItem
                                        name="firstName"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please enter a first name',
                                            },
                                        ]}
                                        label={t('user:modal.invite.firstName')}
                                    >
                                        <Input placeholder="First Name" />
                                    </FormItem>

                                    <FormItem
                                        name="lastName"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please enter a last name',
                                            },
                                        ]}
                                        label={t('user:modal.invite.lastName')}
                                    >
                                        <Input placeholder="Last Name" />
                                    </FormItem>

                                    <FormItem
                                        name="role"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    ' Please select a role',
                                            },
                                        ]}
                                        label={t('user:modal.invite.role')}
                                    >
                                        <Select
                                            placeholder="Select Role"
                                            options={roleData?.map((x) => ({
                                                label:
                                                    x.name?.locales?.find(
                                                        (x) =>
                                                            x.localeName ===
                                                            'en',
                                                    )?.text || x.code,
                                                value: x.code,
                                            }))}
                                            showSearch={true}
                                        ></Select>
                                    </FormItem>
                                </Form>
                            </>
                        ),
                    },
                ]}
            ></StepModal>

            <Import type="users" open={importMode} setOpen={setImportMode} />
        </>
    );
};

export default UserListComponent;
