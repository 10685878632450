import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { Company } from '../../domain/models/company.model';
import { ApiServer } from './api.server';

export class CompanyApiClient extends ApiServer<Company> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'company');
    }

    load(workspace: string) {
        console.log(`client<company>| load(): Enter`);
        console.log(`client<company>| load(): $nworkspaceame = ${workspace}`);
        return super.get('get', workspace);
    }

    search(name?: string) {
        console.log(`client<company>| search(): Enter`);
        console.log(`client<company>| search(): $name = ${name}`);
        return super.get('search', name);
    }

    update(workspace: string, company: Company) {
        console.log(`client<company>| search(): Enter`);
        console.log(`client<company>| search(): $workspace = ${workspace}`);
        console.log(
            `client<company>| search(): $company = ${JSON.stringify(company)}`,
        );
        return super.patch('update', workspace, { entry: company });
    }

    solicitDocs(workspace: string, partner: string) {
        console.log(`client<company>| solicitDocs(): Enter`);
        console.log(
            `client<company>| solicitDocs(): $workspace = ${workspace}`,
        );
        console.log(`client<company>| solicitDocs(): $partner = ${partner}`);

        return super.get('solicitDocuments', workspace, partner);
    }
}
