/**
 * @enum
 * @name RelationRequester
 * @description the identity of twho is requesting the connection
 * @author Mark Leung <markleungcl@lfxdigit.com>
 */
export enum RelationRequester {
  BUYER_CONNECT = 'BUYER_CONNECT',
  SELLER_CONNECT = 'SELLER_CONNECT',
}
