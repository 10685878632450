import common from './common.json';
import company from './company.json';
import dashboard from './dashboard.json';
import invite from './invite.json';
import material from './material.json';
import onboarding from './onboarding.json';
import order from './order.json';
import partner from './partner.json';
import product from './product.json';
import purchase from './purchase.json';
import roles from './roles.json';
import ruleset from './rulesets.json';
import user from './user.json';
import workspace from './workspace.json';

// Joins all the translation exports into one.
const exports = {
    common: common,
    company: company,
    dashboard: dashboard,
    invite: invite,
    material: material,
    onboarding: onboarding,
    order: order,
    partner: partner,
    product: product,
    purchase: purchase,
    role: roles,
    ruleset: ruleset,
    user: user,
    workspace: workspace,
};

export default exports;
