import { UserAddOutlined } from '@ant-design/icons';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from '../../../../domain/models/role.model';
import { useCreateRole } from '../../../../infrastructure/hooks/api/role/use-create-role';
import { useDeleteRole } from '../../../../infrastructure/hooks/api/role/use-delete-role';
import { useRoles } from '../../../../infrastructure/hooks/api/role/use-roles';
import PermissionListComponent from '../permission-list/permission-list.component';
import useVerifyAdmin from '../../../../infrastructure/hooks/use-verify-admin';
import {
    CollapsibleDropdown,
    Form,
    FormItem,
    Input,
    Link,
    Modal,
    Select,
    Space,
    StepModal,
    Table,
    Text,
    useForm,
} from '@ianneo/ui-library';
import { useGetDelegations } from '../../../_api/delegations/hooks/useGetDelegations';
import { DelegationComponent } from '../../Partners/add-partner/delegations.component';

interface RoleListComponentProps {}

const RoleListComponent: React.FC<RoleListComponentProps> = () => {
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);
    const [permissionMode, setPermissionMode] = useState(false);

    const [selected, setSelected] = useState<Role>({});
    const [form] = useForm();

    const { delegations } = useGetDelegations();
    const [delegation, setDelegation] = useState(0);
    const [onBehalfOf, setOnBehalfOf] = useState(0);
    const { data } = useRoles(
        delegations?.find((x) => x.id === delegation)?.workspace?.id,
    );

    const { mutate: create } = useCreateRole();
    const { mutate: remove } = useDeleteRole();

    const { isAdmin } = useVerifyAdmin();

    const onCreate = async () => {
        await form.validateFields();

        const args = form.getFieldsValue(true);
        const request = {
            name: {
                locales: [
                    {
                        localeName: 'en',
                        text: args.name,
                    },
                ],
            },
            description: {
                locales: [
                    {
                        localeName: 'en',
                        text: args.description,
                    },
                ],
            },
            code: args.code,
        };

        await create({
            args: request,
            workspaceId: delegations.find((x) => x.id === onBehalfOf)?.workspace
                ?.id,
        });

        reset();
    };

    const reset = () => {
        form.resetFields();
        setOpen(false);
    };

    const columns = useMemo(() => {
        const col = [
            {
                title: t('user:listing.role.name'),
                dataIndex: 'name',
                render: (_: any, item: Role) => (
                    <>
                        {
                            item.name?.locales?.find(
                                (x) => x.localeName === 'en',
                            )?.text
                        }
                    </>
                ),
            },
            {
                title: t('user:listing.role.description'),
                dataIndex: 'description',
                render: (_: any, item: Role) => (
                    <>
                        <Text>
                            {
                                item.description?.locales?.find(
                                    (x) => x.localeName === 'en',
                                )?.text
                            }
                        </Text>
                    </>
                ),
            },
            { title: t('user:listing.role.code'), dataIndex: 'code' },
            {
                title: t('user:listing.role.permissions'),
                dataIndex: 'permissions',
                render: (_: any, item: Role) => (
                    <>
                        <Link
                            disabled={item.code === 'SYS_ADMIN'}
                            onClick={() => {
                                setSelected(item);
                                setPermissionMode(true);
                            }}
                        >
                            {t('user:listing.role.update')}
                        </Link>
                    </>
                ),
            },
            {
                title: t('common:actions'),
                dataIndex: 'actions',
                render: (_: any, item: Role) => (
                    <>
                        <Space>
                            <Link
                                disabled={item.code === 'SYS_ADMIN'}
                                onClick={() => {
                                    if (!item.id) return;
                                    remove({
                                        id: item.id,
                                        workspaceId: delegations.find(
                                            (x) => x.id === delegation,
                                        )?.workspace?.id,
                                    });
                                }}
                            >
                                {t('common:delete')}
                            </Link>
                        </Space>
                    </>
                ),
            },
        ];

        if (isAdmin) {
            col.pop();
            col.pop();
        }

        return col;
    }, [remove, t, isAdmin, delegations, delegation]);

    const items = useMemo(() => {
        const items = [];
        if (!isAdmin) {
            items.push({
                label: t('user:modal.role.addBtn'),
                onClick: () => setOpen(true),
                icon: <UserAddOutlined />,
                key: 'edit',
            });
        }

        return items;
    }, [t, isAdmin]);

    return (
        <>
            <Table
                dataSource={data}
                columns={columns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                additionalFilters={
                    <div
                        style={{
                            display:
                                !delegations || delegations.length === 0
                                    ? 'none'
                                    : 'flex',
                            gap: '8px',
                            alignItems: 'center',
                        }}
                    >
                        <span style={{ flex: 1 }}>For Company:</span>
                        <Select
                            options={delegations?.map((x) => {
                                return {
                                    label: x.workspace?.company?.name,
                                    value: x.id,
                                };
                            })}
                            style={{
                                maxWidth: '200px',
                                width: '200px',
                                flex: 3,
                            }}
                            onChange={(value) => {
                                setDelegation(value);
                            }}
                            placeholder="None"
                            suffixIcon={null}
                            getPopupContainer={undefined}
                        ></Select>
                    </div>
                }
                actions={
                    <CollapsibleDropdown
                        menu={{
                            items,
                        }}
                    ></CollapsibleDropdown>
                }
            />

            <StepModal
                title={t('user:modal.role.title')}
                open={open}
                closable={false}
                okFn={onCreate}
                width={800}
                cancelFn={reset}
                stepContent={[
                    {
                        title: 'On Behalf Of.',
                        content: (
                            <DelegationComponent
                                form={form}
                                onBehalfOf={onBehalfOf}
                                setOnBehalfOf={setOnBehalfOf}
                            />
                        ),
                    },
                    {
                        title: t('user:modal.role.title'),
                        content: (
                            <Form form={form}>
                                <FormItem
                                    name="name"
                                    label={t('user:modal.role.name')}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a name',
                                        },
                                    ]}
                                >
                                    <Input />
                                </FormItem>

                                <FormItem
                                    name="code"
                                    label={t('user:modal.role.code')}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a code',
                                        },
                                    ]}
                                >
                                    <Input />
                                </FormItem>

                                <FormItem
                                    name="description"
                                    label={t('user:modal.role.description')}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter a description',
                                        },
                                    ]}
                                >
                                    <Input />
                                </FormItem>
                            </Form>
                        ),
                    },
                ]}
            ></StepModal>

            <Modal
                title={t('user:modal.role.title')}
                // open={open}
                closable={false}
                okFn={onCreate}
                cancelFn={reset}
            ></Modal>

            <PermissionListComponent
                open={permissionMode}
                setOpen={setPermissionMode}
                data={selected}
                delegateId={
                    delegations.find((x) => x.id === delegation)?.workspace?.id
                }
            />
        </>
    );
};

export default RoleListComponent;
