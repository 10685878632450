import { useQuery } from '@tanstack/react-query';
import useAppContext from '../../../../infrastructure/hooks/use-context.hook';
import { useDelegationService } from './useDelegationService';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { DelegationModel } from '../delegation.model';

export function useGetDelegations() {
    const context = useAppContext();
    const service = useDelegationService();

    const { data, isError } = useQuery({
        queryKey: ['delegations', 'list'],
        queryFn: async () => {
            return await service.list(context.workspace?.id || '');
        },
        enabled: true,
    });

    useEffect(() => {
        if (isError) {
            toast.error('Failed to get delegations');
        }
    }, [isError]);

    return {
        delegations: data as DelegationModel[],
        isError,
    };
}
