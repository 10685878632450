import { UploadOutlined } from '@ant-design/icons';
import {
    Link,
    Modal,
    Space,
    Table,
    Upload,
    UploadFile,
} from '@ianneo/ui-library';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import certfications from '../../../app/certificates.json';
import { SupportDocType } from '../../../domain/enums/support-doctype.enum';
import {
    PurchaseOrderTrace,
    PurchaseOrderVersion,
} from '../../../domain/models/purchase.model';
import { useAttachOrder } from '../../../infrastructure/hooks/api/order/use-order-attach';
import { useUploadPurchaseFiles } from '../../../infrastructure/hooks/api/purchases/use-upload-purchase-files';
import { PurchaseDocumentItem } from './purchase-documents.component';
import './purchase.css';

interface PurchaseMaterialDocumentsProps {
    order: PurchaseOrderVersion | PurchaseOrderTrace;
    documentItem: PurchaseDocumentItem;
}

const isPurchaseOrderVersion = (
    item: PurchaseOrderVersion | PurchaseOrderTrace,
): item is PurchaseOrderVersion => {
    return (item as PurchaseOrderVersion).owner?.id !== undefined;
};

interface PurchaseMaterialDocumentItem {
    certificate: string;
    files: UploadFile[];
}

export const PurchaseMaterialDocuments: React.FC<
    PurchaseMaterialDocumentsProps
> = ({ order, documentItem }) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<PurchaseMaterialDocumentItem[]>([]);
    const [removedFiles, setRemovedFiles] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const { mutateAsync: upload } = useUploadPurchaseFiles();
    const { mutateAsync: attach } = useAttachOrder();

    const certificatesRequired = useMemo(() => {
        const uniqueCertificates = new Set<string>();

        order.manifest?.forEach((x) => {
            x.purchaseables.compositions?.forEach((y) => {
                y.certificatesRequired?.forEach((z) => {
                    uniqueCertificates.add(z);
                });
            });
        });

        return Array.from(uniqueCertificates);
    }, [order]);

    const onUpload = useCallback((f: UploadFile[], type: string) => {
        setFiles((x) => {
            return x.map((y) => {
                if (y.certificate === type) {
                    return {
                        certificate: y.certificate,
                        files: f,
                    };
                }

                return y;
            });
        });
    }, []);

    useEffect(() => {
        setFiles(
            certificatesRequired.map((x) => {
                const existingFiles = documentItem.documents
                    .filter(
                        (y) =>
                            y.type === SupportDocType.PRODUCT_CERTIFICATE &&
                            y.certificateNumber ===
                                certfications.find((z) => z.name === x)?.code,
                    )
                    .map(
                        (y) =>
                            ({
                                uid: y.id?.toString() || '',
                                name: y.file.originalName,
                            } satisfies UploadFile),
                    );

                return { certificate: x, files: [...existingFiles] };
            }),
        );
    }, [certificatesRequired, open, documentItem.documents]);

    const columns = useMemo(() => {
        return [
            {
                title: t('purchase:detail.materials.certificate'),
                dataIndex: 'certificate',
                width: '50%',
            },
            {
                title: t('common:upload'),
                dataIndex: 'file',
                width: '50%',
                render: (_: unknown, item: PurchaseMaterialDocumentItem) => {
                    return (
                        <Upload
                            files={item.files}
                            showUploadList
                            onChange={({ file, fileList }) => {
                                if (file.status === 'removed') {
                                    setRemovedFiles((x) => [...x, file.uid]);
                                }

                                onUpload(fileList, item.certificate);
                            }}
                            onPreview={() => null}
                        >
                            <Link>
                                <Space>
                                    <UploadOutlined />
                                    {t('common:upload')}
                                </Space>
                            </Link>
                        </Upload>
                    );
                },
            },
        ];
    }, [t, onUpload]);

    const openModal = () => {
        setOpen(true);
    };

    const reset = () => {
        setFiles([]);
        setRemovedFiles([]);
        setOpen(false);
    };

    const submit = async () => {
        const documents = _.cloneDeep(documentItem.documents);
        const docs = _.cloneDeep(files);
        const uploadResponses = await Promise.all(
            docs.map(async (x) => {
                const response = await upload({
                    certificateNumber:
                        certfications.find((y) => y.name === x.certificate)
                            ?.code || '',
                    type: SupportDocType.PRODUCT_CERTIFICATE,
                    files: x.files.filter((y) => y.originFileObj),
                });

                return response;
            }),
        );

        uploadResponses.forEach((x) => {
            if (x === undefined) return;

            documents.push(...x);
        });

        await attach({
            id: Number(documentItem.id),
            documents: documents.filter(
                (x) => !removedFiles.includes(x.id?.toString() || ''),
            ),
            poId: isPurchaseOrderVersion(order)
                ? order.owner?.id || ''
                : order.supplier?.owner?.id || '',
        });

        reset();
    };

    return (
        <>
            <Link onClick={openModal}>
                {t('purchase:detail.documents.documents')}
            </Link>

            <Modal
                title={t('purchase:detail.materials.title')}
                open={open}
                cancelFn={reset}
                okFn={submit}
            >
                <Table
                    columns={columns}
                    dataSource={files}
                    showColumns={false}
                    showFilter={false}
                    showSearch={false}
                    scroll={{ x: 'max-content' }}
                />
            </Modal>
        </>
    );
};
