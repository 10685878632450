import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDownloadFile } from '../../../../infrastructure/hooks/api/file/use-download-file';
import { useProduct } from '../../../../infrastructure/hooks/api/products/use-product';
import ProductDetailLayout from './product-detail.layout';
import { useTranslation } from 'react-i18next';

interface ProductDetailComponentProps {}

const ProductDetailComponent: React.FC<ProductDetailComponentProps> = () => {
    const { t } = useTranslation();
    const { id, delegateId } = useParams<{ id: string; delegateId?: string }>();
    const { data } = useProduct({ id, delegateId });
    const { mutateAsync: downloadFile } = useDownloadFile();

    const [menu, setMenu] = useState<'overview' | 'materials'>('overview');
    const menuOptions = useMemo(
        () => [
            { label: t('product:detail.menu.overview'), value: 'overview' },
            { label: t('product:detail.menu.materials'), value: 'materials' },
        ],
        [t],
    );
    const [images, setImages] = useState<string[]>([]);

    const getImages = useCallback(async () => {
        const promises = (data?.product?.pictures || []).map(async (x) => {
            const res = await downloadFile({ attachment: x });
            return res?.token;
        });

        const response = await Promise.all(promises);

        setImages(response as string[]);
    }, [downloadFile, data?.product?.pictures]);

    useEffect(() => {
        getImages();
    }, [data, getImages]);

    return (
        <ProductDetailLayout
            data={data || {}}
            menu={menu}
            images={images}
            setMenu={setMenu}
            menuOptions={menuOptions}
        />
    );
};

export default ProductDetailComponent;
