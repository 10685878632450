import {
    Checkbox,
    Form,
    FormItem,
    Input,
    Modal,
    Select,
    useForm,
} from '@ianneo/ui-library';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyDocumentType } from '../../../../domain/enums/company-doctype.enum';
import { CompanyDocument } from '../../../../domain/models/company-doc.model';
import {
    UpdateCompanyDocumentParams,
    useUpdateDocument,
} from '../../../../infrastructure/hooks/api/company/documents/use-edit-document';

interface EditDocumentsProps {
    open: boolean;
    data?: CompanyDocument;
    toggle: () => void;
}

export default function EditDocuments({
    open,
    data,
    toggle,
}: EditDocumentsProps) {
    const { t } = useTranslation();
    const [form] = useForm();

    const { mutateAsync: update } = useUpdateDocument();

    const reset = () => {
        form.resetFields();
        toggle();
    };

    const submit = async () => {
        try {
            await form.validateFields();
            const values = form.getFieldsValue(true);
            const params: UpdateCompanyDocumentParams = {
                nature: values.nature,
                comment: values.comment,
                isPrivate: values.isPrivate,
            };

            await update({ id: data?.id || '', params: params });

            reset();
        } catch (err) {}
    };

    useEffect(() => {
        if (open) {
            form.setFieldsValue(data);
        }
    }, [data, form, open]);

    return (
        <Modal
            title={t('company:documents.edit.title')}
            open={open}
            okFn={submit}
            cancelFn={reset}
        >
            <Form form={form}>
                <FormItem
                    label={t('company:documents.docName')}
                    name={['file', 'originalName']}
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Please input the document name!',
                        },
                    ]}
                >
                    <Input disabled />
                </FormItem>

                <FormItem
                    label={t('company:documents.type')}
                    name="nature"
                    required
                    rules={[
                        {
                            required: true,
                            message: 'Please input the type!',
                        },
                    ]}
                >
                    <Select
                        options={Object.values(CompanyDocumentType).map(
                            (x) => ({
                                label: x,
                                value: x,
                                key: x,
                            }),
                        )}
                    />
                </FormItem>

                <FormItem
                    label={t('company:documents.comment')}
                    name="comment"
                    rules={[
                        {
                            required: true,
                            message: 'Please input the comment!',
                        },
                        {
                            pattern: new RegExp('.*[^ ].*'),
                            message:
                                'Whitespace for starting / ending characters is not allowed.',
                        },
                    ]}
                >
                    <Input />
                </FormItem>

                <FormItem
                    label={t('company:documents.modal.isPrivate')}
                    name="isPrivate"
                    valuePropName="checked"
                >
                    <Checkbox />
                </FormItem>
            </Form>
        </Modal>
    );
}
