import { useQuery } from '@tanstack/react-query';
import { useOrderService } from './use-order-service';
import { useAlertContext } from '../../use-alert.hook';
import { SalesOrder } from '../../../../domain/models/sales-order.model';

export function useOrderBatch(purchases: string[]) {
    const { service } = useOrderService();
    const { setAlert } = useAlertContext();

    return useQuery<SalesOrder[]>({
        queryKey: ['order-batch', ...purchases],
        queryFn: async () => {
            return await service.batch(purchases);
        },
        onError: (err) => {
            setAlert({
                type: 'error',
                message: 'Failed to batch order',
                title: 'Batch Order',
            });
        },
    });
}
