import { useQuery } from '@tanstack/react-query';
import { Certification } from '../../../../domain/models/certification.model';
import { AwardService } from '../../../../domain/services/award.service';
import { useAlertContext } from '../../use-alert.hook';
import { useAwardService } from './use-award-service';

const getAwardTypes = async (
    service: AwardService,
): Promise<Certification[]> => {
    const response = await service.listCerts();

    return response;
};

export function useAwardTypes() {
    const { service } = useAwardService();
    const { setAlert } = useAlertContext();

    return useQuery({
        queryKey: ['companyAwardTypes'],
        queryFn: async () => {
            return await getAwardTypes(service);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch company documents information: ${error.message}`,
            });
        },
    });
}
