import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAppContext from '../../use-context.hook';
import { useNominatedListService } from './use-nominated-list.service';
import { useAlertContext } from '../../use-alert.hook';
import { AxiosError } from 'axios';

export function useDeleteNominatedList() {
    const context = useAppContext();
    const client = useQueryClient();

    const { service } = useNominatedListService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({
            id,
            workspaceId,
        }: {
            id: number;
            workspaceId?: string;
        }) => {
            if (!context.workspace?.id || !id) return;
            await service.delete(workspaceId || context.workspace.id, id);
        },
        onSuccess: (_, { workspaceId }) => {
            client.invalidateQueries({
                queryKey: [
                    'nominated-list',
                    { workspace: workspaceId || context.workspace?.id },
                ],
            });
            setAlert({
                type: 'success',
                title: 'Nominated List',
                message: 'Successfully deleted nominated list',
            });
        },
        onError: (err) => {
            const error = err as Error;

            if (err instanceof AxiosError) {
                if (err.response?.status === 403) {
                    setAlert({
                        type: 'error',
                        title: 'Nominated List',
                        message:
                            'You do not have permission to delete nominated list',
                    });
                    return;
                }
            }

            setAlert({
                type: 'error',
                title: 'Internal Server Error',
                message: `Failed to delete nominated list: ${error.message}`,
            });
        },
    });
}
