import { useMutation } from '@tanstack/react-query';
import useAppContext from '../../use-context.hook';
import { useLinkService } from './use-links-service';
import { useAlertContext } from '../../use-alert.hook';

export interface LinkParams {
    id: string;
    orders: {
        purchaseOrders: string[];
    };
    workspaceId?: string;
}

export function useCreateLink() {
    const context = useAppContext();
    const { service } = useLinkService();
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ id, orders, workspaceId }: LinkParams) => {
            if (!context.workspace?.id || !id) return;

            await service.link(id, orders);
        },
        onSuccess: (_, { workspaceId, id }) => {
            // setAlert({
            //     type: 'success',
            //     message: 'Link created successfully',
            //     title: 'Create Link',
            // });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to create link: ${error.message}`,
                title: 'Create Link',
            });
        },
    });
}
