import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Workspace } from '../../../../domain/models/workspace.model';
import { MeService } from '../../../../domain/services/me.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { useMeService } from './use-me-service';

export interface SwitchWorkspaceParams {
    workspace: Workspace;
}

const switchWorkspace = async (service: MeService, workspace: Workspace) => {
    return await service.switch(workspace);
};

export function useSwitchWorkspace() {
    const context = useAppContext();
    const navigate = useNavigate();

    const { service } = useMeService({});
    const { setAlert } = useAlertContext();

    return useMutation({
        mutationFn: async ({ workspace }: SwitchWorkspaceParams) => {
            if (!context.workspace?.id || !service) return;

            await switchWorkspace(service, workspace);
        },
        onSuccess: () => {
            localStorage.setItem('isSwitchWorkspace', 'true');
            navigate(0);
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to invite member: ${error.message}`,
                title: 'Invite new member',
            });
        },
    });
}
