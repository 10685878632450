import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NominatedList } from '../../../../domain/zod/nominated-list.model';
import { useNominatedList } from '../../../../infrastructure/hooks/api/nominated-list/use-nominated-list';
import useToggle from '../../../../infrastructure/hooks/use-toggle';
import { NominatedListType } from '../add-nominated-list';
import { EditNominatedList } from './edit-nominated-list';
import { NominatedListTable } from './nominated-list-table';
import {
    Card,
    RadioChangeEvent,
    RadioGroup,
    Subtitle,
    Title,
} from '@ianneo/ui-library';
import { InfoCard } from '@ianneo/component-library';

export const NominatedListDetail = () => {
    const { t } = useTranslation();
    const { id, workspace } = useParams<{ id: string; workspace?: string }>();
    const [data, setData] = useState<NominatedList>();
    const [menu, setMenu] = useState<NominatedListType>(
        NominatedListType.SUPPLIER,
    );

    const { status: open, toggle } = useToggle();
    const { data: nominatedLists } = useNominatedList({
        workspaceId: workspace,
    });

    const handleChange = (e: RadioChangeEvent) => setMenu(e.target.value);

    const options = useMemo(
        () => [
            {
                label: t('nominatedList:detail.menu.supplier'),
                value: NominatedListType.SUPPLIER,
            },
            {
                label: t('nominatedList:detail.menu.assigned'),
                value: NominatedListType.ASSIGNED,
            },
        ],
        [t],
    );

    useEffect(() => {
        setData(nominatedLists?.find((x) => x.id === Number(id || 0)));
    }, [nominatedLists, id]);

    return (
        <>
            <div className="detail-header-flexbox">
                <div
                    style={{
                        display: 'flex',
                        gap: '12px',
                        alignItems: 'center',
                        // marginBottom: '24px',
                    }}
                >
                    <div
                        style={{
                            width: '8px',
                            backgroundColor: '#972d47',
                            minHeight: '40px',
                            minWidth: '8px',
                        }}
                    ></div>
                    <Title>{t('nominatedList:detail.title')}</Title>
                    <Subtitle>{t('nominatedList:description')}</Subtitle>
                </div>

                <InfoCard infoTitle={data?.name}></InfoCard>
            </div>

            <div className="detail-content-flexbox">
                <RadioGroup
                    options={options}
                    defaultValue={menu}
                    onChange={handleChange}
                    optionType="button"
                />

                <Card key={menu}>
                    <NominatedListTable
                        data={data || {}}
                        type={menu}
                        toggle={toggle}
                    />
                </Card>

                <EditNominatedList
                    open={open}
                    toggle={toggle}
                    data={data || {}}
                />
            </div>
        </>
    );
};
