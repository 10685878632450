import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { Ruleset } from '../../domain/models/ruleset.model';
import { ApiServer } from './api.server';

/**
 * @class
 * @name RulesetsApiClient
 * @description The rulesets API client
 * @author Ian Neo <ian.neo@ecloudvalley.com>
 */
export class RulesetApiClient extends ApiServer<any> {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'ruleset');
    }

    async list(workspace: string) {
        console.log(`client<ruleset>| list(): Enter`);
        console.log(`client<ruleset>| list(): $workspace = ${workspace}`);

        return super.get('list', workspace);
    }

    async delete(workspace: string, item: string) {
        console.log(`client<ruleset>| delete(): Enter`);
        console.log(`client<ruleset>| delete(): $workspace = ${workspace}`);

        return super.delete('delete', workspace, item);
    }

    async create(workspace: string, ruleset: Ruleset) {
        console.log(`client<ruleset>| post(): Enter`);
        console.log(`client<ruleset>| post(): $workspace = ${workspace}`);
        console.log(
            `client<ruleset>| post(): $data = ${JSON.stringify(ruleset)}`,
        );

        return super.post('create', workspace, ruleset);
    }

    async get(workspace: string, item: string) {
        console.log(`client<ruleset>| get(): Enter`);
        console.log(`client<ruleset>| get(): $workspace = ${workspace}`);
        console.log(`client<ruleset>| get(): $item = ${item}`);

        return super.get('get', workspace, item);
    }

    async update(workspace: string, item: string, ruleset: Ruleset) {
        console.log(`client<ruleset>| patch(): Enter`);
        console.log(`client<ruleset>| patch(): $workspace = ${workspace}`);
        console.log(`client<ruleset>| patch(): $item = ${item}`);
        console.log(
            `client<ruleset>| patch(): $data = ${JSON.stringify(ruleset)}`,
        );

        return super.patch('update', workspace, item, ruleset);
    }
}
