import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlertContext } from '../../../use-alert.hook';
import useAppContext from '../../../use-context.hook';
import { useCompanyDocumentService } from './use-document-service';

export function useRemoveDocument() {
    const context = useAppContext();
    const { service: documentService } = useCompanyDocumentService();
    const { setAlert } = useAlertContext();

    const client = useQueryClient();

    return useMutation({
        mutationFn: async (id: string) => {
            if (!context.workspace?.id || !id) return;

            return await documentService.remove(context.workspace.id, id);
        },
        onSuccess: (_, args) => {
            client.invalidateQueries({
                queryKey: ['companyDocuments', { type: context.workspace?.id }],
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to remove document: ${error.message}`,
                title: 'Remove Company Documents',
            });
        },
    });
}
