import { useQuery } from '@tanstack/react-query';
import {
    PurchaseOrder,
    PurchaseOrderLink,
} from '../../../../domain/models/purchase.model';
import { SalesOrder } from '../../../../domain/models/sales-order.model';
import { OrderService } from '../../../../domain/services/order.service';
import { useAlertContext } from '../../use-alert.hook';
import useAppContext from '../../use-context.hook';
import { usePurchaseService } from '../purchases/use-purchase-service';
import { useOrderService } from './use-order-service';
import { useLinkService } from '../links/use-links-service';

const getOrders = async (
    service: OrderService,
    id: string,
): Promise<SalesOrder[]> => {
    const response = await service.list(id);

    return response;
};

export function useOrders(workspaceId?: string) {
    const context = useAppContext();
    const { service } = useOrderService();
    const { service: purchaseService } = usePurchaseService();
    const { service: linkService } = useLinkService();
    const { setAlert } = useAlertContext();

    return useQuery<SalesOrder[]>({
        queryKey: [
            'orders',
            { workspace: workspaceId || context.workspace?.id },
        ],
        queryFn: async () => {
            if (!context.workspace?.id) return [];
            const orders = await getOrders(
                service,
                workspaceId || context.workspace.id,
            );

            const orderIds = orders
                .map((x) => x.purchaseOrderId || '')
                .filter((x) => x);

            const salesOrderIds = orders.map((x) => Number(x.id));

            const purchaseOrders: PurchaseOrder[] =
                await purchaseService.listByBatch({
                    orderIds: orderIds,
                });

            const links: PurchaseOrderLink[] = await linkService.listBatch({
                orderIds: salesOrderIds,
            });

            const data = orders.map((x, index) => {
                const purchaseOrder = purchaseOrders.find(
                    (y) => y.id === x.purchaseOrderId,
                );

                return {
                    ...x,
                    links: links?.[index] ? links[index] : [],
                    purchaseOrder,
                    purchaseProcesses: purchaseOrder?.purchaseProcesses,
                };
            });

            return data as SalesOrder[];
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to fetch order information: ${error.message}`,
            });
        },
    });
}
