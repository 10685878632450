import { ServiceConfigInterface } from '../../domain/interfaces/config.context.interface';
import { ApiServer } from './api.server';

/**
 * @class
 * @name WorkspaceClient
 * @description The client for workspace service APIs
 * @author Mark Leung <markleungcl@lfxdigital.com>
 */
export class WorkspaceApiClient extends ApiServer {
    constructor(config: ServiceConfigInterface, token: string) {
        super(token, config, 'workspaces', 'workspace');
    }

    async get(workspace: string, diversion: string = '0') {
        console.log(`client<workspace>| get(): Enter`);
        console.log(`client<workspace>| get(): $workspace = ${workspace}`);
        return super.get('get', workspace, diversion);
    }

    async getByCompanyName(name?: string, diversion: string = '0') {
        console.log(`client<workspace>| get(): Enter`);
        console.log(`client<workspace>| get(): $name = ${name}`);
        console.log(`client<workspace>| get(): $diversion = ${diversion}`);

        return super.get(
            'search-company',
            name ? encodeURIComponent(name) : undefined,
            diversion,
        );
    }

    async getMembers(workspace: string, diversion: string = '0') {
        console.log(`client<workspace>| getMembers(): Enter`);
        console.log(
            `client<workspace>| getMembers(): $workspace = ${workspace}`,
        );
        return super.get('getMembers', workspace, diversion);
    }

    async getFeatures(diversion: string = '0') {
        console.log(`client<workspace>| getFeatures(): Enter`);
        return super.get('getFeatures', diversion);
    }

    async getRoleInfo(workspace: string, id: string, diversion: string = '0') {
        console.log(`client<workspace>| getRoleInfo(): Enter`);
        console.log(
            `client<workspace>| getRoleInfo(): $workspace = ${workspace}`,
        );
        console.log(`client<workspace>| getRoleInfo(): $id = ${id}`);

        return super.get('getRoleInfo', workspace, id, diversion);
    }

    async verifyRegistration(
        registration: string,
        country: string,
        diversion: string = '0',
    ) {
        console.log(`client<workspace>| verifyRegistration(): Enter`);
        console.log(
            `client<workspace>| verifyRegistration(): $registration = ${registration}`,
        );
        console.log(
            `client<workspace>| verifyRegistration(): $country = ${country}`,
        );

        return super.get(
            'verifyRegistration',
            country,
            registration,
            diversion,
        );
    }
}
