import axios from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Attachment } from '../../../../domain/types/attachment.type';
import useAppContext from '../../use-context.hook';
import { useFileService } from './use-files-service';
import { useAlertContext } from '../../use-alert.hook';
import { FileService } from '../../../../domain/services/file.service';
import { UploadFile } from '@ianneo/ui-library';

interface UploadDocumentsInterface {
    files: UploadFile[];
    type: string;
    workspaceId?: string;
}

const uploadDocument = async (
    fileService: FileService,
    workspaceId: string,
    type: string,
    files: UploadFile[],
) => {
    const response = await Promise.all(
        files.map(async (file) => {
            const request: Attachment = {
                assetName: file.name,
                container: 'temporary',
                contentType: file.type || '',
                originalName: file.name,
                autoResign: false,
            };

            const token = await fileService.upload(workspaceId, type, {
                filename: request.assetName,
                contentType: request.contentType,
            });

            await axios.put(token.token, file.originFileObj, {
                headers: {
                    'x-ms-blob-type': 'BlockBlob',
                    'Content-Type': request.contentType,
                },
            });

            return {
                ...request,
                assetName: token.attachment.assetName,
            };
        }),
    );

    return response;
};

/**
 * Upload certification API which will be updated when any of the parameters in the [] array changes
 * Also will set the refresh state to true which will trigger the useEffect hook to fetch the documents
 */
export function useUploadImage() {
    const context = useAppContext();
    const { service } = useFileService();
    const { setAlert } = useAlertContext();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async ({
            files,
            type,
            workspaceId,
        }: UploadDocumentsInterface) => {
            if (!context.workspace?.id) return;

            return await uploadDocument(
                service,
                workspaceId ? workspaceId : context.workspace.id,
                type,
                files,
            );
        },
        onSuccess: () => {
            client.invalidateQueries({
                queryKey: ['companyDocuments', { type: context.workspace?.id }],
            });
        },
        onError: (err) => {
            const error = err as Error;

            setAlert({
                type: 'error',
                message: `Failed to upload documents: ${error.message}`,
                title: 'Upload Company Documents',
            });
        },
    });
}
