import {
    ContainerOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import {
    CollapsibleDropdown,
    ITableProps,
    Link,
    Select,
    Subtitle,
    Table,
    Text,
    Title,
    Tooltip,
} from '@ianneo/ui-library';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    PurchaseOrder,
    PurchaseOrderItem,
    PurchaseOrderVersion,
} from '../../../domain/models/purchase.model';
import { usePurchases } from '../../../infrastructure/hooks/api/purchases/use-purchases';
import useVerifyAdmin from '../../../infrastructure/hooks/use-verify-admin';
import { getPOStatus } from '../../../infrastructure/utils/purchase-order-labeler';
import { useGetDelegations } from '../../_api/delegations/hooks/useGetDelegations';
import AddPurchaseOrder from './add-purchase-order';
import useAppContext from '../../../infrastructure/hooks/use-context.hook';

interface PurchasesLayoutProps {
    data: PurchaseOrder[];
    columns: ITableProps<PurchaseOrder>['columns'];
    open: boolean;
    setOpen: (open: boolean) => void;
    supplier?: string;
    isCaseDisplayed?: boolean;
}

const PurchasesLayout: React.FC<PurchasesLayoutProps> = (args) => {
    const { t } = useTranslation();
    const w = useAppContext().workspace;
    const { isAdmin } = useVerifyAdmin();
    const { delegations } = useGetDelegations();
    const [selected, setSelected] = useState(0);
    const navigate = useNavigate();

    const { data } = usePurchases(
        delegations?.find((x) => x.id === selected)?.workspace?.id,
    );

    const sortedData = useMemo(() => {
        if (!data) return;
        const sorted = data.sort((a, b) => {
            if (a.lastUpdatedOn && b.lastUpdatedOn) {
                return (
                    new Date(b.lastUpdatedOn).getTime() -
                    new Date(a.lastUpdatedOn).getTime()
                );
            }

            return 0;
        });

        return sorted;
    }, [data]);

    const items = useMemo(() => {
        const items = [];
        if (!isAdmin) {
            items.push({
                key: 'add',
                icon: <PlusOutlined />,
                label: t('purchase:addTitle'),
                onClick: () => args.setOpen(true),
            });
        }

        return items;
    }, [isAdmin, t, args]);

    const getOrderActiveVersion = useCallback((order: PurchaseOrder) => {
        return order.versions
            ?.filter(
                (v) => v.status === 'RELEASED' && (v.manifest ?? []).length > 0,
            )
            .reduce(
                (a, b) =>
                    (a.lastUpdatedOn || new Date()) >
                    (b.lastUpdatedOn || new Date())
                        ? a
                        : b,
                {},
            );
    }, []);

    const getOrderActiveManifest = useCallback(
        (order: PurchaseOrderVersion, debug?: boolean) => {
            const items: number[] = [];
            if (order.manifest) {
                order.manifest?.forEach((m) => {
                    if (items.includes(m.sequence ?? -1) === false) {
                        items.push(m.sequence ?? 0);
                    }
                });
                const manifesta = order.manifest;
                if (items.length > 0 && manifesta.length > 0) {
                    return items
                        .map((i) =>
                            manifesta
                                .filter((m) =>
                                    i === 0 ? true : m.sequence === i,
                                )
                                .reduce(
                                    (
                                        a: PurchaseOrderItem,
                                        b: PurchaseOrderItem,
                                    ) =>
                                        (a.createdOn || new Date()) >
                                        (b.createdOn || new Date())
                                            ? a
                                            : b,
                                ),
                        )
                        .filter((i) => i !== undefined);
                } else {
                    return [];
                }
            } else {
                return [];
            }
        },
        [],
    );

    const getOrderTitle = useCallback((item: PurchaseOrder) => {
        if (item.externalCustomerReference) {
            return item.externalCustomerReference;
        }

        return `Internal Order - ${item.id?.slice(0, 6)}`;
    }, []);

    const getPurchaseOrderItemCategory = useCallback(
        (order: PurchaseOrder) => {
            if (order.versions) {
                const version = order.versions.find(
                    (v) => (v.manifest || []).length > 0,
                );
                if (version?.manifest) {
                    const manifest = version.manifest[0];
                    if (!manifest) {
                        return '--';
                    } else if (manifest.purchaseables) {
                        const purchaseable = manifest.purchaseables;
                        if (purchaseable.category) {
                            const categoryBase =
                                w?.supplyChainLoaderType === 0
                                    ? purchaseable.category.split('::_-').pop()
                                    : purchaseable.category.split('::').shift();
                            console.log(`catBase = ${categoryBase}`);
                            if (categoryBase) {
                                console.log(`we flow into here`);
                                return categoryBase
                                    .split(' ')
                                    .map((i) =>
                                        i
                                            .split('_')
                                            .map(
                                                (j) =>
                                                    j
                                                        .slice(0, 1)
                                                        .toUpperCase() +
                                                    j.slice(1).toLowerCase(),
                                            )
                                            .join(' '),
                                    )
                                    .join(' ');
                            } else {
                                return '--';
                            }
                        } else {
                            return '--';
                        }
                    } else {
                        return '--';
                    }
                }
            } else {
                return '--';
            }
        },
        [w?.supplyChainLoaderType],
    );

    const getOrderTotal = useCallback(
        (order: PurchaseOrder) => {
            console.log(`orderTotal = ${JSON.stringify(order.orderTotal)}`);
            if (order.orderTotal && w?.supplyChainLoaderType !== 0) {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                });
                return formatter.format(order.orderTotal);
            } else if (order.versions) {
                const version = order.versions.find(
                    (v) => (v.manifest || []).length > 0,
                );
                if (version?.manifest && w?.supplyChainLoaderType !== 0) {
                    return version.manifest
                        .map((i) => {
                            const price = i?.ppu ?? 0;
                            const quantity = i?.quantity ?? 0;
                            return price * quantity;
                        })
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2)
                        .toString();
                } else {
                    return '--';
                }
            } else {
                return '--';
            }
        },
        [w?.supplyChainLoaderType],
    );

    const getOrderQuantity = useCallback(
        (order: PurchaseOrder) => {
            function convert(value: any) {
                if (typeof value === 'number') {
                    return value;
                } else if (typeof value === 'string') {
                    return Number.parseInt(value);
                } else {
                    return 0;
                }
            }

            if (order.versions) {
                const version = getOrderActiveVersion(order);
                if (version) {
                    const items: PurchaseOrderItem[] = getOrderActiveManifest(
                        version,
                        true,
                    );
                    if (order.externalCustomerReference === 'A240001953') {
                        console.log(
                            `order-1953 items= ${JSON.stringify(items)}`,
                        );
                    }
                    const quantity = items
                        .map((i) => i?.quantity)
                        .reduce((a, b) => convert(a) + convert(b), 0);
                    if (order.externalCustomerReference === 'A240001953') {
                        console.log(
                            `order-1953 quantity= ${JSON.stringify(quantity)}`,
                        );
                    }
                    return (quantity || 0) > 0 ? quantity?.toFixed(2) : '--';
                } else {
                    return '--';
                }
            } else {
                return '--';
            }
        },
        [getOrderActiveManifest, getOrderActiveVersion],
    );

    const columns = useMemo(
        () => [
            {
                title: t('purchase:listing.poNumber'),
                dataIndex: 'externalDataId',
                render: (_: any, item: PurchaseOrder) => {
                    const delegateId = delegations?.find(
                        (x) => x.id === selected,
                    )?.workspace?.id;

                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px',
                            }}
                        >
                            <Link
                                onClick={() =>
                                    navigate(
                                        delegateId
                                            ? `/purchases/${item.id}/${delegateId}`
                                            : `/purchases/${item.id}`,
                                    )
                                }
                            >
                                {getOrderTitle(item)}
                            </Link>

                            {!item.externalDataId && (
                                <Tooltip title={item.id}>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            )}
                        </div>
                    );
                },
            },
            {
                title: t('purchase:listing.programme'),
                dataIndex: 'programme',
            },
            {
                title: t('purchase:listing.supplier'),
                dataIndex: ['supplier', 'seller', 'name'],
            },
            {
                title: t('purchase:listing.category'),
                render: (_: any, item: PurchaseOrder) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px',
                            }}
                        >
                            {getPurchaseOrderItemCategory(item)}
                        </div>
                    );
                },
            },
            {
                title: t('purchase:listing.items'),
                render: (_: any, order: PurchaseOrder) => {
                    if (order.externalCustomerReference === 'A240002002') {
                        console.log(
                            `order-2002 order= ${JSON.stringify(order)}`,
                        );
                    }
                    if (order.versions) {
                        const version = getOrderActiveVersion(order);
                        if (order.externalCustomerReference === 'A240002002') {
                            console.log(
                                `order-2002 version= ${JSON.stringify(
                                    version,
                                )}`,
                            );
                        }
                        if (version) {
                            const items = getOrderActiveManifest(
                                version,
                                true,
                            ).filter((i) => i !== undefined);
                            if (
                                order.externalCustomerReference === 'A240002002'
                            ) {
                                console.log(
                                    `order-2002 items= ${JSON.stringify(
                                        items,
                                    )}`,
                                );
                            }
                            const itemsWithExternalDataId = items.filter(
                                (item) => {
                                    if (!item) {
                                        return false;
                                    } else if (!item?.purchaseables) {
                                        return false;
                                    } else if (
                                        !item?.purchaseables?.externalDataId
                                    ) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                },
                            );

                            const constructLabel = (
                                items: (PurchaseOrderItem | undefined)[],
                                debug?: boolean,
                            ) => {
                                const base =
                                    items?.[0]?.purchaseables.name?.split('-');
                                let title: string;
                                if (base?.length === 2) {
                                    const first = base[0];
                                    console.log(`first: ${first}`);
                                    try {
                                        if (
                                            Number.isNaN(Number.parseInt(first))
                                        ) {
                                            title =
                                                items?.[0]?.purchaseables
                                                    .name ?? '--';
                                        } else {
                                            title =
                                                items?.[0]?.purchaseables.name
                                                    ?.split('-')
                                                    .pop() ?? '--';
                                        }
                                    } catch (error) {
                                        title =
                                            items?.[0]?.purchaseables.name ??
                                            '--';
                                    }
                                } else {
                                    title =
                                        items?.[0]?.purchaseables.name ?? '--';
                                }

                                if (
                                    items.length > 1 &&
                                    w?.supplyChainLoaderType === 0
                                ) {
                                    return `${title} +${items.length - 1}`;
                                }

                                return title;
                            };

                            const textComponent = (
                                <Text>
                                    {constructLabel(
                                        itemsWithExternalDataId,
                                        order.externalCustomerReference ===
                                            'A240001953',
                                    )}
                                </Text>
                            );

                            // this is the actual component we are returning
                            return w?.supplyChainLoaderType === 1 ? (
                                textComponent
                            ) : (
                                <Tooltip
                                    title={
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                            }}
                                        >
                                            {itemsWithExternalDataId?.map(
                                                (item) => {
                                                    return (
                                                        <span>
                                                            Item:{' '}
                                                            {
                                                                item
                                                                    ?.purchaseables
                                                                    .externalDataId
                                                            }
                                                        </span>
                                                    );
                                                },
                                            )}
                                        </div>
                                    }
                                >
                                    {textComponent}
                                </Tooltip>
                            );
                        } else {
                            return '--';
                        }
                    } else {
                        return '--';
                    }
                },
            },
            {
                title: t('purchase:listing.currency'),
                dataIndex: 'currency',
            },
            {
                title: t('purchase:listing.buy_date'),
                render: (_: any, item: PurchaseOrder) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px',
                            }}
                        >
                            {item.createdOn
                                ? new Date(item.createdOn)
                                      .toLocaleString()
                                      .slice(0, 10)
                                : '--'}
                        </div>
                    );
                },
            },
            {
                title: t('purchase:listing.quantity'),
                dataIndex: 'quantity',
                render: (_: any, item: PurchaseOrder) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px',
                            }}
                        >
                            {getOrderQuantity(item)}
                        </div>
                    );
                },
            },
            {
                title: t('purchase:listing.total'),
                dataIndex: 'total',
                render: (_: any, item: PurchaseOrder) => {
                    return (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '12px',
                            }}
                        >
                            {getOrderTotal(item)}
                        </div>
                    );
                },
            },
            w?.supplyChainLoaderType === 0
                ? {
                      title: t('purchase:listing.status'),
                      dataIndex: 'status',
                      render: (_: any, item: PurchaseOrder) => (
                          <Text>{getPOStatus(item.status)}</Text>
                      ),
                  }
                : {},
            {
                title: t('common:updatedOn'),
                render: (_: any, item: PurchaseOrder) =>
                    item.lastUpdatedOn
                        ? new Date(item.lastUpdatedOn)
                              .toLocaleString()
                              .slice(0, 10)
                        : 'Pending',
            },
            {
                title: t('common:actions'),
                dataIndex: 'actions',
                render: (_: any, item: PurchaseOrder) => (
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Link onClick={() => navigate(`/purchases/${item.id}`)}>
                            <ContainerOutlined />
                        </Link>
                    </div>
                ),
            },
        ],
        [
            navigate,
            t,
            getOrderTitle,
            delegations,
            selected,
            getPurchaseOrderItemCategory,
            w,
            getOrderQuantity,
            getOrderActiveManifest,
            getOrderActiveVersion,
            getOrderTotal,
        ],
    );

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    marginBottom: '24px',
                }}
            >
                <div
                    style={{
                        width: '8px',
                        backgroundColor: '#972d47',
                        minHeight: '40px',
                        minWidth: '8px',
                    }}
                ></div>
                <Title>{t('purchase:name')}</Title>
                <Subtitle>{t('purchase:description')}</Subtitle>
            </div>

            <div
                style={{
                    display: 'flex',
                    gap: '16px',
                    flexDirection: 'column',
                    height: '100dch',
                    flex: 1,
                }}
            >
                <Table
                    dataSource={sortedData}
                    columns={columns}
                    scroll={{ x: 'max-content' }}
                    rowKey="id"
                    additionalFilters={
                        <div
                            style={{
                                display:
                                    !delegations || delegations.length === 0
                                        ? 'none'
                                        : 'flex',
                                gap: '8px',
                                alignItems: 'center',
                            }}
                        >
                            <span style={{ flex: 1 }}>For Company:</span>
                            <Select
                                options={delegations?.map((x) => {
                                    return {
                                        label: x.workspace?.company?.name,
                                        value: x.id,
                                    };
                                })}
                                style={{
                                    maxWidth: '200px',
                                    width: '200px',
                                    flex: 3,
                                }}
                                onChange={(value) => {
                                    setSelected(value);
                                }}
                                placeholder="None"
                                suffixIcon={null}
                                getPopupContainer={undefined}
                            ></Select>
                        </div>
                    }
                    actions={
                        <CollapsibleDropdown
                            menu={{
                                items,
                            }}
                        ></CollapsibleDropdown>
                    }
                />
            </div>

            {args.open ? (
                <AddPurchaseOrder
                    open={args.open}
                    setOpen={args.setOpen}
                    supplier={args.supplier}
                />
            ) : null}
        </>
    );
};

export default PurchasesLayout;
