import { WarningOutlined } from '@ant-design/icons';
import {
    AddTable,
    AutoComplete,
    Form,
    FormItem,
    Input,
    InputNumber,
    Select,
    StepModal,
    Subtitle,
    Table,
    Text,
    useForm,
} from '@ianneo/ui-library';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderNatureType } from '../../../../domain/enums/order-nature.enum';
import { SupplyChainNodeType } from '../../../../domain/enums/supplier-node.enum';
import { UnitType } from '../../../../domain/enums/unit-type.enum';
import {
    Ruleset,
    SupportDocument,
} from '../../../../domain/models/ruleset.model';
import currencies from '../../../../infrastructure/config/data/currency.json';
import { usePartners } from '../../../../infrastructure/hooks/api/partner/use-partners';
import {
    CreatePurchaseParams,
    useCreatePurchase,
} from '../../../../infrastructure/hooks/api/purchases/use-create-purchase';
import { usePurchaseItmes } from '../../../../infrastructure/hooks/api/purchases/use-purchase-items';
import { useRulesets } from '../../../../infrastructure/hooks/api/rulesets/use-rulesets';
import { useWorkspaceByCompanyName } from '../../../../infrastructure/hooks/api/workspaces/use-workspace-by-company-name';
import { getDocumentValue } from '../../../../infrastructure/utils/document-type-labeler';
import { getOrderNature } from '../../../../infrastructure/utils/order-nature-labeler';
import { getProcessValue } from '../../../../infrastructure/utils/process-labeler';
import { getUnitValue } from '../../../../infrastructure/utils/unit-type-labeler';
import useAppContext from '../../../../infrastructure/hooks/use-context.hook';
import { useGetDelegations } from '../../../_api/delegations/hooks/useGetDelegations';
import { DelegationComponent } from '../../Partners/add-partner/delegations.component';

interface AddPurchaseOrderComponentProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    supplier?: string;
}

const AddPurchaseOrderComponent: React.FC<AddPurchaseOrderComponentProps> = (
    args,
) => {
    const context = useAppContext();
    const { t } = useTranslation();
    const [form] = useForm();
    const [searchValue, setSearchValue] = useState('');
    const [options, setOptions] = useState<{ label: string; value: string }[]>(
        [],
    );

    const { delegations } = useGetDelegations();
    const [onBehalfOf, setOnBehalfOf] = useState(0);

    const delegationId = delegations?.find((x) => x.id === onBehalfOf)
        ?.workspace?.id;

    const { data: suppliers } = usePartners(delegationId);
    const { data: rulesets } = useRulesets(delegationId);
    const { data: workspaces } = useWorkspaceByCompanyName(
        delegationId || context.workspace?.id || '',
        searchValue,
    );
    const { data: purchaseItems } = usePurchaseItmes(delegationId);
    const { mutateAsync: createPurchaseOrder } = useCreatePurchase();

    const [selectedRuleset, setSelectedRuleset] = useState<Ruleset>();

    const getRulesetName = (ruleset: Ruleset) => {
        return typeof ruleset.name === 'object'
            ? ruleset.name?.locales?.find((x) => x.localeName === 'en')?.text
            : typeof ruleset.name === 'string'
            ? JSON.parse(ruleset.name)?.locales?.find(
                  (x: any) => x.localeName === 'en',
              ).text
            : '';
    };

    const columns = useMemo(
        () => [
            {
                title: t('purchase:modal.add.productName'),
                dataIndex: 'productName',
                key: 'productName',
                width: '30%',
                component: (
                    <Select
                        options={purchaseItems?.map((x) => ({
                            key: x.id,
                            label: x.name,
                            value: x.id,
                        }))}
                    />
                ),
                rules: [
                    {
                        required: true,
                        message: 'Please select a product',
                    },
                ],
                required: true,
            },
            {
                title: t('purchase:modal.add.quantity'),
                dataIndex: 'quantity',
                key: 'quantity',
                width: '10%',
                component: <InputNumber />,
                rules: [
                    {
                        pattern: /^\d*\.?\d*$/,
                        message: 'Please input a positive number only.',
                    },
                    {
                        required: true,
                        message: 'Please input a quantity',
                    },
                ],
                required: true,
            },
            {
                title: t('purchase:modal.add.unit'),
                dataIndex: 'unit',
                key: 'unit',
                width: '20%',
                component: (
                    <Select
                        options={Object.values(UnitType).map((x) => ({
                            key: x,
                            value: x,
                            label: getUnitValue(x),
                        }))}
                    />
                ),
                skipFilter: true,
                rules: [
                    {
                        required: true,
                        message: 'Please input a unit',
                    },
                ],
                required: true,
            },
            {
                title: t('purchase:modal.add.unitPrice'),
                dataIndex: 'ppu',
                key: 'ppu',
                width: '20%',
                component: <InputNumber />,
                rules: [
                    {
                        pattern: /^\d*\.?\d*$/,
                        message: 'Please input a positive number only.',
                    },
                ],
                required: true,
            },
            {
                title: t('purchase:modal.add.comment'),
                dataIndex: 'comment',
                key: 'comment',
                width: '20%',
                component: <Input />,
                rules: [],
                required: false,
            },
        ],
        [purchaseItems, t],
    );

    const rulesetColumns = useMemo(
        () => [
            {
                title: t('purchase:modal.add.document'),
                dataIndex: 'document',
                render: (_: any, item: SupportDocument) => (
                    <Text>
                        {item.document ? getDocumentValue(item.document) : ''}
                    </Text>
                ),
            },
            {
                title: t('purchase:modal.add.mandatory'),
                render: () => <Text>{t('purchase:modal.add.true')}</Text>,
            },
            {
                title: t('purchase:modal.add.country'),
                render: () => <Text>{t('purchase:modal.add.all')}</Text>,
            },
        ],
        [t],
    );

    const close = () => {
        args.setOpen(false);

        form.resetFields();
    };

    const submit = async () => {
        try {
            console.log('submitting?');
            // await form.validateFields();

            const supplierInfo = form.getFieldsValue(true);
            const secondarySupplier = options.find(
                (x) => x.label === searchValue,
            );
            console.log(supplierInfo);

            const item: CreatePurchaseParams = {
                currency: supplierInfo.currency,
                supplier: supplierInfo.supplier,
                externalDataId: supplierInfo.externalDataId,
                items:
                    supplierInfo?.items?.map((x: any) => ({
                        material: x?.productName,
                        comment: x?.comment,
                        quantity: Number(x?.quantity || 0),
                        ppu: Number(x?.ppu || 0),
                        unit: x?.unit,
                    })) || [],
                rules: selectedRuleset?.id ? [selectedRuleset.id] : [],
                supplierProducer: secondarySupplier?.value,
                purchaseProcesses: supplierInfo.purchaseProcesses,
                orderNature: supplierInfo.orderNature,
                workspaceId: delegationId,
                delegated: delegationId,
                externalCustomerReference:
                    supplierInfo.externalCustomerReference,
            };

            await createPurchaseOrder(item);
            args.setOpen(false);
        } catch (err: any) {
            // setErrors(err.errorFields);
            console.error(err);

            return false;
        }
    };

    const onInputChange = async (value: string, option: any) => {
        const item = await options.find((x) => x.value === value);

        setSearchValue(item?.label || value);
    };

    useEffect(() => {
        if (!workspaces) return;

        const options = workspaces.map((item) => ({
            label: item.company?.name || '',
            value: item.id || '',
        }));

        setOptions(options);
    }, [workspaces, suppliers]);

    useEffect(() => {
        if (form && args.supplier) {
            form.setFieldsValue({
                supplier: args.supplier,
            });
        }
    }, [args, form]);

    return (
        <>
            <StepModal
                open={args.open}
                title={t('purchase:modal.add.title')}
                width={'70vw'}
                okFn={submit}
                closable={false}
                cancelFn={close}
                stepContent={[
                    {
                        title: 'On Behalf Of.',
                        content: (
                            <DelegationComponent
                                form={form}
                                onBehalfOf={onBehalfOf}
                                setOnBehalfOf={setOnBehalfOf}
                            />
                        ),
                    },
                    {
                        title: 'Basic Information',
                        content: (
                            <>
                                <Form form={form} scrollToFirstError>
                                    <FormItem
                                        label={t('purchase:modal.add.currency')}
                                        name="currency"
                                        hasFeedback={false}
                                        help={false}
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input Currency, e.g. USD',
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={currencies.map(
                                                (currency) => ({
                                                    value: currency,
                                                    label: currency,
                                                }),
                                            )}
                                        ></Select>
                                    </FormItem>

                                    <FormItem
                                        label={t('purchase:modal.add.supplier')}
                                        name="supplier"
                                        required
                                        help={false}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please select a supplier',
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={(suppliers || [])
                                                .sort(function (a, b) {
                                                    const comparatorA =
                                                        a.seller?.name || '';
                                                    const comparatorB =
                                                        b.seller?.name || '';

                                                    if (
                                                        comparatorA <
                                                        comparatorB
                                                    ) {
                                                        return -1;
                                                    }

                                                    if (
                                                        comparatorA >
                                                        comparatorB
                                                    ) {
                                                        return 1;
                                                    }

                                                    return 0;
                                                })
                                                .map((x) => ({
                                                    label: x.seller?.name,
                                                    key: x.id,
                                                    value: x.id,
                                                }))}
                                        />
                                    </FormItem>

                                    <FormItem
                                        label={t(
                                            'purchase:modal.add.purchaseProcesses',
                                        )}
                                        name="purchaseProcesses"
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input purchase processes',
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={Object.values(
                                                SupplyChainNodeType,
                                            ).map((x) => ({
                                                label: getProcessValue(x),
                                                key: x,
                                                value: x,
                                            }))}
                                            mode="tags"
                                        />
                                    </FormItem>

                                    <FormItem
                                        label={t('purchase:modal.add.refNo')}
                                        name="externalDataId"
                                        help={false}
                                    >
                                        <Input />
                                    </FormItem>

                                    <FormItem
                                        label={t(
                                            'purchase:modal.add.secondarySupplier',
                                        )}
                                    >
                                        <AutoComplete
                                            style={{ width: '100%' }}
                                            options={options}
                                            onChange={onInputChange}
                                            value={searchValue}
                                        />
                                    </FormItem>

                                    <FormItem
                                        label={t(
                                            'purchase:modal.add.orderNature',
                                        )}
                                        name="orderNature"
                                    >
                                        <Select
                                            options={Object.values(
                                                OrderNatureType,
                                            ).map((x) => ({
                                                label: getOrderNature(x),
                                                key: x,
                                                value: x,
                                            }))}
                                        />
                                    </FormItem>

                                    <FormItem
                                        label="External Customer Reference"
                                        name="externalCustomerReference"
                                    >
                                        <Input />
                                    </FormItem>
                                </Form>
                            </>
                        ),
                    },
                    {
                        title: 'Products',
                        content: (
                            <>
                                <AddTable
                                    columns={columns}
                                    form={form}
                                    scroll={{ x: 'max-content' }}
                                />
                            </>
                        ),
                    },
                    {
                        title: 'Documents',
                        content: (
                            <>
                                <Form form={form}>
                                    <FormItem
                                        name="tracingTemplate"
                                        help={false}
                                        label={t(
                                            'purchase:modal.add.tracingTemplate',
                                        )}
                                    >
                                        <Select
                                            options={rulesets?.map((x) => ({
                                                label: getRulesetName(x),
                                                key: x.id,
                                                value: x.id,
                                            }))}
                                            onChange={(value) => {
                                                setSelectedRuleset(
                                                    rulesets?.find(
                                                        (x) => x.id === value,
                                                    ),
                                                );
                                            }}
                                        />
                                    </FormItem>
                                </Form>

                                <Table
                                    dataSource={
                                        selectedRuleset?.documentation || []
                                    }
                                    scroll={{ x: 'max-content' }}
                                    showColumns={false}
                                    showFilter={false}
                                    showSearch={false}
                                    columns={rulesetColumns}
                                    rowKey="id"
                                />

                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '12px',
                                        padding: '8px',
                                    }}
                                >
                                    <WarningOutlined />
                                    <Subtitle style={{ fontSize: '12px' }}>
                                        Do note that document tracing will be
                                        disabled if this is not filled.
                                    </Subtitle>
                                </div>
                            </>
                        ),
                    },
                ]}
            ></StepModal>
        </>
    );
};

export default AddPurchaseOrderComponent;
