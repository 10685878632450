import {
    Checkbox,
    Collapse,
    CollapsePanel,
    Form,
    FormItem,
    Modal,
    Table,
    useForm,
} from '@ianneo/ui-library';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Feature } from '../../../../domain/models/feature.model';
import { Role } from '../../../../domain/models/role.model';
import { useRole } from '../../../../infrastructure/hooks/api/role/use-role-permissions';
import { useUpdateRolePermissions } from '../../../../infrastructure/hooks/api/role/use-update-role-permissions';
import { useFeatures } from '../../../../infrastructure/hooks/api/workspaces/use-features';

interface PermissionListComponentProps {
    data: Role;
    open: boolean;
    setOpen: (open: boolean) => void;
    delegateId?: string;
}

function groupPermissionsByAppAndObjectCode(permissions: Feature[]) {
    const groups: { [key: string]: any } = {};

    permissions.forEach((permission) => {
        const key = `${permission.application}-${permission.objectCode}`;

        if (!groups[key]) {
            groups[key] = {
                application: permission.application,
                objectCode: permission.objectCode,
                scopes: [],
            };
        }

        groups[key].scopes.push(permission.scope);
    });

    return groupPermissionsByApplication(Object.values(groups));
}

function groupPermissionsByApplication(
    permissions: Feature[],
): Record<string, Feature[]> {
    return permissions.reduce(
        (result: Record<string, Feature[]>, permission) => {
            if (permission.application in result) {
                result[permission.application].push(permission);
            } else {
                result[permission.application] = [permission];
            }
            return result;
        },
        {},
    );
}

const PermissionListComponent: React.FC<PermissionListComponentProps> = ({
    data,
    open,
    setOpen,
    delegateId,
}) => {
    const { t } = useTranslation();
    const [form] = useForm();
    const { data: role } = useRole({ id: data.id || '', delegateId });
    const { data: features } = useFeatures(delegateId);

    const [groupData, setGroupData] = useState<Record<string, Feature[]>>();

    const { mutateAsync: updatePermissions } = useUpdateRolePermissions();

    const submit = async () => {
        const values = form.getFieldsValue(true);

        const output: any = [];

        Object.values(values)
            .filter((x) => x)
            .forEach((group: any) => {
                group.forEach((record: any) => {
                    Object.keys(record).forEach((key) => {
                        if (
                            typeof record[key] === 'boolean' &&
                            record[key] === true
                        ) {
                            output.push({
                                application: record.application,
                                objectCode: record.objectCode,
                                scope: key,
                            });
                        }
                    });
                });
            });

        await updatePermissions({
            id: data.id || '',
            args: { items: output },
            delegateId,
        });

        reset();
    };

    const reset = () => {
        setOpen(false);
        form.resetFields();
    };

    useEffect(() => {
        const response = groupPermissionsByAppAndObjectCode(features || []);

        setGroupData(response);
    }, [role, features]);

    return (
        <>
            <Modal
                title={t('user:modal.permissions.title')}
                open={open}
                width="80%"
                bodyStyle={{
                    maxHeight: '500px',
                    overflow: 'auto',
                }}
                okFn={submit}
                cancelFn={reset}
            >
                <Collapse>
                    {Object.entries(groupData || {}).map(([key, value]) => {
                        const columns = [
                            {
                                title: t('user:modal.permissions.objectCode'),
                                dataIndex: 'objectCode',
                                width: 200,
                                render: (_: any, item: Feature) => {
                                    return capitalize(item.objectCode || '');
                                },
                            },
                            ...[
                                // @ts-ignore
                                ...new Set(
                                    value.flatMap(
                                        // @ts-ignore
                                        ({ scopes }: { scopes: any }) =>
                                            Array.from(scopes),
                                    ),
                                ),
                            ].map((scope) => {
                                return {
                                    title: t(`user:modal.permissions.${scope}`),
                                    dataIndex: scope.toLowerCase(),
                                    width: 100,
                                    render: (
                                        _: unknown,
                                        item: Feature,
                                        index: number,
                                    ) => {
                                        return item.scopes?.find(
                                            (x) => x === scope,
                                        ) ? (
                                            <FormItem
                                                name={scope.toLowerCase()}
                                                style={{ margin: '0 0' }}
                                            >
                                                <Checkbox
                                                    checked={form.getFieldValue(
                                                        [
                                                            key,
                                                            index,
                                                            scope.toLowerCase(),
                                                        ],
                                                    )}
                                                    onChange={(value) => {
                                                        form.setFieldValue(
                                                            [
                                                                key,
                                                                index,
                                                                scope.toLowerCase(),
                                                            ],
                                                            value.target
                                                                .checked,
                                                        );
                                                    }}
                                                />
                                            </FormItem>
                                        ) : (
                                            <></>
                                        );
                                    },
                                };
                            }),
                        ];

                        role?.permissions?.forEach((x) => {
                            if (!x.feature || !x.feature.scope) return;
                            const { application, objectCode, scope } =
                                x.feature;
                            const item = value.find(
                                (y) =>
                                    y.application === application &&
                                    y.objectCode === objectCode,
                            );

                            if (item) {
                                item[scope] = true;
                            }
                        });

                        form.setFieldValue(key, value);
                        const splitTitle = key.split('.');
                        const title = splitTitle[splitTitle.length - 1];

                        return (
                            <CollapsePanel
                                header={capitalize(title || '')}
                                key={key}
                            >
                                <Form form={form} key={`form-${key}`}>
                                    <FormItem
                                        name={key}
                                        key={key}
                                        labelCol={{ span: 0 }}
                                        wrapperCol={{ span: 24 }}
                                        shouldUpdate={(prevValue, currValue) =>
                                            prevValue !== currValue
                                        }
                                    >
                                        <Table
                                            showColumns={false}
                                            showFilter={false}
                                            scroll={{ x: 'max-content' }}
                                            showSearch={false}
                                            columns={columns}
                                            dataSource={value}
                                            pagination={false}
                                            rowKey="objectCode"
                                            style={{ width: '100%' }}
                                        />
                                    </FormItem>
                                </Form>
                            </CollapsePanel>
                        );
                    })}
                </Collapse>
            </Modal>
        </>
    );
};

export default PermissionListComponent;
